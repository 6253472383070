//
// Portion of package containing GET requests for pattern categories.
/////

import axios from 'axios';

export default {

	// As of the time of this writing, retrieves all categories
	categories: (success, payload) => {
		axios.get(`${process.env.VUE_APP_ENDPOINT}/pattern-categories`)
		.then((response) => {

			const categories = [];

			response.data.data.forEach((category) => {
				categories.push({
					id: category.id,
					name: category.attributes.name,
					backgroundColor: category.attributes.background_color,
					textColor: category.attributes.text_color
				});
			});

			success(categories);

		})
		.catch((error) => {

		});

	},

	// Retrieves all categories associated with pattern.
	categoriesFromPattern: (patternID, success, failure) => {
		axios.get(`${process.env.VUE_APP_ENDPOINT}/patterns/${patternID}/pattern-categories`)
		.then((response) => {

			const categories = [];
			const connections = {
				id: patternID,
				categoryIDs: []
			}

			response.data.data.forEach((category) => {

				categories.push({
					id: category.id,
					name: category.attributes.name,
					backgroundColor: category.attributes.background_color,
					textColor: category.attributes.text_color
				});

				connections.categoryIDs.push(category.id);

			});

			success(categories, connections);

		})
		.catch((error) => {

		})
	}

}
