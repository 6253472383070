export default {
	
	methods: {
		
		// Special recursive function.  Used to combine groups specifically into other
		// groups
		combineGroup: function(index) {
		
			// Okay, first, we need to get the group itself.  That can be done by simply
			// assigning whatever's inside cells at the argument index
			const group = this.cells[index];
		
			// Now let's get the index of the first cell with this index.  We can
			// actually do that using some simple math.
			const originIndex = this.calcIndex(group.origin);
		
			// Next, we need to figure out what the adjacent groups are.  We can do that
			// by looking at the group's whole width and whole height
			let widthOffset = Math.floor(group.wholeWidth / this.width);
			let heightOffset = Math.floor(group.wholeHeight / this.height);
		
			// Then get the groups
			let leftGroup = this.cells[originIndex - 1];
			let rightGroup = this.cells[originIndex + (1 * widthOffset)];
			let topGroup = this.cells[originIndex - this.totalColumns];
			let bottomGroup = this.cells[originIndex + (this.totalColumns * heightOffset)];
		
			// Now, let's start with the left group.  Can we combine?
			let combined;
			combined = this.combineGroupLeft(leftGroup, originIndex);
			if (combined) {
				return;
			}
		
			// Still here?  What about the right group?
			combined = this.combineGroupRight(rightGroup, originIndex);
			if (combined) {
				return;
			}
		
			// No?  What about the top
			combined = this.combineGroupTop(topGroup, originIndex);
			if (combined) {
				return;
			}
		
			// Now, then it's bottom or bust
			this.combineGroupBottom(bottomGroup, originIndex);
			return;
		
		},
		
		combineGroupBottom: function(bottomGroup, index) {
		
			const group = this.cells[index];
		
			// Let's also get all the ID values.  The origin ones that is
			const groupChunkX = group.origin.chunkID.x;
			const groupRectX = group.origin.rectID.x;
		
			// Check to see if they're the same color
			if (bottomGroup && bottomGroup.color == group.color) {
		
				// Okay, so they're the same color?  Do they have the same x-origin and
				// the same width?
				const bottomChunkX = bottomGroup.origin.chunkID.x;
				const bottomRectX = bottomGroup.origin.rectID.x;
				if (bottomChunkX == groupChunkX && bottomRectX == groupRectX && bottomGroup.wholeWidth == group.wholeWidth) {
		
					// Cool, we can combine.  Using the calculated originIndex and a newly
					// calculated bottomOriginIndex, cycle through only those cells that are
					// affected.  For the bottomGroup, change wholeWidth to the new values
					// and set it's origin to group's origin.  For THE group, change
					// wholeWidth
					//
					// For select cells, adjust their omit attributes so that borders aren't
					// drawn on their top or bottom sides
		
					const wholeHeight = bottomGroup.wholeHeight + group.wholeHeight;
		
					const bottomIndex = this.calcIndex(bottomGroup.origin);
					const bottomCols = Math.floor(bottomGroup.wholeWidth / 80);
					const bottomRows = Math.floor(bottomGroup.wholeHeight / 40);
					for(let i = 0; i < bottomRows; i ++) {
		
						const startingIndex = bottomIndex + (i * this.totalColumns);
						for(let j = startingIndex; j < (startingIndex + bottomCols); j ++) {
		
							const cell = this.cells[j];
							cell.modified = true;
							cell.origin = group.origin;
							cell.wholeHeight = wholeHeight;
		
							// Are we on the first row?  Then we're also goign to add an omit
							// top
							if (i == 0) {
								cell.omit = cell.omit + '|top';
							}
		
						}
					}
		
					const groupCols = Math.floor(group.wholeWidth / 80);
					const groupRows = Math.floor(group.wholeHeight / 40);
					for(let i = 0; i < groupRows; i ++) {
		
						const startingIndex = index + (i * this.totalColumns);
						for(let j = startingIndex; j < (startingIndex + groupCols); j ++) {
		
							const cell = this.cells[j];
							cell.modified = true;
							cell.wholeHeight = wholeHeight;
		
							// If these cells are on the last row, then make sure they're
							// omitting bottom
							if (i == groupRows - 1) {
								cell.omit = cell.omit + '|bottom';
							}
						}
					}
		
					// Let's see if we can go further
					this.combineGroup(index);
					return true;
				}
		
			}
		
			// Nothing?  Oh well
			return false;
		
		
		},
		
		// Method that checks to see if we can combine a group with the group to the
		// left
		combineGroupLeft: function(leftGroup, index) {
		
			const group = this.cells[index];
		
			// Let's also get all the ID values.  The origin ones that is
			const groupChunkY = group.origin.chunkID.y;
			const groupRectY = group.origin.rectID.y;
		
			if (leftGroup && leftGroup.color == group.color) {
		
				// Same color.  Let's check to make sure they're on the same y-plane
				const leftChunkY = leftGroup.origin.chunkID.y;
				const leftRectY = leftGroup.origin.rectID.y;
				if (leftChunkY == groupChunkY && leftRectY == groupRectY && leftGroup.wholeHeight == group.wholeHeight) {
		
					// Cool, we can combine.  Using the calculated originIndex and a newly
					// calculated leftOriginIndex, cycle through only those cells that are
					// affected.  For the leftGroup, change wholeWidth to
					// the new values.  For THE group, change wholeWidth and give it left's
					// origin.
					//
					// For select cells, adjust their omit
					// attributes so that borders aren't drawn on their left or right sides
					const wholeWidth = leftGroup.wholeWidth + group.wholeWidth;
		
					const leftIndex = this.calcIndex(leftGroup.origin);
					const leftCols = Math.floor(leftGroup.wholeWidth / 80);
					const leftRows = Math.floor(leftGroup.wholeHeight / 40);
					for(let i = 0; i < leftRows; i ++) {
		
						const startingIndex = leftIndex + (i * this.totalColumns);
						for(let j = startingIndex; j < (startingIndex + leftCols); j ++) {
		
							const cell = this.cells[j];
							cell.modified = true;
							cell.wholeWidth = wholeWidth;
		
							// If this cell is the last cell on the row, we need to tell it to
							// not join on the right
							if ((j + 1) == (startingIndex + leftCols)) {
								cell.omit = cell.omit + '|right';
							}
		
						}
					}
		
					const groupCols = Math.floor(group.wholeWidth / 80);
					const groupRows = Math.floor(group.wholeHeight / 40);
					for(let i = 0; i < groupRows; i ++) {
		
						const startingIndex = index + (i * this.totalColumns);
						for(let j = startingIndex; j < (startingIndex + groupCols); j ++) {
		
							const cell = this.cells[j];
							cell.modified = true;
							cell.wholeWidth = wholeWidth;
							cell.origin = leftGroup.origin;
		
							// If this was the first cell on the row, we need to modify omit so
							// that we omit borders on the left side
							if (j == startingIndex) {
								cell.omit = cell.omit + '|left';
							}
						}
					}
		
					// Now let's call combineGroup again to see if we can keep up the fun
					this.combineGroup(index);
					return true;
		
				}
		
			}
		
			// Got this far?  Guess there was nothing to combine
			return false;
		
		},
		
		combineGroupRight: function(rightGroup, index) {
		
			const group = this.cells[index];
		
			// Let's also get all the ID values.  The origin ones that is
			const groupChunkY = group.origin.chunkID.y;
			const groupRectY = group.origin.rectID.y;
		
			// Compare colors
			if (rightGroup && rightGroup.color == group.color) {
		
				// Maybe, make sure they're on the same y-plane and have the same height
				const rightChunkY = rightGroup.origin.chunkID.y;
				const rightRectY = rightGroup.origin.rectID.y;
				if (rightChunkY == groupChunkY && rightRectY == groupRectY && rightGroup.wholeHeight == group.wholeHeight) {
		
					// Cool, we can combine.  Using the calculated originIndex and a newly
					// calculated rightOriginIndex, cycle through only those cells that are
					// affected.  For the rightGroup, change wholeWidth to the new values
					// and set their origin to group's origin.  For THE group, change
					// wholeWidth
					//
					// For select cells, adjust their omit
					// attributes so that borders aren't drawn on their left or right sides
					const wholeWidth = rightGroup.wholeWidth + group.wholeWidth;
		
					const rightIndex = this.calcIndex(rightGroup.origin);
					const rightCols = Math.floor(rightGroup.wholeWidth / 80);
					const rightRows = Math.floor(rightGroup.wholeHeight / 40);
					for(let i = 0; i < rightRows; i ++) {
		
						const startingIndex = rightIndex + (i * this.totalColumns);
						for(let j = startingIndex; j < (startingIndex + rightCols); j ++) {
		
							const cell = this.cells[j];
							cell.modified = true;
							cell.wholeWidth = wholeWidth;
							cell.origin = group.origin;
		
							// If this cell is the first cell on the row, we need to tell it to
							// not join on the left
							if (j == startingIndex) {
								cell.omit = cell.omit + '|left';
							}
		
						}
					}
		
					// Now the group's cells
					const groupCols = Math.floor(group.wholeWidth / 80);
					const groupRows = Math.floor(group.wholeHeight / 40);
					for(let i = 0; i < groupRows; i ++) {
		
						const startingIndex = index + (i * this.totalColumns);
						for(let j = startingIndex; j < (startingIndex + groupCols); j ++) {
		
							const cell = this.cells[j];
							cell.modified = true;
							cell.wholeWidth = wholeWidth;
		
							// If this cell is the last cell on the row, we need to tell it to
							// not join on the right
							if ((j + 1) == (startingIndex + groupCols)) {
								cell.omit = cell.omit + '|right';
							}
						}
					}
		
					// Alight, they're combined.  Let's see if we can do it again
					this.combineGroup(index);
					return true;
				}
			}
		
			// Otherwise, if we got this far, then we couldn't combine
			return false;
		
		},
		
		combineGroupTop: function(topGroup, index) {
		
			const group = this.cells[index];
		
			// Let's also get all the ID values.  The origin ones that is
			const groupChunkX = group.origin.chunkID.x;
			const groupRectX = group.origin.rectID.x;
		
			// Alright, top group?
			if (topGroup && topGroup.color == group.color) {
		
				// Maybe, let's make sure they both have the same x-origin and have the
				// same width
				const topChunkX = topGroup.origin.chunkID.x;
				const topRectX = topGroup.origin.rectID.x;
				if (topChunkX == groupChunkX && topRectX == groupRectX && topGroup.wholeWidth == group.wholeWidth) {
		
					// Cool, we can combine.  Using the calculated originIndex and a newly
					// calculated topOriginIndex, cycle through only those cells that are
					// affected.  For the topGroup, change wholeHeight to the new values.
					// For THE group, change wholeHeight and change the origin to topGroup's
					//
					// For select cells, adjust their omit
					// attributes so that borders aren't drawn on their top or bottom sides
					const wholeHeight = topGroup.wholeHeight + group.wholeHeight;
		
					const topIndex = this.calcIndex(topGroup.origin);
					const topCols = Math.floor(topGroup.wholeWidth / 80);
					const topRows = Math.floor(topGroup.wholeHeight / 40);
					for(let i = 0; i < topRows; i ++) {
		
						const startingIndex = topIndex + (i * this.totalColumns);
						for(let j = startingIndex; j < (startingIndex + topCols); j ++) {
		
							const cell = this.cells[j];
							cell.modified = true;
							cell.wholeHeight = wholeHeight;
		
							// If we're on the last row, then instruct this cell to omit the
							// bottom borderr
							if (i == topRows - 1) {
								cell.omit = cell.omit + '|bottom';
							}
		
						}
					}
		
					// Now the group's cells
					const groupCols = Math.floor(group.wholeWidth / 80);
					const groupRows = Math.floor(group.wholeHeight / 40);
					for(let i = 0; i < groupRows; i ++) {
		
						const startingIndex = index + (i * this.totalColumns);
						for(let j = startingIndex; j < (startingIndex + groupCols); j ++) {
		
							const cell = this.cells[j];
							cell.modified = true;
							cell.origin = topGroup.origin;
							cell.wholeHeight = wholeHeight
		
							// If this is the first row, then instruct the cell to omit top
							if (i == 0) {
								cell.omit = cell.omit + '|top';
							}
						}
					}
		
					// Let's try and keep it up
					this.combineGroup(index);
					return true;
		
				}
			}
		
			// No match?  Oh well
			return false;
		
		},
		
		// Called when we add a new rect and it didn't combine vertically.  Attempts
		// to combine the rect with it's horizontal neighbors
		combineX: function(index) {
		
			// Alright, let's assign our rect to a local let for easy access
			let rect = this.cells[index];
		
			// Go ahead and prep some consts too for it's various ID values
			const rectChunkY = rect.origin.chunkID.y;
			const rectIDY = rect.origin.rectID.y;
		
			// And let's grab the rects directly adjacent to it on the x-axis
			let leftRect = this.cells[index - 1];
			let rightRect = this.cells[index + 1];
		
			// Left rect first, see if they have the same color.
			if (leftRect && leftRect.color == rect.color) {
		
				// Good, now make sure that they are still on the same y-plane and that
				// their height is identical
				const leftRectIDY = leftRect.origin.rectID.y;
				const leftChunkY = leftRect.origin.chunkID.y;
		
				if (leftRectIDY == rectIDY && leftChunkY == rectChunkY && leftRect.wholeHeight == rect.wholeHeight) {
		
					// Great, we're gonna combine them.  To do that, change their whole
					// width attributes to reflect their new combined size.
					// Change origin of the rect so that it's new origin is that of the
					// leftRect's
					leftRect.modified = true;
					rect.modified = true;
					const wholeWidth = leftRect.wholeWidth + rect.width;
		
					// Change the rect itself real fast
					rect.origin = leftRect.origin;
					rect.wholeWidth = wholeWidth;
					rect.omit = 'left';
		
					// Now change all rects bundled together with the left rect
					const leftIndex = this.calcIndex(leftRect.origin);
					const leftCols = Math.floor(leftRect.wholeWidth / 80);
					const leftRows = Math.floor(leftRect.wholeHeight / 40);
					for(let i = 0; i < leftRows; i ++) {
		
						const startingIndex = leftIndex + (i * this.totalColumns);
						for(let j = startingIndex; j < (startingIndex + leftCols); j ++) {
		
							const cell = this.cells[j];
							cell.wholeWidth = wholeWidth;
		
						}
					}
		
					// Now modify the left rect's omit attribute itself to omit the border
					// on the right side
					leftRect.omit = leftRect.omit == '' ? 'right' : leftRect.omit + '|right';
		
					// Now let's see if we can go deeper
					this.combineGroup(index);
					return true;
		
				}
		
			}
		
			// And if not the left rect, then what of the right rect?
			if (rightRect && rightRect.color == rect.color) {
		
				// As with the left rect, see if they're on the same y-plane with respect
				// to chunks and IDs and they have the same height
				let rightChunkY = rightRect.origin.chunkID.y;
				let rightRectY = rightRect.origin.rectID.y;
				if (rightRectY == rectIDY && rightChunkY == rectChunkY && rightRect.wholeHeight == rect.wholeHeight) {
		
					// We can combine.  We're gonna use the same approach as before, just
					// with some minor adjustments
					rect.modified = true;
					rightRect.modified = true;
					const wholeWidth = rect.width + rightRect.wholeWidth;
		
					rect.wholeWidth = wholeWidth;
					rect.omit = 'right';
		
					// For the right rect, assume it may be part of a group.  Get the index
					// for the origin, and then cycle through all cells
					const rightIndex = this.calcIndex(rightRect.origin);
					const rightCols = Math.floor(rightRect.wholeWidth / 80);
					const rightRows = Math.floor(rightRect.wholeHeight / 40);
					for(let i = 0; i < rightRows; i ++) {
		
						const startingIndex = rightIndex + (i * this.totalColumns);
						for(let j = startingIndex; j < (startingIndex + rightCols); j ++) {
		
							const cell = this.cells[j];
							cell.wholeWidth = wholeWidth;
							cell.origin = rect.origin;
		
						}
					}
		
					// Now let's modify the rightRect itself, namely it's omit attribute,
					// so that it doesn't draw a border on the left
					rightRect.omit = rightRect.omit == '' ? 'left' : rightRect.omit + '|left';
		
					// Let's see if we can go deeper
					this.combineGroup(index);
					return true;
		
				}
		
			}
		
			// Got this far?  Guess we couldn't find anything to combine with
			return false;
		
		},
		
		// Called anytime we add a new rect.  It will take said rect and look at any
		// of the adjacent rects to see if it can be combined into a rect group.
		combineY: function(index) {
		
			// First, assign our rect to a local let for easy access
			let rect = this.cells[index];
		
			// Go ahead and prep some consts too for it's various ID values
			const rectChunkX = rect.origin.chunkID.x;
			const rectChunkY = rect.origin.chunkID.y;
			const rectIDX = rect.origin.rectID.x;
		
			// Now let's go ahead and assign any potential adjacent rects into some lets
			// as well
			let topRect = this.cells[index - this.totalColumns];
			let bottomRect = this.cells[index + this.totalColumns];
		
			// Start with the top, see if we have a match
			if (topRect && topRect.color == rect.color) {
		
				// Alright, make sure they're on the same x-plane.  compare chunk and
				// rect x-values
				const topChunkX = topRect.origin.chunkID.x;
				const topRectX = topRect.origin.rectID.x;
				if (topChunkX == rectChunkX && topRectX == rectIDX && topRect.wholeWidth == rect.wholeWidth) {
		
					// Combining them.  Give rect topRect's origin, and combine heights
					// together for each
					topRect.modified = true;
					rect.modified = true;
					const wholeHeight = rect.wholeHeight + topRect.wholeHeight;
		
					rect.origin = topRect.origin;
					rect.wholeHeight = wholeHeight;
					rect.omit = 'top';
		
					// Top rect is trickier since it may be part of a group.  Get index
					// for origin, calculate rows, then cycle through
					const topIndex = this.calcIndex(topRect.origin);
					const topCols = Math.floor(topRect.wholeWidth / 80);
					const topRows = Math.floor(topRect.wholeHeight / 40);
					for(let i = 0; i < topRows; i ++) {
		
						const startingIndex = topIndex + (i * this.totalColumns);
						for(let j = startingIndex; j < (startingIndex + topCols); j ++) {
		
							const cell = this.cells[j];
							cell.wholeHeight = wholeHeight;
		
						}
					}
		
					// Finally, for the top rect itself, change it's omit attribute so that
					// no border is drawn along the bottom
					topRect.omit = topRect.omit == '' ? 'bottom' : topRect.omit + '|bottom';
		
					// And return early, we've made or combination
					this.combineGroup(index);
					return true;
		
				}
		
			}
		
			// And let's see if bottomRect is a match
			if (bottomRect && bottomRect.color == rect.color) {
		
				// Okay, matching colors.  See if they're the same width and are on the
				// same x-plane
				const bottomChunkX = bottomRect.origin.chunkID.x;
				const bottomRectX = bottomRect.origin.rectID.x;
				if (bottomChunkX == rectChunkX && bottomRectX == rectIDX && bottomRect.wholeWidth == rect.wholeWidth) {
		
					// We can combine.  Same rules more-or-less as top, just switching
					// things around a bit
					bottomRect.modified = true;
					rect.modified = true;
					const wholeHeight = rect.wholeHeight + bottomRect.wholeHeight;
		
					rect.wholeHeight = wholeHeight;
					rect.omit = 'bottom';
		
					// Bottom rect is tricky as it may be part of a group.  Get the origin
					// index and the rows and cols
					const bottomIndex = this.calcIndex(bottomRect.origin);
					const bottomCols = Math.floor(bottomRect.wholeWidth / 80);
					const bottomRows = Math.floor(bottomRect.wholeHeight / 40);
					for(let i = 0; i < bottomRows; i ++) {
		
						const startingIndex = bottomIndex + (i * this.totalColumns);
						for(let j = startingIndex; j < (startingIndex + bottomCols); j ++) {
		
							const cell = this.cells[j];
							cell.origin = rect.origin;
							cell.wholeHeight = wholeHeight;
		
						}
					}
		
					// Make sure we change the bottomRect itself to have an omit attribute
					bottomRect.omit = bottomRect.omit == '' ? 'top' : bottomRect.omit + '|top';
		
					// See if we can dive deeper
					this.combineGroup(index);
					return true;
		
				}
		
			}
		
			return false;
		
		}
	}
}