//
// Mixin for the min-max stuff for blobs.  Made a mixin partly because of
// complexity, but mostly because Rects needs this info as well, and I figured 
// it would be easier to make it a reusable mixin rather than for Blob to
// share this data with Rects
/////

export default {
	
	computed: {
		
		maxX: function() {
		
			let maxX;
		
			this.initialChunks.forEach((chunk) => {
				if (maxX === undefined || chunk.id.x > maxX) {
					maxX = chunk.id.x;
				}
			});
		
			return maxX;
		
		},
		
		minX: function() {
		
			let minX;
		
			this.initialChunks.forEach((chunk) => {
				if (minX === undefined || chunk.id.x < minX) {
					minX = chunk.id.x;
				}
			});
		
			return minX;
		
		},
		
		// Simply retrieves the largest chunkY in the chunks
		maxY: function() {
		
			let maxY;
		
			this.initialChunks.forEach((chunk) => {
				if (maxY === undefined || chunk.id.y > maxY) {
					maxY = chunk.id.y;
				}
			});
		
			return maxY;
		
		},
		
		// Like maxY, only for minimums obviously
		minY: function() {
		
			let minY;
		
			this.initialChunks.forEach((chunk) => {
				if (minY === undefined || chunk.id.y < minY) {
					minY = chunk.id.y;
				}
			});
		
			return minY;
			
		},

	}
}