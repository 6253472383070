//
// A mixin specifically for a methods that responds to color changes to a color
// group.  This assumes that parsedSVG is present somewhere in the component.
// It takes the svg and replaces fills as necessary.
/////

export default {

	methods: {

		// Responds to updates to a color group.  This will update the SVG preview
		// client-side
		handleColor: function(group, color) {

			const groupRegExp = /<g[^>]*>((?!<\/g>)\s|(?!<\/g>).)*<\/g>/g;
			const IDRegExp = new RegExp(`id="${group.name}"`, 'i');
			const fillRegExp = /fill="#([a-f0-9]{0,6})"|style="[^"]*fill:#([a-f0-9]{0,6})[^"]*"/i;

			// To make this work, let's take our time and reuse what we have.  Start
			// by just getting the group
			const groups = this.parsedSVG.match(groupRegExp);
			for(let i = 0; i < groups.length; i ++) {

				let group = groups[i];

				// Now, go through each group one by one and see if it has an ID that
				// matches the arg color's name.
				const groupIDMatch = group.match(IDRegExp);
				if (groupIDMatch) {

					// Okay, this is the color group being updated.  Let's change its fill
					// using the replace method and the above fill regexp
					const newGroup = group.replace(fillRegExp, `fill="#${color}"`);

					// Then, replace the group substring in our parsedSVG with the
					// newGroup substring.  Wasteful, but save the elegance for when we
					// have the time
					this.parsedSVG = this.parsedSVG.replace(group, newGroup);
					return;

				}
			}
		}

	}
}
