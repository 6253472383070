//
// Draws color queue for a mobile context.  By this I mean it's drawn at the
// very bottom of the page, so that the user can physically tap on the color
// they want to pick in the queue when it's powered up.
//////

function mobileDraw(ball, sketch) {

	// Let's go ahead and take a look at the width.  If it's under 400, then
	// well divide it by 4.  Otherwise, we'll plan for 4 100x100 cells, and
	// they'll be along the bottom left unless the user indicates they want them
	// along the bottom right.
	let cellWidth = 80;
	let padding = 0;
	if (sketch.width < 320) {
		cellWidth = sketch.width / 4;
	} else {
		padding = (sketch.width - 320) / 2 - 2.5;
	}

	// Next, white out the previous stuff
	sketch.fill(255, 255, 245);
	sketch.strokeWeight(0);
	sketch.rect(
		sketch.width - cellWidth * 4 - 40,
		sketch.height - cellWidth - 40,
		cellWidth * 4 + 80,
		cellWidth + 80
	)

	// Is the ball pinned?  In which case we actually simplify things
	// greatly.  We simply draw a bar single bar with the first color in the
	// color queue and three arrows pointing up, indicating that tapping on
	// the bar will launch the ball
	if (ball.pinned) {

		sketch.fill('#FFE0D8')
		sketch.strokeWeight(0);
		sketch.stroke(255, 255, 245)
		sketch.rect(
			sketch.width - cellWidth * 2 + 10 - padding,
			sketch.height - cellWidth + 5,
			cellWidth * 2- 14,
			cellWidth - 14,
			5,
			5,
			5,
			5,
		)

		// Draw our rect
		sketch.fill('#FFFFFF')
		sketch.strokeWeight(8);
		sketch.stroke(0);
		sketch.rect(
			sketch.width - cellWidth + 5 - padding,
			sketch.height - cellWidth + 5,
			cellWidth - 14,
			cellWidth - 14
		)

		sketch.strokeWeight(8);
		sketch.stroke(0);

		// Create masked color
		const maskedColor = this.colors[0].img.get();
		maskedColor.mask(this.mask);

		// Draw ball
		sketch.ellipse(
			sketch.width - cellWidth * 2 + cellWidth / 2 - padding + 6,
			sketch.height - (cellWidth / 2) - 2,
			30,
			30);
		sketch.image(maskedColor,
			sketch.width - cellWidth * 2 + cellWidth / 2 - 26 / 2 - padding + 6,
			sketch.height - (cellWidth / 2) - 26/2 - 2,
			26,
			26);

		sketch.strokeCap(sketch.PROJECT);
		sketch.line(
			sketch.width - cellWidth + cellWidth / 2 - padding - 1,
			sketch.height - cellWidth + 30 - 3,
			sketch.width - cellWidth + cellWidth / 2 - padding - 1,
			sketch.height - cellWidth + 60 - 3
		);

		sketch.line(
			sketch.width - cellWidth + cellWidth / 2 - 15 - padding - 1,
			sketch.height - cellWidth / 2 - 3 - 3,
			sketch.width - cellWidth + cellWidth / 2 - padding - 1,
			sketch.height - cellWidth + 22 - 3
		);

		sketch.line(
			sketch.width - cellWidth + cellWidth / 2 + 15 - padding - 1,
			sketch.height - cellWidth / 2 - 3 - 3,
			sketch.width - cellWidth + cellWidth / 2 - padding - 1,
			sketch.height - cellWidth + 22 - 3
		);

		return;

	}

	// Otherwise, Let's draw some lightly-colored red, yellow, and blue rounded rects
	// along the bottom, followed by a rect which contains an arrow, with a
	// circle green backdrop if powered up
	sketch.strokeWeight(0);
	sketch.stroke(255, 255, 245);
	for(let i = 0; i < 4; i ++) {

		switch(i) {

			case 0:
				sketch.fill('#D7DAED');
				break;
			case 1:
				sketch.fill('#FFFAE5');
				break;
			case 2:
				sketch.fill('#FFEAE5');
				break;
			case 3:
				sketch.fill('#E9FFE5');
				break;

		}

		sketch.rect(
			sketch.width - (i + 1) * cellWidth - padding - 5,
			sketch.height - cellWidth + 5,
			cellWidth + 5,
			cellWidth - 15,
			5,
			5,
			5,
			5
		);

	}

	// This rect contains our indicator arrow, unless we have activated the
	// power up.  Instead, it draws itself around whichever ball is closest to
	// the current mouse pos on the x-axis
	sketch.fill('#FFFFFF')
	sketch.strokeWeight(8);
	sketch.stroke(0);

	if (ball.poweringUp) {

		// Okay, so let's assign the 4 possible positions into an array and
		// compare mouseX with each of them.  The smallest absolute value is the
		// one we're going to draw to
		const positions = [
			sketch.width - cellWidth + cellWidth / 2 + 5 - padding,
			sketch.width - cellWidth * 2 + cellWidth / 2 + 5 - padding,
			sketch.width - cellWidth * 3 + cellWidth / 2 + 5 - padding,
			sketch.width - cellWidth * 4 + cellWidth / 2 + 5 - padding
		];

		let delta = 1000;
		let position = 0;
		positions.forEach((pos, index) => {

			const currentDelta = Math.abs(sketch.mouseX - pos);
			if (currentDelta < delta) {
				delta = currentDelta;
				position = pos - cellWidth / 2;
				ball.choice = 3 - index;
			}

		});

		switch(ball.choice) {

			case 1:
				sketch.fill('#FF6D4C');
				break;
			case 2:
				sketch.fill('#FFDE4C');
				break;
			case 3:
				sketch.fill('#576BED');
				break;
			case 0:
				sketch.fill('#5FC43A');
				break;

		}

		sketch.rect(
			position,
			sketch.height - cellWidth + 5,
			cellWidth - 14,
			cellWidth - 14
		);

		sketch.fill('#FFFFFF')

	} else {

		if (this.powerUpMouse) {
			sketch.fill('#5FC43A');
		} else {
			sketch.fill('#FFFFFF');
		}

		sketch.rect(
			sketch.width - cellWidth * 4 + 5 - padding,
			sketch.height - cellWidth + 5,
			cellWidth - 14,
			cellWidth - 14
		);

	}

	// Now for the colors
	sketch.strokeWeight(4);
	sketch.stroke(0);
	this.colors.forEach((color, index) => {

		// Create masked color
		const maskedColor = color.img.get();
		maskedColor.mask(this.mask);

		// Draw ball
		sketch.ellipse(
			sketch.width - (4 - (index + 1)) * cellWidth + cellWidth / 2 - padding - 2,
			sketch.height - (cellWidth / 2) - 2,
			30,
			30);
		sketch.image(maskedColor,
			sketch.width - (4 - (index + 1)) * cellWidth + cellWidth / 2 - 26 / 2 - padding - 2,
			sketch.height - (cellWidth / 2) - 26/2 - 2,
			26,
			26);

	});

	// Finally, in the last cell point our indicator arrow
	sketch.strokeWeight(6);
	sketch.strokeCap(sketch.PROJECT);

	if (this.powerUpMouse) {

		sketch.stroke(255);

	}

	sketch.line(
		sketch.width - 4 * cellWidth + 25 - padding - 3,
		sketch.height - cellWidth / 2 - 2,
		sketch.width - 4 * cellWidth + 50 - padding - 3,
		sketch.height - cellWidth / 2 - 2
	);

	sketch.line(
		sketch.width - 4 * cellWidth + 42.5 - padding - 3,
		sketch.height - cellWidth / 2 - 12.5 - 2,
		sketch.width - 4 * cellWidth + 55 - padding - 3,
		sketch.height - cellWidth / 2 - 2
	);

	sketch.line(
		sketch.width - 4 * cellWidth + 42.5 - padding - 3,
		sketch.height - cellWidth / 2 + 12.5 - 2,
		sketch.width - 4 * cellWidth + 55 -padding - 3,
		sketch.height - cellWidth / 2 - 2
	);
}

function mobileMouse(ball, paddle, sketch) {

	let cellWidth = 80;
	let padding = 0;
	if (sketch.width < 320) {
		cellWidth = sketch.width / 4;
	} else {
		padding = (sketch.width - 320) / 2 - 2.5;
	}

	// Let's first make sure the mouse is within range
	if (sketch.mouseX < padding || sketch.mouseX > padding + cellWidth * 4) {
		return false;
	}

	if (sketch.mouseY < sketch.height - cellWidth) {
		return false;
	}

	// Otherwise, looks like we're within the range.  First, if the ball is
	// pinned, a mousePressed or touchEvent will release the ball
	if (ball.pinned) {

		ball.unpin(paddle,sketch);
		return true;

	}

	// Otherwise, if we haven't activated the powerup, then we'll go ahead and
	// slow the ball.  We don't need to do anything other than return true,
	// for now.
	if (this.powerUpMouse) {
		ball.activatePowerUp();
		return true;
	}

	return false;
}

export { mobileDraw, mobileMouse };
