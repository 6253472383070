<!-- A page for creating a new pattern -->
<template>

	<div class="new-pattern">

		<div style="width: 100%">

			<EditablePatternName
				v-model="name"
				@save="savePattern"/>

			<div class="action">

				<div class="pattern">

					<PatternUploader
						:loading="loading"
						@svg="handleUpload"/>

				</div>

				<!-- Finally a list of colors used by the pattern -->
				<div class="colors">
					<ColorList :colors="colors"/>
				</div>

			</div>

		</div>

	</div>

</template>

<script>
import ColorList from '../components/colors/ColorList.vue';
import EditablePatternName from '../components/patterns/EditablePatternName.vue';
import PatternRequest from '../network/patterns';
import PatternUploader from '../components/PatternUploader.vue';
export default {
	name: "NewPattern",
	components: { ColorList, EditablePatternName, PatternUploader },
	data: function() {
		return {
			colors: [],
			loading: false,
			name: '',
			pattern: "",
			svg: null,
		}
	},

	methods: {

		// When the user uploads a file, presumably an SVG, we make an effort at
		// both reading it not as an image, but as text.
		readSVG: function(evt) {

			evt.preventDefault();
			this.svg = evt.srcElement.files[0];

			// Go ahead and set up our regular expressions.  We only care about groups
			const groupRegExp = /<g[^>]*>((?!<\/g>)\s|(?!<\/g>).)*<\/g>/g;
			const groupOpeningOnlyRegExp = /<g (.*)\>/g;
			const IDRegExp = /id="(.*)"/i;
			const fillRegExp = /fill="(#[a-f0-9]{0,6})"|style="[^"]*fill:(#[a-f0-9]{0,6})[^"]*/i;
			const backgroundRegExp = /(background)|(bg)/i;

			// Go ahead and generate a preview of it and read the colors
			let reader = new FileReader();
			reader.addEventListener("load", (evt) => {

				// Here's the pattern, as raw text
				this.pattern = evt.target.result;

				// Now, then, let's pick apart each group and generate palettes based on
				// whatever fill is present, and ideally name the palettes
				const groups = this.pattern.match(groupRegExp);
				if (groups == null) { return; };

				for(let i = 0; i < groups.length; i ++) {

					const group = groups[i];
					let groupID;
					let groupColor;
					let isBackground = false;

					const groupIDMatch = group.match(IDRegExp);
					if (groupIDMatch) {

						groupID = groupIDMatch[1];

						// A special case is if the group in question is called "background"
						// or "bg", because background groups are special.  Flag if so
						const backgroundMatch = groupID.match(backgroundRegExp);
						if (backgroundMatch) {
							isBackground = true;
						}

					}

					const groupColorMatch = group.match(fillRegExp);
					if (groupColorMatch) {
						// So, the color is in there somewhere, but it could be in index 1
						// or two.  We'll default to the first if it exists, but settle for
						// the second
						groupColor = groupColorMatch[1] ? groupColorMatch[1] : groupColorMatch[2];
					}

					// That's all we need.  So, let's create a new color using the
					// information provided
					this.colors.push({
						name: groupID,
						color: groupColor,
						isBackground
					});

				}

				// Once we're done here, go ahead and save what info we have about the
				// pattern if necessary and upload the SVG
				this.savePattern();

			}, false);
			reader.readAsText(this.svg);

		},

		// Handles events from pattern uploader.  Updates pattern with new colors
		// and uploads the svg
		handleUpload: function(event) {
			this.colors = event.colors;
			this.svg = event.file;
			this.savePattern();
		},

		// Uploads an SVG to the server.  Only called if we have an actual pattern
		// to work with
		saveSVG: function(svg, id) {

			console.log('save');
			let _this = this;
			PatternRequest.postSVG(id, svg, (response) => {
				console.log('saved');
				_this.loading = false;
			});

		},

		// Using the information available, will try to either create a new pattern
		// on the server or change an existing one
		savePattern: function() {

			let data = {
				data: {
					type: "pattern",
					attributes: {
						name: this.name
					},
					relationships: {
						pattern_color: {
							data: []
						}
					}
				},
				included: []
			}

			this.colors.forEach((color, index) => {

				data.data.relationships.pattern_color.data.push({
					id: `${index}`,
					type: "pattern_color"
				});

				data.included.push({
					id: `${index}`,
					type: "pattern_color",
					attributes: {
						name: color.name,
						color: color.color,
						background: color.isBackground
					}
				});
			});

			// Alright, let's stringify
			let payload = JSON.stringify(data);

			// And send
			let _this = this;
			this.loading = true;
			PatternRequest.postPattern(payload, (pattern, colors) => {

				// Once you're done, save the svg as well
				_this.saveSVG(_this.svg, pattern.id);

			});

		}

	}
}
</script>

<style lang="scss" scoped>
.new-pattern {
	display: flex;
	align-items: center;
	width: 100%;
	height: 100vh;
}

.action {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: stretch;
}

.pattern, .colors {
	width: 30%;
	margin: 12px;
}

.pattern {
	display: flex;
	align-items: center;
	justify-content: center;

	&:after {
		content: "";
		padding-bottom: 100%;
		display: block;
	}

}

.editable-pattern-name {
	width: 60%;
	padding: 0 -24px;
	margin: 0 auto;
}

.color-list {
	height: 100%;
	overflow-y: scroll;
}
</style>
