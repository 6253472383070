//
// Portion of package for managing level colors state
/////

export default {

	addLevelColors: (state, payload) => {

		payload.forEach((color) => {

			for(let i = 0; i < state.levelColors.length; i ++) {

				if (state.levelColors[i].id == color.id) {
					state.levelColors.splice(i, 1, color);
					return;
				}

			}

			state.levelColors.push(color);

		});

	},

	// Special state method.  When we're working with randomly generated level
	// colors and we opt to get a new pattern, we want to reset its colors.
	removeLevelColors: (state, assignmentID) => {

		for(let i = 0; i < state.levelColors.length; i ++) {

			if (state.levelColors[i].assignmentID == assignmentID) {
				console.log('remove');
				state.levelColors.splice(i, 1);
				i --;
			}

		}

	}
}
