<!-- A modal for displaying ALL pattern categories with the intent to pick one
	of them for a pattern.  Can also switch view to create a pattern. -->
<template>
	<Modal title="Pattern Categories">

		<!-- Default view.  Displays all pattern categories, lets you pick one. -->
		<PickPatternCategory v-if="mode == 'pick'"
			:patternID="props.pattern"
			@new="switchToNew"
			@edit="editCategory"/>

		<NewPatternCategory  v-else-if="mode == 'new'"
			:category="category"
			:patternID="props.pattern"
			:pre-name="name"
			@pick="mode = 'pick'"/>

	</Modal>
</template>

<script>
import Modal from '../../modals/Modal.vue';
import NewPatternCategory from '../NewPatternCategory.vue';
import PickPatternCategory from '../PickPatternCategory.vue';
export default {
	name: "ModalPatternCategories",
	components: { Modal, NewPatternCategory, PickPatternCategory },
	props: ['props'],

	data: function() {
		return {
			category: null,
			mode: 'pick',
			name: ''
		}
	},

	methods: {

		editCategory: function(category) {
			this.category = category;
			this.mode = 'new';
		},

		switchToNew: function(name) {
			this.name = name;
			this.mode = 'new';
		}

	}
}
</script>
