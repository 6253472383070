//
// Manages store for orders
/////

export default {

	addOrders: (state, payload) => {

		payload.forEach((order) => {

			for(let i = 0; i < state.orders.length; i ++) {
				if (state.orders[i].id == order.id) {

					state.orders.splice(i, 1, order);
					return;

				}
			}

			state.orders.push(order);

		});

	}
}
