//
// Portion of package containing methods for making DELETE requests.
/////

import axios from 'axios';

export default {

	// Removes a pattern from a given level
	patternFromLevel: (ids, success, failure) => {
		axios.delete(`${process.env.VUE_APP_ENDPOINT}/levels/${ids.levelID}/patterns/${ids.assignmentID}`, {
			headers: {"Authorization": "Bearer " + localStorage.getItem('token')}
		})
		.then((response) => {
			success();
		})
		.catch((error) => {

		})
	}
}
