<!-- Page to view webhook configuration and change if necessary. -->
<template>
	<div>
		<h1 class="text-center">Webhooks</h1>

		<p v-if="endpoint == ''" class="text-center">Webhooks have not been enabled yet.  To enable them,
			simply select some event types to track below and click the
			<strong>Enable Webhooks</strong> button.</p>

		<div class="form-container">

			<form @submit.prevent="changeWebhooks" class="thick-border">

				<Checkbox checkboxId="orderFailed" label="Order Failed" v-model="orderFailed" />

				<Checkbox checkboxId="orderHold" label="Order Hold" v-model="orderHold" />

				<Checkbox checkboxId="orderRemoveHold" label="Order Remove Hold" v-model="orderRemoveHold" />

				<Checkbox checkboxId="packageReturned" label="Package Returned" v-model="packageReturned" />

				<Checkbox checkboxId="packageShipped" label="Package Shipped" v-model="packageShipped" />

				<button type="submit" class="block-button">
					Enable Webhooks
				</button>

			</form>

		</div>

	</div>
</template>

<script>
import Checkbox from '../components/forms/Checkbox.vue';
import WebhookRequest from '../network/webhooks'
export default {
	name: "Webhooks",
	components: { Checkbox },
	data: function() {
		return {
			endpoint: '',

			orderFailed: false,
			orderHold: false,
			orderRemoveHold: false,
			packageReturned: false,
			packageShipped: false,

			params: [],
		}
	},

	methods: {

		// Takes whatever's currently checked and uses that to update our webhooks
		changeWebhooks: function() {

			const data = {
				types: []
			}

			if (this.orderFailed) {
				data.types.push('order_failed')
			}

			if (this.orderHold) {
				data.types.push('order_put_hold');
			}

			if (this.orderRemoveHold) {
				data.types.push('order_remove_hold');
			}

			if (this.packageReturned) {
				data.types.push('package_returned');
			}

			if (this.packageShipped) {
				data.types.push('package_shipped');
			}

			const payload = JSON.stringify(data);

			WebhookRequest.changeConfig(payload, () => {});

		}

	},

	mounted: function() {

		const _this = this;
		WebhookRequest.getConfig((config) => {
			_this.endpoint = config.url;

			if (config.types) {

				config.types.forEach((eventType) => {

					switch(eventType) {

						case "order_failed":
							_this.orderFailed = true;
							return;

						case "order_put_hold":
							_this.orderHold = true;
							return;

						case "order_remove_hold":
							_this.orderRemoveHold = true;
							return;

						case "package_returned":
							_this.packageReturned = true;
							return;

						case "package_shipped":
							_this.packageShipped = true;
							return;

					}

				});

			}

			//_this.types = config.types;
			_this.params = config.params;

		});

	}
}
</script>

<style lang="scss" scoped>
.form-container {

	form {
		max-width: 40rem;
		margin: 0 auto;
		padding: 1.2rem;

		button {
			margin-top: 20px;
		}
	}
}
</style>
