//
// Portion of package for making PUT requests involving colors.
/////

import axios from 'axios';

export default {

	color: (colorID, payload, success, failure) => {
		axios.put(`${process.env.VUE_APP_ENDPOINT}/pattern-colors/${colorID}`, payload)
		.then((response) => {

		})
		.catch((error) => {
			// TOOD
		})
	}
}
