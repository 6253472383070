//
// Portion of package handling GET requests.
/////

import axios from 'axios';

export default {

	order: (orderID, success, failure) => {
		axios.get(`${process.env.VUE_APP_ENDPOINT}/orders/${orderID}`, {
			headers: {"Authorization": "Bearer " + localStorage.getItem('token')}
		})
		.then((response) => {

			success({
				id: response.data.data.id,
				status: response.data.data.attributes.status,
				previewURL: response.data.data.attributes.preview_url,
				shippingName: response.data.data.attributes.name,
				shippingStreet: response.data.data.attributes.shipping_street,
				shippingUnit: response.data.data.attributes.shipping_unit,
				shippingCity: response.data.data.attributes.shipping_city,
				shippingState: response.data.data.attributes.state,
				shippingZip: response.data.data.attributes.zip,
			});

		})
		.catch((error) => {

		})
	},

	// Retrieves a number of orders from the user.
	orders: (success, failure) => {
		axios.get(`${process.env.VUE_APP_ENDPOINT}/orders`, {
			headers: {"Authorization": "Bearer " + localStorage.getItem('token')}
		})
		.then((response) =>{

			const orders = [];

			response.data.data.forEach((order) => {

				orders.push({
					id: order.id,
					previewURL: order.attributes.preview_url,
					placedOn: order.attributes.placed_on,
					status: order.attributes.status,
				});

			});

			success(orders);

		});
	}

}
