//
// Collection of methods for making GET requests to level endpoints.
/////

import axios from 'axios';

export default {

	level: (id, editing, difficulty, success, failure) => {

		axios.get(`${process.env.VUE_APP_ENDPOINT}/levels/${id}${editing ? '?context=edit' : ''}${difficulty ? '?difficulty=' + difficulty : ''}`,{
			headers: {"Authorization": "Bearer " + localStorage.getItem('token')}
		})
		.then((response) => {

			// First, convert string values meant to be interpreted as booleans as,
			// well, actual booleans
			const published = (
				response.data.data.attributes.published == "true" ||
				response.data.data.attributes.published == "1"
			);

			const quickstart = (
				response.data.data.attributes.quickstart == "true" ||
				response.data.data.attributes.quickstart == "1"
			);

			// Go ahead and get the level
			const level = {
				id: response.data.data.id,
				name: response.data.data.attributes.name,
				layout: response.data.data.attributes.layout ? JSON.parse(response.data.data.attributes.layout) : null,
				difficulty: response.data.data.attributes.difficulty,
				published,
				quickstart,
				randomPatterns: response.data.data.attributes.random_patterns,
				categoryIDs: [],
				patternIDs: []
			};

			// Now let's grab any additional included info
			const categories = [];
			const colors = [];
			const levelColors = [];
			const patterns = [];
			const patternAssignments = [];

			if (response.data.included) {

				response.data.included.forEach((included, index) => {

					if (included.type == "assigned_pattern") {

						patternAssignments.push({
							id: included.id,
							patternID: included.attributes.pattern_id,
							levelID: included.attributes.level_id
						});

						return;

					}

					// Level category?  Add to the categories array
					if (included.type == "level_category") {

						categories.push({
							id: included.id,
							name: included.attributes.name,
							color: included.attributes.color
						});

						level.categoryIDs.push(included.id);
						return;

					}

					// Level color?  Add to the level colors array
					if (included.type == "level_color") {

						levelColors.push({
							id: included.id,
							levelID: included.attributes.level_id,
							colorID: included.attributes.pattern_color_id,
							assignmentID: included.attributes.assignment_id,
							color: included.attributes.color
						});
						return;

					}

					// Pattern?  Add to patterns array and do a bit of work with any
					// colors.
					if (included.type == "pattern") {

						patterns.push({
							id: included.id,
							name: included.attributes.name,
							filePath: included.attributes.file_path,
							random: included.attributes.random
						});

						if (included.attributes.random) {

							// Random patterns are tricky since they're not explicitly
							// assigned to the level.  That's alright, we'll make an
							// assignment up
							patternAssignments.push({
								id: `${index}:0`,
								levelID: level.id,
								patternID: included.id
							});

							// Additionally, we may have "drawn" (as in drawn from a deck)
							// this pattern more than once. In which case, let's create some
							// additional assignments
							if (included.attributes.draw_count && included.attributes.draw_count > 1) {

								for(let i = 1; i < included.attributes.draw_count; i ++) {

									patternAssignments.push({
										id: `${index}:${i}`,
										levelID: level.id,
										patternID: included.id
									});

								}
							}

						}

						level.patternIDs.push(included.id);

						// Patterns are a nasty case, because we'll also likely get some
						// colors as well.  We only have relationship data though.  So,
						// do your best
						if (included.relationships.pattern_color) {

							included.relationships.pattern_color.data.forEach((color) => {

								// We might have already added the color, sans patternID.
								// compare ids to see if we have.
								for(let i = 0; i < colors.length; i ++) {
									if (colors[i].id == color.id) {
										// Already added.  Now let's establish which pattern this
										// color belongs to
										colors[i].patternID = included.id;
										return;
									}
								}

								// If we got this far, we haven't.  Add a very basic color
								// object
								colors.push({
									id: color.id,
									patternID: included.id
								});

							});

						}

						return;

					}

					// Pattern color group?  See if it hasn't already been added.  If it
					// has, then modify existing color
					if (included.type == "pattern_color") {

						for(let i = 0; i < colors.length; i ++) {
							let color = colors[i];
							if (color.id == included.id) {

								// Got a match.  Don't push to end of array.  Instead, modify
								// what we have
								color.name = included.attributes.name;
								color.color = included.attributes.color;
								color.background = included.attributes.background;
								return;

							}
						}

						// Otherwise, add to end
						colors.push({
							id: included.id,
							name: included.attributes.name,
							color: included.attributes.color,
							background: included.attributes.background
						});
						return;

					}

				});

			}

			// Finally, let's call success
			success(level, categories, patterns, colors, levelColors, patternAssignments);

		})
	},

	// Retrieves multiple levels from the server
	levels: (success, failure) => {
		axios.get(`${process.env.VUE_APP_ENDPOINT}/levels`, {
			headers: {"Authorization": "Bearer " + localStorage.getItem('token')}
		})
		.then((response) => {

			const levels = [];
			response.data.data.forEach((level) => {

				const published = (
					level.attributes.published == "true" ||
					level.attributes.published == "1"
				);

				const quickstart = (
					level.attributes.quickstart == "true" ||
					level.attributes.quickstart == "1"
				);

				levels.push({
					id: level.id,
					name: level.attributes.name,
					layout: level.attributes.layout,
					difficulty: level.attributes.difficulty,
					published,
					quickstart
				});

			});

			success(levels);

		})
		.catch((failure) => {

		});
	}
}
