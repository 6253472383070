import Consts from './consts';
export default {
	mixins: [ Consts ],
	inject: ['GameEventBus'],
	
	methods: {
		
		// Given the index of chunk, this  method will each rect inside the chunk into a
		// blobette, with a position such that it can actually be drawn on the screen
		chunkToBlobette: function(index) {
		
			const chunk = this.GameState.drawnChunks[index];
			chunk.newChunk = true;
		
			// First, let's see which row and column of the drawnChunks this chunk is
			// one
			const row = Math.floor(index / this.chunksWide);
			const column = index % this.chunksWide;
			let midpoint = this.screenWidth / 2;
		
			if (this.chunksWide == 1) {
				midpoint += (this.CHUNK_WIDTH / 2) * this.BLOB_WIDTH;
			}
		
			// Now let's calculate how much actually padding there is on the sides of
			// our drawn chunks (not minimum padding, which is a const, but actual,
			// which is dynamic given screen size and how many chunks we can squeeze in)
			const drawnWidthInPixels = this.chunksWide * this.CHUNK_WIDTH * this.BLOB_WIDTH
			const leftPadding = (this.screenWidth - drawnWidthInPixels) / 2;
		
			// Using all of the above values, we can actually go ahead and determine
			// a "starting point" for the chunk that is used when drawing neighboring
			// "subdued" rects.  It's a bit complicated.
			chunk.startingPoint = {
				x: 0,
				y: 0,
			};
			const adjustedStartColumn = column % 2 == 0 ? (Math.floor(column / 2) + 1) * -1 : Math.floor(column / 2);
			chunk.startingPoint.x = (adjustedStartColumn * this.CHUNK_WIDTH * this.BLOB_WIDTH) + midpoint;
			chunk.startingPoint.y = (this.chunksTall - 1 - row) * this.CHUNK_HEIGHT * this.BLOB_HEIGHT + this.TOP_PADDING
		
			// Alright, now the stupid part where we convert rects to blobettes.
			chunk.rects.forEach((rect, index) => {
				
				// First, go ahead and just the x and y attributes of the ID so that
				// they're 0-indexed.
				const adjustedX = rect.id.x - 1;
				const adjustedY = rect.id.y - 1;
				
				// Now, figure out the x and y position of the rects RELATIVE TO the
				// other drawn rects (i.e. a rect in the top left corner would make xPos
				// and yPos both zero, even though it's actual position would be some
				// positive number)
				
				// xPos is weird.  Basically, even columns are always on the LEFT relative
				// to the midpoint, and odd columns are always to the right
				const adjustedColumn = column % 2 == 0 ? (Math.floor(column / 2) + 1) * -1 : Math.floor(column / 2);
				const xPos = (adjustedColumn * this.CHUNK_WIDTH * this.BLOB_WIDTH) + // Chunk X
					(adjustedX * this.BLOB_WIDTH) +
					midpoint; // Rect X
					
				// yPos math is pretty weird.  Remember that row 0 is the row at the bottom
				// and the last row is the row closest to the screen.
				const yPos = (
					(this.chunksTall - 1 - row) // So do this bit of fancy math to compensate
					* this.CHUNK_HEIGHT * this.BLOB_HEIGHT) + // Now we have the chunk Y
					(adjustedY * this.BLOB_HEIGHT); // And here's our rect Y
					
				// Now use the positions to figure out what the blob index should be.
				// Remove leftPadding from xPos in below calculation.  It will throw
				// things off.
				const blobIndex = Math.floor((xPos - leftPadding) / (this.BLOB_WIDTH)) + // row
					(Math.floor(yPos / (this.BLOB_HEIGHT)) * this.shownColumns); // column;
					
				// And we can modify the blobettes array at that spot with a new blobette
				this.blobettes[blobIndex] = {
					chunkID: chunk.id,
					rectID: rect.id,
					x: xPos,
					y: yPos + this.TOP_PADDING
				}
				
			});
		
		},
		
		// Removes a blobette, naturally.  The way it does so is worth mentioning.
		// It doesn't merely remove the blobette from the array, but also replaces it
		// with a "blank" cell.  We do this to make the drawing process for the whole
		// blob a little easier
		// TODO: Elaborate on last sentence
		// This method will also call a method to add a rect from a drawn chunk.  The
		// idea being to gradually empty out a chunk until
		removeBlobette: function(blobette) {
					
			for(let i = 0; i < this.blobettes.length; i ++) {
				
				// Check position
				if (this.blobettes[i].x == blobette.x && this.blobettes[i].y == blobette.y) {
					
					// Conveniently, the blobette contains both a chunk and rect ID.
					// We'll remove the rect from the chunk here so as to avoid any weird
					// circular import issues.
					const chunkID = blobette.chunkID;
					const rectID = blobette.rectID;
					for(let j = 0; j < this.GameState.drawnChunks.length; j ++) {
						
						let chunk = this.GameState.drawnChunks[j];
						// This could be a null slot.  Skip ahead if so.
						if (chunk == null) {
							continue;
						}
						
						if (chunk.id.x == chunkID.x && chunk.id.y == chunkID.y) {
							
							for(let k = 0; k < chunk.rects.length; k ++) {
								
								let rect = chunk.rects[k];
								
								if (rect.id.x == rectID.x && rect.id.y == rectID.y) {
									
									// Match.  Let's splice it away
									chunk.rects.splice(k, 1);
									// Additionally, if the chunk has no more rects, then set that
									// part of drawnChunks to null, and copy that chunk over to
									// ghostChunks
									if (chunk.rects.length == 0) {
										this.GameState.ghostChunks.push(Object.assign({}, chunk));
										this.GameState.drawnChunks[j] = null;
										break;
									}
									
								}
								
							}
							
							// Regardless of whether or not we found our rect, leave here,
							// because we found the chunk it was most certainly supposed to be
							// inside
							break;
							
						}
						
					}
					
					// Finally, splice off our blobette and call the drawBlob method to draw
					// the updated blob
					this.blobettes.splice(i, 1, false);
					this.drawBlob();
					return;
				}
				
			}
		
		},
		
		// Shifts a group of blobettes down when their accompanying chunk has been
		// shifted down
		shiftBlobettes: function(chunk) {
			
			console.log("I'm gonna shift!!!");
		
			let maximum = this.blobettes.length;
		
			for(let i = 0; i < this.blobettes.length; i ++) {
				
				if (i == maximum) {
					return;
				}
				
				const blobette = this.blobettes[i];
				
				if (!blobette) {
					continue;
				}
				
				if (blobette.chunkID.x == chunk.id.x && blobette.chunkID.y == chunk.id.y) {
					
					// Shift this blobette down by increasing its yPos by CHUNK_HEIGHT *
					// RECT_HEIGHT
					blobette.y += this.BLOB_HEIGHT * this.CHUNK_HEIGHT;
					
					// We also need to change where in the blobettes array it should be.
					// Easy fix really.  We merely need to take its current index and
					// increase it by CHUNK_HEIGHT * this.cols
					const shiftIndex = this.CHUNK_HEIGHT * this.cols;
					
					// We need to make sure we don't go too far.  Here's a simple match to
					// ensure that
					if (i + shiftIndex < maximum) {
						maximum = i + shiftIndex;
					}
					
					this.blobettes[i + shiftIndex] = blobette;
					this.blobettes[i] = false;
				}
				
			}
		
		}
		
	},
	
	mounted: function() {
		this.GameEventBus.$on('remove-blobette', (blobette) => {
			this.removeBlobette(blobette);
		})
	}
}