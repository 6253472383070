//
// Portion of package for handling POST requests.
/////

import axios from 'axios';

export default {

	// Used to post new webhook config information
	config: (payload, success, failure) => {

		axios.post(`${process.env.VUE_APP_ENDPOINT}/printful-webhooks/config`, payload, {
			headers: {"Authorization": "Bearer " + localStorage.getItem('token')}
		})
		.then((response) => {
			success();
		})
		.catch((error) => {
			// TODO
		})
	}
}
