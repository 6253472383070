<!-- Displays a preview of what a category will look like when we're either
	creating a new category or editing an existing one. -->
<template>

	<div class="category-preview"
		:class="{'category': name != '' }"
		:style="{'background-color': name ? `#${backgroundHex}` : '#FFF'}">

		<h2
			:class="{'empty': name == ''}"
			:style="{'color': name ? `#${textHex}` : '#777'}">
			{{ name ? name : 'Category Preview Will Appear Here' }}
		</h2>
	</div>

</template>

<script>
export default {
	name: "CategoryPreview",
	props: ['background-color', 'name', 'text-color'],

	computed: {

		// Converts background-color, which is an object with three channel values,
		// into a hex value
		backgroundHex: function() {

			let red = this.backgroundColor.red.toString(16).toUpperCase();
			if (red.length < 2) {
				red = '0' + red;
			}
			let green = this.backgroundColor.green.toString(16).toUpperCase();
			if (green.length < 2) {
				green = '0' + green;
			}
			let blue = this.backgroundColor.blue.toString(16).toUpperCase();
			if (blue.length < 2) {
				blue = '0' + blue;
			}

			const color = red + green + blue;
			return color;

		},

		// Converts text-color, which is an object with three channel values, into
		//a hex value
		textHex: function() {

			let red = this.textColor.red.toString(16).toUpperCase();
			if (red.length < 2) {
				red = '0' + red;
			}
			let green = this.textColor.green.toString(16).toUpperCase();
			if (green.length < 2) {
				green = '0' + green;
			}
			let blue = this.textColor.blue.toString(16).toUpperCase();
			if (blue.length < 2) {
				blue = '0' + blue;
			}

			const color = red + green + blue;
			return color;

		}

	}
}
</script>

<style lang="scss" scoped>

.category-preview {
	margin: 10px;
	margin-bottom: 0;
	display: inline-block;
	padding: 4px 10px;
	border-radius: 4px;

	&.category {
		box-shadow: 1px 1px 1px #BBB;
	}
}

h2 {
	margin: 0;
}

h2.empty {
	display: inline-block;
	font-style: italic;
	color: #777;
}
</style>
