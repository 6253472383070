//
// Portion of package containing PUT methods.
/////

import axios from 'axios';

export default {

	category: (id, payload, success, failure) => {
		axios.put(`${process.env.VUE_APP_ENDPOINT}/pattern-categories/${id}`, payload)
		.then((response) => {

			// Already have what we need, so just call success
			success();

		}).catch((error) => {

		});
	}

}
