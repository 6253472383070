<!-- Simple navbar.  Really only used in the context of orders. -->
<template>

	<div class="navbar">

		<div class="brand">
			<img src="../assets/logo-small-no-paddle.svg"/>
		</div>

		<div class="content m-w-2">

			<router-link :to="{name: 'Home'}" class="play">
				Play
			</router-link>

			<router-link :to="{name: 'Poster'}" class="poster">
				Poster
			</router-link>

			<router-link :to="{name: 'CurrentOrder'}" class="orders">
				Orders
			</router-link>

		</div>

		<!--
		<div class="account">
			You
		</div>
		-->

	</div>

</template>

<script>
export default {
	name: "Navbar"
}
</script>

<style lang="scss" scoped>
.navbar {
	display: flex;
	position: fixed;
	top: 0;
	height: 50px;
	width: 100%;
	padding: 0 20px;
	background-color: #FFF;
	font-weight: 700;
	font-size: 1.8rem;
	box-shadow: 0 2px 4px #AAA;
	border-bottom: 8px solid #FFD100;
	z-index: 500;

	div {
		height: 100%;
		display: flex;
		align-items: center;
	}

	div.content {
		height: 50px;
		position: relative;
	}

	a, .account {
		color: #000;
		padding: 16px;
		padding-top: 12px;
		border-bottom: 8px solid #333;
		position: relative;
		display: inline-block;
		height: 50px;
		transition: all 0.4s ease;

		&:hover {
			border-bottom-style: solid;
		}

		&.play {
			border-color: #FF2E00;

			&:hover, &.active {
				background-color: #FF2E00;
				color: #FFF;
			}

		}

		&.poster {
			border-color: #1733ED;

			&:hover, &.router-link-active {
				background-color: #1733ED;
				color: #FFF;
			}
		}

		&.orders {
			border-color: #FFD100;

			&:hover, &.router-link-active {
				background-color: #FFD100;
			}
		}
	}

	.account {
		min-width: 100px;
		text-align: center;
	}
}

.brand img {
	width: 40px;
	height: 40px;
}

.content {
	flex-grow: 1;
}
</style>
