<!-- A component that can toggle between a form group and a component that
	vaguely resmebles a form group only the input is replaced with a text
	value. -->
<template>

	<div class="toggleable-form-group">

		<div class="form-group" v-if="!editing">

			<label>{{ fieldName }}: </label>

			<div class="value">

				<img src="../../assets/right-arrow-black.svg" class="indicator"/>

				<div :class="valueClass" class="field-value">

					<span v-if="value">
						{{ value }}
					</span>
					<span class="no-value" v-else>
						Left blank
					</span>

				</div>
				<button class="btn-yellow" @click="editing = true">
					Edit
				</button>
			</div>

		</div>

		<div v-else class="editing">

			<FormGroup class="form-group"
				:field-name="fieldName"
				:group-id="groupId"
				:placeholder="placeholder"
				:type="type"
				:value="value"
				@input="$emit('input', $event)"
				@enter="doneEditing"/>

				<button class="btn-yellow" @click="doneEditing">
					Done
				</button>

		</div>

	</div>

</template>

<script>
import FormGroup from './FormGroup.vue';
export default {
	name: "ToggleableFormGroup",
	props: ['edit', 'group-id', 'field-name', 'placeholder', 'type', 'value', 'value-class'],
	components: { FormGroup },

	data: function() {
		return {
			editing: false
		}
	},

	methods: {


		doneEditing: function() {

			this.editing = false;
			this.$emit('finished');

		}

	},

	watch: {

		edit: function() {
			this.editing = this.edit;
		}

	}

}
</script>

<style lang="scss" scoped>
.indicator {
	height: 3.6rem;
	background-color: #FFEEA5;
	padding: 0.8rem;
	border-radius: 100%;
	margin-right: 0.8rem;
}
.toggleable-form-group {
	width: 100%;

	&.col {
		width: auto;
	}
}
.value {
	width: 100%;
	display: flex;
	align-items: center;
	margin-left: 0.4rem;

	.field-value {
		padding: 0.8rem;
		height: 100%;
		width: 100%;
		flex-grow: 1;
		font-size: 1.8rem;
		border-bottom: 0.1rem dashed #333;
	}
}

span.no-value {
	color: #777;
	font-style: italic;
}

.editing {
	width: auto;
	display: flex;
	align-items: flex-end;

	.form-group {
		margin-right: 15px;
	}

	button {
		box-sizing: content-box;
		height: 17.6px;
	}

}
</style>
