<!-- A self-enclosed form for a user to enter in their billing address
	information. -->
<template>

	<div>

		<div class="flex-row">

			<FormGroup class="col w-6 p-r-1"
				group-id="firstName"
				field-name="First Name"
				placeholder="Piet"
				type="text"
				v-model="firstName"/>

			<FormGroup class="col w-6 p-l-1"
				group-id="lastName"
				field-name="Last Name"
				placeholder="Mondrian"
				type="text"
				v-model="lastName"/>

		</div>

		<div class="flex-row">

			<FormGroup class="col w-6 p-r-1"
				group-id="streetAddr"
				field-name="Street Address"
				placeholder="11 W 53rd St"
				type="text"
				v-model="streetAddr"/>

			<FormGroup class="col w-6 p-l-1"
				group-id="unitAddr"
				field-name="Unit Address"
				placeholder="Apt 303"
				type="text"
				v-model="unitAddr"/>

		</div>

		<div class="flex-row">

			<FormGroup class="col w-4 p-r-1"
				group-id="cityAddr"
				field-name="City"
				placeholder="New York"
				type="text"
				v-model="city"/>

			<FormGroup class="col w-4 p-l-1 p-r-1"
				group-id="stateAddr"
				field-name="State"
				placeholder="NY"
				type="text"
				v-model="state"/>

			<FormGroup class="col w-4 p-l-1"
				group-id="zipAddr"
				field-name="Zip Code"
				placeholder="10019"
				type="text"
				v-model="zip"/>

		</div>

		<PagerButtons class="pager-buttons"
			:prev="true"
			:prev-ready="true"
			:next="true"
			:next-ready="next"
			@next="handleNext"
			@prev="$emit('prev')"/>

		<button @click="emit('back-to-confirm')" v-if="finished">
			Skip Ahead To Confirmation
		</button>

	</div>

</template>

<script>
import FormGroup from '../../forms/FormGroup.vue';
import PagerButtons from '../../PagerButtons.vue';
export default {
	name: "BillingAddress",
	props: ['finished', 'saved-info'],
	components: { FormGroup, PagerButtons },

	data: function() {
		return {
			firstName: this.savedInfo && this.savedInfo.firstName ? this.savedInfo.firstName : '',
			lastName: this.savedInfo && this.savedInfo.lastName ? this.savedInfo.lastName : '',
			streetAddr: this.savedInfo && this.savedInfo.streetAddr ? this.savedInfo.streetAddr : '',
			unitAddr: this.savedInfo && this.savedInfo.unitAddr ? this.savedInfo.unitAddr : '',
			city: this.savedInfo && this.savedInfo.city ? this.savedInfo.city : '',
			state: this.savedInfo && this.savedInfo.state ? this.savedInfo.state : '',
			zip: this.savedInfo && this.savedInfo.zip ? this.savedInfo.zip : ''
		}
	},

	methods: {

		// Bubble up the values we have and continue
		handleNext: function() {

			this.$emit('next', {
				firstName: this.firstName,
				lastName: this.lastName,
				streetAddr: this.streetAddr,
				unitAddr: this.unitAddr,
				city: this.city,
				state: this.state,
				zip: this.zip
			});

		}

	},

	computed: {

		// Makes sure the user can move next by ensuring that all necessary fields
		// are filled out.
		next: function() {

			if (!this.firstName) {
				return false;
			}

			if (!this.lastName) {
				return false;
			}

			if (!this.streetAddr) {
				return false;
			}

			if (!this.city) {
				return false;
			}

			if (!this.state) {
				return false;
			}

			if (!this.zip) {
				return false;
			}

			return true;

		}

	}
}
</script>

<style lang="scss" scoped>

.pager-buttons {
	margin-top: 2.4rem;
}

@media(max-width: 768px) {
	.col {
		padding-left: 0;
		padding-right: 0;
	}
}
</style>
