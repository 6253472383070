//
// Contains a number of methods for making GET requests involving patterns.
/////

import axios from 'axios';

export default {

	// Retrieves extra details about a single pattern by its hashID
	pattern: (id, success, failure) => {

		axios.get(`${process.env.VUE_APP_ENDPOINT}/patterns/${id}`, {
			headers: {"Authorization": "Bearer " + localStorage.getItem('token')}
		})
		.then((response) => {

			let pattern = {
				id: response.data.data.id,
				name: response.data.data.attributes.name,
				filePath: response.data.data.attributes.file_path
			};

			let colorGroups = [];
			response.data.included.forEach((colorGroup) => {

				if (colorGroup.type == "pattern_color") {
					colorGroups.push({
						id: colorGroup.id,
						patternID: pattern.id,
						name: colorGroup.attributes.name,
						color: colorGroup.attributes.color,
						background: colorGroup.attributes.background
					});
				}

			});

			success(pattern, colorGroups);

		})
		.catch((error) => {

		});

	},

	// Retrieves a specified number of of random patterns
	randomPatterns: (number, success, failure) => {

		axios.get(`${process.env.VUE_APP_ENDPOINT}/patterns/random?n=${number}`)
		.then((response) => {

			let patterns = [];
			let colors = [];

			response.data.data.forEach((pattern) => {

				patterns.push({
					id: pattern.id,
					name: pattern.attributes.name,
					filePath: pattern.attributes.file_path,
					random: true
				});

				if (pattern.relationships && pattern.relationships.pattern_color) {

					pattern.relationships.pattern_color.data.forEach((color) => {
						colors.push({
							id: color.id,
							patternID: pattern.id,
						})
					})
				}

			});

			response.data.included.forEach((color) => {

				for(let i = 0; i < colors.length; i ++) {

					if (colors[i].id == color.id) {
						colors[i].name = color.attributes.name;
						colors[i].color = color.attributes.color;
						return;
					}

				}

			});

			success(patterns, colors);

		})
		.catch((error) => {

		});

	},

	// Retrieves all patterns from the server
	patterns: (success, failure) => {

		axios.get(`${process.env.VUE_APP_ENDPOINT}/patterns`)
		.then((response) => {

			let patterns = [];
			let colors = [];

			response.data.data.forEach((pattern) => {

				patterns.push({
					id: pattern.id,
					name: pattern.attributes.name,
					filePath: pattern.attributes.file_path
				});

				if (pattern.relationships && pattern.relationships.pattern_color) {

					pattern.relationships.pattern_color.data.forEach((color) => {
						colors.push({
							id: color.id,
							patternID: pattern.id,
						})
					})
				}

			});

			response.data.included.forEach((color) => {

				for(let i = 0; i < colors.length; i ++) {

					if (colors[i].id == color.id) {
						colors[i].name = color.attributes.name;
						colors[i].color = color.attributes.color;
						return;
					}

				}

			});

			success(patterns, colors);

		})
		.catch((error) => {

		});

	},

	// Retrieves all PUBLISHED patterns from the server
	publishedPatterns: (success, failure) => {
		axios.get(`${process.env.VUE_APP_ENDPOINT}/patterns?published=true`)
		.then((response) => {

			let patterns = [];
			response.data.data.forEach((pattern) => {

				patterns.push({
					id: pattern.id,
					name: pattern.attributes.name,
					filePath: pattern.attributes.file_path,
					published: true
				});

			});

			success(patterns);

		})
		.catch((error) => {

		});
	}
}
