
import Get from './get';
import Post from './post';
import Put from './put';
import Delete from './delete';

export default {

	getPattern: Get.pattern,
	getPatterns: Get.patterns,
	getPublishedPatterns: Get.publishedPatterns,
	getRandomPatterns: Get.randomPatterns,

	postPattern: Post.pattern,
	postSVG: Post.svg,

	updatePattern: Put.pattern,
	replaceColorGroups: Put.replaceColors,

	deletePattern: Delete.pattern

}
