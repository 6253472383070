<!-- Variation of pattern swatch that lays things out in list form.  Typically
	used with levels, hence why level is a prop-->
<template>
	<div class="pattern-swatch">

		<div class="swatch">
			<img :src="pattern.filePath"/>

			<SwatchColors :colors="colors" context="list"/>
		</div>

		<div class="controls">

			<!-- Edits the pattern in question -->
			<button class="square-button" @click="inspectPattern">
				<img src="../assets/save-icon.svg"/>
			</button>

			<!-- Removes pattern from the level -->
			<button class="square-button" @click="removePattern">
			</button>

		</div>

	</div>
</template>

<script>
import SwatchColors from './SwatchColors.vue';
export default {
	name: "PatternSwatchList",
	props: ['level', 'pattern'],
	inject: ['ModalEventBus', 'LevelEventBus'],
	components: { SwatchColors },

	computed: {

		colors: function() {

			let colors = [];
			this.$store.state.patternColors.forEach((color) => {
				if (color.patternID == this.pattern.id) {
					colors.push(color);
				}
			});
			return colors;

		}
	},

	methods: {

		inspectPattern: function() {
			this.ModalEventBus.$emit('modal', {
				name: 'PatternInspectModal',
				props: {
					pattern: this.pattern,
					level: this.level
				}
			});
		},

		removePattern: function() {
			this.LevelEventBus.$emit('remove-pattern', this.pattern);
		}
	}
}
</script>

<style lang="scss" scoped>
.pattern-swatch {
	height: 100px;
	margin: 10px 0;
	display: flex;

	.swatch {
		flex-grow: 1;
		height: 100%;
		display: flex;
		align-items: stretch;

		img {
			display: block;
			height: 100%;
			border-radius: 4px;
			box-shadow: 1px 1px 1px #BBB;
		}
	}


}

.controls {
	flex-grow: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;

	button {
		width: 30px;
		height: 30px;
		display: block;
	}

}


.colors {
	margin-left: 12px;
	flex-grow: 1;
}
</style>
