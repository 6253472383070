//
// Portion of package containing DELETE endpoints.
/////

import axios from 'axios';

export default {

	order: (orderID, success, failure) => {
		axios.delete(`${process.env.VUE_APP_ENDPOINT}/orders/${orderID}`, {
			headers: {"Authorization": "Bearer " + localStorage.getItem('token')}
		})
		.then((response) => {
			success();
		})
	}

}
