//
// Portion of package containing constants.
/////

const LINE_GAP = 4;

export default {

	LINE_GAP,
	
}
