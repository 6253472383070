//
// Comes up with random colors / patterns 3-ahead of whatever the present color
// is, and informs user of these colors.
/////

import { mobileDraw, mobileMouse } from './mobile';
const COLORS = ['#FFFFFF', '#FF220B', '#FFC100', '#1733ED'];

export default class ColorQueue {

	constructor(patterns, sketch, mobile, patternColors, levelColors, previewed, poster, callback) {

		this.mobileDraw = this.mobileDraw.bind(this);
		this.mobileMouse = this.mobileMouse.bind(this);

		this.colors = [];
		this.onDone = callback ? callback : undefined;
		this.previewed = previewed;
		this.poster = poster;
		this.isMobile = mobile;

		this.powerUp = true;
		this.powerUpMouse = true;

	}

	// Handles mouse pressed events.  If and only if the mouse is below a certain
	// y-threshold does it look.  If the ball is primed to launch, then we may
	// launch the ball.  If we can activate the power-up, then we may do that
	// instead.  Otherwise, nothing will happen
	mousePressed(sketch, ball, paddle) {

		if (this.isMobile) {

			let mouse = this.mobileMouse(ball, paddle, sketch);
			if (mouse && this.powerUpMouse && !ball.pinned) {
				this.powerUpMouse = false;
			}

			return mouse;

		}

		return false;
	}

	// Used when user has chosen a color during a powered-up state.  This will
	// change the color of the ball to the selected color, and replace selected
	// color in queue with some other random color
	releasePower(ball) {

		if (ball.choice) {
			ball.color = this.colors[ball.choice - 1];
			this.colors.splice(ball.choice - 1, 1, this.patterns[Math.floor(Math.random() * this.patterns.length)])
		}
	}

}
ColorQueue.prototype.mobileDraw = mobileDraw;
ColorQueue.prototype.mobileMouse = mobileMouse;
