//
// A rect that is drawn wherever the mouse happens to be inside the canvas
//////

export default class PlacementRect {

	constructor(options, sketch) {
		this.cellWidth = options.cellWidth ? options.cellWidth : 0;
		this.cellHeight = options.cellHeight ? options.cellHeight : 0;
		this.midpoint = options.midpoint ? options.midpoint : {x: 0, y: 0};
		this.width = options.width ? options.width : 100;
		this.height = options.height ? options.height : 100;

		this.addBlock = sketch.loadImage('/add-block.svg');
		this.removeBlock = sketch.loadImage('/remove-block.svg');
	}

	// Draws our rectangle with a width and a height equal to specified width
	// and cell height
	draw(sketch, offset, zoom, mode, controls) {

		// If we're not in placement mode, don't bother
		if ((mode != 'place' && mode != 'remove') || controls) {
			return;
		}

		// Otherwise, let's get to work.  First, the screen likely isn't entirely
		// evenly divisible by our grid.  So, get the remainders to see how much
		// spacing there is on the edge
		let xRemainder = this.width % (this.cellWidth * zoom);
		let yRemainder = this.height % (this.cellHeight * zoom);


		// Now, the remainders are tricky.  We'll almost certainly need to make
		// adjustments
		const totalYCells = Math.floor(this.height / (this.cellHeight * zoom));
		if (totalYCells % 2 != 0) {

			if (yRemainder > (this.cellHeight * zoom) / 2) {

				yRemainder -= this.cellHeight * zoom;

			} else if (yRemainder < (this.cellHeight * zoom) / 2 && yRemainder != 0) {

				yRemainder += this.cellHeight * zoom;

			}

		}

		const totalXCells = Math.floor(this.width / (this.cellWidth * zoom));
		if (totalXCells % 2 != 0) {

			if (xRemainder > (this.cellWidth * zoom) / 2) {

				xRemainder -= this.cellWidth * zoom;

			} else if (xRemainder < (this.cellWidth * zoom) / 2 && xRemainder != 0) {

				xRemainder += this.cellWidth * zoom;

			}

		}

		// The screen may also have been offset a little by the user panning.  We
		// don't need the actual offset value, just what the remainder is when
		// divided by cell dimensions
		let offsetXRemainder = offset.x % (this.cellWidth * zoom);
		let offsetYRemainder = offset.y % (this.cellHeight * zoom);

		// We'll use said remainders to offset our mouse position back by half of
		// their values when we try and determine which cell they're in
		const xCells = Math.floor(
			(sketch.mouseX - (xRemainder / 2) - offsetXRemainder) /
			(this.cellWidth * zoom)
		);
		const yCells = Math.floor(
			(sketch.mouseY - (yRemainder / 2) - offsetYRemainder) /
			(this.cellHeight * zoom)
		);

		// Then we can use these cell counts, multiplied by their respective
		// dimensions, with the remainders added, to get the position of our
		// rectangle
		const position = {
			x: xCells * (this.cellWidth * zoom) + xRemainder / 2 + offsetXRemainder,
			y: yCells * (this.cellHeight * zoom) + yRemainder / 2 + offsetYRemainder
		}

		if (mode == 'place') {

			sketch.image(this.addBlock, position.x, position.y, 80 * zoom, 40 * zoom);

		} else {
			sketch.image(this.removeBlock, position.x, position.y, 80 * zoom, 40 * zoom);

		}

		/*

		sketch.rectMode(sketch.CORNER);
		sketch.fill('#bfc7fa');
		sketch.strokeWeight(0);
		sketch.rect(
			position.x,
			position.y,
			this.cellWidth * zoom,
			this.cellHeight * zoom
		);
		*/

	}
}
