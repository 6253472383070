<!-- Component that tells the user how to activate the power up using their keys -->
<template>

	<div>

		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 250 250">
			<title>Power Up Keys</title>
			<g id="PowerUpIndicator" data-name="Power Up Indicator" style="fill:#35cc00">
				<circle cx="35" cy="42.5" r="22.5" />
			</g>
			<g id="PowerUpWhiteBall" data-name="Power Up White Ball">
				<circle cx="35" cy="42.5" r="10" style="fill:#fff;stroke:#000;stroke-miterlimit:10;stroke-width:4px"/>
			</g>
			<g id="PowerUpArrow" data-name="Power Up Arrow">
				<path d="M43.88,42l-6.53-6.53a.92.92,0,0,0-1.32,0L34.71,36.8a.92.92,0,0,0,0,1.32l2.69,2.69h-11a.93.93,0,0,0-.93.93v1.87a.93.93,0,0,0,.93.93h11l-2.69,2.69a.92.92,0,0,0,0,1.32L36,49.87a.94.94,0,0,0,1.32,0l6.53-6.54A.92.92,0,0,0,43.88,42Z" style="fill:#fff"/>
			</g>
			<g id="Power_Up_Static" data-name="Power Up Static">
				<circle cx="215" cy="42.5" r="10" style="fill:#ff2e00;stroke:#000;stroke-miterlimit:10;stroke-width:4px"/>
				<circle cx="155" cy="42.5" r="10" style="fill:#ffd100;stroke:#000;stroke-miterlimit:10;stroke-width:4px"/>
				<circle cx="95" cy="42.5" r="10" style="fill:#1733ED;stroke:#000;stroke-miterlimit:10;stroke-width:4px"/>
				<rect x="26" y="165.03" width="64.52" height="64.52" rx="5" style="fill:#ffd100"/>
				<path d="M69.92,192.62h-7v-7a2.33,2.33,0,0,0-2.33-2.33H55.92a2.33,2.33,0,0,0-2.33,2.33v7h-7A2.33,2.33,0,0,0,44.26,195v4.66A2.33,2.33,0,0,0,46.59,202h7v7a2.33,2.33,0,0,0,2.33,2.33h4.67A2.33,2.33,0,0,0,62.92,209v-7h7a2.34,2.34,0,0,0,2.34-2.34V195A2.34,2.34,0,0,0,69.92,192.62Z"/>
				<path d="M158.52,218H94v7a5,5,0,0,0,5,5h54.52a5,5,0,0,0,5-5Z"/>
				<path d="M225,138H25v12a5,5,0,0,0,5,5H220a5,5,0,0,0,5-5Z"/>
				<path d="M226,216H161.48v9a5,5,0,0,0,5,5H221a5,5,0,0,0,5-5Z"/>
			</g>
			<g id="DKeyPowerUp" data-name="D Key Power Up">
				<rect x="163.48" y="161.03" width="60.52" height="60.52" rx="3" style="fill:#fff"/>
				<path d="M221,163a1,1,0,0,1,1,1v54.52a1,1,0,0,1-1,1H166.48a1,1,0,0,1-1-1V164a1,1,0,0,1,1-1H221m0-4H166.48a5,5,0,0,0-5,5v54.52a5,5,0,0,0,5,5H221a5,5,0,0,0,5-5V164a5,5,0,0,0-5-5Z"/>
				<text transform="translate(185.48 191.65)" style="font-size:24.19355010986328px;font-family:NeuzeitGro-Bol, Neuzeit Grotesk">D</text>
				<path d="M202.3,203l-6.54-6.54a.94.94,0,0,0-1.32,0l-1.32,1.32a.94.94,0,0,0,0,1.32l2.69,2.69h-11a.94.94,0,0,0-.94.93v1.87a.93.93,0,0,0,.94.93h11l-2.69,2.69a.94.94,0,0,0,0,1.32l1.32,1.32a.92.92,0,0,0,1.32,0l6.54-6.53A.94.94,0,0,0,202.3,203Z"/>
			</g>
			<g id="AKeyPowerUp" data-name="A Key Power Up">
				<rect x="96" y="161.03" width="60.52" height="60.52" rx="3" style="fill:#fff"/>
				<path d="M153.52,163a1,1,0,0,1,1,1v54.52a1,1,0,0,1-1,1H99a1,1,0,0,1-1-1V164a1,1,0,0,1,1-1h54.52m0-4H99a5,5,0,0,0-5,5v54.52a5,5,0,0,0,5,5h54.52a5,5,0,0,0,5-5V164a5,5,0,0,0-5-5Z"/>
				<text transform="translate(118 191.65)" style="font-size:24.19355010986328px;font-family:NeuzeitGro-Bol, Neuzeit Grotesk">A</text>
				<path d="M116.59,204.29l6.53,6.53a.92.92,0,0,0,1.32,0l1.32-1.32a.94.94,0,0,0,0-1.32l-2.69-2.69h11a.93.93,0,0,0,.93-.93v-1.87a.93.93,0,0,0-.93-.93h-11l2.69-2.69a.94.94,0,0,0,0-1.32l-1.32-1.32a.94.94,0,0,0-1.32,0L116.59,203A.92.92,0,0,0,116.59,204.29Z"/>
			</g>
			<g id="ShiftKeyPowerUp" data-name="Shift Key Power Up">
				<rect x="27" y="86.05" width="196" height="60.52" rx="3" style="fill:#fff"/>
				<path d="M220,88.05a1,1,0,0,1,1,1v54.52a1,1,0,0,1-1,1H30a1,1,0,0,1-1-1V89.05a1,1,0,0,1,1-1H220m0-4H30a5,5,0,0,0-5,5v54.52a5,5,0,0,0,5,5H220a5,5,0,0,0,5-5V89.05a5,5,0,0,0-5-5Z"/>
				<path d="M51.81,107.25l-6.54,6.53a.94.94,0,0,0,0,1.32l1.32,1.32a.92.92,0,0,0,1.32,0l2.69-2.68v11a.93.93,0,0,0,.93.93H53.4a.92.92,0,0,0,.93-.93v-11L57,116.42a.92.92,0,0,0,1.32,0l1.32-1.32a.94.94,0,0,0,0-1.32l-6.53-6.53A.94.94,0,0,0,51.81,107.25Z"/>
				<text transform="translate(65.77 125.37)" style="font-size:24.19355010986328px;font-family:NeuzeitGro-Bol, Neuzeit Grotesk">
					<tspan style="letter-spacing:0.006014322488400702em">S</tspan>
					<tspan x="12.75" y="0">hi</tspan>
					<tspan x="31.67" y="0" style="letter-spacing:0.010010415953848148em">f</tspan>
					<tspan x="38.83" y="0">t</tspan>
				</text>
			</g>
		</svg>

		<p>Press the <strong>shift</strong> plus either the
			<strong>A / left arrow</strong> or the
			<strong>D / right arrow key</strong> to change the ball's color when
			green circle appears in the top left.
		</p>

	</div>

</template>

<script>
export default {
	name: "PowerUpKeys"
}
</script>

<style lang="css" scoped>
div {
	max-width: 300px;
}

p {
	text-align: center;
}

text {
	font-weight: 700;
}

#AKeyPowerUp {
	animation-name: aKey;
	animation-duration: 4s;
	animation-timing-function: ease;
	animation-iteration-count: infinite;
}

#DKeyPowerUp {
	animation-name: dKey;
	animation-duration: 4s;
	animation-timing-function: ease;
	animation-iteration-count: infinite;
}

#PowerUpArrow {
	animation-name: arrow;
	animation-duration: 4s;
	animation-timing-function: linear;
	animation-iteration-count: infinite;
}

#PowerUpWhiteBall {
	animation-name: ball;
	animation-duration: 4s;
	animation-timing-function: linear;
	animation-iteration-count: infinite;
}

#PowerUpIndicator {
	animation-name: indicator;
	animation-duration: 4s;
	animation-timing-function: ease;
	animation-iteration-count: infinite;
}

#ShiftKeyPowerUp {
	animation-name: shiftKey;
	animation-duration: 4s;
	animation-timing-function: ease;
	animation-iteration-count: infinite;
}

@keyframes aKey {
	0% {
		transform: translateY(0px);
	}

	60% {
		transform: translateY(0px);
	}

	65% {
		transform: translateY(5px);
	}

	66% {
		transform: translateY(0px);
	}

	100% {
		transform: translateY(0px);
	}
}

@keyframes arrow {
	0% {
		opacity: 1;
	}

	15% {
		opacity: 1;
	}

	17% {
		opacity: 0;
	}

	100% {
		opacity: 0;
	}
}

@keyframes ball {
	0% {
		opacity: 0;
	}

	15% {
		opacity: 0;
	}

	17% {
		opacity: 1;
	}

	100% {
		opacity: 1;
	}
}

@keyframes dKey {
	0% {
		transform: translateY(0px);
	}

	20% {
		transform: translateY(0px);
	}

	25% {
		transform: translateY(5px);
	}

	26% {
		transform: translateY(0px);
	}

	35% {
		transform: translateY(0px);
	}

	40% {
		transform: translateY(5px);
	}

	41% {
		transform: translateY(0px);
	}

	50% {
		transform: translateY(0px);
	}

	55% {
		transform: translateY(5px);
	}

	56% {
		transform: translateY(0px);
	}

	85% {
		transform: translateY(0px);
	}

	90% {
		transform: translateY(0px);
	}

	100% {
		transform: translateY(0px);
	}
}

@keyframes indicator {
	0% {
		fill: #35CC00;
		transform: translateX(0px);
	}

	23% {
		fill: #35CC00;
		transform: translateX(0px);
	}

	28% {
		fill: #FF2E00;
		transform: translateX(60px);
	}

	38% {
		fill: #FF2E00;
		transform: translateX(60px);
	}

	43% {
		fill: #1733ED;
		transform: translateX(120px);
	}

	53% {
		fill: #1733ED;
		transform: translateX(120px);
	}

	58% {
		fill: #FFD100;
		transform: translateX(180px);
	}

	63% {
		fill: #FFD100;
		transform: translateX(180px);
	}

	68% {
		fill: #1733ED;
		transform: translateX(120px);
	}

	88% {
		opacity: 1;
		fill: #1733ED;
		transform: translateX(120px);
	}

	90% {
		opacity: 0;
	}

	93% {
		opacity: 1;
	}

	96% {
		opacity: 0;
	}

	100% {
		fill: #1733ED;
		transform: translateX(120px);
	}

}

@keyframes shiftKey {
	0% {
		transform: translateY(0px);
	}

	15% {
		transform: translateY(0px);
	}

	20% {
		transform: translateY(5px);
	}

	85% {
		transform: translateY(5px);
	}

	86% {
		transform: translateY(0px);
	}

	100% {
		transform: translateY(0px);
	}
}
</style>
