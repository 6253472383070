<!-- A portion of the pattern category modal for picking an existing category. -->
<template>
	<div>

		<div>

			<div v-if="!inspect">

				<form @submit.prevent>
					<input type="text" class="underlined" v-model="name"/>
				</form>

				<h4>Assigned Categories</h4>

				<div class="assigned-categories">

					<PatternCategory v-for="category in selectedCategories"
						@select="inspect = $event"
						format="cluster"
						:category="category"/>

					<PatternCategory v-for="category in assignedCategories"
						@select="inspect = $event"
						format="cluster"
						:category="category"/>

				</div>

				<button @click="saveSelected">
					Save Selection
				</button>

				<button @click="newCategory">
					New Category
				</button>

			</div>

			<div v-else class="inspect-options">

				<PatternCategory
					format="cluster"
					:category="inspect"
					@select="inspect = false"/>

				<button class="square-button" @click="$emit('edit', inspect)">
					E
				</button>

				<button class="square-button" @click="removeAssignment">
					R
				</button>

				<button class="square-button"
					:class="{'delete-primed': deletePrimed}"
					@click="deleteCategory">
					D
				</button>

			</div>

		</div>

		<h3>Available Categories</h3>

		<div class="categories">

			<div v-if="categories.length">
				<PatternCategory v-for="category in categories"
					@select="selectCategory"
					format="cluster"
					:category="category"/>
			</div>

			<h2 v-else class="empty">No Categories Found</h2>

		</div>

	</div>
</template>

<script>
import PatternCategory from './PatternCategory.vue';
import PatternCategoryRequest from '../../network/pattern-categories';
export default {
	name: "PickPatternCategory",
	components: { PatternCategory },
	props: ['patternID'],
	data: function() {
		return {
			deletePrimed: false,
			inspect: false,
			name: '',
			selectedCategories: []
		}
	},

	computed: {

		assignedCategories: function() {

			let referencePattern;
			const categories = [];

			// First, we'll want to get the pattern referenced by patternID
			for(let i = 0; i < this.$store.state.patterns.length; i ++) {

				const pattern = this.$store.state.patterns[i];
				if (pattern.id == this.patternID) {

					referencePattern = pattern;
					break;

				}

			}

			if (!referencePattern || !referencePattern.categoryIDs) {
				return categories;
			}

			// Okay, so we have a pattern, now let's find all categories associated
			// with it
			referencePattern.categoryIDs.forEach((categoryID) => {

				this.$store.state.patternCategories.forEach((category) => {

					if (category.id == categoryID) {
						categories.push(category);
					}

				});

			});

			return categories;

		},

		// Returns only those categories which contain name data attribute as some
		// kind of substring
		categories: function() {

			let categories = [];

			// First, narrow by search results
			if (this.name == '') {
				categories = this.$store.state.patternCategories.slice(0);
			} else {

				this.$store.state.patternCategories.forEach((category) => {
					if (category.name.includes(this.name)) {
						categories.push(category);
					}
				});

			}

			// Then, narrow so that any categories that are already selected don't
			// show up
			this.selectedCategories.forEach((category) => {

				for(let i = 0; i < categories.length; i ++) {
					if (category.id == categories[i].id) {
						categories.splice(i, 1);
						return;
					}
				}

			});

			this.assignedCategories.forEach((category) => {

				for(let i = 0; i < categories.length; i ++) {
					if (category.id == categories[i].id) {
						categories.splice(i, 1);
						return;
					}
				}
			});

			return categories;
		},

		pattern: function() {

			for(let i = 0; i < this.$store.state.patterns.length; i ++) {
				const pattern = this.$store.state.patterns[i];
				if (pattern.id == this.patternID) {
					return pattern;
				}
			}

			return false;

		}

	},

	methods: {

		// Full on deletes a category
		deleteCategory: function() {

			// Make sure user really wants to delete this category
			if (!this.deletePrimed) {
				this.deletePrimed = true;
				return;
			}

			// Otherwise, let's delete the category.  Let's start by first
			// disassociating it from the pattern
			for(let i = 0; i < this.pattern.categoryIDs.length; i ++) {
				const id = this.pattern.categoryIDs[i];
				if (id == this.inspect.id) {

					const newPattern = Object.assign({}, this.pattern);
					newPattern.categoryIDs.splice(i, 1);
					this.$store.commit('addPatterns', [newPattern]);
					break;

				}
			}

			// Now let's remove said category from the store
			this.$store.commit('removePatternCategory', this.inspect.id);

			// Finally, let's make our request
			PatternCategoryRequest.deleteCategory(this.inspect.id, () => {});
			this.inspect = false;

		},

		newCategory: function() {
			this.$emit('new', this.name);
		},

		// Removes whatever category is assigned to inspect from this pattern
		removeAssignment: function() {

			// First, see if this category is in selectedCategories.  If so, un-assigning
			// it is as simple as just removing it from that array
			for(let i = 0; i < this.selectedCategories.length; i ++) {
				if (this.selectedCategories[i].id == this.inspect.id) {
					this.selectedCategories.splice(i, 1);
					this.inspect = false;
					return;
				}
			}

			// Otherwise, we can probably assume that this category is assigned to
			// the pattern, and that we have to make a request
			const newPattern = Object.assign({}, this.pattern);
			for(let i = 0; i < newPattern.categoryIDs.length; i ++) {
				if (newPattern.categoryIDs[i] == this.inspect.id) {
					newPattern.categoryIDs.splice(i, 1);
					this.$store.commit('addPatterns', [newPattern]);
					break;
				}
			}

			const ids = {
				patternID: this.patternID,
				categoryID: this.inspect.id
			}
			PatternCategoryRequest.removePatternFromCategory(ids, () => {});
			this.inspect = false;

		},

		// Will take all categories in selectedCategories and assign them to this
		// pattern.  Unfortunately, we do this one-by-one
		saveSelected: function() {

			this.selectedCategories.forEach((category) => {

				const ids = {
					patternID: this.patternID,
					categoryID: category.id
				}

				PatternCategoryRequest.addPatternToCategory(ids, () => {
					// TODO: Do something here
				});

			});

		},


		// Queues the category to be assigned to this pattern.  Before assignment,
		// user may edit category or delete it
		selectCategory: function(category) {

			this.selectedCategories.push(category);

		}

	},

	// Fetches all categories, as well as all categories associated with the
	// pattern
	mounted: function() {

		const store = this.$store;
		PatternCategoryRequest.getAllCategories((categories) => {
			store.commit('addPatternCategories', categories);
		});

		// Technically, we have already retrieved ALL categories, but this will
		// allow use to retrieve the associations
		PatternCategoryRequest.getCategoriesFromPattern(this.patternID, (categories, pattern) => {

			store.commit('addPatterns', [pattern]);

			// I mean, we might as well
			store.commit('addPatternCategories', categories);

		});

	},

	watch: {

		inspect: function() {
			if (!this.inspect) {
				this.deletePrimed = false;
			}
		}

	}
}
</script>

<style lang="scss" scoped>
.inspect-options {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 20px;

	button, div {
		margin: 0;
		margin-left: 10px;
	}
}

.assigned-categories {
	margin: 10px;
}

.delete-primed {
	background-color: #FF2E00;
}
</style>
