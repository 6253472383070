//
// Collection of methods for making PUT requests involving levels
/////

import axios from 'axios';
export default {

	level: (id, payload, success, failure) => {
		axios.put(`${process.env.VUE_APP_ENDPOINT}/levels/${id}`, payload, {
			headers: {"Authorization": "Bearer " + localStorage.getItem('token')}
		})
		.then((response) => {
			success();
		})
		.catch((error) => {
			// TODO
		})
	}

}
