//
// Mixin for calculating the total rows and columns for a blob.  Made a mixin
// for reusability with Rects
/////

export default {
	
	computed: {
				
		// Determines how many columns there are from one extreme to another given the
		// contents of this.GameState.chunks
		totalColumns: function() {
		
			let smallestX;
			let largestX;
		
			// Go through the chunks
			this.initialChunks.forEach((chunk) => {
		
				// And for each chunk we're going to see where it lies relative to
				// the center using it's ID x-value.  If the value is greater than 0,
				// decrement.  It's because there are no 0 id x-values, throws math off
				let chunkX = chunk.id.x;
				if (chunkX > 0) {
					chunkX --;
				}
		
				// If totalX is less than smallestX, or larger than greatestX, make
				// an assignment
				if (chunkX < smallestX || smallestX === undefined) {
					smallestX = chunkX;
				}
				if (chunkX > largestX || largestX === undefined) {
					largestX = chunkX;
				}
		
			});
		
			// Now, return the difference between smallestX and largestX.  A delta of
			// 0 doesn't mean the total num of rows is 0, but rather that there's only
			// one row, which is responsible for the smallest and largest X both being
			// the same value.  So, with that in mind, +1 the final result
			return ((largestX - smallestX) + 1) * 4;
		
		},
		
		// Given contents of this.GameState.chunks, this will tell you how many rows across they
		// are from one extreme to another
		// Also, this is a repeat of getColumns.  So, if you need to understand
		// something in greater detail, use that method as a reference
		totalRows: function() {
		
			let largestY;
			let smallestY;
		
			// Go through the chunks
			this.initialChunks.forEach((chunk) => {
		
				// See where it sets relative to all other chunks.  Decrement y-value
				// if it's greater than 0.
				let chunkY = chunk.id.y;
				if (chunkY > 0) {
					chunkY --;
				}
		
				// Now check to see if this is either the largest or smallest Y we've
				// stumbled across
				if (chunkY < smallestY || smallestY === undefined) {
					smallestY = chunkY
				}
				if (chunkY > largestY || largestY === undefined) {
					largestY = chunkY
				}
		
			});
		
			// Okay, now do our math and +1 the final value
			return ((largestY - smallestY) + 1) * 6;
		
		},
		
	}
}