<!-- Component for displaying the colors used in a pattern swatch. -->
<template>
	<div class="colors" :class="{'list': context == 'list'}">

		<!-- The colors proper. -->
		<div v-for="color in colors" class="color thick-border"
			:style="{'background-color': `#${color.color}`}">
		</div>

	</div>

</template>

<script>
export default {
	name: "SwatchColors",
	props: ['colors', 'context']
}
</script>

<style lang="scss" scoped>
.colors {
	display: flex;
	align-items: center;
	justify-content: center;

	&.list {
		justify-content: flex-start;
	}

	.color {
		width: 16%;
		margin-right: 4%;
		border-radius: 100%;

		&:after {
			content: "";
			padding-bottom: 100%;
			display: block;
		}

		&.background {
			border-radius: 0;
		}

	}
}
</style>
