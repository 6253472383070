import Consts from './consts';
import Blobette from './blobette'
export default {
	
	mixins: [ Blobette, Consts ],
	
	methods: {
		
		// Given an array of chunks on the screen which MAY have some empty slots, and
		// an array of chunks which have yet to be drawn, this will try and
		// re-populate the array, and call another method to turn that newly-added
		// chunk into blobettes.  Returns a boolean ultimately meant to tell main
		// game loop whether or not we should redraw the screen
		populateDrawnChunks: function() {
					
			// Firstly, if chunks is empty or undefined.  If there's nothing there,
			// then there's no more chunks to be added.
			if (!this.GameState.chunks || this.GameState.chunks.length == 0) {
				return;
			}

			// Otherwise...
			let newRow = false;
			let shiftRow = false;
			this.GameState.drawnChunks.forEach((spot, index) => {
				
				// If we find an empty spot within the array of drawnChunks...
				if (spot == null) {
					
					// First, determine what row of drawn chunks we're on using the width
					// arg
					const row = Math.floor(index / this.chunksWide);
					
					// If the row in question is NOT the first row then we're only going to
					// draw in another chunk if and only if we haven't created a new row
					// or shifted down during an earlier stage within the loop's execution
					if (row > 0 && !newRow && !shiftRow) {
						return;
					}
					
					// Otherwise let's see if there are any neighboring chunks on the same
					// row.  If there are, this complicates things a bit
					const rowStart = row * this.chunksWide
					const rowEnd = rowStart + this.chunksWide - 1;
					let yValue
					for(let i = rowStart; i < rowEnd; i ++) {
						if (this.GameState.drawnChunks[i]) {
							// Found a chunk
							yValue = this.GameState.drawnChunks[i].id.y;
							break;
						}
					}
					
					// Okay, if we got this far without a y-value then that means this row
					// Is empty of chunks.  If the row also happens to be zero, then this
					// makes things relatively easy for us.  If there's more than one row
					// and the row above has chunks, then we're going to take the chunk
					// directly above and move it down
					if (yValue === undefined && row == 0) {
						
						// First things first, we're also going to empty the array of ghostChunks
						this.GameState.ghostChunks.splice(0, this.GameState.ghostChunks.length);
						
						// Our multi-row test
						if(this.GameState.drawnChunks.length > this.chunksWide) {
							
							// Multiple rows.  Check the row above, same column, for a chunk
							const aboveIndex = this.chunksWide + (index % this.chunksWide);
							const aboveChunk = this.GameState.drawnChunks[aboveIndex];
							if (aboveChunk) {
								
								// Okay, the chunk above exists.  Let's drag it down
								this.GameState.drawnChunks[index] = aboveChunk;
								aboveChunk.startingPoint.y += this.CHUNK_HEIGHT * this.BLOB_HEIGHT
								this.GameState.drawnChunks[aboveIndex] = null;
								
								// We also need to shift its blobettes down.  Pretty easy
								// actually.  Just take all the blobettes with the appropriate
								// chunkID and increase their yPos by a chunk's height
								this.shiftBlobettes(aboveChunk);
								
								// We'll also need to shift any rectangles as well
								this.GameEventBus.$emit('shift-rects',
									aboveChunk.id,
									0,
									this.CHUNK_HEIGHT * this.BLOB_HEIGHT
								);
								// We then toggle the shiftRow flag and return here
								shiftRow = true;
								return;
							}
							
							// If there isn't a chunk directly above, then at least see if
							// there's a chunk SOMEWHERE above.  If so, we'll wait until we
							// bring that chunk down
							const aboveRowStart = this.chunksWide;
							for(let i = aboveRowStart; i < aboveRowStart + this.chunksWide; i ++) {
								
								if (this.GameState.drawnChunks[i]) {
									// We found a chunk, return early as a means of "holding off"
									return;
								}
							}
							
						}
						
						// Otherwise, we are going to bring in the first chunk we can from
						// drawn chunks
						newRow = true;
						this.GameState.drawnChunks[index] = this.GameState.chunks[0];
						this.GameState.chunks.splice(0, 1);
						this.chunkToBlobette(index);
						this.drawBlob();
						return;
						
					}
					
					// Otherwise, we're on an empty row that is ABOVE another row that
					// should be filled with chunks.  Take a peak at the row below to
					// get the chunkID, then attempt to pull a chunk from our chunks that
					// is directly above and has the same xID
					else if (yValue === undefined) {
						
						console.log(row);
					}
					
					// Okay, so there's a chunk on this row possibly in need of a neighbor
					// if shiftRow is set to true, then check to see if there's a chunk
					// above that we can shift down
					if (shiftRow) {
						
						const aboveIndex = this.chunksWide + (index % this.chunksWide);
						let aboveChunk;
						if (aboveIndex < this.GameState.drawnChunks.length) {
							aboveChunk = this.GameState.drawnChunks[aboveIndex];
						}
						if (aboveChunk) {
							
							// Okay, the chunk above exists.  Let's drag it down
							aboveChunk.startingPoint.y += this.CHUNK_HEIGHT * this.BLOB_HEIGHT
							this.GameState.drawnChunks[index] = aboveChunk;
							this.GameState.drawnChunks[aboveIndex] = null;
							
							// We also need to shift its blobettes down.  Pretty easy
							// actually.  Just take all the blobettes with the appropriate
							// chunkID and increase their yPos by a chunk's height
							this.shiftBlobettes(aboveChunk);
							this.GameEventBus.$emit('shift-rects',
								aboveChunk.id,
								0,
								this.CHUNK_HEIGHT * this.BLOB_HEIGHT
							);
							return;
						}
						
					}
					
					// Otherwise, we want to find undrawn chunks with the neighbor chunk's
					// y-value.  For x-value, that depends on the current index.  If the
					// index is even-numbered, we'll use the first chunk with a negative
					// value ID-x.  If the index is odd, find a postive ID-x.
					const findNegative = index % 2 == 0 ? true : false;
					for(let i = 0; i < this.GameState.chunks.length; i ++) {
						
						if (this.GameState.chunks[i].id.y < yValue) {
							// Not far along yet, keep going
							continue;
						} else if (this.GameState.chunks[i].id.y > yValue) {
							// Too far.  All chunks here and above are invalid.  Exit early
							return;
						}
						
						// Okay, so this is a valid chunk y-wise at least.  See if it's
						// x-value checks as well
						if (findNegative && this.GameState.chunks[i].id.x < 0 || !findNegative && this.GameState.chunks[i].id.x > 0) {
							
							// It works.
							this.GameState.drawnChunks[index] = this.GameState.chunks[i];
							this.GameState.chunks.splice(i, 1);
							this.chunkToBlobette(index);
							this.drawBlob();
							return;
							
						}
					}
				}
		
				// What if this spot isn't empty, but we have created a new row, and this
				// row is somewhere above?  In that case, we likely need to take this
				// otherwise perfectly good chunk and replace it with the chunk directly
				// above the chunk directly below.  This chunk will be placed back in an
				// appropriate spot in the chunks array
				if (newRow) {
					
					// Just to be sure, using the current index, check to see what the chunk
					// directly below is.  Namely, check it's id x and y values to see if
					// that chunk is NORMALLY directly below this one.
					const belowIndex = index - this.chunksWide;
					if (belowIndex < 0) {
						// Guess we don't need to worry then
						return;
					}
					
					const belowChunk = this.GameState.drawnChunks[index];
					if (belowChunk) {
						
						// Compare IDs
						const validY = belowChunk.id.y + 1 == spot.id.y ||
							belowChunk.id.y == -1 && spot.id.y == 1;
							
						if (belowChunk.id.x != spot.id.x || !validY) {
							
							// Okay, so either it's on a different column or is too far away on
							// the y-axis, or both.  In either case, we're going to replace
							// the current chunk with a chunk that has the same xID value and
							// an appropriate yID.  For now, go ahead and put spot back into
							// the chunks array
							this.resortChunk(spot, this.GameState.chunks);
							this.GameState.drawnChunks[index] = null;
							// Go ahead and also turn any blobettes connect to it into false
							// cells
							this.removeBlobettes(spot);
							
							// Now let's cycle through chunks until we find one with an
							// identical xID as the belowChunk and a yID that is basically one-
							// above the belowChunk
							for(let i = 0; i < this.GameState.chunks.length; i ++) {
								
								const chunk = this.GameState.chunks[i];
								if (chunk.id.x == belowChunk.id.x) {
									
									// Okay, good, now let's check the yID
									if (chunk.id.y == belowChunk.id.y + 1 || belowChunk.id.y == -1 && chunk.id.y == 1) {
										
										// We found our match.
										this.GameState.drawnChunks[index] = this.GameState.chunks[i];
										this.GameState.chunks.splice(i, 1);
										this.chunkToBlobette(index);
										this.drawBlob();
										return;
									}
									
								}
								
							}
							
						}
						
					}
					
				}
			})
		
			// Finally, having gone this far, see if either newRow or shiftRow were
			// tripped.  If so, return true indicating that we need to redraw the
			// screen, false otherwise.
			if (newRow || shiftRow) {
				this.GameEventBus.$emit('churn-rects');
				return true;
			}
			return false;
		
		},
		
		// Takes a chunk from drawn chunks and places it back into the chunks array.
		// For sorting guidelines, see sort
		resortChunk: function(rechunk) {
		
			for(let i = 0; i < this.GameState.chunks.length; i ++) {
				const chunk = this.GameState.chunks[i];
				
				// Compare y-values first.  Smaller y-value is grounds for immediate
				// splicing of the array
				if (rechunk.id.y < chunk.id.y) {
					this.GameState.chunks.splice(i, 0, rechunk);
					return;
					
				} else if (rechunk.id.y == chunk.id.y) {
					
					// Okay, same y-values.  Check x values.
					if (rechunk.id.x > 0) {
						
						if(this.GameState.chunks[i].id.x > rechunk.id.x || this.GameState.chunks[i].id.x < -1) {
							this.GameState.chunks.splice(i, 0, 1);
							return;
						}
						
					} else {
						
						if (this.GameState.chunks[i].id.x < rechunk.id.x) {
							this.GameState.chunks.splice(i, 0, 1);
							return;
						}
						
					}
					
				}
				
			}
		
			// Reached the end of the array?  I guess this chunk goes in dead last
			this.GameState.chunks.push(rechunk);
		},
		
	}
}