/**
 * Since all auth requests are POST requests, package is divided up into
 * more specific use cases.  This is for logging someone in.
 * * * * */

import axios from 'axios';

export default {

	// Logs a user in.  Returns JWT on success
	login: (payload, success, failure) => {
		axios.post(`${process.env.VUE_APP_ENDPOINT}/users/login`, payload)
		.then((response) => {

			// Only thing we care about is the JWT.  Grab it and attach it
			const token = response.data.data.attributes.token;

			// Want to be specific here.  If admin was not in response, then we're
			// explicitly making this false
			const admin = response.data.data.attributes.admin ? true : false;
			localStorage.setItem('token', token);

			if (admin) {
				localStorage.setItem('a', admin);
			}

			// And then call success
			success();

		})
		.catch((error) => {

			// TODO: handle this
      failure(error)

		});
	}

}
