<!-- Implements a simple pattern category.  Can fit into a cluster, can fit
	into a list. -->
<template>
	<div class="pattern-category"
		:class="{'cluster': format == 'cluster'}"
		:style="{'background-color': `#${category.backgroundColor}`}"
		@click="$emit('select', category)">

		<h3 :style="{'color': `#${category.textColor}`}">
			{{ category.name }}
		</h3>

	</div>
</template>

<script>
export default {
	name: "PatternCategory",
	props: ['category', 'format']
}
</script>

<style lang="scss" scoped>
.pattern-category {
	padding: 8px 10px;
	border-radius: 2px;
	box-shadow: 1px 1px 1px #BBB;
	margin: 5px;
	cursor: pointer;

	h3 {
		margin: 0;
	}

	&.cluster {
		display: inline-block;
	}
}
</style>
