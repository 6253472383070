//
// Portion of grid package specifically for drawing the "midpoint", or more
// specifically the lines that intersect the midpoint
/////

import Consts from './consts';

function drawMidpoint(offset, sketch) {

	// Go ahead and define the color and stroke width of the lines
	sketch.stroke('#FF4837');
	sketch.strokeWeight(2);

	const horizontalSegments = this.width / Consts.LINE_GAP;
	const verticalSegments = this.height / Consts.LINE_GAP;

	// Only draw vertical midpoint line if the absolute value of the offset
	// is still less than the width
	const verticalMidX = this.midpoint.x + offset.x;

	if (Math.abs(offset.x) < this.width / 2) {

		for(let i = 0; i < verticalSegments; i += 2) {

			sketch.line(
				verticalMidX,
				i * Consts.LINE_GAP,
				verticalMidX,
				(i + 1) * Consts.LINE_GAP
			);

		}

	}

	// Same for horizontal lines, only this time for offset.y
	const horizontalMidY = this.midpoint.y + offset.y;

	if (Math.abs(offset.y) < this.height / 2) {

		for(let i = 0; i < horizontalSegments; i += 2) {
			sketch.line(
				i * Consts.LINE_GAP,
				horizontalMidY,
				(i + 1) * Consts.LINE_GAP,
				horizontalMidY
			);

		}


	}
}

export { drawMidpoint };
