<script>
import BallConstsMixin from '../../mixins/ball/consts';
export default {
	name: "Paddle",
	props: ['mobile', 'paused', 'screen-width', 'screen-height'],
	inject: ['GameEventBus', 'GameState', 'PIXIWrapper'],
	mixins: [BallConstsMixin], 
	
	data: function() {
		return{
			acceleration: 0,
			container: null,
			DRAG: 0.10,
			goTo: false,
			height: 20,
			keys: {
				shift: false,
				left: false,
				right: false,
			},
			paddleGraphic: null,
			velocity: 0,
			width: 140,
		}
	},
	
	methods: {
		
		// Determines if the ball made contact with the paddle.  We always go with
		// the first collision.  It's extremely unlikely that there's more than one
		// "collision of interest" and even if there was more than one the first
		// result should be good enough for the sake of results.
		contact: function() {
		
		  const topMost = this.paddleGraphic.y;
		  const rightMost = this.paddleGraphic.x + this.width
		  const leftMost = this.paddleGraphic.x;
		  const bottomMost = this.paddleGraphic.y + this.height;
		
		  const ballVec = {
			  x: this.GameState.x,
			  y: this.GameState.y
		  };
		
		  let collisions = [];
		
		  // So, let's take a trip around the paddle
		  for(let i = 0; i < 4; i ++) {
		
			// For top and bottom collisions, see if this.GameState.x is between either the
			// leftMost or rightMost point
			if (i == 0 || i == 2) {
		
			  if (this.GameState.x > leftMost && this.GameState.x < rightMost) {
		
				// It is.  Depending on whether this is top or bottom, we're gonna
				// find the delta between the this.GameState.y and the appropriate paddleY
				// The delta between the two should be less than the ball's combined
				// radius and stroke
				if (i == 0) {
		
				  // Compare delta of topMost and this.GameState.y.  Orientation matters,
				  // if delta is negative then the ball is below the top, which is
				  // bad
				  const delta = topMost - this.GameState.y;
		
				  if (delta >= 0 && delta < this.BALL_RADIUS + this.BALL_STROKE_WEIGHT) {
					// We have a collision.
					collisions.push({
					  ballPoint: { x: this.GameState.x, y: this.GameState.y + this.BALL_RADIUS + this.BALL_STROKE_WEIGHT},
					  paddlePoint: {x: this.GameState.x, y: topMost},
					  side: "top",
					  paddleVelocity: this.velocity
					});
					this.GameState.collision = collisions[0];
					return;
		
				  }
		
				} else if (i == 2) {
		
				  // Literally do nothing.  I do not give a shit about collisions with
				  // the bottom paddle and am too lazy to remove this part
		
				}
			  }
		
			  // Okay, if that's the case then let's check to see if the ball is at
			  // least touching the corners.  If so, we'll cut the user some slack
			  else {
		
				// Convert corner coordinates into a vector.  Then, substract
				// ballVec from it to create a new vector that basically measurses
				// the distance between the two.  If the magnitude of this vector is
				// less than the ball's radius + stroke, then we have a winner
				let delta = {
					x: leftMost,
					y: topMost
				};
				delta.x -= ballVec.x;
				delta.y -= ballVec.y;
				
				let deltaMag = Math.sqrt(delta.x * delta.x + delta.y * delta.y);
				if (deltaMag < this.BALL_RADIUS + this.BALL_STROKE_WEIGHT) {
		
				  // Contact.
				  collisions.push({
					side: "top",
					ballPoint: {x: this.GameState.x, y: this.GameState.y + this.BALL_RADIUS + this.BALL_STROKE_WEIGHT},
					paddlePoint: {x: leftMost, y: topMost},
					paddleVelocity: this.velocity
				  });
				  this.GameState.collision = collisions[0]
				  return;
				}
		
				// Not quite?  Well, let's try the other corner then
				delta = {
					x: rightMost,
					y: topMost
				};
				
				delta.x -= ballVec.x;
				delta.y -= ballVec.y;
				deltaMag = Math.sqrt(delta.x * delta.x + delta.y * delta.y);
				if (deltaMag < this.BALL_RADIUS + this.BALL_STROKE_WEIGHT) {
		
				  // Contact.
				  collisions.push({
					side: "top",
					ballPoint: {x: this.GameState.x, y: this.GameState.y + this.BALL_RADIUS + this.BALL_STROKE_WEIGHT},
					paddlePoint: {x: rightMost, y: topMost},
					paddleVelocity: this.velocity
				  });
				  this.GameState.collision = collisions[0]
				  return;
		
				}
		
			  }
		
			}
		
			if (i == 1 || i == 3) {
		
			  // For left and right collisions, see if this.GameState falls between topMost
			  // and bottomMost position of paddle
			  if (this.GameState.y > topMost && this.GameState.y < bottomMost) {
		
				// Okay, ball is an easy to calculate range.  Draw a vector from the
				// ball center to the paddle
				if (i == 1) {
		
				  const delta = this.GameState.x - rightMost;
		
				  // Orientation matters.  If delta is smaller than zero than the
				  // ball is (presumably) not where it should be
				  if (delta > (this.width / -2) && delta < this.BALL_RADIUS + this.BALL_STROKE_WEIGHT) {
		
					// Contact
					collisions.push({
					  ballPoint: { x: this.GameState.x - this.BALL_RADIUS - this.BALL_STROKE_WEIGHT, y: this.GameState.y },
					  paddlePoint: { x: rightMost, y: this.GameState.y },
					  side: "right",
					  paddleVelocity: this.velocity
					});
					this.GameState.collision = collisions[0];
					return;
		
				  }
		
				} else if (i == 3) {
		
				  const delta = leftMost - this.GameState.x;
		
				  if (delta > (this.width / -2) && delta < this.BALL_RADIUS + this.BALL_STROKE_WEIGHT) {
		
					// Contact
					collisions.push({
					  ballPoint: { x: this.GameState.x + this.BALL_RADIUS + this.BALL_STROKE_WEIGHT, y: this.GameState.y },
					  paddlePoint: { x: leftMost, y: this.GameState.y },
					  side: "left",
					  paddleVelocity: this.velocity
					});
					this.GameState.collision = collisions[0]
					return;
				  }
		
				}
		
			  }
		
			}
		
		  }
		  
		},

		draw: function() {
			
			if (this.container == null) {
				this.container = new this.PIXIWrapper.PIXI.Container();
				this.GameState.container.addChild(this.container);
			}
			
			if (this.paddleGraphic == null) {
				this.paddleGraphic = new this.PIXIWrapper.PIXI.Graphics();
			} else {
				this.paddleGraphic.clear();
			}
			
			this.paddleGraphic.beginFill(0xFFFFFF);
			this.paddleGraphic.lineStyle(4, 0x000000);
			this.paddleGraphic.drawRect(0, 0, this.width, this.height);
			this.paddleGraphic.endFill();
			
			// Set initial positions
			this.paddleGraphic.x = this.screenWidth / 2 - this.width / 2;
			if (this.mobile) {
				this.paddleGraphic.y = this.screenheight - this.height - 90 - this.height / 2;
			} else {
				this.paddleGraphic.y = this.screenHeight - this.height - 20 - this.height / 2;
			}
			
			this.container.addChild(this.paddleGraphic);
		},
		
		handleKeyDown: function(keyCode) {
			
			if (keyCode === "ArrowLeft") {
				this.acceleration = -2;
				this.goTo = false;
				return;
			}
			
			if (keyCode === "ArrowRight") {
				this.acceleration = 2;
				this.goTo = false;
				return;
			}
		},
		
		movePaddle: function() {
			
			if(!this.paddleGraphic) {
				return;
			}
			

			// Go ahead and update the velocity
			this.velocity += this.acceleration - (this.velocity * this.DRAG);
			
			// Then the position along the x-axis
			this.paddleGraphic.x += this.velocity;
			
			// Did the user click on or touch on a space on the screen?  If so, are we
			// past it?  If so, stop moving
			const condition = this.goTo && (
					(this.paddleGraphic.x >= this.goToX && this.acceleration > 0)
					|| (this.paddleGraphic.x <= this.goToX && this.acceleration < 0)
				);
			if (condition) {
				this.acceleration = 0;
				this.velocity = 0;
				this.paddleGraphic = this.goToX;
			}
				
			// Additionally, make sure that the paddle doesn't move too far past the
			// edges of the screen (basically ensure a small amount still remains)
			if (this.GameState.pin) {
			
				if (this.paddleGraphic.x < (this.BALL_DIAMETER + this.BALL_STROKE_WEIGHT + 4) / 2 - this.width / 2 + 10) {
					this.paddleGraphic.x = (this.BALL_DIAMETER + this.BALL_STROKE_WEIGHT + 4) / 2 - this.width / 2 + 10;
				} else if (this.paddleGraphic.x > this.screenWidth - (this.BALL_DIAMETER + this.BALL_STROKE_WEIGHT + 4) / 2 - this.width / 2 - 10) {
					this.paddleGraphic.x = this.screenWidth - (this.BALL_DIAMETER + this.BALL_STROKE_WEIGHT + 4) / 2 - this.width / 2 - 10;
				}
			
			} else if (this.paddleGraphic.x < (0 - this.width * 0.4) - this.width / 2) {
				this.paddleGraphic.x = 0 - this.width * 0.4 - this.width / 2;
			} else if (this.paddleGraphic.x > (this.screenWidth + this.width * 0.4) - this.width / 2) {
				this.paddleGraphic.x = (this.screenWidth + this.width * 0.4) - this.width / 2;
			}
			
			if (this.GameState.pin) {
				this.GameState.x = this.paddleGraphic.x + this.width / 2;
			}
			
			// And check for collisions;
			this.contact();


		},
		
		// Handles stage mouse events where the user has clicked on a point somewhere
		// on this Canvas.  This basically instructs the paddle to move to that location
		moveTo: function(e) {
		
		  if (e.mouseX > this.paddleGraphic.x + this.width / 2) {
			this.acceleration = 2;
		  } else if (e.mouseX < this.paddleGraphic.x + this.width / 2) {
			this.acceleration = -2;
		  }
		
		  this.goTo = true;
		  this.goToX = e.mouseX
		
		},
		
		// Responds to a touch move event (i.e. the user moved their finger across
		// the screen).  If go-to is true, then we'll update goToX to whatever the
		// mouse value is, and update the acceleration if necessary
		updateMoveTo: function(e) {
		
		  if (this.goTo) {
			this.goToX = e.mouseX;
		  }
		
		  if (e.mouseX > this.paddleGraphic.x + this.width / 2) {
			this.acceleration = 2;
		  } else if (e.mouseX < this.paddleGraphic.x + this.width / 2) {
			this.acceleration = -2;
		  }
		
		},
		
		slow: function() {
			this.acceleration = 0;
		}
		
	},
	
	beforeDestroy: function() {
		
		this.PIXIWrapper.PIXIApp.ticker.remove(this.movePaddle);
		this.GameEventBus.$off('arrow', this.handleKeyDown);
		this.GameEventBus.$off('arrow-release', this.slow);
		
		if (this.container != null) {
			this.GameState.container(this.container);
			this.container.destroy({
				children: true
			});
		} else {
			
			if (this.paddleGraphic != null) {
				this.paddleGraphic.destroy();
			}
			
		}
		
		this.container = null;
		this.paddleGraphic = null;

	},
	
	mounted: function() {
		this.draw();
		
		// Setup some key events
		this.GameEventBus.$on('arrow', this.handleKeyDown);
		this.GameEventBus.$on('arrow-release', this.slow);
		
		// And add some events to the ticker
		this.PIXIWrapper.PIXIApp.ticker.add(this.movePaddle);
		
	},
	
	render: function(h) {
		return h('template');
	}
}
</script>