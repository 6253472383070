import Vue from 'vue';
import VueX from 'vuex';

import Levels from './levels';
import LevelColors from './level-colors';
import Orders from './orders';
import Patterns from './patterns';
import PatternAssignments from './pattern-assignments';
import PatternCategories from './pattern-categories';
import PatternColors from './patterncolors';
import Poster from './poster';

Vue.use(VueX);

export default new VueX.Store({

	state: {

		// This one is special
		finishLevel: null,

		poster: null,

		levels: [],
		levelColors: [],
		orders: [],
		patterns: [],
		patternAssignments: [],
		patternCategories: [],
		patternColors: [],

	},

	mutations: {

		addFinishedLevel: Levels.addFinishedLevel,
		addLevels: Levels.addLevels,

		addLevelColors: LevelColors.addLevelColors,
		removeLevelColors: LevelColors.removeLevelColors,

		addOrders: Orders.addOrders,

		addPatterns: Patterns.addPatterns,
		removePattern: Patterns.removePattern,

		addPatternAssignments: PatternAssignments.addPatternAssignments,
		removePatternAssignment: PatternAssignments.removePatternAssignment,

		addPatternCategories: PatternCategories.addPatternCategories,
		removePatternCategory: PatternCategories.removePatternCategory,

		addPatternColors: PatternColors.addColors,

		addPoster: Poster.addPoster,

	}

});
