<!-- Poster is a page (mostly implemented in p5) for letting the user pick out
	the details of a poster, namely the size, orientation (landscape or portrait),
	and the scaling of their finished level on the page -->
<template>

	<div ref="poster">

		<Navbar />

		<div class="poster">

			<div v-if="!finishedLevel">
				<h2 >Couldn't find any levels to turn into a poster.</h2>
				<p>Have you finished any levels in Mondranoid recently?</p>
			</div>

			<VueP5 v-else-if="ready"
				@setup="preSetup"
				@draw="draw"
				@mousedragged="mouseDragged"
				@mousewheel="mouseWheel"
				@mousepressed="mousePressed"
				@touchmoved="mouseDragged"
				@touchstarted="mousePressed"/>

			<PosterControls
				:hide="controls"
				@toggle="controls = !controls"
				:material="material"
				@material="material = $event"
				:orientation="orientation"
				@orientation="orientation = $event"
				:size="size"
				@size="size = $event"
				:units="units"
				@units="units = $event"/>

			<CostAndConfirm
				:size="size"
				:material="material"
				:units="units"
				@confirm="handleCheckout"/>

		</div>

	</div>

</template>

<script>
import { changeDpiBlob } from 'changedpi';
import ColorQueue from '../game/colorqueue';
import CostAndConfirm from '../components/poster/CostAndConfirm.vue';
import LevelBoundary from '../poster/level-boundary';
import Hammer from 'hammerjs/hammer.min';
import MobileMixin from '../mixins/mobile';
import Navbar from '../components/Navbar.vue';
import PosterBoundary from '../poster/poster-boundary';
import PosterControls from '../components/poster/PosterControls.vue';
//import Rects from '../game/rects';
import VueP5 from 'vue-p5';

const RECT_WIDTH = 80;
const RECT_HEIGHT = 40;
const STROKE_WEIGHT = 8;

const MIN_DPI = 150;
const MAX_DPI = 300;

export default {
	name: "Poster",
	components: { CostAndConfirm, Navbar, PosterControls, VueP5 },
	mixins: [ MobileMixin ],
	data: function() {
		return {
			boundary: null,
			checkout: false,
			colors: null,
			controls: false,
			cost: 0,
			levelBoundary: null,
			mobile: false,
			offset: {
				x: 0,
				y: 0,
			},
			originalMouse: {
				x: 0,
				y: 0
			},
			panning: false,
			patterns: null,
			ready: false,
			rects: null,
			scale: 1,
			sketch: null,
			zoom: 0.5,

			// Poster attributes
			material: 'matte',
			orientation: 'portrait',
			size: '18x24',
			units: 'in'
		}
	},

	computed: {

		// Retrieves the finished level.  Checks localStorage first.  If that
		// doesn't work then it will check the store
		finishedLevel: function() {

			// Try local storage
			let level = JSON.parse(localStorage.getItem('level'));
			if (level) {
				return level;
			}

			// Okay, that didn't work.  Check the store then
			level = this.$store.state.finishedLevel;
			if (!level) {
				// We're returning false explicitly since, by default, level is null,
				// which is ambiguous, or whatever
				return false;
			}

			return level;

		}
	},

	methods: {

		handleCheckout: function(cost) {
			this.checkout = true;
			this.cost = cost;
		},

		// If user does a gesture that looks somewhat like a pinching gesture,
		// complete with two distinct "pointers", then we change the zoom value if
		// and only if we are at the end
		handlePinch: function(evt) {

			// Don't do anything if the controls are out
			if (this.isMobile && !this.controls) {
				return;
			}

			let newZoom = this.zoom;
			if (evt.scale > 1) {
				newZoom += (evt.scale - 1) / 10;
			} else {
				newZoom -= (1 - evt.scale) / 10;
			}

			if (newZoom >= 0.4 && newZoom <= 2.5) {
				this.zoom = newZoom;
			} else if (newZoom < 0.4) {
				this.zoom = 0.3999;
			} else {
				this.zoom = 2.5001;
			}

		},

		reScale: function() {

			if (!this.size) {
				return;
			}

			const dimensions = this.size.split('x');

			const width = this.orientation == 'portrait' ? dimensions[0] - 2 : dimensions[1] - 2;
			const height = this.orientation == 'portrait' ? dimensions[1] - 2 : dimensions[0] - 2;

			// Great, now compare the width and height with the width and height of
			// the bounding box and get a scaling factor for both x and y that will
			// determine how much we need to scale the level in order to get it to fit
			const pixels = this.units == 'in' ? 40 : 40 / 2.54;

			const scalingX = (width * pixels) / this.levelBoundary.width;
			const scalingY = (height * pixels) / this.levelBoundary.height;

			// Okay, so we have our scaling factors.  Regardless of actual values, we
			// always choose the smaller one when it comes to scaling to fit.
			this.scale = Math.min(scalingX, scalingY);

		},

		preSetup: function(sketch) {

			this.patterns = this.finishedLevel.patterns;
			this.levelColors = this.finishedLevel.levelColors;
			this.patternColors = this.finishedLevel.patternColors;

			this.colors = new ColorQueue(this.patterns, sketch, this.mobile,
				this.patternColors, this.levelColors, false, true, this.setup);

		},

		setup: function(sketch) {

			this.sketch = sketch;
			sketch.createCanvas(sketch.windowWidth, sketch.windowHeight);

			// Get our rects setup, don't bother with rows or columns though.  We have
			// something special planned
			if (!this.rects) {

				this.rects = new Rects(
					RECT_WIDTH,
					RECT_HEIGHT,
					STROKE_WEIGHT
				);

				this.rects.cells = this.finishedLevel.cells;
				this.rects.resetImages(this.colors.patterns);

			}

			// Next, let's set up the "level controls".  This provides some handles,
			// as well as a background, for our levels
			this.levelBoundary = new LevelBoundary(this.finishedLevel.cells, sketch);

			// Then setup the boundary
			this.boundary = new PosterBoundary(sketch);

			this.reScale();

		},

		draw: function(sketch) {

			// A weird hack.  We set the drawn chunks and remaining chunks args to
			// empty arrays to "trick" it to draw all cells
			if (!this.rects) {
				return;
			}

			if (this.checkout) {

				sketch.noLoop();

				// User has clicked the checkout button.  We're going to call create
				// graphics to create a new canvas the size of our poster, with 250
				// pixels per inch, as well as a second "preview" canvas that is 1/10th
				// the size
				const dimensions = this.size.split('x');
				const dpi = 250;
				const width = this.orientation == 'portrait' ? dimensions[0] * dpi : dimensions[1] * dpi;
				const height = this.orientation == 'portrait' ? dimensions[1] * dpi : dimensions[0] * dpi;
				sketch.pixelDensity(1);
				const poster = sketch.createGraphics(width, height);
				const preview = sketch.createGraphics(width / 10, height / 10);
				poster.background('#FFFFFF');

				this.rects.draw(
					[],
					[],
					0,
					false,
					poster,
					{x: 0, y: 0},
					1,
					this.scale * (dpi/ 40),
					this.levelBoundary.offset
				);
				this.rects.draw(
					[],
					[],
					0,
					false,
					preview,
					{x: 0, y: 0},
					1,
					this.scale * ((dpi / 40) / 10),
					this.levelBoundary.offset
				);

				let _this = this;
				preview.canvas.toBlob(function(previewBlob) {

					poster.canvas.toBlob(function(posterBlob) {

						changeDpiBlob(posterBlob, 250).then(function(posterBlobBetter) {

							_this.$store.commit('addPoster', {
								poster: posterBlobBetter,
								preview: previewBlob,
								orientation: _this.orientation,
								size: _this.size,
								material: _this.material,
								cost: _this.cost
							});

							_this.checkout = false;
							_this.$router.push({name: 'Checkout'});

						})

					}, "image/png")
				}, "image/png")

			}

			sketch.background('#FFFFE5');

			const dimensions = this.size.split('x');

			if (this.orientation == 'portrait') {
				this.boundary.draw(
					dimensions[0],
					dimensions[1],
					this.offset,
					this.zoom,
					this.units,
					sketch
				);
			} else {
				this.boundary.draw(
					dimensions[1],
					dimensions[0],
					this.offset,
					this.zoom,
					this.units,
					sketch
				);
			}


			this.rects.draw(
				[],
				[],
				0,
				false,
				sketch,
				this.offset,
				this.zoom,
				this.scale,
				this.levelBoundary.offset
			);

		},

		// Depending on where the mouse was pressed, this will either pan the
		// selection, or it will move the finished level
		mousePressed: function(sketch) {

			// Don't do anything if the controls are out
			if (this.mobile && !this.controls) {
				return;
			}

			this.originalMouse.x = sketch.mouseX - this.offset.x;
			this.originalMouse.y = sketch.mouseY - this.offset.y;

			this.panning = true;

		},

		mouseDragged: function(sketch) {

			// Don't do anything if the controls are out
			if (this.mobile && !this.controls) {
				return;
			}

			if (this.panning) {
				this.offset.x = sketch.mouseX - this.originalMouse.x;
				this.offset.y = sketch.mouseY - this.originalMouse.y;
			}

		},

		// Used for zoom events
		mouseWheel: function(sketch, eventData) {

			const newZoom = this.zoom + (eventData.delta / 1000);

			if (newZoom >= 0.25 && newZoom <= 2) {
				this.zoom = newZoom;
			} else if (newZoom < 0.5) {
				this.zoom = 0.2499;
			} else {
				this.zoom = 2.0001;
			}

		}

	},

	beforeDestroy: function() {
		this.sketch.remove();
		this.sketch = null;
		gl.getExtension('WEBGL_lose_context').loseContext();
	},

	mounted: function() {

		if (this.finishedLevel) {
			this.ready = true;
		}

		this.mobile = this.isMobile();
		if (this.mobile) {
			this.controls = true;
		}

		// Attach hammer to the enclosing div.  Listen to pinch events.  Same story
		// that we have with the game page.
		const posterElement = this.$refs.poster;
		const hammer = new Hammer(posterElement);
		hammer.get('pinch').set({enable: true});
		hammer.on('pinch', this.handlePinch);


	},

	watch: {

		finishedLevel: function() {

			if (this.finishedLevel) {

				this.ready = true;

				if (this.rects) {

					this.patterns = this.finishedLevel.patterns;
					this.levelColors = this.finishedLevel.levelColors;
					this.patternColors = this.finishedLevel.patternColors;
					this.rects.cells = this.finishedLevel.cells;

				} else {

					this.rects = new Rects(
						RECT_WIDTH,
						RECT_HEIGHT,
						STROKE_WEIGHT
					);
					this.patterns = this.finishedLevel.patterns;
					this.levelColors = this.finishedLevel.levelColors;
					this.patternColors = this.finishedLevel.patternColors;
					this.rects.cells = this.finishedLevel.cells;

				}

			}

		},

		orientation: function() {
			this.reScale();
		},

		// When there are changes to the size of the poster, compare the new size
		// with the known boundaries of the level.  If the level is currently larger
		// or smaller than the size, then attempt to scale to fit.
		size: function() {
			this.reScale();
		}

	}
}
</script>

<style lang="scss" scoped>
.poster {
	overflow: hidden;
	height: 100%;
	width: 100%;
	position: absolute;
}
</style>
