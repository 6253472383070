<!-- PIXI representation of the "blob". -->

<script>
// Height of blobette actually, not the whole blobby mass.  Could be confusing
const BLOB_HEIGHT = 40

// Width of blobette.
const BLOB_WIDTH = 80

// How much space should be at the bottom, at minimum
const BOTTOM_PADDING = 160

// Height of chunks in blobettes
const CHUNK_HEIGHT = 6

// Width of chunks in blobettes
const CHUNK_WIDTH = 4

const LEFT_PADDING = 60

// How much space should be at the top, at minimum
const TOP_PADDING = 80

import BlobetteMixin from '../../mixins/blob/blobette'
import ChunksMixin from '../../mixins/blob/chunks';
import CollisionsMixin from '../../mixins/blob/collisions';
import InitialChunksMixin from '../../mixins/blob/initial-chunks';
import LineDrawingMixin from '../../mixins/blob/line-drawing';
import MinMaxMixin from '../../mixins/blob/min-max';
import TotalRowsColsMixin from '../../mixins/blob/total-rows-cols';
export default {
	name: "Blob",
	inject: ['GameEventBus', 'GameState', 'PIXIWrapper'],
	mixins: [
		BlobetteMixin,
		ChunksMixin,
		CollisionsMixin,
		InitialChunksMixin,
		LineDrawingMixin,
		MinMaxMixin,
		TotalRowsColsMixin
	],
	props: ['layout', 'screenWidth', 'screenHeight'],
	
	data: function() {
		return {
			blobettes: [],
			blobetteSprites: [],
			container: null,
			blobGraphic: null,
			chunks: [],
			drawBlobettes: false,
		}
	},
	
	computed: {
		
		// Determines how many chunks can fit onto the screen with room to spare
		// width-wise.
		chunksWide: function() {
		
			// First, padding.  We want about a blob's amount of padding on either side
			// at least
			const widthWithPadding = this.screenWidth - LEFT_PADDING * 2;
		
			// Next, see how many columns we could fit
			const columns = Math.floor(widthWithPadding / BLOB_WIDTH);
		
			// Now divide that number by the width of our chunks to get the chunks on
			// screen
			let chunkWide = Math.floor(columns / CHUNK_WIDTH);
		
			// Quick caveat.  Having an odd number of chunks across is kind of shitty
			// unless the user is playing on a very small screen.  So, if chunkWide
			// is odd AND greater than 1, then reduce by one
			if (chunkWide > 1 && chunkWide % 2 == 1) {
				chunkWide --;
			}
		
			// Also, if chunkWide is 0, then just set it to 1.  Scaling will take care
			// of scaling it down for us
			if (chunkWide < 1) {
				chunkWide = 1;
			}
			return chunkWide;
		
		},
		
		chunksTall: function() {
		
			// First, padding.  We want decent clearance between ball / paddle and
			// blobettes.  We also want same amount of padding on top as we do on the
			// sides (A blob-width's amount)
			const heightWithPadding = this.screenHeight - BOTTOM_PADDING -
				TOP_PADDING;
		
			// Now let's divide that amount by BLOB_HEIGHT to see how many row may fit
			const rows = Math.floor(heightWithPadding / BLOB_HEIGHT);
		
			// And then divide that amount by CHUNK_HEIGHT to get chunks we can fit onto
			// a screen
			return Math.floor(rows / CHUNK_HEIGHT);
		
		},

		scaleToFitWide: function() {
			// First, padding.  We want about a blob's amount of padding on either side
			// at least
			const widthWithPadding = this.screenWidth - LEFT_PADDING * 2;
			
			// Now see how large widthWithPadding is compared to the width of a standard
			// chunk
			let widthRatio = widthWithPadding / (CHUNK_WIDTH *
				BLOB_WIDTH);
			
			// If widthRatio is greater than one, then just return 1.  Otherwise,
			// return the computed value
			if (widthRatio > 1) {
				return 1;
			}
			
			widthRatio = this.screenWidth / ((CHUNK_WIDTH + (LEFT_PADDING * 2) / BLOB_WIDTH) *
				BLOB_WIDTH);
			
			// Note that scaling works best when it's rounded down to a tenth.  So,
			// return said value.
			widthRatio = Math.floor(widthRatio * 10) / 10;
			
			return widthRatio;
			
		},
		
		// Tells you how many rows be shown on the screen
		shownRows: function() {
			return this.chunksTall * CHUNK_HEIGHT;
		},
		
		// Tells you how many columns can be shown on the screen
		shownColumns: function() {
			return this.chunksWide * CHUNK_WIDTH;
		},
	
	},
	
	methods: {
		
		drawBlob: function() {
			
			// Setup a container, if necessary
			if (this.container == null) {
				this.container = new this.PIXIWrapper.PIXI.Container();
			}
			
			// And either init the drawable, or clear it
			if (this.blobGraphic == null) {
				this.blobGraphic = new this.PIXIWrapper.PIXI.Graphics();
			} else {
				this.blobGraphic.clear();
			}
			
			// for blobetteSprites, go through the array and delete what we've already
			// created
			while(this.blobetteSprites.length) {
				const sprite = this.blobetteSprites[0];
				
				this.container.removeChild(sprite.left);
				this.container.removeChild(sprite.right);
				sprite.left.destroy();
				sprite.right.destroy();
				
				this.blobetteSprites.splice(0, 1);
			}
			
			// We then iterate through each blobette and draw a sprite
			const blobTexture = this.PIXIWrapper.PIXI.Loader.shared.resources.mondrablob.texture;
			
			this.blobGraphic.lineStyle(8, 0x000000, 1, 0.5);
			
			this.blobettes.forEach((blobette, index) => {
			
				// Okay this is where it gets tricky  These are rectangles, but they're
				// drawn to look like they're part of one mass.  Regardless, draw a rect
				if (blobette) {
					
					// Draw the image using our tiling sprite
					const sprite = {
						left: new this.PIXIWrapper.PIXI.Sprite(blobTexture),
						right: new this.PIXIWrapper.PIXI.Sprite(blobTexture)
					}
					
					sprite.left.position.x = blobette.x;
					sprite.left.position.y = blobette.y;
					
					sprite.right.position.x = blobette.x + 40;
					sprite.right.position.y = blobette.y;
					
					sprite.left.width = 40;
					sprite.left.height = 40;
					
					sprite.right.width = 40;
					sprite.right.height = 40;
					
					this.container.addChild(sprite.left);
					this.container.addChild(sprite.right);
					
					this.blobetteSprites.push(sprite);
					
					// Now what we're going to do is see what lines need to be drawn around
					// the blobette.  We'll iterate around the 4 cardinal directions and
					// see what needs to be done.
					for(let i = 0; i < 4; i ++) {
			
						if (i == 0) {
							this.drawTopLine(index);
						}
						if (i == 1) {
							this.drawRightLine(index);
						}
						if (i == 2) {
							this.drawBottomLine(index);
						} else {
							this.drawLeftLine(index);
						}
						
					}
			
				}
			
			});
			
			this.container.addChild(this.blobGraphic);
			this.GameState.container.addChild(this.container);
			
		}
	},
	
	mounted: function() {
		
		this.GameState.chunks = this.initialChunks.slice(0);
		
		// Allocate space in a drawn chunks array, which contains cells for all
		// the chunks that can fit on the screen
		this.GameState.drawnChunks = [];
		for(let i = 0; i < this.chunksWide * this.chunksTall; i ++) {
			this.GameState.drawnChunks.push(null);
		}
		
		// Also, create a ghost chunks array.  This is for chunks that were removed
		// from the drawnChunks array but are kept around until a new row of chunks
		// is brought in.  They have yet to move on to the other side, if you will
		this.GameState.ghostChunks = [];
		
		// Allocate space in a blobettes array, containing cells for all the
		// blobettes that can be drown on the screen at any given time
		this.blobettes = [];
		for(let i = 0; i < this.shownColumns * this.shownRows; i ++) {
			this.blobettes.push(false);
		}
		
		// Finally let's call populateDrawnChunks to fill drawnChunks with, well,
		// chunks.
		// As a side-effect, it will also fill blobettes with stuff as well
		this.populateDrawnChunks();
		
		//this.shiftCallback = shiftCallback;
		
		if (this.shownRows > 0) {
			this.GameState.threshold = 160 + this.shownRows * 40
		} else {
			this.GameState.threshold = this.screenHeight / 2;
		}
		
		// Finally, add an event listener for when we need to refresh the chunks
		this.GameEventBus.$on('refresh-chunks', this.populateDrawnChunks);
		
	},
	
	render: function(h) {
		return h('template')
	},
	
	watch: {
		
		// Responsible for setting the "threshold" that, if passed by the ball,
		// triggers a refresh of the chunks
		totalRows: function() {
			
			if (this.shownRows > 0) {
				this.GameState.threshold = 160 + this.shownRows * 40
			} else {
				this.GameState.threshold = this.screenHeight / 2;
			}
		}
		
	}
}
</script>