
export default {
	
	computed: {
		
		// Made a method because order matters
		initialChunks: function() {
							
			// Make a copy of the layout
			const layoutCopy = [];
			this.layout.forEach((chunk) => {
				layoutCopy.push({
					id: chunk.id,
					rects: []
				});
			
				chunk.rects.forEach((rect) => {
					layoutCopy[layoutCopy.length - 1].rects.push(rect);
				})
			});
					
			// Sort the chunks in the layout.  The method will tell you how we do it
			return this.sort(layoutCopy);
		}
		
	},
	
	methods: {
		// A method for taking an array of chunks and returning a new array of the
		// same chunks.  But sorted.
		// Sorted in this context means that we first sort from row closest to bottom
		// (negative Y's to positive Y's for chunk IDs) and within each row sort from
		// the "center" defined as the chunk with the smallest -x value and go from
		// all the way positive, then all the way negative (i.e -1, 1, 2, 3, 4, -2, -3, -4)
		sort: function(layout) {
			// Go ahead and initialize an empty array of sorted chunks
			let chunks = [];
			
			layout.forEach((chunk) => {
				
				for(let i = 0; i < chunks.length; i ++) {
					
					// Let's start by looking at their ID y-value.  Lowest is best
					if (chunk.id.y < chunks[i].id.y) {
						
						// Insert
						chunks.splice(i, 0, chunk);
						return;
						
					} else if (chunk.id.y == chunks[i].id.y) {
						
						// Special case.  If x == -1, immediately splice it in.  It's always
						// is first on a row
						if (chunk.id.x == -1) {
							chunks.splice(i, 0, chunk);
							return;
						}
						
						// If they're on the same row, then check x value.  If it's positive,
						// then we're going to keep going until we find an xID that is larger
						// or one that is negative.  If it's negative, then we're going to
						// keep going until we find the smallest value.
						if (chunk.id.x > 0) {
							
							if (chunks[i].id.x > chunk.id.x || chunks[i].id.x <= -2) {
								chunks.splice(i, 0, chunk);
								return;
							}
							
						} else {
							
							if (chunks[i].id.x < chunk.id.x) {
								chunks.splice(i, 0, chunk);
								return;
							}
							
						}
						
					}
				}
				
				// If we got this far, then this chunk must be dead last.  Push it into
				// the array
				chunks.push(chunk);
				
			});
			
			// Finally, once we're done, return the chunks
			return chunks;
		}
	}
}