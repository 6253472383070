//
// Portion of package containing methods which handle DELETE requests.
/////

import axios from 'axios';

export default {

	pattern: (patternID, success, failure) => {
		axios.delete(`${process.env.VUE_APP_ENDPOINT}/patterns/${patternID}`, {
			headers: {"Authorization": "Bearer " + localStorage.getItem('token')}
		})
		.then((response) => {
			success();
		}).
		catch((error) => {
			// TODO
		});
	}
}
