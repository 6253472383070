<!-- Page for displaying a single level.  More specifically, page for displaying
	a single level so you may edit it, or for working on a level that doesn't
	exist yet -->
<template>
	<div>
		<LevelEditor
			:level="level"/>
	</div>
</template>

<script>
import LevelEditor from '../components/LevelEditor.vue';
import LevelRequest from '../network/levels';
import Vue from 'vue';
export default {
	name: "EditLevel",
	props: ['levelID'],
	components: { LevelEditor },
	data: function() {
		return {
			name: '',
			layout: null,
			angle: 0,
			published: false,
			LevelEventBus: new Vue()
		}
	},

	computed: {

		// Attempts to retrieve the level from the store based on the levelID
		// passed in
		level: function() {

			for(let i = 0; i < this.$store.state.levels.length; i ++) {
				let level = this.$store.state.levels[i];
				if (level.id == this.levelID) {
					return level;
				}
			}

			return false;

		},

		// Simply tells us if levelID is present.  If not, then this is set to true.
		newLevel: function() {
			if (this.levelID || this.level) {
				return false;
			}
			return true;
		}

	},

	methods: {

		// Adds the given pattern to this level
		addPattern: function(pattern) {

			// Store-wise, we'll want to update the level with a new ID on it's array
			// of patternIDs.  Only if the level exist though.  If it doesn't then we
			// can skip this step
			if (!this.newLevel) {

				if (pattern.id == "random") {

					// Special case for random patterns.  We take the level's random
					// attribute and increment it.
					let level = Object.assign({}, this.level);
					level.randomPatterns ++;
					this.$store.commit('addLevels', [level]);

				} else {

					let patternIDs = this.level.patternIDs ? this.level.patternIDs : [];
					patternIDs.push(pattern.id);
					let level = Object.assign(this.level, {patternIDs});
					this.$store.commit('addLevels', [level]);

				}

			}

			// Next, let's send off the request.  If the level hasn't been created yet
			// then this will also simultaneously create the level
			let ids = {
				levelID: this.levelID ? this.levelID : 'new',
				patternID: pattern.id
			}
			let store = this.$store;

			let payload = null;
			if (this.newLevel) {
				payload = JSON.stringify({
					type: "level",
					attributes: {
						name: this.name,
						layout: JSON.stringify(this.layout),
						angle: this.angle
					}
				});
			}
			LevelRequest.addPatternToLevel(ids, payload, (level) => {
				// If we just created a new level, then we'll want to add it to the
				// store
				if (level) {
					store.commit('addLevels', [level]);
				}
			});

		},

		// Removes a pattern from the level.  Or, rather, removes the assignment
		removePattern: function(pattern) {

			// First, let's go ahead and remove the association client-side
			let level = Object.assign({}, this.level);
			if (pattern == 'random') {

				level.randomPatterns --;

			} else {

				// Remove patternID
				for(let i = 0; i < level.patternIDs.length; i ++) {

					let patternID = level.patternIDs[i];
					if (patternID == pattern.id) {
						level.patternIDs.splice(i, 1);
					}
					break;

				}

				// Also, remove pattern assignment
				this.$store.commit('removePatternAssignment', pattern.assignmentID);

			}

			this.$store.commit('addLevels', [level]);

			// Next, and we'll change this later, but remove the pattern from the
			// level server-side.
			let ids = {
				levelID: level.id,
				assignmentID: pattern == 'random' ? pattern : pattern.assignmentID
			}
			LevelRequest.removePatternFromLevel(ids, () => {
				// TODO:
			});

		},

		publishLevel: function() {

			const level = Object.assign({}, this.level);
			level.published = !level.published;
			this.$store.commit('addLevels', [level]);

			const payload = JSON.stringify({
				data: {
					id: this.levelID,
					type: "level",
					attributes: {
						published: `${level.published}`
					}
				}
			});

			LevelRequest.updateLevel(this.levelID, payload, () => {});

		},

		saveLevel: function() {

			// Go ahead and construct a payload based off what we have
			let payload = JSON.stringify({
				data: {
					id: this.levelID,
					type: "level",
					attributes: {
						name: this.name,
						layout: JSON.stringify(this.layout),
						angle: this.angle
					}
				}
			});

			// Let's also get a handle on the store
			let store = this.$store;

			// New level?  Then take what we have and save it.
			if (this.newLevel) {

				LevelRequest.addLevel(payload, (level) => {
					store.commit('addLevels', [level]);
				});

			} else if (this.level) {

				LevelRequest.updateLevel(this.levelID, payload, () => {
					// Do nothing
				});

			}
		},

		updateLayout: function(chunks) {

			// layout might have some empty chunks.  Get rid of them.
			for(let i = 0; i < chunks.length; i ++) {
				let chunk = chunks[i];
				if (!chunk.rects || chunk.rects.length == 0) {
					chunks.splice(i, 1);
					i --;
				}
			}

			this.layout = chunks;
		}

	},

	// If a levelID is present, then attempt to retrieve the level.  Additionally,
	// setup some event listeners on the event bus
	mounted: function() {

		// Retrieves the level in question, including all its fun stuff
		let store = this.$store;
		LevelRequest.getLevel(this.levelID, true, 0, (level, categories, patterns, colors, levelColors, assignments) => {
			this.layout = level.layout;
			store.commit('addLevels', [level]);
			// /store.commit('addCategories', categories);
			store.commit('addPatterns', patterns);
			store.commit('addPatternColors', colors);
			store.commit('addLevelColors', levelColors);
			store.commit('addPatternAssignments', assignments)
		});

		// Setup some event listeners
		this.LevelEventBus.$on('save', this.saveLevel);
		this.LevelEventBus.$on('layout', this.updateLayout);
		this.LevelEventBus.$on('pattern', this.addPattern);
		this.LevelEventBus.$on('publish', this.publishLevel);
		this.LevelEventBus.$on('remove-pattern', this.removePattern);

	},

	provide: function() {
		return {
			LevelEventBus: this.LevelEventBus
		}
	}
}
</script>
