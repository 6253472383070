//
// A package for making requests involving level colors.
/////

import Post from './post';
import Put from './put';

export default {

	addColor: Post.color,

	updateColor: Put.color
	
}
