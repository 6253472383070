<template>
	<div class="color-slider">
		
		<input type="range" min="0" max="255" step="1" v-if="channel != 'alpha'"
			:value="color"
			@input="$emit('color', $event.target.value)"/>
		  
		<input type="range" min="0" max="1" :step="1 / 255" v-else
			:value="color"
			@input="$emit('color', $event.target.value)"/>
			
		<div class="slider-tracks"
			:style="{'background': gradient}">
		</div>
		
	</div>
</template>

<script>
export default {
	name: "ColorSlider",
	props: ['channel', 'color', 'value', 'whole-color'],
	
	computed: {
		
		// Generates a color gradient from a color with 0 of the color this slider
		// deals with, all the way up to max
		gradient: function() {
			
			let minColor = {
				red: this.channel == 'red' ? 0 : this.wholeColor.red,
				green: this.channel == 'green' ? 0: this.wholeColor.green,
				blue: this.channel == 'blue' ? 0: this.wholeColor.blue,
				alpha: this.channel == 'alpha' ? 0 : this.wholeColor.alpha,
			};
			
			let maxColor = {
				red: this.channel == 'red' ? 255 : this.wholeColor.red,
				green: this.channel == 'green' ? 255 : this.wholeColor.green,
				blue: this.channel == 'blue' ? 255 : this.wholeColor.blue,
				alpha: this.channel == 'alpha' ? 1 : this.wholeColor.alpha
			};
						
			return `linear-gradient(0.25turn, rgba(${minColor.red}, ${minColor.green}, ${minColor.blue}, ${minColor.alpha}), rgba(${maxColor.red}, ${maxColor.green}, ${maxColor.blue}, ${maxColor.alpha}))`;
			
		}
	}

}
</script>

<style lang="scss" scoped>
/**
 * CSS used to hide the input range
 */
input[type=range] {
  -webkit-appearance: none; // Hides the slider so that custom slider can be made
  width: 100%; // Specific width is required for Firefox.
  background: transparent; // Otherwise white in Chrome
  position: absolute;
  z-index: 1000;
  height: 10px;
  margin: 0;
  padding: 0;
  left: 0;

  &::-webkit-slider-thumb {
	-webkit-appearance: none;
	display: none;
  }

  // This removes the blue border when focused.  Useful
  &:focus {
	outline: none;
  }

  &::-ms-track {
	width: 100%;
	cursor: pointer;

	// Hides the slider so custom styles can be added
	background: transparent;
	border-color: transparent;
	color: transparent;
  }
}

/* Special styling for WebKit/Blink */
input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 12px;
  width: 12px;
  border-radius: 0px;
  background: #ffffff;
  border: 4px solid #333;
  cursor: pointer;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
  margin-top: -14px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
}

/* All the same stuff for Firefox */
input[type=range]::-moz-range-thumb {
  height: 12px;
  width: 12px;
  border-radius: 0px;
  background: #ffffff;
  border: 4px solid #333;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

/* All the same stuff for IE */
input[type=range]::-ms-thumb {
  height: 12px;
  width: 12px;
  border-radius: 0px;
  background: #ffffff;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
  border: 4px solid #333;
  cursor: pointer;
}

.color-slider {
  width: 100%;
  padding: 6px 0;
  margin: 0px auto;
  position: relative;
}

.slider-tracks {
  position: relative;
  border-radius: 0px;
  border: 4px solid #333;
  box-shadow: inset 1px 1px 2px rgba(0, 0, 0, 0.2);
  height: 12px;
}
</style>