<!-- Component that tells the user how to move the paddle using their keys. -->
<template>

	<div>

		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 250 250">
			<title>Moving Keys</title>
			<g id="Moving_Static" data-name="Moving Static">
				<path d="M157.26,207H92.74v18a5,5,0,0,0,5,5h54.52a5,5,0,0,0,5-5Z" style="fill:#777"/>
				<rect x="94.74" y="161.03" width="60.52" height="60.52" rx="3" style="fill:#fff"/>
				<path d="M152.26,163a1,1,0,0,1,1,1v54.52a1,1,0,0,1-1,1H97.74a1,1,0,0,1-1-1V164a1,1,0,0,1,1-1h54.52m0-4H97.74a5,5,0,0,0-5,5v54.52a5,5,0,0,0,5,5h54.52a5,5,0,0,0,5-5V164a5,5,0,0,0-5-5Z" style="fill:#777"/>
				<path d="M157.26,143H92.74v7.81a5,5,0,0,0,5,5h54.52a5,5,0,0,0,5-5Z" style="fill:#777"/>
				<rect x="94.74" y="86.84" width="60.52" height="60.52" rx="3" style="fill:#fff"/>
				<path d="M152.26,88.84a1,1,0,0,1,1,1v54.51a1,1,0,0,1-1,1H97.74a1,1,0,0,1-1-1V89.84a1,1,0,0,1,1-1h54.52m0-4H97.74a5,5,0,0,0-5,5v54.51a5,5,0,0,0,5,5h54.52a5,5,0,0,0,5-5V89.84a5,5,0,0,0-5-5Z" style="fill:#777"/>
				<path d="M225,216H160.48v9a5,5,0,0,0,5,5H220a5,5,0,0,0,5-5Z"/>
				<path d="M89.52,217H25v8a5,5,0,0,0,5,5H84.52a5,5,0,0,0,5-5Z"/>
			</g>
			<g id="DKeyMoving" data-name="D Key Moving">
				<rect x="162.48" y="161.03" width="60.52" height="60.52" rx="3" style="fill:#fff"/>
				<path d="M220,163a1,1,0,0,1,1,1v54.52a1,1,0,0,1-1,1H165.48a1,1,0,0,1-1-1V164a1,1,0,0,1,1-1H220m0-4H165.48a5,5,0,0,0-5,5v54.52a5,5,0,0,0,5,5H220a5,5,0,0,0,5-5V164a5,5,0,0,0-5-5Z"/>
				<text transform="translate(184.48 191.65)" style="font-size:24.19355010986328px;font-family:NeuzeitGro-Bol, Neuzeit Grotesk">D</text>
				<path d="M201.3,203l-6.54-6.54a.94.94,0,0,0-1.32,0l-1.32,1.32a.94.94,0,0,0,0,1.32l2.69,2.69h-11a.94.94,0,0,0-.94.93v1.87a.93.93,0,0,0,.94.93h11l-2.69,2.69a.94.94,0,0,0,0,1.32l1.32,1.32a.92.92,0,0,0,1.32,0l6.54-6.53A.94.94,0,0,0,201.3,203Z"/>
			</g>
			<g id="AKeyMoving" data-name="A Key Moving">
				<rect x="27" y="161.03" width="60.52" height="60.52" rx="3" style="fill:#fff"/>
				<path d="M84.52,163a1,1,0,0,1,1,1v54.52a1,1,0,0,1-1,1H30a1,1,0,0,1-1-1V164a1,1,0,0,1,1-1H84.52m0-4H30a5,5,0,0,0-5,5v54.52a5,5,0,0,0,5,5H84.52a5,5,0,0,0,5-5V164a5,5,0,0,0-5-5Z"/>
				<text transform="translate(49 191.65)" style="font-size:24.19355010986328px;font-family:NeuzeitGro-Bol, Neuzeit Grotesk">A</text>
				<path d="M47.59,204.29l6.53,6.53a.92.92,0,0,0,1.32,0l1.32-1.32a.94.94,0,0,0,0-1.32l-2.69-2.69h11a.93.93,0,0,0,.93-.93v-1.87a.93.93,0,0,0-.93-.93h-11l2.69-2.69a.94.94,0,0,0,0-1.32l-1.32-1.32a.94.94,0,0,0-1.32,0L47.59,203A.92.92,0,0,0,47.59,204.29Z"/>
			</g>
			<g id="PaddleMovement" data-name="Paddle Movement">
				<rect x="65" y="20" width="120" height="20" style="fill:#fff;stroke:#000;stroke-miterlimit:10;stroke-width:5px"/>
			</g>
		</svg>

		<p>Pressing the <strong>left and right arrow keys</strong> or the <strong>A and D keys</strong> will move the paddle.</p>

	</div>

</template>

<script>
export default {
	name: "MovingKeys"
}
</script>

<style lang="css" scoped>

div {
	max-width: 300px;
}

p {
	text-align: center;
}

text {
	font-weight: 700;
}

#AKeyMoving {
	animation-name: aKey;
	animation-duration: 4s;
	animation-timing-function: ease;
	animation-iteration-count: infinite;
}

#DKeyMoving {
	animation-name: dKey;
	animation-duration: 4s;
	animation-timing-function: ease;
	animation-iteration-count: infinite;
}

#PaddleMovement {
	animation-name: paddle;
	animation-duration: 4s;
	animation-timing-function: ease;
	animation-iteration-count: infinite;
}

@keyframes paddle {
	0% {
		transform: translateX(0px);
	}

	33% {
		transform: translateX(-60px);
	}

	66% {
		transform: translateX(60px);
	}

	100% {
		transform: translateX(0px);
	}
}

@keyframes aKey {
	0% {
		transform: translateY(0px);
	}

	5% {
		transform: translateY(5px);
	}

	33% {
		transform: translateY(5px);
	}

	34% {
		transform: translateY(0px);
	}

	66% {
		transform: translateY(0px);
	}

	71% {
		transform: translateY(5px);
	}

	100% {
		transform: translateY(5px);
	}
}

@keyframes dKey {
	0% {
		transform: translateY(0px);
	}

	33% {
		transform: translateY(0px);
	}

	38% {
		transform: translateY(5px);
	}

	66% {
		transform: translateY(5px);
	}

	67% {
		transform: translateY(0px);
	}

	100% {
		transform: translateY(0px);
	}
}
</style>
