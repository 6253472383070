//
// Contains all methods for making POST requests to server regarding patterns
/////

import axios from 'axios';

export default {

	// Used to create a new pattern.  Note that it doesn't handle file uploads
	// for the SVG part of the pattern
	pattern: (payload, success, failure) => {

		axios.post(`${process.env.VUE_APP_ENDPOINT}/patterns`, payload, {
			headers: {"Authorization": "Bearer " + localStorage.getItem('token')}
		})
		.then((response) => {

			// Grab the response JSON payload and let the caller handle it
			let pattern = {
				id: response.data.data.id,
				name: response.data.data.attributes.name,
				filePath: response.data.data.attributes.file_path
			};

			let colorGroups = [];
			response.data.included.forEach((colorGroup) => {

				if (colorGroup.type == "pattern_color") {
					colorGroups.push({
						id: colorGroup.id,
						patternID: pattern.id,
						name: colorGroup.attributes.name,
						color: colorGroup.attributes.color,
						background: colorGroup.attributes.background
					});
				}

			});

			success(pattern, colorGroups);

		})
		.catch((error) => {
			// TODO:

		})
	},

	svg: (id, file, success, failure) => {

		let formData = new FormData();
		formData.append("images[]", file, file.name);

		axios.post(`${process.env.VUE_APP_ENDPOINT}/patterns/${id}/image`, formData, {
			headers: {
				'content-type': 'multipart/form-data',
				"Authorization": "Bearer " + localStorage.getItem('token')
			}
		}).
		then((response) => {
			success();
		})
		.catch((error) => {

		});

	}

}
