<!-- Just a component that renders two buttons, a next and prev button.
	Visibility is toggled through props. -->
<template>

	<div class="pager-buttons">

		<button v-if="prev"
			:class="{'disabled': !prevReady}"
			:disabled="!prevReady"
			@click="$emit('prev')">
			Previous
		</button>

		<button v-if="next"
			:class="{'disabled': !nextReady}"
			:disabled="!nextReady"
			@click="$emit('next')">
			Next
		</button>

	</div>

</template>

<script>
export default {
	name: "PagerButtons",
	props: ['next', 'next-ready', 'prev', 'prev-ready'],
}
</script>
