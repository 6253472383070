
export default {
	
	methods: {
		
		// Used to draw the bottom borders of the blob, one blobette at a time
		drawBottomLine: function(index) {
		
			const blobette = this.blobettes[index];
		
			// First, let's start by seeing if a blobette directly below the arg
			// blobette exists.  If it doesn't. things get interesting
			let bottomBlobette = this.blobettes[index + this.shownColumns];
			if (typeof bottomBlobette != "object") {
				
				this.blobGraphic.moveTo(
					blobette.x + 8,
					blobette.y + 40
				);
				
				this.blobGraphic.lineTo(
					blobette.x + (80 - 8),
					blobette.y + 40
				);
		
				// Next, let's see if there's a bottom right blobette.  If it's present,
				// then we need to draw an arc
				let bottomRightBlobette = this.blobettes[index + this.shownColumns + 1];
				if (typeof bottomRightBlobette == "object" && (index + 1) % this.shownColumns != 0) {
					
					// Draw an arc
					this.blobGraphic.moveTo(
						blobette.x + 80 - 8,
						blobette.y + 40
					);
					
					this.blobGraphic.arc(
						blobette.x + (80 - 8),
						blobette.y + (40 + 8),
						8,
						Math.PI / 180 * 270,
						0
					);
				
					
				} else {
		
					// Okay, it's missing.  If the right blobette is present though, we need
					// to draw a line segment.  Otherwise, do nothing
					let rightBlobette = this.blobettes[index + 1];
					if (typeof rightBlobette == "object" && (index + 1) % this.shownColumns != 0) {
		
						// Line segment
						this.blobGraphic.moveTo(
							blobette.x + (80 - 8),
							blobette.y + 40
						)
						
						this.blobGraphic.lineTo(
							blobette.x + 80,
							blobette.y + 40
						);
		
					}
		
				}
		
				// Now let's look at the left corner.  Start by seeing if a bottom left
				// blobette exists
				let bottomLeftBlobette = this.blobettes[index + this.shownColumns - 1];
				if (typeof bottomLeftBlobette == "object" && (index - 1) % this.shownColumns != this.shownColumns- 1) {
		
					// So it exists.  We draw an arc directing towards it
					this.blobGraphic.moveTo(
						blobette.x,
						blobette.y + 40 + 8
					);
					this.blobGraphic.arc(
						blobette.x + 8,
						blobette.y + (40 + 8),
						8,
						Math.PI,
						Math.PI / 180 * 270
					);
		
				} else {
		
					// Otherwise, if the left blobette is also missing, then we draw an arc
					// that wraps around this one
					let leftBlobette = this.blobettes[index - 1];
					if (typeof leftBlobette != "object" || (index - 1) % this.shownColumns == this.shownColumns - 1) {
		
						this.blobGraphic.moveTo(
							blobette.x + 8,
							blobette.y + 40
						);
						
						// Draw an arc
						this.blobGraphic.arc(
							blobette.x + 8,
							blobette.y + (40 - 8),
							8,
							Math.PI / 180 * 90,
							Math.PI
						);

					} else {
						
						// Draw a line segment
						this.blobGraphic.moveTo(
							blobette.x,
							blobette.y + 40,
						);
						
						this.blobGraphic.lineTo(
							blobette.x + 8,
							blobette.y + 40
						)
		
					}
		
				}
		
			}
		
		},
		
		
		drawLeftLine: function(index){
		
			const blobette = this.blobettes[index];
			// Let's start first by seeing if a blobette exists to the left.  If so,
			// then we need to draw a line segment
			let leftBlobette = this.blobettes[index - 1];
			if (typeof leftBlobette != "object" || index % this.shownColumns == 0) {
		
				// Okay then, at the least we need to draw a line segment
				this.blobGraphic.moveTo(
					blobette.x,
					blobette.y + 8,
				);
				
				this.blobGraphic.lineTo(
					blobette.x,
					blobette.y + (40 - 8)
				);
		
				// Now let's see if there's a bottom left blobette.  If so, then we draw
				// an arc that curves into that blobette
				let bottomLeftBlobette = this.blobettes[index + this.shownColumns - 1];
				if (typeof bottomLeftBlobette == "object" && index % this.shownColumns != 0) {
					
					this.blobGraphic.moveTo(
						blobette.x,
						blobette.y + 40 - 8
					);
		
					// Okay, so it exists.  Draw that arc
					this.blobGraphic.arc(
						blobette.x - 8,
						blobette.y + (40 - 8),
						8,
						0,
						Math.PI / 180 * 90
					);
		
				} else {
		
					// Okay, so it doesn't exist.  If a bottom blobette exists then we draw
					// a line segment.  Otherwise, drawBottomLine will have taken care of
					// the necessary arc
					let bottomBlobette = this.blobettes[index + this.shownColumns];

					if (typeof bottomBlobette == "object") {
		
						// Draw a line segment
						this.blobGraphic.moveTo(
							blobette.x,
							blobette.y + (40 - 8),
						)
						
						this.blobGraphic.lineTo(
							blobette.x,
							blobette.y + 40
						)
					}
				}
		
				// Next, see what the situation is with the top and top left blobette.
				// Start by seeing if the top left blobette exists
				let topLeftBlobette = this.blobettes[index - this.shownColumns - 1];
				if (typeof topLeftBlobette == "object" && index % this.shownColumns != 0) {
		
					// Okay, so it exists.  Draw an arc going into it
					this.blobGraphic.moveTo(
						blobette.x - 8,
						blobette.y
					);
					
					this.blobGraphic.arc(
						blobette.x - 8,
						blobette.y + 8,
						8,
						Math.PI / 180 * 270,
						0
					);
		
				} else {
		
					// Okay, so if a top blobette exists then we need to draw a line segment,
					// otherwise don't worry about it since drawTopLine took care of the
					// requisite arc
					let topBlobette = this.blobettes[index - this.shownColumns];
					if (typeof topBlobette == "object") {
		
						// Draw a line segment
						this.blobGraphic.moveTo(
							blobette.x,
							blobette.y,
						);
						
						this.blobGraphic.lineTo(
							blobette.x,
							blobette.y + 8
						)
						
					}
				}
		
			}
		
		},
		
		drawRightLine: function(index) {
		
			// Get our blobette
			const blobette = this.blobettes[index];
		
			// We first start by seeing if a blobette exists to the right of the
			// argument blobette
			const rightBlobette = this.blobettes[index + 1];
			if (typeof rightBlobette != "object" || (index + 1) % this.shownColumns == 0) {
		
				// So it doesn't exist.  Let's go ahead and draw a short line segment
				this.blobGraphic.moveTo(
					blobette.x + 80,
					blobette.y + 8
				)
				
				this.blobGraphic.lineTo(
					blobette.x + 80,
					blobette.y + (40 - 8)
				)
				
				// Now let's see what's needed for the top right corner.  All we need to
				// do here is see if a top-right blobette exists.  If so, we need to
				// draw an arc.  If it doesn't exist, but there's a top blobette, then we
				// need to draw a line segment
				// We're still not certain, next step is to see if a top right blobette
				// exists.  If it does, then we need to draw a line segment
				const topRightBlobette = this.blobettes[index - this.shownColumns + 1];
				if (typeof topRightBlobette == "object" && (index + 1) % this.shownColumns != 0) {
		
					// Okay, so a blobette definitely exists to the top right.  Draw an
					// arc
					this.blobGraphic.moveTo(
						blobette.x + 80,
						blobette.y + 8
					);
					
					this.blobGraphic.arc(
						blobette.x + (80 + 8),
						blobette.y + 8,
						8,
						Math.PI,
						Math.PI / 180 * 270
					);
		
				} else {
		
					// Okay, it doesn't exist.  If the top blobette is lacking as well then
					// the necessary arc has been taken care of.  Otherwise, we'll need to
					// draw a line segment
					let topBlobette = this.blobettes[index - this.shownColumns];
					if (typeof topBlobette == "object") {
		
						// Okay, so draw a line segment
						this.blobGraphic.moveTo(
							blobette.x + 80,
							blobette.y,
						)
						
						this.blobGraphic.lineTo(
							blobette.x + 80,
							blobette.y + 8
						);
		
					}
		
				}
		
				// Now for the bottom right corner.  Let's first see if there's a bottom
				// right blobette
				let bottomRightBlobette = this.blobettes[index + this.shownColumns + 1];
				if (typeof bottomRightBlobette == "object" && (index + 1) % this.shownColumns != 0) {
		
					// So there's a blobette here.  In that case, we'll draw an arc pointing
					// towards it
					this.blobGraphic.moveTo(
						blobette.x + 80 + 8,
						blobette.y + 40
					);
					
					this.blobGraphic.arc(
						blobette.x + (80 + 8),
						blobette.y + (40 - 8),
						8,
						Math.PI / 180 * 90,
						Math.PI
					);
		
				} else {
		
					// Alright, it's missing, if a bottom blobette is missing as well then
					// we draw another arc wrapping around this blobette.  Otherwise, we
					// draw a straight line
					let bottomBlobette = this.blobettes[index + this.shownColumns];
					if (typeof bottomBlobette == "object") {
		
						// It exists, draw a line
						this.blobGraphic.moveTo(
							blobette.x + 80,
							blobette.y + (40 - 8)
						)
						
						this.blobGraphic.lineTo(
							blobette.x + 80,
							blobette.y + 40
						);
		
					} else {
						
						this.blobGraphic.moveTo(
							blobette.x + 80,
							blobette.y + 40 - 8
						);
		
						// Draw an arc
						this.blobGraphic.arc(
							blobette.x + (80 - 8),
							blobette.y + (40 - 8),
							8,
							0,
							Math.PI / 180 * 90
						);
		
					}
		
				}
		
			}
		
		},
		
		drawTopLine: function(index) {
		
			// First, get the blobette in question
			const blobette = this.blobettes[index];
			
			// See if a blobette exists one row up (or rather,
			// index - cols in the array)
			let topBlobette = this.blobettes[index - this.shownColumns]
			if (typeof topBlobette != "object") {
		
				// Okay, so by virtue of x being undefined we can conclude there's an
				// empty space above.  Let's draw the basic line in, leaving room for
				// rounded corners
				this.blobGraphic.moveTo(
					blobette.x + 8,
					blobette.y,
				)
				this.blobGraphic.lineTo(
					blobette.x + (80 - 8),
					blobette.y
				);
		
				// Next, see what the situation is with the top left corner.  We may need
				// an arc there if there's no blobette to the left of this one (index - 1).
				let leftBlobette = this.blobettes[index - 1];
				let topLeftBlobette = this.blobettes[index - this.shownColumns - 1];
				if (typeof leftBlobette != "object" || index % this.shownColumns == 0) {
		
					// Okay, so there's no left blobette.  If there's also no upper left
					// corner blobette then we're going to draw a downward arc.  Otherwise,
					// we're going to draw an upward arc
					let topLeftBlobette = this.blobettes[index - this.shownColumns - 1];
					if (typeof topLeftBlobette != "object" || index % this.shownColumns == 0) {
		
						// And a corner blobette is lacking.  Draw a downward arc
						this.blobGraphic.moveTo(
							blobette.x,
							blobette.y + 8,
						)
						this.blobGraphic.arc(
							blobette.x + 8,
							blobette.y + 8,
							8,
							Math.PI,
							Math.PI / 180 * 270
						);
		
					} else {
						
						// Draw an upward arc
						/*
						this.blobGraphic.moveTo(
							blobette.x + 8,
							blobette.y
						);
						*/
		
						this.blobGraphic.arc(
							blobette.x + 8,
							blobette.y - 8,
							8,
							Math.PI / 180 * 90,
							Math.PI
						);
		
					}
		
				} else {
		
					// Left blobette exists, but there still may be a top-left blobette,
					// if so, then we're going to draw an upward arc
					if (typeof topLeftBlobette != "object" || index & this.shownColumns == 0) {
		
						// Missing, Line segment
						this.blobGraphic.moveTo(
							blobette.x,
							blobette.y,
						);
						
						this.blobGraphic.lineTo(
							blobette.x + 8,
							blobette.y
						);
		
					} else {
						
						this.blobGraphic.moveTo(
							blobette.x + 8,
							blobette.y,
						);
		
						// Draw an upward arc
						this.blobGraphic.arc(
							blobette.x + 8,
							blobette.y - 8,
							8,
							Math.PI / 180 * 90,
							Math.PI
						);
		
					}
		
				}
		
				// Now then, we're almost done.  The next step is the right corner.  If
				// no right blobette exists, then there may need to be an arc
				let rightBlobette = this.blobettes[index + 1];
				let topRightBlobette = this.blobettes[index + 1 - this.shownColumns];
				if (typeof rightBlobette != "object" || (index + 1) % this.shownColumns == 0) {
		
					// Okay, so it's missing.  We're going to draw either an upward or
					// downward arc
					if (typeof topRightBlobette != "object" || (index + 1) % this.shownColumns == 0) {

						// And a corner blobette is lacking.  Draw a downward arc
						this.blobGraphic.moveTo(
							blobette.x + 80 - 8,
							blobette.y,
						);
						
						this.blobGraphic.arc(
							blobette.x + (80 - 8),
							blobette.y + 8,
							8,
							Math.PI / 180 * 270,
							0
						);
		
					} else {
						
						this.blobGraphic.moveTo(
							blobette.x + 80,
							blobette.y - 8,
						);
		
						// Upward arc
						this.blobGraphic.arc(
							blobette.x + (80 - 8),
							blobette.y - 8,
							8,
							0,
							Math.PI / 180 * 90
						);
		
					}
		
				} else {
		
					// We may still draw an upward arc if the top right blobette is also
					// present.  Check for that
					if (typeof topRightBlobette != "object" || (index + 1) % this.shownColumns == 0) {
		
						// Line
						this.blobGraphic.moveTo(
							blobette.x + (80 - 8),
							blobette.y,
						)
						
						this.blobGraphic.lineTo(
							blobette.x + 80,
							blobette.y
						);
		
					} else {
						
						this.blobGraphic.moveTo(
							blobette.x + 80,
							blobette.y - 8,
						);
		
						// Upward arc
						this.blobGraphic.arc(
							blobette.x + (80 - 8),
							blobette.y - 8,
							8,
							0,
							Math.PI / 180 * 90
						);
		
					}
		
				}
		
			}
		
		}


	}
}