//
// Portion of store for managing pattern state.
/////

export default {

	addPatterns: (state, payload) => {

		payload.forEach((pattern) => {

			for(let i = 0; i < state.patterns.length; i ++) {
				if (state.patterns[i].id == pattern.id) {

					let target = Object.assign({}, state.patterns[i])
					target = Object.assign(target, pattern);
					state.patterns.splice(i, 1, target);

					return;
					
				}
			}

			state.patterns.push(pattern);

		});

	},

	removePattern: (state, patternID) => {

		for(let i = 0; i < state.patterns.length; i ++) {
			if (state.patterns[i].id == patternID) {
				state.patterns.splice(i, 1);
				return;
			}
		}

	}

}
