<!-- a login form, which can either be dropped into the dedicated login
	page or used in the first step of the checkout process. -->
<template>

	<div>

		<div v-if="loading" class="loading">
			<Loader />
			<p class="text-center">One sec.  Logging you in.</p>
		</div>

		<form @submit.prevent="login" v-else>

			<div v-if="incorrectCreds || error" class="thick-border error">

				<p v-if="incorrectCreds">Your username or password were incorrect
				</p>

				<p v-else>{{ error }}</p>
			</div>

			<div>
				<label for="inputEmail">Email: </label>
				<input type="text" class="input" id="inputEmail"
					placeholder="Email" v-model="email"/>
			</div>

			<div>
				<label for="inputPassword">Password: </label>
				<input type="password" class="input" id="inputPassword"
					placeholder="Password" v-model="password"/>
			</div>

			<button type="submit" class="block-button">
				Login
			</button>

			<button type="button" class="block-button" v-if="createAccount"
				@click="$emit('switch')">
				Create An Account
			</button>

			<p>
				<a href="#">Forgot Password?</a>
			</p>

		</form>

	</div>

</template>

<script>
import Loader from '../Loader.vue';
import AuthRequests from '../../network/authentication';
export default {
	name: "LoginForm",
	props: ['admin', 'create-account'],
	components: { Loader },

	data: function() {
		return {
			email: '',
			error: false,
			incorrectCreds: false,
			loading: false,
			password: '',
		}
	},

	methods: {

		// Sends a request to authenticate user.
		login: function() {

			this.incorrectCreds = false;
			this.loading = true;

			const payload = JSON.stringify({
				data: {
					type: "user",
					attributes: {
						email: this.email,
						password: this.password
					}
				}
			});

			const _this = this;
			AuthRequests.login(payload, () => {
				// Emit an event letting parent know that the user was logged in
				_this.$emit('success', _this.email);
			}, (error) => {

				_this.loading = false;

				if (error.response.status == 401) {
					_this.incorrectCreds = true;
				} else if (error.response.status == 400) {

					if (error.response.data.errors[0].detail == "No email present in request") {
						_this.error = "You Forgot Your Email";
					} else if (error.response.data.errors[0].detail == "No password present in request") {
						_this.error = "You Forgot To Enter A Password"
					}

				} else {

					_this.error = "We can't log you in at the moment.  Please try again later";
					
				}
			});
		}

	}

}
</script>

<style lang="scss" scoped>
form {
	max-width: 400px;
	width: 100%;
	margin: 0 auto;

	button {
		margin-top: 2rem;
	}
}

.error {
	border-color: #FF2E00;
	color: #FF2E00;
	box-shadow: 2px 2px 2px #BBB;
	width: 100%;
	margin: 0.4rem;
	padding: 1rem;
	margin-bottom: 1rem;
	text-align: center;
}

a {
	margin-left: 0.4rem;
}

.loading {
	margin: 5rem auto;
	display: flex;
	flex-direction: column;
}
</style>
