<!-- Nothing much happening here, it's just a container for a router-view -->
<template>
  <div id="app">
    <component v-bind:is="modal" v-bind:props="modalProps"></component>
    <router-view>
    </router-view>
  </div>
</template>

<script>
import ModalLevelSettings from './components/level/modals/LevelSettingsModal.vue';
import ModalPatternCategories from './components/pattern-categories/modal/ModalPatternCategories.vue';
import ModalPatternCustomizer from './components/modals/ModalPatternCustomizer.vue';
import ModalPatternPicker from './components/modals/ModalPatternPicker.vue';
import Vue from 'vue';
export default {
  name: 'app',
  components: {
    ModalLevelSettings,
    ModalPatternCategories,
    ModalPatternCustomizer,
    ModalPatternPicker
  },
  data: function() {
    return {
      ModalEventBus: new Vue(),
      modal: '',
      modalProps: null,
    }
  },

  provide: function() {
    return {
      ModalEventBus: this.ModalEventBus
    }
  },

  mounted: function() {

    this.ModalEventBus.$on('modal', (evt) => {
      this.modal = evt.modal;
      this.modalProps = evt.props;
    });

    this.ModalEventBus.$on('close', () => {
      this.modal = '';
      this.modalProps = null;
    })
  }
}
</script>

<style src="./styles/style.scss" lang="scss" />
