<!-- Component for displaying a single color in a list context. -->
<template>
	<div class="color-list-item thick-border">

		<div class="color-content">
			<div class="color-well thick-border"
				:class="{'background': color.isBackground}"
				:style="{'background-color': `#${renderColor.color}`}" />

			<h4 class="color-name">{{ color.name }}</h4>

			<div class="right-align">

				<h4 class="color-hex" :style="{'color': `#${renderColor.color}`}">#{{ renderColor.color }}</h4>

				<button class="square-button" @click="toggleEdit">
					<img v-if="!edit && !editColor" src="../../assets/toybox-pencil-black.svg"/>
					<img v-else src="../../assets/save.svg"/>
				</button>

			</div>
		</div>

		<div v-if="editColor || edit" class="color-sliders">
			
			<ColorSlider
				channel="red"
				:color="red"
				:whole-color="{red, green, blue, alpha}"
				@color="changeColor('red', $event)"/>
				
			<ColorSlider
				channel="green"
				:color="green"
				:whole-color="{red, green, blue, alpha}"
				@color="changeColor('green', $event)"/>
				
			<ColorSlider
				channel="blue"
				:color="blue"
				:whole-color="{red, green, blue, alpha}"
				@color="changeColor('blue', $event)"/>
				
			<!--
			<ColorSlider
				channel="red"
				:color="alpha"
				:whole-color="{red, green, blue, alpha}"
				@color="changeColor('alpha', $event)"/>
			-->
		</div>

	</div>
</template>

<script>
import ColorSlider from './ColorSlider.vue';
export default {
	name: "ColorListItem",
	props: ['color', 'edit', 'level', 'pattern', 'preview'],
	inject: ['PatternEventBus'],
	components: { ColorSlider },

	data: function() {
		return {
			editColor: false,
			red: 0,
			green: 0,
			blue: 0,
			alpha: 1
		}
	},

	computed: {

		// If level is passed in as a prop, this will see if there's a level color
		// associated with this particular level and color.
		levelColor: function() {

			if (!this.pattern) {
				return false;
			}

			for(let i = 0; i < this.$store.state.levelColors.length; i ++) {

				const color = this.$store.state.levelColors[i];
				if (color.assignmentID == this.pattern.assignmentID && color.colorID == this.color.id) {
					return color;
				}
			}

			return false;

		},

		renderColor: function() {

			if (this.levelColor) {
				return this.levelColor;
			}

			return this.color;

		}

	},

	methods: {

		changeColor: function(channel, value) {

			if (channel == 'red') {
				this.red = parseInt(value);
			}
			if (channel == 'green') {
				this.green = parseInt(value);
			}
			if (channel == 'blue') {
				this.blue = parseInt(value);
			}

			// We're not going to do anything here besides convert color channels
			// to hex values.  Uuse the PatternBus to send it all topside and let
			// that component handle it.
			let red = this.red.toString(16).toUpperCase();
			if (red.length < 2) {
				red = '0' + red;
			}
			let green = this.green.toString(16).toUpperCase();
			if (green.length < 2) {
				green = '0' + green;
			}
			let blue = this.blue.toString(16).toUpperCase();
			if (blue.length < 2) {
				blue = '0' + blue;
			}

			const color = red + green + blue;

			this.PatternEventBus.$emit('color', this.color, color);

			// If no level was passed in as an arg, then presumably we're updating the
			// prime color.  Go ahead and update it in the store.
			if (!this.level) {

				const group = Object.assign({}, this.color);
				group.color = color;
				this.$store.commit('addPatternColors', [group]);

			}
			// Otherwise, what if we have a level, but no level color?  Then we are
			// updating a what is about to become a level color.  We can't really
			// update what doesn't exist though.  No matter.  We'll create a new level
			// color to update anyway with no ID but a timestamp
			else if (this.level && !this.levelColor) {

				const date = new Date();
				const newColor = {
					id: 0,
					assignmentID: this.pattern.assignmentID,
					levelID: this.level.id,
					colorID: this.color.id,
					color,
					timestamp: date.getTime()
				}

				this.$store.commit('addLevelColors', [newColor]);

			}
			// Final case.  This is a level.  There is a level color present.  Update
			// the level colorr
			else {

				// Otherwise, we're updating a level color.
				const group = Object.assign({}, this.levelColor);
				group.color = color;
				this.$store.commit('addLevelColors', [group]);

			}

		},

		// Besides toggling the edit state, this will also emit a request to save
		// changes to a color group if we are toggling from on/true to off/false
		toggleEdit: function() {

			this.editColor = this.edit ? false : !this.editColor;
			this.$emit('toggle-edit', this.editColor, this.color);

			if (!this.editColor) {

				// Special case if this is a random color.  Just update the damn
				// store
				/*
				if (this.pattern.random) {

					const color = Object.assign({}, this.color);

					let red = this.red.toString(16).toUpperCase();
					if (red.length < 2) {
						red = '0' + red;
					}
					let green = this.green.toString(16).toUpperCase();
					if (green.length < 2) {
						green = '0' + green;
					}
					let blue = this.blue.toString(16).toUpperCase();
					if (blue.length < 2) {
						blue = '0' + blue;
					}

					color.color = red + green + blue;
					this.$store.commit('addLevelColors', [color]);

					return;

				}
				*/

				// If we're updating the "prime" pattern, or we're updating a level
				// color, fire this event
				if (!this.level || (this.levelColor && this.levelColor.id)) {
					this.PatternEventBus.$emit('update-color', this.color, this.levelColor);
				}

				// Otherwise, if there's a level passed in as a prop but no level color
				// then we're creating a new level color
				else if (this.level && (!this.levelColor || !this.levelColor.id)) {
					this.PatternEventBus.$emit('add-color', this.color, this.levelColor);
				}

			}
		}

	},

	// When mounted, convert hex color into three RGB channels
	mounted: function() {

		if (this.levelColor) {
			this.red = parseInt(this.levelColor.color.substring(0, 2), 16);
			this.green = parseInt(this.levelColor.color.substring(2, 4), 16);
			this.blue = parseInt(this.levelColor.color.substring(4, 6), 16);
		} else {
			this.red = parseInt(this.color.color.substring(0, 2), 16);
			this.green = parseInt(this.color.color.substring(2, 4), 16);
			this.blue = parseInt(this.color.color.substring(4, 6), 16);
		}

	},

	watch: {

		levelColor: function() {
			if (this.levelColor) {
				this.red = parseInt(this.levelColor.color.substring(0, 2), 16);
				this.green = parseInt(this.levelColor.color.substring(2, 4), 16);
				this.blue = parseInt(this.levelColor.color.substring(4, 6), 16);
			}
		}
	}

}
</script>

<style lang="scss" scoped>
.color-list-item {
	padding: 12px;
	margin: 10px 0;
	box-shadow: 1px 1px 1px #BBB;
}

.color-content {
	display: flex;
	align-items: center;
}

.color-sliders {
	margin-top: 16px;
}
h4 {
	margin: 0;
	margin-left: 8px;
}

.right-align {
	text-align: right;
	flex-grow: 2;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.color-well {
	border-radius: 100%;
	width: 45px;
	height: 45px;

	&.background {
		border-radius: 0;
	}
}


@media(max-width: 768px) {

	.color-content {
		flex-wrap: wrap;
	}

	.color-name {
		text-align: center;
		margin-left: 0;
		margin-bottom: 8px;
		order: 1;
		width: 100%;
	}

	.color-well {
		order: 2;
		width: 40px;
		height: 40px;
	}

	.right-align {
		order: 2;
	}

}
</style>
