//
// Portion of package for managing neighbors within the blobprint
/////

// Given the array of chunks and a given blob (with it's chunkID and rectID)
// this determine what the given blob's neighbors are and make adjustments
// as necessary
function calcNeighbors(chunkID, rectID, newRect, modify) {

	// First, see what the blob's rectID is.  We can use that to determine
	// what the 8 neighbors (4 adjacent, 4 corner)
	const neighborIDs = [];
	for(let i = 0; i < 8; i ++) {

		let chunkIDX;
		let chunkIDY;
		let rectIDX;
		let rectIDY;

		switch(i) {

			case 0:

				// Let's start at the top.  If rectIDY is 1 (they're not zero indexed)
				// then we set chunkIDY to current +1 and rectIDY to 6.  Otherwise,
				// We're only changing rectID
				if (rectID.y == 1) {

					chunkIDY = chunkID.y + 1;
					rectIDY = 6;

					// And if chunkID is zero, then correct
					if (chunkIDY == 0) {
						chunkIDY = 1;
					}

				} else {

					chunkIDY = chunkID.y;
					rectIDY = rectID.y - 1;

				}

				chunkIDX = chunkID.x;
				rectIDX = rectID.x;
				break;

			case 1:

				// For the top right corner (we're moving clockwise) same rule as before
				// applies for the y.  For x, if rectID.x == 6 then we increment
				// chunkIDX.  Otherwise, we just increment rectIDX
				if (rectID.y == 1) {

					chunkIDY = chunkID.y + 1;
					rectIDY = 6;

					if (chunkIDY == 0) {
						chunkIDY = 1;
					}

				} else {

					chunkIDY = chunkID.y;
					rectIDY = rectID.y - 1;

				}

				if (rectID.x == 4) {

					chunkIDX = chunkID.x + 1;
					rectIDX = 1;

					if (chunkIDX == 0) {
						chunkIDX = 1;
					}

				} else {

					chunkIDX = chunkID.x;
					rectIDX = rectID.x + 1;

				}

				break;

			case 2:

				// For the right side, keep the y-stuff the same, and change x-stuff
				// using same rules as before for top right
				if (rectID.x == 4) {

					chunkIDX = chunkID.x + 1;
					rectIDX = 1;

					if (chunkIDX == 0) {
						chunkIDX = 1;
					}

				} else {

					chunkIDX = chunkID.x;
					rectIDX = rectID.x + 1;

				}

				chunkIDY = chunkID.y;
				rectIDY = rectID.y;
				break;

			case 3:

				// For the bottom right corner, check the x-axis.  Same rules as right
				// side apply
				if (rectID.x == 4) {

					chunkIDX = chunkID.x + 1;
					rectIDX = 1;

					if(chunkIDX == 0) {
						chunkIDX = 1;
					}

				} else {

					chunkIDX = chunkID.x;
					rectIDX = rectID.x + 1;

				}

				// Now for the y-axis.  If rectID.y == 4 we decrement chunkID and
				// reset rectIDY.  Otherwise, it's just a simple increment of rectID
				if (rectID.y == 6) {

					chunkIDY = chunkID.y - 1;
					rectIDY = 1;

					if (chunkIDY == 0) {
						chunkIDY = -1;
					}

				} else {

					chunkIDY = chunkID.y;
					rectIDY = rectID.y + 1;

				}

				break;

			case 4:

				// Now we're at the bottom.  Basically, just take the y-axis rules for
				// bottom right
				if (rectID.y == 6) {

					chunkIDY = chunkID.y - 1;
					rectIDY = 1;

					if (chunkIDY == 0) {
						chunkIDY = -1;
					}

				} else {

					chunkIDY = chunkID.y;
					rectIDY = rectID.y + 1;

				}

				chunkIDX = chunkID.x;
				rectIDX = rectID.x;

				break;

			case 5:

				// Bottom left.  Take the same rules as before for y-axis.  Left will
				// be a bit different.  You're gonna take the logic for right-side and
				// invert it
				if (rectID.y == 6) {

					chunkIDY = chunkID.y - 1;
					rectIDY = 1;

					if (chunkIDY == 0) {
						chunkIDY = -1;
					}

				} else {

					chunkIDY = chunkID.y;
					rectIDY = rectID.y + 1;

				}

				if (rectID.x == 1) {

					chunkIDX = chunkID.x - 1;
					rectIDX = 4;

					if (chunkIDX == 0) {
						chunkIDX = -1;
					}

				} else {

					chunkIDX = chunkID.x;
					rectIDX = rectID.x - 1;

				}

				break;

			case 6:

				// Left hand side.  Only need to worry about seeing what's on the left
				if (rectID.x == 1) {

					chunkIDX = chunkID.x - 1;
					rectIDX = 4;

					if (chunkIDX == 0) {
						chunkIDX = -1;
					}

				} else {

					chunkIDX = chunkID.x;
					rectIDX = rectID.x - 1;

				}

				chunkIDY = chunkID.y;
				rectIDY = rectID.y;

				break;

			case 7:

				// Top left side.  Whatever, you know what to do
				if (rectID.x == 1) {

					chunkIDX = chunkID.x - 1;
					rectIDX = 4;

					if (chunkIDX == 0) {
						chunkIDX = -1;
					}

				} else {

					chunkIDX = chunkID.x;
					rectIDX = rectID.x - 1;

				}

				if (rectID.y == 1) {

					chunkIDY = chunkID.y + 1;
					rectIDY = 6;

					// And if chunkID is zero, then correct
					if (chunkIDY == 0) {
						chunkIDY = 1;
					}

				} else {

					chunkIDY = chunkID.y;
					rectIDY = rectID.y - 1;

				}

				break;

		}

		// Finally, having presumably set our IDs, let's push them all to the
		// neighborIDs array
		neighborIDs.push({
			chunkID: {
				x: chunkIDX,
				y: chunkIDY
			},
			rectID: {
				x: rectIDX,
				y: rectIDY
			}
		});

	}

	// We're not done yet.  We've merely bundled together an array of chunk and
	// rectIDs.  Now the goal is to find the blobettes with those IDs
	neighborIDs.forEach((neighbor, index) => {

		// Find the chunk
		let matchingChunk;

		for(let i = 0; i < this.chunks.length; i ++) {

			const chunk = this.chunks[i];
			if (chunk.id.x == neighbor.chunkID.x && chunk.id.y == neighbor.chunkID.y) {
				matchingChunk = chunk;
				break;
			}

		}

		if (!matchingChunk) {
			return;
		}

		// Alright, we found the chunk.  Now let's iterate through the array of
		// rectIDs and find one that matches the neighbor rectID
		for(let i = 0; i < matchingChunk.rects.length; i ++) {

			const rect = matchingChunk.rects[i];
			if (rect.id.x == neighbor.rectID.x && rect.id.y == neighbor.rectID.y) {

				// We found a rect that neighbors the given blobette.  Next step is to
				// mutate both affected rects so that they are aware of their neighbors
				// NOTE: Neighbors are identified as numbers rather than as "top" or
				// "bottom" or whatever.  This is because, well, hyphenated corner
				// names (top-left) cause problems
				if (newRect.neighbors != "") {
					newRect.neighbors = newRect.neighbors + `|${index}`;
				} else {
					newRect.neighbors = `${index}`;
				}

				if (modify) {
					if (rect.neighbors != "") {
						rect.neighbors = rect.neighbors = `|${(index + 4) % 8}`;
					} else {
						rect.neighbors = `${(index + 4) % 8}`;
					}
				}


			}

		}

	});

	return;

}

export { calcNeighbors };
