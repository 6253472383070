//
// Collection of POST methods for levels
/////

import axios from 'axios';

export default {

	// Creates a new level via POST request
	level: (payload, success, failure) => {
		axios.post(`${process.env.VUE_APP_ENDPOINT}/levels`, payload, {
			headers: {"Authorization": "Bearer " + localStorage.getItem('token')}
		})
		.then((response) => {

			const published = (
				response.data.data.attributes.published == "true" ||
				response.data.data.attributes.published == "1"
			);

			const quickstart = (
				response.data.data.attributes.quickstart == "true" ||
				response.data.data.attributes.quickstart == "1"
			);

			success({
				id: response.data.data.id,
				name: response.data.data.attributes.name,
				layout: response.data.data.attributes.layout ? JSON.parse(response.data.data.attributes.layout) : null,
				difficulty: response.data.data.attributes.difficulty,
				published,
				difficulty
			});

		})
		.catch((error) => {

		});
	},

	// Adds a pattern to a level.  Additionally, if the level in question doesn't
	// exist (ids.levelID == 'new') then an empty new level is created.
	patternToLevel: (ids, payload, success, failure) => {

		axios.post(`${process.env.VUE_APP_ENDPOINT}/levels/${ids.levelID}/patterns/${ids.patternID}`, payload, {
			headers: {"Authorization": "Bearer " + localStorage.getItem('token')}
		})
		.then((response) => {

			// We should get a level back if we created a new one.  If that's the
			// case, unpack the response and send it back
			if (response.data) {

				success({
					id: response.data.data.id,
					name: response.data.data.attributes.name,
					published: false,
					layout: response.data.data.attributes.layout ? JSON.parse(response.data.data.attributes.layout) : null
				});

				return;

			}

			success();

		})
		.catch((error) => {

		})
	}
}
