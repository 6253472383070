<!-- Special custom checkbox component -->
<template>

	<div class="check-container">
		<input type="checkbox" :id="checkboxId" :checked="value"
			@input="$emit('input', $event.target.value)"/>
		<label class="h5" :for="checkboxId"><span class="checkbox"/>{{ label }}</label>
	</div>

</template>

<script>
export default {
	name: "Checkbox",
	props: ['checkbox-id', 'label', 'value']
}
</script>
