<!-- A page listing all the different available patterns -->
<template>

	<div>

		<div class="header">
			<h1>Patterns</h1>
			<router-link class="button" :to="{name: 'NewPattern'}">
				New Pattern
			</router-link>
		</div>

		<div class="patterns">

			<div class="category-list">
				<h3>Categories</h3>

				<CategoryList
					@select="filterByCategory"/>

			</div>

			<div class="pattern-grid">

				<div v-for="pattern in patterns">
					<PatternSwatch
						:pattern="pattern"
						action="look"/>
				</div>

			</div>

		</div>

	</div>

</template>

<script>
import CategoryList from '../components/pattern-categories/PatternCategoryList.vue';
import PatternRequests from '../network/patterns';
import PatternSwatch from '../components/PatternSwatchGrid.vue';
export default {
	name: "PatternCatalogue",
	components: { CategoryList, PatternSwatch },
	data: function() {
		return {
			categories: []
		}
	},

	computed: {

		patterns: function() {
			return this.$store.state.patterns;
		}

	},

	methods: {

		// Allows us to filter by a certain category.  For now this is a rather
		// ungainly process where we make a request for all patterns associated
		// with the given category, then update the store.
		filterByCategory: function() {

		}

	},

	// When ready, grab our patterns
	mounted: function() {

		let store = this.$store;
		PatternRequests.getPatterns((patterns, colors) => {
			store.commit('addPatterns', patterns);
			store.commit('addPatternColors', colors)
		});

	}
}
</script>

<style lang="scss" scoped>
.pattern-grid {

	display: flex;
	justify-content: flex-start;
	flex-wrap: wrap;

	& > div {
		width: 25%;
	}

}

.header {
	display: flex;
	align-items: center;
	margin: 10px 20px;

	h1 {
		flex-grow: 1;
	}
}

.patterns {
	display: flex;
	align-items: flex-start;

	& > div {
		margin: 10px;
	}

	.category-list {
		flex-grow: 0;
		min-width: 20%;
	}

	.pattern-grid {
		flex-grow: 1;
	}
}
</style>
