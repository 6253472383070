//
// Portion of package for handling DELETE requests.
/////

import axios from 'axios';

export default {

	category: (id, success, failure) => {
		axios.delete(`${process.env.VUE_APP_ENDPOINT}/pattern-categories/${id}`)
		.then((response) => {
			success();
		})
		.catch((error) => {

		});
	},

	// Removes a pattern from a category without deleting it
	patternFromCategory: (ids, success, failure) => {
		axios.delete(`${process.env.VUE_APP_ENDPOINT}/pattern-categories/${ids.categoryID}/patterns/${ids.patternID}`)
		.then((response) => {
			success();
		})
		.catch((error) => {
			// TODO
		});
	}
}
