<!-- A self-enclosed form for capturing shipping info -->
<template>

	<div>

		<div class="check-container">
			<input type="checkbox" id="sameAsBilling" v-model="sameAsBilling"/>
			<label for="sameAsBilling"><span class="checkbox"/>Same As Billing</label>
		</div>

		<div v-if="!sameAsBilling">

			<div class="row">

				<FormGroup class="col"
					field-name="First Name"
					group-id="firstName"
					placeholder="Piet"
					type="text"
					v-model="firstName"/>

				<FormGroup class="col"
					field-name="Last Name"
					group-id="lastName"
					placeholder="Mondrian"
					type="text"
					v-model="lastName"/>

			</div>

			<div class="row">

				<FormGroup class="col"
					field-name="Street Address"
					group-id="streetAddr"
					placeholder="11 W 53rd St"
					type="text"
					v-model="streetAddr"/>

				<FormGroup class="col"
					group-id="unitAddr"
					field-name="Unit Address"
					placeholder="Apt 303"
					type="text"
					v-model="unitAddr"/>

			</div>

			<div class="row">

				<FormGroup class="col"
					group-id="cityAddr"
					field-name="City"
					placeholder="New York"
					type="text"
					v-model="city"/>

				<FormGroup class="col"
					group-id="stateAddr"
					field-name="State"
					placeholder="NY"
					type="text"
					v-model="state"/>

				<FormGroup class="col"
					group-id="zipAddr"
					field-name="Zip Code"
					placeholder="10019"
					type="text"
					v-model="zip"/>

			</div>

		</div>

		<hr />

		<p class="text-center">Posters take approximately
			<strong>2-5 days to be printed</strong>.
			After they're printed, it can take up to
			<strong>2-7 days for your poster to be shipped to your door</strong>.
		  We apologize for making you wait, and sincerely hope that the custom
			artwork you're ordering from us makes up for the long wait.</p>

			<p class="text-center">You can always play more Mondranoid to help pass the time.</p>

		<hr />

		<PagerButtons class="pager-buttons"
			:prev="true"
			:prev-ready="true"
			:next="true"
			:next-ready="next"
			@next="handleNext"
			@prev="$emit('prev')"/>

		<button @click="emit('back-to-confirm')" v-if="finished">
			Skip Ahead To Confirmation
		</button>

	</div>

</template>

<script>
import FormGroup from '../../forms/FormGroup.vue';
import PagerButtons from '../../PagerButtons.vue';
export default {
	name: "ShippingAddress",
	props: ['finished', 'savedInfo'],
	components: { FormGroup, PagerButtons },

	data: function() {
		return {
			sameAsBilling: this.savedInfo && this.savedInfo.sameAsBilling !== undefined ? this.savedInfo.sameAsBilling : true,
			firstName: this.savedInfo && this.savedInfo.firstName ? this.savedInfo.firstName : '',
			lastName: this.savedInfo && this.savedInfo.lastName ? this.savedInfo.lastName : '',
			streetAddr: this.savedInfo && this.savedInfo.streetAddr ? this.savedInfo.streetAddr : '',
			unitAddr: this.savedInfo && this.savedInfo.unitAddr ? this.savedInfo.unitAddr : '',
			city: this.savedInfo && this.savedInfo.city ? this.savedInfo.city : '',
			state: this.savedInfo && this.savedInfo.state ? this.savedInfo.state : '',
			zip: this.savedInfo && this.savedInfo.zip ? this.savedInfo.zip : ''
		}
	},

	computed: {

		// Makes sure either all fields are filled out or user checked "same as
		// billing"
		next: function() {

			// Go ahead and mark things as good if "same as billing" was checked
			if (this.sameAsBilling) {
				return true;
			}

			if (!this.firstName) {
				return false;
			}

			if (!this.lastName) {
				return false;
			}

			if (!this.streetAddr) {
				return false;
			}

			if (!this.city) {
				return false;
			}

			if (!this.state) {
				return false;
			}

			if (!this.zip) {
				return false;
			}

			return true;

		}

	},

	methods: {

		// Bubble up the values we have and continue
		handleNext: function() {

			this.$emit('next', {
				sameAsBilling: this.sameAsBilling,
				firstName: this.firstName,
				lastName: this.lastName,
				streetAddr: this.streetAddr,
				unitAddr: this.unitAddr,
				city: this.city,
				state: this.state,
				zip: this.zip
			});

		}

	}

}
</script>

<style lang="scss" scoped>

hr {
	margin: 2.4rem 0;
}

.check-container {
	display: flex;
	justify-content: center;
	margin: 20px;
}

.row {
	display: flex;
	align-items: flex-start;

	&.poster-row > div {
		margin-left: 30px;
	}

	.poster {
		min-width: 300px;
		max-width: 30%;
		text-align: left;

		img {
			max-height: 200px;
			max-width: 200px;
			margin-right: 10px;
		}
	}

	.billing {
		flex-grow: 1;
		padding-right: 15px;
	}

	.col {
		margin-right: 15px;
	}
}
</style>
