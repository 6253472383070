//
// Portion of package responsible for drawing a border around a a blobprint.
// Used exclusively in a preview context.
/////

// Draws the borders for a given blobette in blobprint.  We don't pass in the
// blobprint itself, just its position, its neighbors, the overall zoom, and
// of course sketch
function drawBorders(neighbors, x, y, zoom, sketch) {

	sketch.strokeWeight(8 * zoom);
	sketch.strokeCap(sketch.SQUARE);

	// Alright, let's go around 8 times.
	for(let i = 0; i < 8; i ++) {

		// See if it's missing a neighbor along a given side
		if (!neighbors.includes(`${i}`)) {

			switch(i) {

				case 0:
					this.drawTopLine(x, y, zoom, sketch);
					break;

				case 2:
					this.drawRightLine(x, y, zoom, sketch);
					break;

				case 4:
					this.drawBottomLine(x, y, zoom, sketch);
					break;

				case 6:
					this.drawLeftLine(x, y, zoom, sketch);
					break;

			}

		}

	}

	// Then look at the corners
	this.drawTopRightCorner(neighbors, x, y, zoom, sketch);
	this.drawBottomRightCorner(neighbors, x, y, zoom, sketch);
	this.drawBottomLeftCorner(neighbors, x, y, zoom, sketch);
	this.drawTopLeftCorner(neighbors, x, y, zoom, sketch);

}

function drawBottomLine(x, y, zoom, sketch) {

	sketch.line(
		x + 8 * zoom,
		y + 40 * zoom,
		x + (80 - 8) * zoom,
		y + 40 * zoom
	);

}

function drawBottomLeftCorner(neighbors, x, y, zoom, sketch) {

	const bottomLine = !neighbors.includes("4") && !neighbors.includes("5") &&
		neighbors.includes("6");
	const leftLine = !neighbors.includes("5") && !neighbors.includes("6") &&
		neighbors.includes("4");
	const cornerLine = !neighbors.includes("4") && !neighbors.includes("5") &&
		!neighbors.includes("6");
	const concaveCornerLine = !neighbors.includes("4") && neighbors.includes("5");

	if (bottomLine) {

		sketch.line(
			x,
			y + 40 * zoom,
			x + 8 * zoom,
			y + 40 * zoom
		);
		return;

	}

	if (leftLine) {

		sketch.line(
			x,
			y + (40 - 8) * zoom,
			x,
			y + 40 * zoom
		);

	}

	if (cornerLine) {

		sketch.arc(
			x + 8 * zoom,
			y + (40 - 8) * zoom,
			16 * zoom,
			16 * zoom,
			90 * Math.PI / 180,
			Math.PI
		);

	}

	if (concaveCornerLine) {

		sketch.arc(
			x + 8 * zoom,
			y + (40 + 8) * zoom,
			16 * zoom,
			16 * zoom,
			180 * Math.PI / 180,
			270 * Math.PI / 180,
		);

	}

}

function drawBottomRightCorner(neighbors, x, y, zoom, sketch) {

	const rightLine = !neighbors.includes("2") && !neighbors.includes("3") &&
		neighbors.includes("4");
	const bottomLine = !neighbors.includes("3") && !neighbors.includes("4") &&
		neighbors.includes("2");
	const cornerLine = !neighbors.includes("2") && !neighbors.includes("3") &&
		!neighbors.includes("4");
	const concaveCornerLine = !neighbors.includes("2") && neighbors.includes("3");

	if (rightLine) {

		sketch.line(
			x + 80 * zoom,
			y + (40 - 8) * zoom,
			x + 80 * zoom,
			y + 40 * zoom
		);
		return;

	}

	if (bottomLine) {

		sketch.line(
			x + (80 - 8) * zoom,
			y + 40 * zoom,
			x + 80 * zoom,
			y + 40 * zoom
		);

	}

	if (cornerLine) {

		sketch.arc(
			x + (80 - 8) * zoom,
			y + (40 - 8) * zoom,
			16 * zoom,
			16 * zoom,
			0,
			90 * Math.PI / 180
		);
		return;

	}

	if (concaveCornerLine) {

		sketch.arc(
			x + (80 + 8) * zoom,
			y + (40 - 8) * zoom,
			16 * zoom,
			16 * zoom,
			90 * Math.PI / 180,
			180 * Math.PI / 180
		);

	}

}

function drawLeftLine(x, y, zoom, sketch) {

	sketch.line(
		x,
		y + 8 * zoom,
		x,
		y + (40 - 8) * zoom
	)

}

function drawRightLine(x, y, zoom, sketch) {

	sketch.line(
		x + 80 * zoom,
		y + 8 * zoom,
		x + 80 * zoom,
		y + (40 - 8) * zoom
	);

}

function drawTopLeftCorner(neighbors, x, y, zoom, sketch) {

	const leftLine = !neighbors.includes("6") && !neighbors.includes("7") &&
		neighbors.includes("0");
	const topLine = !neighbors.includes("7") && !neighbors.includes("0") &&
		neighbors.includes("6");
	const cornerLine = !neighbors.includes("6") && !neighbors.includes("7") &&
		!neighbors.includes("0");
	const concaveCornerLine = !neighbors.includes("6") && neighbors.includes("7");

	if (leftLine) {

		sketch.line(
			x,
			y + 8 * zoom,
			x,
			y
		);
		return;

	}

	if (topLine) {

		sketch.line(
			x,
			y,
			x + 8 * zoom,
			y
		);
		return;

	}

	if (cornerLine) {

		sketch.arc(
			x + 8 * zoom,
			y + 8 * zoom,
			16 * zoom,
			16 * zoom,
			Math.PI,
			270 * Math.PI / 180
		);
		return;

	}

	if (concaveCornerLine) {

		sketch.arc(
			x - 8 * zoom,
			y + 8 * zoom,
			16 * zoom,
			16 * zoom,
			270 * Math.PI / 180,
			0
		);
		return;

	}

}

function drawTopRightCorner(neighbors, x, y, zoom, sketch) {

	const topLine = !neighbors.includes("0") && !neighbors.includes("1") &&
		neighbors.includes("2");
	const rightLine = !neighbors.includes("1") && !neighbors.includes("2") &&
		neighbors.includes("0");
	const cornerLine = !neighbors.includes("0") && !neighbors.includes("1") &&
		!neighbors.includes("2");
	const concaveCornerLine = !neighbors.includes("0") && neighbors.includes("1");

	if (topLine) {

		sketch.line(
			x + (80 - 8) * zoom,
			y,
			x + 80 * zoom,
			y
		);
		return;

	}

	if (rightLine) {

		sketch.line(
			x + 80 * zoom,
			y,
			x + 80 * zoom,
			y + 8 * zoom
		);
		return;

	}

	if (cornerLine) {

		sketch.arc(
			x + (80 - 8) * zoom,
			y + 8 * zoom,
			16 * zoom,
			16 * zoom,
			270 * Math.PI / 180,
			0
		);

		return;

	}

	if (concaveCornerLine) {

		sketch.arc(
			x + (80 - 8) * zoom,
			y - 8 * zoom,
			16 * zoom,
			16 * zoom,
			0,
			90 * Math.PI / 180
		);

	}

}

function drawTopLine(x, y, zoom, sketch) {

	sketch.line(
		x + 8 * zoom,
		y,
		x + (80 - 8) * zoom,
		y
	);

}

export { drawBorders, drawBottomLine, drawBottomLeftCorner,
	drawBottomRightCorner, drawLeftLine, drawRightLine, drawTopLeftCorner,
	drawTopRightCorner, drawTopLine };
