<!-- A modal for customizing a pattern within the context of a level.  Within
	levels, the only thing you can really customize are the color groups. -->
<template>
	<Modal title="Customize Pattern For Level">

		<div class="flex-row"
			:style="{'margin-top': editing ? 0 : '-30px'}">

			<div class="col">

				<h2 class="text-center m-t-0">{{ props.pattern.name }}</h2>

				<PatternPreview class="pattern-img"
					:pattern="props.pattern"
					:colors="renderColors"/>

			</div>

			<div class="col p-r-2 colors">

				<ColorList
					:pattern="props.pattern"
					:level="props.level"
					:colors="colors"
					@toggle-edit="editing = $event"/>

			</div>

		</div>
	</Modal>
</template>

<script>
import ColorList from '../colors/ColorList.vue';
import Modal from './Modal.vue';
import PatternPreview from '../patterns/PatternPreview.vue';
import LevelColorRequest from '../../network/level-colors';
import Vue from 'vue';
export default {
	name: "ModalPatternCustomizer",
	props: ['props'],
	components: { ColorList, Modal, PatternPreview },

	data: function() {
		return {
			editing: false,
			PatternEventBus: new Vue(),
		}
	},

	computed: {

		// Retrieves color groups associated with the pattern in question.
		colors: function() {

			let colors = [];

			// Next, we're going to retrieve the default colors, but if and only if
			// they aren't already in our colors array (i.e. they haven't been
			// customized)
			this.$store.state.patternColors.forEach((color) => {

				if (color.patternID != this.props.pattern.id) {
					return;
				}

				for(let i = 0; i < colors.length; i ++) {
					if (colors[i].colorID == color.id) {
						return;
					}
				}

				// Got this far?  This means that we should add the color
				colors.push(color);

			});

			return colors;

		},

		// Computes an array of both level colors and pattern colors to be used for
		// coloring the SVG preview
		renderColors: function() {

			const colors = this.colors.slice(0);

			if (!this.props.pattern.assignmentID) {
				// This is the prime pattern.  No need to go any further.
				return colors;
			}

			this.$store.state.levelColors.forEach((color) => {

				if (color.assignmentID == this.props.pattern.assignmentID) {

					// This pattern cleary belongs and likely replaces one of the colors
					// in our colors array.  Let's find which one to replace by comparing
					// ids.  Also, make sure to include a name
					for(let i = 0; i < colors.length; i ++) {

						if (colors[i].id == color.colorID) {
							const combinedColor = Object.assign({}, color);
							combinedColor.name = colors[i].name;
							colors.splice(i, 1, combinedColor);
							return;
						}

					}

				}

			});

			return colors;

		}
	},

	methods: {

		// Handles events where the user changes a pattern's color group.  We don't
		// update the source pattern.  Instead we update a variation of the color
		// specifically tied to this level.  In this specific instance the variation
		// doesn't exist yet, so we create it
		addColor: function(color, levelColor) {

			// As with update color, make sure the context is correct
			if (this.props.context == 'preview') {
				return;
			}

			// Go ahead and construct a payload
			const payload = JSON.stringify({
				data: {
					type: "level_color",
					attributes: {
						level_id: this.props.level.id,
						assignment_id: this.props.pattern.assignmentID,
						pattern_id: levelColor ? levelColor.patternID : color.patternID,
						pattern_color_id: levelColor? levelColor.colorID : color.id,
						color: levelColor ? levelColor.color : color.color,
					}
				}
			});

			// Now let's send off the request
			const store = this.$store;
			LevelColorRequest.addColor(payload, (color) => {

				// If we pre-created the level color, we'll want to update it with a new
				// ID
				if (levelColor) {
					levelColor.id = color.id;
					store.commit('addLevelColors', [color]);
					return;
				}

				store.commit('addLevelColors', [color]);

			});

		},

		// Updates a pre-existing level color
		updateColor: function(color, levelColor) {

			// First, what's the context?  If we're using this in a preview context
			// then we're not actually updating anything.  Instead, we're merely
			// changing the color locally.
			if (this.props.context == 'preview') {
				return;
			}

			const payload = JSON.stringify({
				data: {
					id: levelColor.id,
					type: "level_color",
					attributes: {
						color: levelColor.color
					}
				}
			});

			//const store = this.$store;
			LevelColorRequest.updateColor(levelColor.id, payload, () => {
				// Do nothing
			})
		}

	},

	mounted: function() {

		this.PatternEventBus.$on('add-color', this.addColor);
		this.PatternEventBus.$on('update-color', this.updateColor);

	},

	provide: function() {
		return {
			PatternEventBus: this.PatternEventBus
		}
	}
}
</script>

<style lang="scss" scoped>
.pattern-img {
	display: flex;
	align-items: center;
	justify-content: center;
}

.flex-row {
	height: 100%;
	align-items: center;
}


@media(max-width: 768px) {

	.flex-row {
		height: calc(100% - 16px);
		margin-top: 16px;
	}

}

.colors {
	overflow-y: scroll;
}


@media(max-width: 768px) {

	.colors {
		padding: 16px 8px 0;
	}

}
</style>
