<!-- A page for displaying all levels that have been created thus far -->
<template>
	<div>

		<div class="header">
			<h1>Levels</h1>
			<router-link class="button" :to="{name: 'NewLevel'}">
				New Level
			</router-link>
		</div>

		<div>

			<div>
				<LevelList />
			</div>

			<div class="level-grid">

				<div v-for="level in levels">
					<LevelPreview :level="level"/>
				</div>

			</div>

		</div>

	</div>
</template>

<script>
import LevelPreview from '../components/LevelPreview.vue';
import LevelRequest from '../network/levels';
export default {
	name: "LevelCatalogue",
	components: { LevelPreview },

	computed: {

		levels: function() {

			return this.$store.state.levels;

		}
	},

	// Grab all levels when ready
	mounted: function() {
		let store = this.$store;
		LevelRequest.getLevels((levels) => {
			store.commit('addLevels', levels);
		});
	}

}
</script>
