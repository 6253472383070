<!-- Kind of lame.  Just presents user with a splash page -->
<template>
	<div id="home">

		<div class="mondranoid-menu">

			<div class="mondranoid-header">

				<div class="name-spacing"/>

				<div class="name-section">
					<div>
						<div class="name">

							<h1>Mondranoid</h1>
							<div class="ball-container">
								<div class="ball"/>
							</div>

						</div>

						<div class="paddle-container">
							<div class="paddle"/>
						</div>

					</div>
				</div>
			</div>

			<div class="mondranoid-fill">

				<div class="video-container">
				</div>

				<div class="video-spacing">
				</div>

			</div>

			<div class="menu-buttons">

				<div class="button-space">
				</div>

				<div class="buttons p-l-2">

					<router-link class="text-blue" :to="{name: 'Game', params: {levelID: 'quickstart'}}">
						<h3>Quick Start</h3>
					</router-link>

					<router-link class="text-red"
						:to="{name: 'Preview', params: {levelID: 'random'}, query: {difficulty: difficulty}}">
						<h3>Random Level</h3>
					</router-link>

					<a href="#about" class="text-yellow">
						<h3>About</h3>
					</a>
				</div>

				<div class="options">

					<h3 class="easier"
						:class="{'selected': difficulty == 0}"
						@click="difficulty = 0">Simple</h3>

					<h3 class="harder"
						:class="{'selected': difficulty == 1}"
						@click="difficulty = 1">Complex</h3>

				</div>

			</div>

		</div>

		<div id="about">
			<h2>About</h2>
			<hr />

			<p>Mondranoid is a game that plays a lot like the arcade classic
				block-breaking game
				<a target="_blank" href="https://en.wikipedia.org/wiki/Breakout_(video_game)">Breakout</a>,
				but with a twist.  Rather than simply trying to break away blocks, what
				if we tried to replace them with something else?  In Mondranoid's case,
				what if we replaced those blocks with little boxes filled with colors
				and patterns?  What if we combined adjacent boxes with the same patterns
				into even larger patterns?</p>

			<p>Basically, you wind up with something that looks a lot like a painting
				that could have been made by Dutch artist
				<a target="blank" href="https://en.wikipedia.org/wiki/Piet_Mondrian">Piet Mondrian</a>,
				or any one of his contemporaries in the
				<a target="blank" href="https://en.wikipedia.org/wiki/De_Stijl">De Stijl</a> art
				movement.  We initially thought about calling the game Stijlball, but
				it didn't quite have the ring we were looking for.  So instead, we came
				up with Mondranoid, a combination of <strong>Mondr</strong>ian and a
				Japanese refinement of Breakout called
				<a target="blank" href="https://en.wikipedia.org/wiki/Arkanoid">
					Ark<strong>anoid</strong>
				</a>.</p>

			<p>Mondranoid is a free-to-play game.  There's no limitation to how much
				you can play or what you can do in the game.  Instead, when you create
				something in the game that you really like, you have the option of
				turning that thing into a poster or wall-canvas that you can buy and
				(presumably) put in a nice corner of the house where it gets a lot of
				attention.  We'll never pressure you into doing this though.  It's up to
				you whether you think that's a good idea.</p>

			<p>Mondranoid is simultaneously about having fun, being creative, and
				making something.  Each creation you make is unique, depending on the
				way you play the game as well as how you customize colors and patterns
				to suite your taste.  It's easy to learn and quick to finish.  It isn't
				demanding when it comes to your attention.  It's a game designed to be
				played as much (or as little) as you want before you do something else
				with your day.</p>

			<p>We hope you enjoy playing it as much as we enjoyed creating it.</p>

		</div>

		<Footer />

	</div>
</template>

<script>
import Footer from '../components/Footer.vue';
export default {
	name: "Home",
	components: { Footer },
	data: function() {
		return {
			difficulty: 0
		}
	}
}
</script>

<style lang="scss" scoped>
#home {
	height: 100%;
}
.mondranoid-menu {
	height: 100%;
	position: relative;
	display: flex;
	flex-direction: column;
}

.mondranoid-fill {
	width: 100%;
	border-top: 12px solid #16161D;
	border-bottom: 12px solid #16161D;
	flex-grow: 1;
	display: flex;

	.video-container {
		min-width: calc(50% + 12px);
		height: 100%;
		border-right: 12px solid #16161D;
	}

	.video-spacing {
		flex-grow: 1;
		height: 100%;
		background-color: #1733ED;
	}

	@media(max-width: 768px) {
		.video-container {
			width: 100%;
			border-right: none;
		}

		.video-spacing {
			display: none;
		}
	}
}

.mondranoid-header {
	width: 100%;
	display: flex;

	.name-spacing {
		flex-grow: 1;
		background-color: #FF2E00;
	}

	.name-section {
		min-width: 50%;
		flex-grow: 0;
		margin-right: auto;
		display: inline-block;
		border-left: 12px solid #16161D;
		padding-top: 20px;
		padding-right: 20px;

		& >  div {
			display: inline-block;
			width: auto;
		}

	}
	.name {
		display: flex;
		align-items: center;
	}

	.ball-container{
		width: 2rem;
		height: 6.4rem;

		.ball {
			width: 2.2rem;
			height: 2.2rem;
			border-radius: 100%;
			background-color: #FF2E00;
			border: 4px solid #16161D;
			animation: ball 2s infinite, ballcolor 6s infinite;
		}
	}

	@media(max-width: 768px) {
		.ball-container {
			height: 4.8rem;
		}
	}

	.paddle-container {
		height: 2rem;
		width: 100%;
		margin: 10px;
		margin-right: 0;

		.paddle {
			width: 10rem;
			height: 2rem;
			background-color: #FFF;
			border: 4px solid #16161D;
			animation-name: paddle;
			animation-duration: 2s;
			animation-iteration-count: infinite;
		}
	}
}

.menu-buttons {
	width: 100%;
	display: flex;
	align-items: center;

	.buttons {
		display: inline-block;
		text-align: right;
		width: auto;
		border-right: 12px solid #16161D;
		border-left: 12px solid #16161D;
		padding-right: 1.6rem;
	}

	.button-space {
		background-color: #FFD100;
		flex-grow: 1;
		height: 100%;
	}

	.options {
		display: inline-block;
		margin-left: 1.6rem;
		width: calc(50% - 2.8rem);
		padding-top: 10px;

		h3 {
			cursor: pointer;
			display: inline;
			padding: 8px 10px;
			transition: all 0.4s ease;

			&.easier.selected {
				color: #FFF;
				background-color: #1733ED;
				box-shadow: 2px 2px 2px #AAA;
			}

			&.harder.selected {
				color: #FFF;
				background-color: #FF2E00;
				box-shadow: 2px 2px 2px #AAA;
			}
		}
	}
}

@media(max-width: 768px) {

	.menu-buttons {
		flex-direction: row-reverse;

		.options {
			display: none;
		}

		.buttons {
			border-left: none;
		}

	}

}

h1 {
	font-size: 6.4rem;
	margin: 0 10px;
}

@media(max-width: 768px) {
	h1 {
		font-size: 4.8rem;
	}
}

p {
	line-height: 1.6;
}

a {
	border-bottom: 1px dashed #1733ED;
}

#about {
	max-width: 1200px;
	padding: 2rem;
	margin: 0 auto;
}

@keyframes paddle {
	0% { margin-left: 0%; }
	50% { margin-left: calc(100% - 8rem)}
	100% {margin-left: 0%;}
}

@keyframes ball {
	0% {
		margin-top: 0;
	}

	50% {
		margin-top: 5.9rem;
	}

	100% {
		margin-top: 0;
	}
}

@media(max-width: 768px) {
	@keyframes ball {
		0% {
			margin-top: 0;
		}

		50% {
			margin-top: 4.3rem;
		}

		100% {
			margin-top: 0;
		}
	}
}

@keyframes ballcolor {
	0% {
		background-color: #FF2E00;
	}

	17% {
		background-color: #1733ED;
	}

	34% {
		background-color: #1733ED;
	}

	51% {
		background-color: #FFD100;
	}

	68% {
		background-color: #FFD100;
	}

	85% {
		background-color: #FF2E00;
	}

	100% {
		background-color: #FF2E00;
	}
}
</style>
