//
// Mixin for checking collisions.  Really just consists of a single method
//////

import BlobConsts from '../../utils/consts/blob-consts';
import BallConsts from '../../utils/consts/ball-consts';
export default {
	
	props: ['zoom'],
	inject: ['GameState', 'PIXIWrapper'],
	
	methods: {
		
		// Checks for collisions with any "blobettes", returns all collisions to caller
		checkCollisions: function() {
					
			let collisions = [];
		
			const ballVec = {
				x: this.GameState.x,
				y: this.GameState.y
			}
		
			// Let's take a look at our blobettes
			this.blobettes.forEach((blobette) => {
		
				let individualCollisions = [];
		
				// For each blobette, look at its 4 sides
				for(let i = 0; i < 4; i ++) {
		
					// If i is a multiple of two, we take this to mean that we're looking
					// at a top or bottom side
					if (i == 0 || i == 2) {
		
						// Let's first see if the center of the ball falls within the x-bounds
						// of this blobette
						if (this.GameState.x > blobette.x && this.GameState.x < blobette.x + BlobConsts.BLOB_WIDTH) {
		
							// Good sign so far.  If i == 0, compare this.GameState.y with blobette.y.
							// Otherwise, compare this.GameState.y with blobette.y + height
							if (i == 0) {
		
								const delta = blobette.y - this.GameState.y;
								if (Math.abs(delta) < BallConsts.RADIUS + BallConsts.STROKE_WEIGHT) {
		
									// Contact
									individualCollisions.push({
										blobette,
										ballPoint: { x: this.GameState.x, y: this.GameState.y + BallConsts.RADIUS + BallConsts.STROKE_WEIGHT},
										blobPoint: {x: this.GameState.x, y: blobette.y},
										side: "top"
									});
		
								}
		
							} else if (i == 2) {
		
								const delta = blobette.y + BlobConsts.BLOB_HEIGHT - this.GameState.y;
								if (Math.abs(delta) < BallConsts.RADIUS + BallConsts.STROKE_WEIGHT) {
		
									individualCollisions.push({
										blobette,
										ballPoint: { x: this.GameState.x, y: this.GameState.y - BallConsts.RADIUS - BallConsts.STROKE_WEIGHT},
										blobPoint: {
											x: this.GameState.x,
											y: blobette.y + BlobConsts.BLOB_HEIGHT
										},
										side: "bottom"
									});
		
								}
		
							}
		
						}
		
						// If the ball wasn't in the bounds, then lets still check the corners
						else {
		
							if (i == 0) {
		
								// Check the top left corner
								const delta = {
									x: blobette.x,
									y: blobette.y
								}
								delta.x -= ballVec.x;
								delta.y -= ballVec.y;
								const deltaMag = Math.sqrt(delta.x * delta.x + delta.y * delta.y)
		
								if (deltaMag < BallConsts.RADIUS + BallConsts.STROKE_WEIGHT) {
		
									// Contact.  Normalize the delta vect then scale it using the
									// ball's stroke and radius to get the point on the ball that made
									// contact
									delta.x /= deltaMag;
									delta.y /= deltaMag;
									
									delta.x *= BallConsts.RADIUS + BallConsts.STROKE_WEIGHT;
									delta.y *= BallConsts.RADIUS + BallConsts.STROKE_WEIGHT
									individualCollisions.push({
										blobette,
										side: "top-left",
										ballPoint: {x: this.GameState.x + delta.x, y: this.GameState.y + delta.y },
										blobPoint: {x: blobette.x, y: blobette.y}
									});
		
								}
							}
		
							// What about bottom-right corner?
							else if (i == 2) {
		
								const delta = {
									x: blobette.x + BlobConsts.BLOB_WIDTH,
									y: blobette.y + BlobConsts.BLOB_HEIGHT
								}		
												
								delta.x -= ballVec.x;
								delta.y -= ballVec.y;
								const deltaMag = Math.sqrt(delta.x * delta.x + delta.y * delta.y);
								
								if (deltaMag < BallConsts.RADIUS + BallConsts.STROKE_WEIGHT) {
		
									delta.x /= deltaMag;
									delta.y /= deltaMag;
									
									delta.x *= BallConsts.RADIUS + BallConsts.STROKE_WEIGHT
									delta.y *= BallConsts.RADIUS + BallConsts.STROKE_WEIGHT
									individualCollisions.push({
										blobette,
										side: "bottom-right",
										ballPoint: {x: this.GameState.x + delta.x, y: this.GameState.y + delta.y },
										blobPoint: {
											x: blobette.x + BlobConsts.BLOB_WIDTH,
											y: blobette.y + BlobConsts.BLOB_HEIGHT
										}
									});
		
								}
							}
		
						}
		
		
		
					}
		
					// What if instead i is an odd number?  Then check the left and right
					// sides
					if (i == 1 || i == 3) {
		
						// Check to see if the y position of the ball falls within the
						// blobette's "range"
						if (this.GameState.y > blobette.y && this.GameState.y < blobette.y + BlobConsts.BLOB_HEIGHT) {
		
							// Makes our life easier.  Check to see which side we're on
							if (i == 1) {
		
								// Compare deltas.  Remember, this is the right-hand side
								const delta = blobette.x + BlobConsts.BLOB_WIDTH - this.GameState.x;
								if (Math.abs(delta) < BallConsts.RADIUS + BallConsts.STROKE_WEIGHT) {
		
									// Contact
									individualCollisions.push({
										blobette,
										ballPoint: {
											x: this.GameState.x - BallConsts.RADIUS - BallConsts.STROKE_WEIGHT,
											y: this.GameState.y},
										blobPoint: {
											x: blobette.x + BlobConsts.BLOB_WIDTH,
											y: this.GameState.y
										},
										side: "right"
									});
		
								}
		
							}
		
							else if (i == 3) {
		
								const delta = blobette.x - this.GameState.x;
								if (Math.abs(delta) < BallConsts.RADIUS + BallConsts.STROKE_WEIGHT) {
		
									individualCollisions.push({
										blobette,
										ballPoint: {
											x: this.GameState.x + BallConsts.RADIUS + BallConsts.STROKE_WEIGHT,
											y: this.GameState.y
										},
										blobPoint: { x: blobette.x, y: this.GameState.y},
										side: "left"
									});
		
								}
		
							}
		
						}
		
						// Otherwise, check the corners
						else {
		
							// Check top-right corner
							if (i == 1) {
		
								const delta = {
									x: blobette.x + BlobConsts.BLOB_WIDTH,
									y: blobette.y
								};
								delta.x -= ballVec.x
								delta.y -= ballVec.y;
								const deltaMag = Math.sqrt(delta.x * delta.x + delta.y * delta.y)
		
								if (deltaMag < BallConsts.RADIUS + BallConsts.STROKE_WEIGHT) {
		
									delta.x /= deltaMag;
									delta.y /= deltaMag;
									
									delta.x *= BallConsts.RADIUS + BallConsts.STROKE_WEIGHT
									delta.y *= BallConsts.RADIUS + BallConsts.STROKE_WEIGHT
									individualCollisions.push({
										blobette,
										side: "top-right",
										ballPoint: {x: this.GameState.x + delta.x, y: this.GameState.y + delta.y},
										blobPoint: {
											x: blobette.x + BlobConsts.BLOB_WIDTH,
											y: blobette.y
										}
									});
		
								}
		
							}
		
							// Check bottom-left corner
							else if (i == 3) {
		
								const delta = {
									x: blobette.x,
									y: blobette.y + BlobConsts.BLOB_HEIGHT
								}
								delta.x -= ballVec.x;
								delta.y -= ballVec.y;
								const deltaMag = Math.sqrt(delta.x * delta.x + delta.y * delta.y)
		
								if (deltaMag < BallConsts.RADIUS + BallConsts.STROKE_WEIGHT) {
									
									delta.x /= deltaMag;
									delta.y /= deltaMag;
									
									delta.x *= BallConsts.RADIUS + BallConsts.STROKE_WEIGHT
									delta.y *= BallConsts.RADIUS + BallConsts.STROKE_WEIGHT
									individualCollisions.push({
										blobette,
										side: "bottom-left",
										ballPoint: {
											x: this.GameState.x + delta.x,
											y: this.GameState.y + delta.y
										},
										blobPoint: {
											x: blobette.x,
											y: blobette.y + BlobConsts.BLOB_HEIGHT
										}
									});
		
								}
							}
						}
		
					}
		
				}
		
				// Now then, let's check the individual collisions.  We should have
				// only one.  Any more than that means we goofed
				if (individualCollisions.length > 1) {
		
					// No sweat, it's clearly a combination of side and corner.  Remove
					// the corner
					for(let i = 0; i < individualCollisions.length; i ++) {
		
						let collision = individualCollisions[i];
						let isCorner = collision.side == "top-left" ||
							collision.side == "top-right" ||
							collision.side == "bottom-left" ||
							collision.side == "bottom-right";
		
						if (isCorner) {
							individualCollisions.splice(i, 1);
						}
		
					}
		
				}
		
				// Alright then, add the collision to our array of collisions
				collisions = collisions.concat(individualCollisions);
		
			});
		
			// Finally, we have our collisions.  Now, let's try and make things a tad
			// easier.  We can have no more than 3 collisions, but that is rare, the
			// norm being one or two.  If there was two collisions, there's a good
			// chance it was on the same side
			if (collisions.length == 2) {
		
				// So, what we can do is see if they have sides in common.  If they do,
				// then we omit the corner side, as we can basically guarantee that it was
				// the side with the actual edge collision that was "touched"
				let sideA = collisions[0].side;
				let sideB = collisions[1].side;
				for(let i = 0; i < 4; i ++) {
		
					let commonSide;
					// For each iteration, set common side to one of the four sides
					switch(i) {
		
						case 0:
							commonSide = "top";
							break;
		
						case 1:
							commonSide = "left";
							break;
		
						case 2:
							commonSide = "bottom";
							break;
		
						case 3:
							commonSide = "right";
							break;
		
					}
		
					// Next, see if they both include this side
					if (sideA.includes(commonSide) && sideB.includes(commonSide)) {
		
						// If they do, remove from collisions the collision that is a corner
						// (i.e, the side that isn't exactly commonSide)
						if (sideA != commonSide) {
							collisions.splice(0, 1);
						} else if (sideB != commonSide) {
							collisions.splice(1, 1);
						}
		
					}
		
				}
		
			}
		
			// If we got this far, we're in luck.  The ball has certainly nestled itself
			// into an "L"-shaped "pocket".  The good news is that by angle alone you
			// can tell both the orientation of the pocket (they permit only specific
			// 90deg arcs) and the first blob that was contacted
			if (collisions.length >= 2) {
		
				if (this.GameState.angle >= 0 && this.GameState.angle < 90 * Math.PI / 180) {
		
					// In this pocket, we have what is certainly a left collision and a
					// top collision
					//   []
					// [][]
					if (this.GameState.angle < 45 * Math.PI / 180) {
		
						// Very likely that ball hit top first, so let's remove all but
						// top from collisions
						for(let i = 0; i < collisions.length; i ++) {
							let collision = collisions[i];
							if (collision.side != "top") {
								collisions.splice(i, 1);
							}
						}
		
					} else {
		
						// Very likely that ball hit left first, so remove all but the left
						// from collisions
						for(let i = 0; i < collisions.length; i ++) {
							let collision = collisions[i];
							if (collision.side != "left") {
								collisions.splice(i, 1);
							}
						}
		
					}
		
				} else if (this.GameState.angle >= 90 * Math.PI / 180 && this.GameState.angle < Math.PI) {
		
					// In this pocket, we have what is certainly a top collision and
					// a right collision (an actual L)
					// []
					// [][]
		
					if (this.GameState.angle < 135 * Math.PI / 180) {
		
						// Ball likely hit the right first, so remove all but collision with
						// side "right" from collisions
						for(let i = 0; i < collisions.length; i ++) {
							let collision = collisions[i];
							if (collision.side != "right") {
								collisions.splice(i, 1);
							}
						}
		
					} else {
		
						// Ball likely hit the top first, so...you get the idea
						for(let i = 0; i < collisions.length; i ++) {
							let collision = collisions[i];
							if (collision.side != "top") {
								collisions.splice(i, 1);
							}
						}
		
					}
		
				} else if (this.GameState.angle >= Math.PI && this.GameState.angle < 270 * Math.PI / 180) {
		
					// In this pocket, we have what is certainly a right collision and a
					// bottom collision
					// [][]
					// []
					if (this.GameState.angle < 225 * Math.PI / 180) {
		
						// Almost certainly the bottom was hit first, so let's remove all
						// but side with bottom
						for(let i = 0; i < collisions.length; i ++) {
							let collision = collisions[i];
							if (collision.side != "bottom") {
								collisions.splice(i, 1);
							}
						}
		
					} else {
		
						// Almost certainly the right was hit first
						for(let i = 0; i < collisions.length; i ++) {
							let collision = collisions[i];
							if (collision.side != "right") {
								collisions.splice(i, 1);
							}
						}
		
					}
		
				} else if (this.GameState.angle >= 270 * Math.PI && this.GameState.angle < 360 * Math.PI / 180) {
		
					// Finally, in this pocket, we have what is certainly a bottom collision
					// and a left collision
					// [][]
					//   []
					if (this.GameState.angle < 315 * Math.PI / 180) {
		
						// Means the left was probably hit.  So, remove all but left from
						// collisions
						for(let i = 0; i < collisions.length; i ++) {
							let collision = collisions[i];
							if (collision.side != "left") {
								collisions.splice(i, 1);
							}
						}
		
					} else {
		
						// Means the bottom was likely hit, so remove all but bottom
						for(let i = 0; i < collisions.length; i ++) {
							let collision = collisions[i];
							if (collision.side != "bottom") {
								collisions.splice(i, 1);
							}
						}
					}
				}
		
			}
			
			// Finally, attach the collisions to the GameState
			this.GameState.blobettes = collisions;
				
		}
	},
	
	mounted: function() {
		
		this.PIXIWrapper.PIXIApp.ticker.add(this.checkCollisions);

	}
}