<!-- A component for displaying categories in list form -->
<template>
	<div>

		<!-- Used when you want to fit in an extra button or what have you -->
		<slot></slot>

		<PatternCategory v-for="category in categories"
			format="list"
			:category="category"
			@select="$emit('select', category)"/>

	</div>
</template>

<script>
import PatternCategory from './PatternCategory.vue';
import PatternCategoryRequest from '../../network/pattern-categories';
export default {
	name: "PatternCategoryList",
	components: { PatternCategory },
	props: ['selected'],

	computed: {

		categories: function() {
			return this.$store.state.patternCategories.slice(0);
		}

	},

	// When mounted, retrieve all categories from the server
	mounted: function() {

		const store = this.$store;
		PatternCategoryRequest.getAllCategories((categories) => {

			store.commit('addPatternCategories', categories);

		});

	}

}
</script>
