<!-- Not just a normal pattern name.  This component can be used to edit
	patterns. -->
<template>

	<div class="editable-pattern-name">

		<div v-if="editing" style="align-items: end;">

			<router-link class="button square-button"
				:to="{'name': 'PatternCatalogue'}">
				<img src="../../assets/left-arrow-black.svg"/>
			</router-link>

			<form @submit.prevent="saveName">
				<input type="text" class="h2" :value="value" @input="$emit('input', $event.target.value)"
					placeholder="Pattern Name"/>
			</form>

			<button class="square-button" @click="saveName">
				<img src="../../assets/save-icon.svg"/>
			</button>

		</div>

		<div v-else>

			<router-link class="button square-button"
				:to="{'name': 'PatternCatalogue'}">
				<img src="../../assets/left-arrow-black.svg"/>
			</router-link>

			<h1 style="text-align: center">{{pattern.name}}</h1>

			<button class="square-button" @click="editing = true">
				<img src="../../assets/toybox-pencil-black.svg"/>
			</button>

			<!-- Delete button -->
			<button class="square-button" @click="$emit('delete')">
				<img src="../../assets/times.svg"/>
			</button>

			<!-- Publish button -->
			<button class="square-button" @click="$emit('publish')">
				<img src="../../assets/publish.svg"/>
			</button>

		</div>

	</div>

</template>

<script>
export default {
	name: "EditablePatternName",
	props: ['pattern', 'value'],
	data: function() {
		return {
			editing: this.pattern ? false : true,
		}
	},

	methods: {

		// Doesn't actually make a request to save the name from here.  Instead, it
		// emits name up and lets parent handle it
		saveName: function() {
			this.$emit('save');
			this.editing = false;
		}
	}
}
</script>

<style lang="scss" scoped>
.editable-pattern-name div {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
}

form {
	display: block;
	flex-grow: 1;
	margin: 0 4px;
}

h1 {
	flex-grow: 1;
}
</style>
