<!-- A component rendering the game itself -->
<template>
	<div class="game" ref="game">

		<div class="loader-container" v-if="ready.length">
			<Loader />
		</div>
		
		<canvas ref="renderCanvas"/>
			
		<ColorQueue v-if="drawReady && !gameDone"
			:svgs="svgs"
			:zoom="zoom"/>
			
		<Blob v-if="drawReady && !gameDone"
			:layout="this.level.layout"
			:screen-width="screenWidth"
			:screen-height="screenHeight"
			:zoom="zoom" />
			
		<Rects v-if="drawReady"
			@endgame="handleEndGame"
			:layout="this.level.layout"
			:screen-width="screenWidth"
			:screen-height="screenHeight"/>
			
		<Paddle v-if="drawReady && !gameDone"
			:mobile="mobile"
			:screen-width="screenWidth"
			:screen-height="screenHeight"/>
			
		<Ball v-if="drawReady && !gameDone"
			:paused="paused"
			:screen-width="screenWidth"
			:screen-height="screenHeight"
			:sounds="sounds"/>

		<ControlOverlay v-if="firstTime && !controlsDismissed"
			:mobile="mobile"
			@play="dismissControls"/>

		<PauseOverlay v-if="paused"
			:mobile="mobile"
			@play="paused = !paused"/>

		<div class="end-game-container" v-if="gameDone">

			<div class="buttons thick-border">

				<button class="block-button left-border-red" @click="goToPoster">
					Turn Into A Poster
				</button>

				<button class="block-button left-border-blue" @click="randomLevel(0)">
					Random Simple Level
				</button>

				<button class="block-button left-border-blue" @click="randomLevel(1)">
					Random Complex Level
				</button>

				<button class="block-button left-border-yellow" @click="resetLevel">
					Play Again
				</button>

			</div>

		</div>

	</div>
</template>

<script>
import * as PIXI from 'pixi.js';
import axios from 'axios';
import Ball from '../components/game/Ball.vue';
import Blob from '../components/game/Blob.vue';
import ColorQueue from '../components/game/ColorQueue.vue';
import ControlOverlay from '../components/controls/ControlOverlay.vue';
import Hammer from 'hammerjs/hammer.min';
import LevelRequest from '../network/levels';
import Loader from '../components/Loader.vue';
import MobileMixin from '../mixins/mobile';
import Paddle from '../components/game/Paddle.vue';
import PauseOverlay from '../components/controls/PauseOverlay.vue';
import Rects from '../components/game/Rects.vue';
import Vue from 'vue';

export default {
	name: "Game",
	components: { Ball, Blob, ColorQueue, ControlOverlay, Loader, Paddle, PauseOverlay, Rects },
	props: ['levelID'],
	mixins: [ MobileMixin ],

	data: function() {
		return {
			controlsDismissed: false,
			drawReady: false,
			eventData: null,
			finishedRects: [],
			gameDone: false,
			GameEventBus: new Vue(),
			GameState: {
				angle: 90 * Math.PI / 180,
				blobettes: [],
				choice: 0,
				collision: false,
				colorqueue: [],
				color: null,
				container: null,
				chunks: [],
				drawnChunks: [],
				ghostChunks: [],
				pin: true,
				powerUp: true,
				poweringUp: false,
				threshold: 0,
				x: 0,
				y: 0,
			},
			keys: {
				enter: false,
				left: false,
				mouse: false,
				right: false,
				shift: false,
				touch: false,
			},
			mobile: false,
			nonRandomID: this.levelID,
			offset: {
				x: 0,
				y: 0,
			},
			offsetContainer: null,
			originalMouse: {
				x: 0,
				y: 0
			},
			panning: false,
			paused: false,
			PIXIWrapper: {
				PIXI,
				PIXIApp: null,
			},
			preview: false,
			ready: [false, false, false, false],
			refresh: false,
			reset: false,
			sounds: {
				blobCollision: [],
				blobIndex: 0,
				paddleCollision: null,
			},
			svgs: [],
			waiting: [],
			zoom: 1,
			zoomContainer: null,
		}
	},

	computed: {

		firstTime: function() {

			const removeControls = localStorage.getItem('remove-controls');
			return !removeControls;

		},

		// Retrieves level from the store.
		level: function() {

			for(let i = 0; i < this.$store.state.levels.length; i ++) {
				const level = this.$store.state.levels[i];
				if (level.id == this.nonRandomID) {
					return level;
				}
			}

		},

		// Retrieves any patterns associated with this particular level from the
		// store
		patterns: function() {

			let patterns = [];
			if (!this.level) {
				return patterns;
			}

			this.$store.state.patternAssignments.forEach((assignment) => {

				// Find an assignment associated with this level
				if (assignment.levelID == this.level.id) {

					// Now go through the patterns
					for(let i = 0; i < this.$store.state.patterns.length; i ++) {

						let pattern = this.$store.state.patterns[i];
						if (pattern.id == assignment.patternID) {

							let combo = Object.assign({}, pattern);
							combo.assignmentID = assignment.id;
							patterns.push(combo);
							return;

						}

					}

				}

			});

			return patterns;

		},

		// Retrieves variations of the pattern colors that only occur on this level
		levelColors: function() {

			let colors = [];
			if (!this.patternColors || !this.patternColors.length) {
				return false;
			}

			this.$store.state.levelColors.forEach((color) => {

				for(let i = 0; i < this.patterns.length; i ++) {
					let pattern = this.patterns[i];

					if (color.assignmentID == pattern.assignmentID) {
						colors.push(color);
						return;
					}
				}

			});

			return colors;

		},

		// Retrieves colors associated with above patterns
		patternColors: function() {

			let colors = [];
			if (!this.patterns || !this.patterns.length) {
				return colors;
			}

			this.$store.state.patternColors.forEach((color) => {

				for(let i = 0; i < this.patterns.length; i ++) {

					const pattern = this.patterns[i];
					if (color.patternID == pattern.id) {
						colors.push(color);
						return;
					}
				}

			});

			return colors;

		}

	},

	methods: {
		
				
		// Called after we've fetched the svg pattern swatches from the server.
		// This will alter the svg images with different colors (if necessary) and
		// convert them into useable pngs
		alterPatterns: function() {
		
		  // Now let's match our patternColors with our levelColors
		  let colors = [];
		  this.levelColors.forEach((color) => {
		
			for(let i = 0; i < this.patternColors.length; i ++) {
		
			  if (color.colorID == this.patternColors[i].id) {
		
				colors.push({
				  group: this.patternColors[i],
				  assignmentID: color.assignmentID,
				  color: color.color
				});
				return;
		
			  }
		
			}
		
			// Got this far?  This probably just means the level color doesn't have
			// a color ID.  That will happen with randomly generated colors.  Just
			// add it anyway.
			colors.push({
			  group: {name: color.name},
			  assignmentID: color.assignmentID,
			  color: color.color
			});
		
		  });
		
		  // Next, let's iterate through the raw svgs
		  const loader = PIXI.Loader.shared;
		  this.svgs.forEach((svg, index) => {
		
			if (svg.random && !this.previewed && !this.poster) {
		
			  // Special case.  We're going to ignore color groups for this pattern,
			  // instead looking at every single group and fill statement attached
			  // and replacing them with something else.
			  const groupRegExp = /<g[^>]*>((?!<\/g>)\s|(?!<\/g>).)*<\/g>/g;
			  const fillRegExp = /fill="#([a-f0-9]{0,6})"|style="[^"]*fill:#([a-f0-9]{0,6})[^"]*"/i;
			  const groups = svg.svg.match(groupRegExp);
		
			  for(let j = 0; j < groups.length; j ++) {
		
				const group = groups[j];
		
				let red = Math.round(Math.random() * 256).toString(16);
				if (red.length < 2) {
				  red = '0' + red;
				}
		
				let green = Math.round(Math.random() * 256).toString(16);
				if (green.length < 2) {
				  green = '0' + green;
				}
		
				let blue = Math.round(Math.random() * 256).toString(16);
				if (blue.length < 2) {
				  blue = '0' + blue;
				}
		
				// Okay, this is the color group being updated.  Let's change its fill
				// using the replace method and the above fill regexp
				const newGroup = group.replace(fillRegExp, `fill="#${red + green + blue}"`);
		
				// Then, replace the group substring in our parsedSVG with the
				// newGroup substring.  Wasteful, but save the elegance for when we
				// have the time
				svg.svg = svg.svg.replace(group, newGroup);
		
			  }
		
			} else {
		
			  // Now let's see if one of the above colors affects this pattern
			  for(let i = 0; i < colors.length; i ++) {
		
				const color = colors[i];
				if (color.assignmentID == svg.assignmentID) {
		
				  // We have a match.  So what we're gonna do is use this shamefully
				  // copy/pasted mess of a code to replace some fill styling
				  const groupRegExp = /<g[^>]*>((?!<\/g>)\s|(?!<\/g>).)*<\/g>/g;
				  const IDRegExp = new RegExp(`id="${color.group.name}"`, 'i');
				  const fillRegExp = /fill="#([a-f0-9]{0,6})"|style="[^"]*fill:#([a-f0-9]{0,6})[^"]*"/i;
		
				  // To make this work, let's take our time and reuse what we have.  Start
				  // by just getting the group
				  const groups = svg.svg.match(groupRegExp);
				  for(let j = 0; j < groups.length; j ++) {
		
					let group = groups[j];
		
					// Now, go through each group one by one and see if it has an ID that
					// matches the arg color's name.
					const groupIDMatch = group.match(IDRegExp);
					if (groupIDMatch) {
		
					  // Okay, this is the color group being updated.  Let's change its fill
					  // using the replace method and the above fill regexp
					  const newGroup = group.replace(fillRegExp, `fill="#${color.color}"`);
		
					  // Then, replace the group substring in our parsedSVG with the
					  // newGroup substring.  Wasteful, but save the elegance for when we
					  // have the time
					  svg.svg = svg.svg.replace(group, newGroup);
		
					  // Then, while you're at it, remove the color object from the
					  // colors array so we don't have to look at it again
					  colors.splice(i, 1);
					  i --;
					}
				  }
				}
		
			  }
		
			}
		
			// Finally, regardless of whether not any colors were replaced, we're
			// gonna take the SVG, draw it to a canvas, then use that canvas as our
			// image
			const encoded = "data:image/svg+xml;base64," + window.btoa(svg.svg);
			loader.add(`svg${svg.id}/${index}`, encoded);
			
		  });
		  
		  const _this = this;
		  loader.load((loader, res) => {
			  _this.setup();
		  })
		
		},

		dismissControls: function() {
			this.controlsDismissed = true;
			this.paused = false;
		},

		// Takes the cells from rects and puts them into the store.  Then, it
		// directs the user to the poster page.
		goToPoster: function() {

			const stringfiedlevel = JSON.stringify({
				cells: this.finishedRects,
				patterns: this.patterns,
				patternColors: this.patternColors,
				levelColors: this.levelColors,
			});

			localStorage.setItem('level', stringfiedlevel);
			this.$store.commit('addFinishedLevel', this.rects.cells);
			this.$router.push({name: 'Poster'});

		},
		
		handleArrow: function(arrow) {
			
			if (this.keys.shift && this.GameState.poweringUp) {
				
				let choice = this.GameState.choice
				if (arrow == "ArrowLeft") {
					
					choice --;
					if (choice < 0) {
						choice = 3;
					}
					
				} else if (arrow == "ArrowRight") {
					choice = (choice + 1) % 4
				}
				
				this.GameState.choice = choice;
				this.GameEventBus.$emit('power-up-choice');
				return;

			}
			
			// Otherwise, let paddle handle it
			this.keys.arrow = true;
			this.GameEventBus.$emit('arrow', arrow);
			
		},
		
		handleArrowRelease: function(arrow) {
			this.GameEventBus.$emit('arrow-release');
		},
		
		// Handles end game event from Rects.  Trips a flag and assigns the rect data
		// to something we can use later
		handleEndGame: function(rects) {
			this.finishedRects = rects
			this.gameDone = true;
		},

		// If user does a gesture that looks somewhat like a pinching gesture,
		// complete with two distinct "pointers", then we change the zoom value if
		// and only if we are at the end
		handlePinch: function(evt) {

			// Don't even bother if we aren't done with the level
			if (!this.rects.done) {
				return;
			}

			// Otherwise, there's a scale value in evt, which doesn't really go any
			// further than 3 nor any lower than 0.3.  We need this to be additive
			// (otherwise zoom will be kinda weird and jumpy)
			let newZoom = this.zoom;
			if (evt.scale > 1) {
				newZoom += (evt.scale - 1) / 10;
			} else {
				newZoom -= (1 - evt.scale) / 10;
			}

			if (newZoom >= 0.4 && newZoom <= 2.5) {
				this.zoom = newZoom;
			} else if (newZoom < 0.4) {
				this.zoom = 0.3999;
			} else {
				this.zoom = 2.5001;
			}

		},

		handleShiftDown: function() {
			
			this.keys.shift = true;
			
			// Unpin ball if it's pinned, do nothing else
			if (this.GameState.pin) {
				this.GameState.pin = false;
				return;
			}
			
			// Otherwise, if we have a powerup available, we'll emit an event
			// handled by both the ColorQueue and the Ball in their own respective
			// fashions
			if (this.GameState.powerUp) {
				this.GameState.powerUp = false;
				this.GameState.poweringUp = true;
				this.GameState.choice = 0;
				this.GameEventBus.$emit('power-up');
			}

		},
		
		handleShiftReleased: function() {
			
			this.keys.shift = false;
			
			if (this.GameState.poweringUp) {
				this.GameState.poweringUp = false;
				this.GameEventBus.$emit('power-up-release')
			}
			
		},
				
		// Used to load pattern swatches.  Used by the colorqueue as well as the ball
		loadPatterns: function() {
			
			this.svgs = [];
			const waiting = [];
			this.patterns.forEach((pattern) => {
			
				// First, go ahead and get the text for the SVG
				waiting.push(true);
				axios.get(pattern.filePath)
				.then((response) => {
					
					this.svgs.push({
						id: pattern.id,
						assignmentID: pattern.assignmentID,
						svg: response.data,
						random: pattern.random
					});
					waiting.splice(0, 1);
					if (!waiting.length) {
						this.alterPatterns();
					}
					
				})
				.catch((error) => {
				
				});
			
			});
		
		},
		
		// Key events are handled here, additional directions are sent to child
		// components if necessary
		keyPressed: function(e) {
			
			if (this.paused) {
				return;
			}
			
			// This function is pretty light in its duties.  Rather, it delegates to
			// separate functions
			switch (e.key) {
				
				case "Shift":
					this.handleShiftDown();
					return;
					
				case "ArrowLeft":
				case "ArrowRight":
					this.handleArrow(e.key);
					return;
			}
		},

		// Handler for p5 keyReleased events.  Used to kill acceleration on the
		// paddle.
		keyReleased: function(e) {
			
			if (this.paused && e.key == "Enter") {
				this.paused = false;
				return;
			}
			
			switch(e.key) {
				case "Shift":
					this.handleShiftReleased();
					return;
					
				case "ArrowLeft":
				case "ArrowRight":
					this.handleArrowRelease(e.key);
					return;
				case "Enter":
					this.paused = !this.paused;
					return;
			}
			
		},


		// Handles the beginning of a either a mouse or touch event.
		pointerDown: function(event) {
						
			this.eventData = event.data;
			const pointerPos = this.eventData.getLocalPosition(this.PIXIWrapper.PIXIApp.stage);

			// If the game is finished, then mark the position relative to the offset
			// container and trip the panning flag
			if (this.gameDone) {
				this.offset.x = pointerPos.x - this.offsetContainer.x;
				this.offset.y = pointerPos.y - this.offsetContainer.y;
				this.panning = true;
				return;
			}

			// Otherwise, we treat this as the user wanting to move the paddle.  The
			// only other option is an intent to trigger the power up, but this is
			// handled by separate event listeners on colorqueue.
			this.GameEventBus.$emit('paddle-move');


		},
		
		
		// Handles mouse and touch movement events.  
		pointerMoved: function(event) {
			
			if (this.eventData == null) {
				this.eventData = event.data;
			}
			
			// Get pointer position
			const pointerPos = this.eventData.getLocalPosition(this.PIXIWrapper.PIXIApp.stage);
					
			// If we're currently dragging/panning, then we're going to assign the
			// position to the offset container, taking care to subtract the offset from
			// the result
			if (true && this.panning) {
				
				this.offsetContainer.x = pointerPos.x - this.offset.x;
				this.offsetContainer.y = pointerPos.y - this.offset.y;
				return;
			}
		
			//this.paddle.updateMoveTo(sketch);
		
		},
		
		// Handles the beginning of a either a mouse or touch event.
		pointerUp: function(event) {
			
			// We always set panning to false regardless
			this.panning = false;
		
		},

		mouseReleased: function(sketch, evt) {

			this.paddle.slow();

			if (evt.type == "mouseup") {
				this.keys.mouse = false;

				if (this.ball.poweringUp) {
					this.ball.poweringUp = false;
					this.colors.releasePower(this.ball);
				}
			} else {
				this.keys.touch = false;

				if (this.ball.poweringUp) {
					this.ball.poweringUp = false;
					this.colors.releasePower(this.ball);
				}
			}

			this.panning = false;

			return false;

		},

		// Handles scroll events by using the delta to zoom in and out
		mouseWheel: function(eventData) {

			// Don't do this unless the game is done
			if (!this.gameDone) {
				return;
			}
						
			// Otherwise, take the delta, divide it by an arbitrary amount to slow it
			// down a bit, and add to zoom
			const newZoom = this.zoom + (eventData.deltaY / 1000);
			
			// And make sure that the zoom is within a certain range
			if (newZoom >= 0.5 && newZoom <= 2) {
				this.zoom = newZoom;
			} else if (newZoom < 0.5) {
				this.zoom = 0.4999;
			} else {
				this.zoom = 5;
			}

		},
		
		randomLevel: function(difficulty) {
			this.$router.push({name: 'Preview', params: {levelID: 'random'}, query: {'difficulty': difficulty}});
		},

		resetLevel: function() {
			this.gameDone = false;
			this.reset = true;
			this.zoom = this.blob.scaling;
		},

		// Handler for p5 setup function.  Creates our canvas and initializes
		// everything with values dependent on the state of the drawing area.
		setup: function(sketch) {

			const renderCanvas = this.$refs.renderCanvas;
			const width = renderCanvas.offsetWidth;
			const height = renderCanvas.offsetHeight;
			
			this.screenWidth = width;
			this.screenHeight = height;
			
			this.PIXIWrapper.PIXIApp = new PIXI.Application({
				width,
				height,
				view: renderCanvas,
				backgroundColor: 0xFFFFFA,
				antialias: true,
				resolution: 2
			});
			
			// Now for all the supporting containers, starting with what isn't a
			// container at all, but rather a simple rectangle that helps the
			// root container capture mouse events
			const contactContainer = new this.PIXIWrapper.PIXI.Graphics();
			contactContainer.beginFill(0xFFFFFA, 1);
			contactContainer.drawRect(0, 0, width, height);
			this.PIXIWrapper.PIXIApp.stage.addChild(contactContainer);
			
			// Let's also make the stage interactive and assign pointer events to it here
			this.PIXIWrapper.PIXIApp.stage.interactive = true;
			this.PIXIWrapper.PIXIApp.stage.on('pointerdown', this.pointerDown);
			this.PIXIWrapper.PIXIApp.stage.on('pointermove', this.pointerMoved);
			this.PIXIWrapper.PIXIApp.stage.on('pointerup', this.pointerUp);
			
			// Add a zoom container, which is used to, as the name implies, scale
			// everything in accordance with the level of zom
			this.zoom = 1;
			this.zoomContainer = new PIXI.Container();
			this.zoomContainer.pivot.set(width / 2, height / 2);
			this.zoomContainer.position ={
				x: width / 2,
				y: height / 2
			}
			this.PIXIWrapper.PIXIApp.stage.addChild(this.zoomContainer);
			
			// And add an offset container.  This container is where everything that's
			// part of the game attaches, and is used for panning.
			this.offsetContainer = new PIXI.Container();
			this.zoomContainer.addChild(this.offsetContainer);
			this.GameState.container = this.offsetContainer;
			
			// Take a moment to load in our assets
			const _this = this;
			const loader = PIXI.Loader.shared;
			loader.add('mondrablob', '/mondrablob.svg');
			loader.load((loader, res) => {
				_this.drawReady = true;
			})

			// Let's also compute the level of scaling necessary to make a single
			// chunk fit onto the screen (if required)
			/*

			// Also, we need to set up pinch recognition if user is on a mobile device.
			// Don't even check to see if they're on a device, just do it.
			const gameElement = this.$refs.game;
			const hammer = new Hammer(gameElement);
			hammer.get('pinch').set({enable: true});
			hammer.on('pinch', this.handlePinch);
			*/
			
			// Setup event listeners
			document.body.addEventListener('keydown', this.keyPressed);
			document.body.addEventListener('keyup', this.keyReleased);
			document.body.addEventListener('wheel', this.mouseWheel);
			
			this.PIXIWrapper.PIXIApp.ticker.add(this.trackZoom);
		},

		touchMoved: function(sketch) {

			if (this.rects.done && this.panning) {
				this.offset.x = sketch.mouseX - this.originalMouse.x;
				this.offset.y = sketch.mouseY - this.originalMouse.y;
				return;
			}

			if (!this.keys.touch && !this.keys.mouse) {
				this.paddle.updateMoveTo(sketch);
			}

		},
		
		trackZoom: function() {
			if (this.zoomContainer) {
				this.zoomContainer.scale.set(this.zoom, this.zoom);
			}
		}

	},

	// Should be a levelID.  Let's use that to get the published level, but only
	// if we haven't fetched it already
	mounted: function() {

		this.mobile = this.isMobile();

		let store = this.$store;
		let _this = this;

		for(let i = 0; i < 10; i ++) {
			this.sounds.blobCollision.push(new Audio('/blob-collision.mp3'));
		}
		this.sounds.paddleCollision = new Audio('/paddle-collision.mp3');

		// Retrieve a specific level
		if (this.level) {

			this.previewed = true;

			this.ready.splice(0, 1);

			if (this.patterns.length > 0) {
				this.ready.splice(0, 1);
			}

			if (this.patternColors.length > 0) {
				this.ready.splice(0, 1);
			}

			if (this.levelColors.length >= 0) {
				this.ready.splice(0, 1);
			}

			return;

		}

		LevelRequest.getLevel(this.levelID, false, 0, (level, categories, patterns, colors, levelColors, patternAssignments) => {

			if (this.levelID == 'random' || this.levelID == 'quickstart') {
				this.nonRandomID = level.id;
			}

			store.commit('addLevels', [level]);
			store.commit('addLevelCategories', categories);
			store.commit('addPatterns', patterns);
			store.commit('addPatternColors', colors);
			store.commit('addLevelColors', levelColors);
			store.commit('addPatternAssignments', patternAssignments);
			
			this.loadPatterns();
		});

		const previewElement = this.$refs.preview;
		/*
		const hammer = new Hammer(previewElement);
		hammer.get('pinch').set({enable: true});
		hammer.on('pinch', this.handlePinch);
		*/


	},
	
	beforeDestroy: function () {
		
		this.PIXIWrapper.PIXIApp.destroy(true, {children: true});
		this.PIXIWrapper.PIXIApp = null;
		
		const canvas = this.$refs.renderCanvas;
		const gl = canvas.getContext('webgl2');
		gl.getExtension('WEBGL_lose_context').loseContext();
	},
	
	provide: function() {
		return {
			GameState: this.GameState,
			GameEventBus: this.GameEventBus,
			PIXIWrapper: this.PIXIWrapper
		}
	},

	watch: {

		level: function() {
			if (this.level) {
				this.ready.splice(0, 1);
			}
		},

		patterns: function() {
			if (this.patterns.length > 0) {
				this.ready.splice(0, 1);
			}
		},

		levelColors: function() {
			if (this.levelColors.length >= 0) {
				this.ready.splice(0, 1);
			}
		},

		patternColors: function() {
			if (this.patternColors.length > 0) {
				this.ready.splice(0, 1);
			}
		}

	}
}
</script>

<style lang="scss" scoped>
.game {
	overflow: hidden;
	height: 100%;
	width: 100%;
	position: absolute;

	.buttons {
		padding: 10px;
		background: #FFF;
		position: absolute;
		bottom: 0;
		left: 37.5%;
		margin: 1.6rem auto;
		width: 25%;
	}

	@media(max-width: 768px) {
		.buttons {
			left: 0;
			width: calc(100% - 3.2rem);
			margin: 1.6rem;
		}
	}

}

.end-game-container, .paused{
	position: absolute;
	height: 100%;
	width: 100%;
	top: 0;
}

.end-game-container {
	
	pointer-events: none;
	
	& > * {
		pointer-events: all;
	}
	
	button {
		width: 100%;
		margin: 4px 0;
		border: none;
		box-shadow: none;

		&.left-border-blue {
			border-left: 8px #1733ED solid;

			&:hover {
				background-color: #1733ED;
				color: #FFF;
			}
		}

		&.left-border-red {
			border-left: 8px #FF2E00 solid;

			&:hover {
				background-color: #FF2E00;
				color: #FFF;
			}
		}

		&.left-border-yellow {
			border-left: 8px #FFD100 solid;

			&:hover {
				background-color: #FFD100;
				color: #000
			}
		}
	}
}

.loader-container {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
}

canvas {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
</style>
