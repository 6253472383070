//
// Package for making webhook requests.  More specifically, webhook config
// requests.
/////

import Get from './get';
import Post from './post';

export default {

	changeConfig: Post.config,
	getConfig: Get.config,

}
