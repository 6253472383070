import Middleware from './middleware';
import Vue from 'vue';
import Router from 'vue-router';

import Checkout from './pages/Checkout.vue';
import Game from './pages/Game.vue';
import EditLevel from './pages/EditLevel.vue';
import Home from './pages/Home.vue';
import LevelCatalogue from './pages/LevelCatalogue.vue';
import Login from './pages/Login.vue';
import Orders from './pages/orders/Orders.vue';
import CurrentOrder from './pages/orders/CurrentOrder.vue';
import Pattern from './pages/Pattern.vue';
import PatternCatalogue from './pages/PatternCatalogue.vue';
import Poster from './pages/Poster.vue';
import Preview from './pages/Preview.vue';
import NewPattern from './pages/NewPattern.vue';
import Webhooks from './pages/Webhooks.vue';

Vue.use(Router);

export default new Router({
	routes: [
		{
			path: '/',
			name: 'Home',
			component: Home
		},
		{
			path: '/game/:levelID',
			name: 'Game',
			component: Game,
			props: true,
		},
		{
			path: '/levels',
			name: 'Levels',
			component: LevelCatalogue,
			beforeEnter: (to, from, next) => {
				Middleware.adminMiddle(next);
			}
		},
		{
			path: '/levels/new',
			name: 'NewLevel',
			component: EditLevel,
			beforeEnter: (to, from, next) => {
				Middleware.adminMiddle(next);
			}
		},
		{
			path: '/levels/:levelID',
			name: 'EditLevel',
			component: EditLevel,
			props: true,
			beforeEnter: (to, from, next) => {
				Middleware.adminMiddle(next);
			}
		},
		{
			path: '/login',
			name: 'Login',
			component: Login,
		},
		{
			path: '/orders',
			name: 'Orders',
			component: Orders,
			children: [
				{
					path: '/',
					name: "CurrentOrder",
					component: CurrentOrder
				},
				{
					path: ':orderID',
					name: "Order",
					component: CurrentOrder,
					props: true
				}
			]
		},
		{
			path: '/patterns',
			name: 'PatternCatalogue',
			component: PatternCatalogue,
			beforeEnter: (to, from, next) => {
				Middleware.adminMiddle(next);
			}
		},
		{
			path: '/patterns/new',
			name: 'NewPattern',
			component: NewPattern,
			beforeEnter: (to, from, next) => {
				Middleware.adminMiddle(next);
			}
		},
		{
			path: '/patterns/:patternID',
			name: 'Pattern',
			component: Pattern,
			props: true,
			beforeEnter: (to, from, next) => {
				Middleware.adminMiddle(next);
			}
		},
		{
			path: '/preview/:levelID',
			name: 'Preview',
			component: Preview,
			props: true,
		},
		{
			path: '/poster',
			name: 'Poster',
			component: Poster
		},
		{
			path: '/poster/checkout',
			name: 'Checkout',
			component: Checkout
		},
		{
			path: '/webhooks',
			name: 'Webhooks',
			component: Webhooks,
			beforeEnter: (to, from, next) => {
				Middleware.adminMiddle(next);
			}
		}
	]
});
