<!-- A componoent that breaks up the sections of the checkout process into
	sections.  Has buttons for going back and forth and a progress bar of sorts
	for showing progress within. -->
<template>

	<div>

		<CheckoutProgress />

		<!-- Login section -->
		<section v-if="section =='login'" class="paper p-2 m-t-2">

			<div class="text-center">
				<h3>Create An Account To Continue</h3>
				<p>Having an account is necessary to check the progress of your order
					and make last-minute changes if necessary.  Make sure to use your
					preferred email address, since <strong>all updates to your order will
					be sent to that email</strong>.</p>
				</div>

			<LoginForm v-if="!newAccount"
				:create-account="true"
				@switch="newAccount = true"
				@success="handleLogin"/>

			<CreateAccountForm v-else
				@switch="newAccount = false"
				@success="handleLogin"/>

		</section>

		<!-- Section for collecting billing address -->
		<section v-if="section == 'billing'" class="paper p-2 m-t-2">

			<h3>Billing Address</h3>
			<BillingAddress
				:savedInfo="billingAddress"
				:finished="finished"
				@prev="section = 'login'"
				@next="handleBilling"/>

		</section>

		<!-- Section for collecting shipping address -->
		<section v-if="section == 'shipping'" class="paper p-2">

			<h3>Shipping Address</h3>
			<ShippingAddress
				:savedInfo="shippingAddress"
				:finished="finished"
				@prev="section = 'billing'"
				@next="handleShipping"/>

		</section>

		<!-- Section for collecting card info -->
		<section v-if="section == 'cardInfo'" class="paper p-2">

			<h3>Card Info</h3>
			<CardInfo
				:billing-info="billingAddress"
				:card-token="cardInfo"
				:finished="finished"
				@prev="section = 'shipping'"
				@next="handleCard"/>

		</section>

		<!-- Section for confirming payment details -->
		<section v-if="section == 'confirm'" class="m-t-2">

			<CheckoutConfirm
				:billing="billingAddress"
				:email="email"
				:shipping="shippingAddress"
				:poster="poster"
				:card-token="cardInfo"
				@prev="section = 'shipping'"
				@redo="section = $event"
				@order-placed="$emit('order-placed', $event)"/>

		</section>

	</div>

</template>

<script>
import BillingAddress from './forms/BillingAddress.vue';
import CardInfo from './forms/CardInfo.vue';
import CheckoutConfirm from './CheckoutConfirm.vue';
import CheckoutProgress from './CheckoutProgress.vue';
import CreateAccountForm from '../auth/CreateAccountForm.vue';
import LoginForm from '../auth/LoginForm.vue';
import ShippingAddress from './forms/ShippingAddress.vue';
export default {
	name: "CheckoutPager",
	props: ['poster'],
	components: {
		BillingAddress,
		CardInfo,
		CheckoutConfirm,
		CheckoutProgress,
		CreateAccountForm,
		LoginForm,
		ShippingAddress
	},

	data: function() {
		return {
			billingAddress: false,
			cardInfo: false,
			email: '',
			finished: false,
			newAccount: true,
			section: 'login',
			shippingAddress: false,
		}
	},

	methods: {

		handleBilling: function(billingAddress) {

			this.billingAddress = billingAddress;
			this.section = 'shipping';

		},

		handleCard: function(cardInfo) {

			this.cardInfo = cardInfo;
			this.finished = false;
			this.section = 'confirm';

		},

		handleLogin: function(email) {
			this.email = email;
			this.billingAddress = false;
			this.shippingAddress = false;
			this.cardInfo = false;
			this.finished = false;
			this.section = 'billing';
		},

		handleShipping: function(shippingAddress) {

			this.shippingAddress = shippingAddress;
			this.section = 'cardInfo';

		}

	}

}
</script>

<style lang="scss" scoped>
section {

	h3 {
		text-align: center;
	}
}

@media(max-width: 768px) {
  section {
    padding: 16px 8px;
  }
}
</style>
