/**
 * Package for handling authentication requests.  They're all post requests, so
 * rather than creating a single very busy post.js file we create sep files for
 * sep use cases.
 * * * * */

import CreateAccount from './create-account';
import Login from './login';

export default {

  createAccount: CreateAccount.createAccount,
	login: Login.login,

}
