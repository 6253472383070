<!-- A page that displays order information for a user.  By default, latest
	order is displayed first, with current order information as well as where its
	being shipped to. -->
<template>

	<div class="has-nav">

		<Navbar />

		<div class="container">

			<div v-if="loading"
				class="full-height-viewport full-width-viewport flex-line absolute">
				<div style="margin: 0 auto;" class="text-center">
					<Loader />
					<h4>Fetching Orders...</h4>
				</div>
			</div>

			<NoOrders v-else-if="orders.length == 0"/>

			<div v-else>

				<div class="p-h-2 flex-line order-controls">

					<h1 class="m-h-o m-r-2">Orders</h1>

					<div class="h3 m-h-0 latest-order button" :class="{'btn-blue active': isLatest && !showAllOrders}">
						<router-link :to="{name: 'CurrentOrder'}"
							@click.native="showAllOrders = false">
							<img src="../../assets/right-arrow-white.svg" v-if="isLatest && !showAllOrders"/>
							Latest Order
						</router-link>
					</div>

					<button class="show-all-orders"
						:class="{'btn-red active': showAllOrders}"
						@click="showAllOrders = true">
						<span>
							<img src="../../assets/right-arrow-white.svg" v-if="showAllOrders"/>
							<h3 class="m-h-0">Show All Orders</h3>
						</span>
					</button>

				</div>

				<div class="flex-row m-t-2">

					<div class="col w-8"
						:class="{'hidden': showAllOrders}">
						<router-view>
						</router-view>
					</div>

					<!-- Displays the five last orders. -->
					<div class="col w-4 p-l-2 order-list"
						:class="{'show': showAllOrders}">
						<OrderList :orders="orders"
							@change-to="showAllOrders = false"/>
					</div>

				</div>

			</div>

		</div>

	</div>

</template>

<script>
import Loader from '../../components/Loader.vue';
import Navbar from '../../components/Navbar.vue';
import NoOrders from '../../components/orders/NoOrders.vue';
import OrderList from '../../components/orders/OrderList.vue';
import OrderRequest from '../../network/orders';
export default {
	name: "Orders",
	components: { Loader, Navbar, NoOrders, OrderList },
	data: function() {
		return {
			loading: true,
			showAllOrders: false,
		}
	},

	computed: {

		isLatest: function() {

			if (!this.$route.params.orderID) {
				return true;
			}

			return false;

		},

		orders: function() {
			return this.$store.state.orders;
		}

	},

	// Retrieves all orders, albeit without order details.
	mounted: function() {

		const store = this.$store;
		const _this = this;
		OrderRequest.getOrders((orders) => {
			_this.loading = false;
			store.commit('addOrders', orders);

		});

	}
}
</script>

<style lang="scss" scoped>
.latest-order {
	display: inline-block;
	transition: none;

	a {
		color: #333;
		display: flex;
		align-items: center;
	}

	&.active {

		border: none;
		padding: 1.4rem 1.6rem;

		a {
			color: #FFF;
		}
	}

}

button, .button {

	img {
		margin: 0 0.8rem;
		height: 30px;
	}

	span {
		display: flex;
		align-items: center;
	}

}

.active {

	border: none;
	padding: 1.4rem 1.6rem;

	a {
		color: #FFF;
	}
}

.show-all-orders {
	display: none;
}

@media(max-width: 768px) {
	.order-list {
		display: none;
		padding-left: 0;

		&.show {
			display: block;
		}
	}

	.show-all-orders {
		transition: none;
		display: inline-block;
	}
}

@media(max-width: 568px) {
	.order-controls {

		button, .button {
			display: block;
			width: 100%;
			margin: 0.8rem 0;
		}
	}
}

</style>
