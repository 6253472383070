//
// Blobprint is a collection of rectangles that show you where blobettes would
// appear were this an actual level.  The sharp corners are one key
// differentiator, the other is that the blobettes alternate betweent two
// bluish-purplish shades depending on what chunk they're in.
//////

import { drawBorders, drawBottomLine, drawBottomLeftCorner,
	drawBottomRightCorner, drawLeftLine, drawRightLine, drawTopLeftCorner,
	drawTopRightCorner, drawTopLine } from './borders';
import { calcNeighbors } from './neighbors';

export default class Blobprint {

	constructor(options, sketch) {

		// Bind imported methods
		this.calcNeighbors = this.calcNeighbors.bind(this);
		this.drawBorders = this.drawBorders.bind(this);
		this.drawBottomLine = this.drawBottomLine.bind(this);
		this.drawBottomLeftCorner = this.drawBottomLeftCorner.bind(this);
		this.drawBottomRightCorner = this.drawBottomRightCorner.bind(this);
		this.drawLeftLine = this.drawLeftLine.bind(this);
		this.drawRightLine = this.drawRightLine.bind(this);
		this.drawTopLeftCorner = this.drawTopLeftCorner.bind(this);
		this.drawTopRightCorner = this.drawTopRightCorner.bind(this);
		this.drawTopLine = this.drawTopLine.bind(this);

		this.chunkWidth = options.chunkWidth ? options.chunkWidth : 4;
		this.chunkHeight = options.chunkHeight ? options.chunkHeight : 6;
		this.cellWidth = options.cellWidth ? options.cellWidth : 80;
		this.cellHeight = options.cellHeight ? options.cellHeight : 40;
		this.midpoint = options.midpoint ? options.midpoint : {x: 0, y: 0};
		this.width = options.width ? options.width : 100;
		this.height = options.height ? options.height : 100;
		this.chunks = options.chunks ? options.chunks : [];

		// If chunks has, well, chunks, then quickly calculate the neighbors for
		// each rect in the chunk.
		this.recalcNeighbors();

		// Now for the images
		this.images = null;
		this.preview = options.preview ? options.preview : false;
		if (this.preview) {
			this.images = sketch.loadImage('/mondrablob.svg');
		} else {

			this.images = {
				red: sketch.loadImage('/yellow-no-border.svg'),
				blue: sketch.loadImage('/blue-no-border.svg'),

				'top-red': sketch.loadImage('/yellow-top.svg'),
				'top-blue': sketch.loadImage('/blue-top.svg'),

				'bottom-red': sketch.loadImage('/yellow-bottom.svg'),
				'bottom-blue': sketch.loadImage('blue-bottom.svg'),

				'right-red': sketch.loadImage('/yellow-right.svg'),
				'right-blue': sketch.loadImage('/blue-right.svg'),

				'left-red': sketch.loadImage('/yellow-left.svg'),
				'left-blue': sketch.loadImage('/blue-left.svg'),

				'top-right-red': sketch.loadImage('/yellow-top-right.svg'),
				'top-right-blue': sketch.loadImage('/blue-top-right.svg'),

				'top-left-red': sketch.loadImage('/yellow-top-left.svg'),
				'top-left-blue': sketch.loadImage('/blue-top-left.svg'),

				'right-bottom-red': sketch.loadImage('yellow-bottom-right.svg'),
				'right-bottom-blue': sketch.loadImage('blue-bottom-right.svg'),

				'bottom-left-red': sketch.loadImage('/yellow-bottom-left.svg'),
				'bottom-left-blue': sketch.loadImage('/blue-bottom-left.svg'),

				'top-right-bottom-red': sketch.loadImage('/yellow-all-right.svg'),
				'top-right-bottom-blue': sketch.loadImage('/blue-all-right.svg'),

				'top-bottom-left-red': sketch.loadImage('/yellow-all-left.svg'),
				'top-bottom-left-blue': sketch.loadImage('/blue-all-left.svg'),

			}

		}

	}

	// Adds a rectangle to the blobprint.  In the process an attempt will be made
	// to join this rectangle to any other nearby rectangles to form a single
	// polygon
	addTo(mousePos, offset, zoom) {

		// Get the chunk and rect IDs real fast
		let ids = this.getChunkRect(mousePos, offset, zoom);

		// Now let's see if the chunk exists.  If not, we'll just add it to the
		// the stack
		for (let i = 0; i < this.chunks.length; i ++) {

			const chunk = this.chunks[i];
			if (chunk.id.x == ids.chunkID.x && chunk.id.y == ids.chunkID.y) {

				// We have a match.  Now, as a matter of precaution, see whether we
				// should be bothered adding a rect by comparing rectIDs
				for(let j = 0; j < chunk.rects.length; j ++) {

					const rect = chunk.rects[j];
					if (rect.id.x == ids.rectID.x && rect.id.y == ids.rectID.y) {
						// Well, silly adding twice.  Exit early
						return;
					}

				}

				// Now we can safely add this rect to this chunk.
				chunk.rects.push({
					id: ids.rectID,
					neighbors: ""
				});

				// Recalculate neighbors
				this.recalcNeighbors();

				return;
			}

		}

		// If we got this far then the chunk doesn't exist.  So, create a new one
		// with this rect
		this.chunks.push({
			id: ids.chunkID,
			rects: [{
				id: ids.rectID,
				neighbors: ""
			}]
		});

		this.recalcNeighbors();

	}

	// Converts a position into a chunk and rectID
	getChunkRect(mousePos, offset, zoom) {

		// Firstly, convert mousePos into actual position by combining with the
		// offset
		let position = {
			x: (mousePos.x - offset.x),
			y: (mousePos.y - offset.y)
		};

		// Now, let's determine the chunk and rect ID.
		let chunkIDX;
		let chunkIDY;

		if (position.x - this.midpoint.x < 0) {

			chunkIDX =
				Math.floor(
					Math.floor(
						(position.x - this.midpoint.x) /
						(this.cellWidth * zoom)
					) /
					this.chunkWidth
				);

		} else {

			chunkIDX =
				Math.ceil(
					Math.ceil(
						(position.x - this.midpoint.x) /
						(this.cellWidth * zoom)
					) /
					this.chunkWidth
				);

		}

		// Note that the y part of the id will be negative, despite it being
		// positive in most cartesian coordinate systems with a midpoint
		if (position.y - this.midpoint.y < 0) {

			chunkIDY = Math.ceil(
				Math.ceil(
					(this.midpoint.y - position.y) /
					(this.cellHeight * zoom)
				) /
				this.chunkHeight
			);

		} else {

			chunkIDY = Math.floor(
				Math.floor(
					(this.midpoint.y - position.y) /
					(this.cellHeight * zoom)
				) /
				this.chunkHeight
			);

		}

		let chunkID = {
			x: chunkIDX,
			y: chunkIDY
		}

		// if chunkIDX is greater than 0, then we'll want to correct it to zero
		// for the positional equation below
		let correctedX = chunkID.x > 0 ? chunkID.x - 1 : chunkID.x;
		let correctedY = chunkID.y < 0 ? chunkID.y + 1 : chunkID.y;

		// Now, we can figure out the rectID
		const chunkX = ((correctedX * this.cellWidth * this.chunkWidth * zoom) + this.midpoint.x);
		const chunkY = (this.midpoint.y -(correctedY * this.cellHeight * this.chunkHeight * zoom));
		let rectID = {
			x: Math.ceil((position.x - chunkX) / (this.cellWidth * zoom)),
			y: Math.ceil((position.y - chunkY) / (this.cellHeight * zoom))
		}

		return {
			chunkID,
			rectID
		}

	}

	// Removes a blobette from the layout
	remove(mousePos, offset, zoom) {

		// Go ahead and convert the mouse pos into a set of ids
		let ids = this.getChunkRect(mousePos, offset, zoom);

		// Now, using our ids, let's traverse through until we find the right
		// chunk
		for(let i = 0; i < this.chunks.length; i ++) {

			let chunk = this.chunks[i];
			if (chunk.id.x == ids.chunkID.x && chunk.id.y == ids.chunkID.y) {

				// Found the chunk, now find the rect
				for(let j = 0; j < chunk.rects.length; j ++) {

					let rect = chunk.rects[j];
					if (rect.id.x == ids.rectID.x && rect.id.y == ids.rectID.y) {

						// Found our match.  Let's splice it out and exit
						chunk.rects.splice(j, 1);
						this.recalcNeighbors();
						return;
					}
				}
			}
		}
	}

	// Draws any rectangles in the blobprint one-by-one.  Does so by going through
	// chunks first, and drawing each rect inside that particular chunk
	draw(sketch, offset, zoom) {

		sketch.strokeWeight(0);
		sketch.stroke(0);
		sketch.fill(0, 0);

		let color;

		this.chunks.forEach((chunk) => {

			// The big picture is such that the chunks take on a sort of checkboard
			// pattern for easier viewing.  To make this work, the fill is determined
			// by the pattern of the chunkID.  If
			if (chunk.id.x < 0 && chunk.id.y < 0 || chunk.id.x > 0 && chunk.id.y > 0) {

				if (Math.abs(chunk.id.x % 2) == 1 && Math.abs(chunk.id.y % 2) == 1 ||
					Math.abs(chunk.id.x % 2) == 0 && Math.abs(chunk.id.y % 2) == 0) {

					color = 'red';

				} else {

					color = 'blue';

				}

			} else {

				if (Math.abs(chunk.id.x % 2) == 1 && Math.abs(chunk.id.y % 2) == 0 ||
					Math.abs(chunk.id.x % 2) == 0 && Math.abs(chunk.id.y % 2) == 1) {

					color = 'red';

				} else {

					color = 'blue';

				}
			}

			// Now we want get around to drawing the actual rects.  We don't store
			// positions.  Instead, multiply the ids by the appropriate chunk and
			// cell dimensions and position the rects relative to the midpoint.
			// Be sure to factor in the size of the screen
			chunk.rects.forEach((rect) => {

				// Lets get the chunk and rect x and y positions
				let chunkX = chunk.id.x > 0 ? (chunk.id.x - 1) : chunk.id.x;
				chunkX *= this.chunkWidth * this.cellWidth * zoom;
				let rectX = (rect.id.x - 1) * this.cellWidth * zoom;

				let chunkY = chunk.id.y < 0 ? (chunk.id.y + 1) : chunk.id.y;
				chunkY *= this.chunkHeight * this.cellHeight * zoom;
				let rectY = (rect.id.y - 1) * this.cellHeight * zoom;

				// Now combine these with midpoint and offset to get total position
				let totalX = this.midpoint.x + chunkX + rectX + offset.x;
				let totalY = this.midpoint.y - chunkY + rectY + offset.y;

				// Now, draw the rect if and only if the totalX is within the screen's
				// dimensions and the totalY is as well
				let xCondition = totalX + this.cellWidth > 0 && totalX < this.width;
				let yCondition = totalX + this.cellHeight > 0 && totalY < this.height;
				if (xCondition && yCondition) {

					let images = {
						left: null,
						right: null
					};

					if (this.preview) {

						images.left = this.images;
						images.right= this.images;

					} else {
						images = this.neighborToImage(rect.neighbors, color);
					}

					if (images.left === undefined) {
						images.left = this.images[color];
					}
					if (images.right === undefined) {
						images.right = this.images[color];
					}

					sketch.image(images.left, totalX, totalY, 40 * zoom, 40 * zoom);
					sketch.image(images.right, totalX + 40 * zoom, totalY, 40 * zoom, 40 * zoom);

					if (this.preview) {
						// Draw borders
						this.drawBorders(rect.neighbors, totalX, totalY, zoom, sketch);
					}

				}


			});

		});
	}

	// Analyzes the "neighbor pattern" of a rect in a chunk to determine which
	// images it should use when drawing
	neighborToImage(neighbors, color, sketch) {

		let neighborStringLeft = "";
		let neighborStringRight = "";

		// First, is there a top?
		if (!neighbors.includes("0")) {

			neighborStringLeft = "top-";
			neighborStringRight = "top-"

		}

		// What about a right?
		if (!neighbors.includes("2")) {

			if (neighborStringRight == "") {
				neighborStringRight = "right-";
			} else {
				neighborStringRight = neighborStringRight + "right-";
			}

		}

		// A bottom?
		if (!neighbors.includes("4")) {

			if (neighborStringLeft == "") {
				neighborStringLeft = "bottom-";
			} else {
				neighborStringLeft = neighborStringLeft + "bottom-";
			}

			if (neighborStringRight == "") {
				neighborStringRight = "bottom-";
			} else {
				neighborStringRight = neighborStringRight + "bottom-";
			}

		}

		// A left?
		if (!neighbors.includes("6")) {

			if (neighborStringLeft == "") {
				neighborStringLeft = "left-";
			} else {
				neighborStringLeft = neighborStringLeft + "left-";
			}

		}
		return {
			left: this.images[`${neighborStringLeft}${color}`],
			right: this.images[`${neighborStringRight}${color}`],
			leftString: `${neighborStringLeft}${color}`,
			rightString: `${neighborStringRight}${color}`
		}

	}

	recalcNeighbors() {

		this.chunks.forEach((chunk) => {

			chunk.rects.forEach((rect) => {

				rect.neighbors = "";
				this.calcNeighbors(
					chunk.id,
					rect.id,
					rect
				);

			});

		});

	}
}

Blobprint.prototype.calcNeighbors = calcNeighbors;
Blobprint.prototype.drawBorders = drawBorders;
Blobprint.prototype.drawBottomLine = drawBottomLine;
Blobprint.prototype.drawBottomLeftCorner = drawBottomLeftCorner;
Blobprint.prototype.drawBottomRightCorner = drawBottomRightCorner;
Blobprint.prototype.drawLeftLine = drawLeftLine;
Blobprint.prototype.drawRightLine = drawRightLine;
Blobprint.prototype.drawTopLeftCorner = drawTopLeftCorner;
Blobprint.prototype.drawTopRightCorner = drawTopRightCorner;
Blobprint.prototype.drawTopLine = drawTopLine;
