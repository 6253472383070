<!-- A form for creating a new account.  Can't be used to create admin accounts.
	Only good really for the checkout page. -->
<template>

	<div>

		<div v-if="loading" class="loading">
			<Loader />
			<p class="text-center">One sec.  Logging you in.</p>
		</div>

		<form @submit.prevent="createAccount" v-else>

			<div>
				<label for="inputEmail">Email: </label>
				<input type="text" class="input" id="inputEmail"
					placeholder="Email" v-model="email"/>
			</div>

			<div>
				<label for="inputPassword">Password: </label>
				<input type="password" class="input" id="inputPassword"
					placeholder="Password" v-model="password"/>
			</div>

			<div>
				<label for="inputPassword">Password (Again): </label>
				<input type="password" class="input" id="inputPassword"
					placeholder="Password" v-model="passAgain"/>
			</div>

			<button type="submit" class="block-button">
				Create Account
			</button>

			<button type="button" class="block-button"
				@click="$emit('switch')">
				Login To Existing Account
			</button>

		</form>

	</div>

</template>

<script>
import AuthRequests from '../../network/authentication';
export default {
	name: "CreateAccountForm",

	data: function() {
		return {
			email: '',
			password: '',
			passAgain: '',
			errors: false
		}
	},

	methods: {

		// Fires off a request to create a (non-admin) account using provided info.
		// Also makes sure that the passwords match and that the email is valid
		// enough.
		createAccount: function() {

			let errors = []

			// Make sure both passwords are present
			if (!this.password) {
				errors.push('no-password');
			}

			if (!this.passAgain) {
				errors.push('no-pass-again');
			}

			// Make sure they match
			if (this.password && this.passAgain && this.password != this.passAgain) {
				errors.push('no-pass-match');
			}

			// Make sure the email is present
			if (!this.email) {
				errors.push('no-email');
			}

			// Make sure the email has an "@" sign along with a something vaguely
			// resembling a top-level domain at the end
			// TODO

			if (errors.length) {
				this.errors = errors;
				return;
			}

			// Alright, that's all the checking we need.  Send away
			let payload = JSON.stringify({
				data: {
					type: "user",
					attributes: {
						email: this.email,
						password: this.password
					}
				}
			});

			const _this = this;
			AuthRequests.createAccount(payload, () => {
				// Let parent know that they've created
				_this.$emit('success', _this.email);
			});

		}

	}
}
</script>

<style lang="scss" scoped>
form {
	max-width: 400px;
	width: 100%;
	margin: 0 auto;
	padding: 10px;

	button {
		margin-top: 2rem;
	}
}

.loading {
	margin: 5rem auto;
	display: flex;
	flex-direction: column;
}
</style>
