//
// Portion of store for keeping track of specific pattern assignments to levels.
/////

export default {

	addPatternAssignments: (state, payload) => {

		payload.forEach((assignment) => {

			for(let i = 0; i < state.patternAssignments.length; i ++) {

				if (assignment.id == state.patternAssignments[i].id) {
					state.patternAssignments.splice(i, 1, assignment);
					return;
				}

			}

			state.patternAssignments.push(assignment);

		});

	},

	removePatternAssignment: (state, id) => {

		for(let i = 0; i < state.patternAssignments.length; i ++) {

			if (id == state.patternAssignments[i].id) {
				state.patternAssignments.splice(i, 1);
				return;
			}

		}

	}

}
