//
// Collection of methods for making requests to the server involving levels.
/////

import Get from './get';
import Post from './post';
import Put from './put';
import Delete from './delete';

export default {

	getLevel: Get.level,
	getLevels: Get.levels,

	addLevel: Post.level,
	addPatternToLevel: Post.patternToLevel,

	updateLevel: Put.level,

	removePatternFromLevel: Delete.patternFromLevel

}
