import { render, staticRenderFns } from "./PauseKeys.vue?vue&type=template&id=3b5efb52&scoped=true&"
var script = {}
import style0 from "./PauseKeys.vue?vue&type=style&index=0&id=3b5efb52&prod&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b5efb52",
  null
  
)

export default component.exports