export default {
	
	data: function() {
		return {
			BALL_DIAMETER: 20,
			BALL_RADIUS: 10,
			BALL_STROKE_WEIGHT: 4,
			BALL_MAGNITUDE: 7
		}
	}
}