<template>

	<div>

		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 250 250">
			<title>Launching Keys</title>
			<g id="Launching_Keys_Static" data-name="Launching Keys Static">
				<rect x="64.5" y="106.5" width="120" height="20" style="fill:#fff;stroke:#000;stroke-miterlimit:10;stroke-width:5px"/>
				<path d="M225,212H25v13a5,5,0,0,0,5,5H220a5,5,0,0,0,5-5Z"/>
			</g>
			<g id="ShiftKeyLaunch" data-name="Shift Key Launch">
				<rect x="27" y="161.03" width="196" height="60.52" rx="3" style="fill:#fff"/>
				<path d="M220,163a1,1,0,0,1,1,1v54.52a1,1,0,0,1-1,1H30a1,1,0,0,1-1-1V164a1,1,0,0,1,1-1H220m0-4H30a5,5,0,0,0-5,5v54.52a5,5,0,0,0,5,5H220a5,5,0,0,0,5-5V164a5,5,0,0,0-5-5Z"/>
				<path d="M51.81,182.23l-6.54,6.53a.94.94,0,0,0,0,1.32l1.32,1.32a.92.92,0,0,0,1.32,0l2.69-2.68v11a.93.93,0,0,0,.93.93H53.4a.92.92,0,0,0,.93-.93v-11L57,191.4a.92.92,0,0,0,1.32,0l1.32-1.32a.94.94,0,0,0,0-1.32l-6.53-6.53A.94.94,0,0,0,51.81,182.23Z"/>
				<text transform="translate(65.77 200.35)" style="font-size:24.19355010986328px;font-family:NeuzeitGro-Bol, Neuzeit Grotesk">
					<tspan style="letter-spacing:0.006014322488400702em">S</tspan>
					<tspan x="12.75" y="0">hi</tspan>
					<tspan x="31.67" y="0" style="letter-spacing:0.010010415953848148em">f</tspan>
					<tspan x="38.83" y="0">t</tspan>
				</text>
			</g>
			<g id="BallKeyLaunching" data-name="Ball Key Launching">
				<circle cx="124" cy="65" r="10" style="fill:#fff;stroke:#000;stroke-miterlimit:10;stroke-width:4px"/>
			</g>
		</svg>

		<p>Pressing the <strong>shift key</strong> (either one) will launch the ball.</p>

	</div>
</template>

<script>
export default {
	name: "LaunchingKeys"
}
</script>

<style lang="css" scoped>
div {
	max-width: 300px;
}

p {
	text-align: center;
}

text {
	font-weight: 700;
}

#BallKeyLaunching {
	animation-name: ball;
	animation-duration: 2s;
	animation-timing-function: linear;
	animation-iteration-count: infinite;
}

#ShiftKeyLaunch {
	animation-name: shift;
	animation-duration: 2s;
	animation-timing-function: ease;
	animation-iteration-count: infinite;
}

@keyframes ball {
	0% {
		transform: translateY(0px);
	}

	20% {
		transform: translateY(0px);
	}

	25% {
		transform: translateY(26.5px);
	}

	100% {
		transform: translateY(-300px);
	}
}

@keyframes shift {
	0% {
		transform: translate(0px);
	}

	20% {
		transform: translateY(0px);
	}

	30% {
		transform: translateY(5px);
	}

	32% {
		transform: translateY(0px)
	}
}
</style>
