//
// Manages state for pattern color groups.
/////

export default {

	addColors: (state, payload) => {

		payload.forEach((color) => {

			for (let i = 0; i < state.patternColors.length; i ++) {
				if (state.patternColors[i].id == color.id) {
					state.patternColors.splice(i, 1, color);
					return;
				}
			}

			state.patternColors.push(color);

		});

	}

}
