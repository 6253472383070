<!-- A self-enclosed form for users to enter in their card info -->
<template>

	<div>

		<!-- We'll put the error messages in this element -->
		<div id="card-errors" role="alert"></div>

		<!-- Elements will create input elements here -->
		<div v-if="!cardToken">
			<div class="card-info flex-row">

				<div class="col w-4 p-r-1">
					<label>Card Number</label>
					<div class="text-input" id="card-number">
					</div>
				</div>

				<div class="col w-4 p-l-1 p-r-1">
					<label>Card Expiration</label>
					<div class="text-input" id="card-expiry">
					</div>
				</div>

				<div class="col w-4 p-l-1">
					<label>Card CVC</label>
					<div class="text-input" id="card-cvc">
					</div>
				</div>

			</div>

			<div class="form-group">
				<label for="cardHolderName">Name On Card</label>
				<input type="text" id="cardHolderName" placeholder="Theo van Doesburg"
					v-model="cardHolderName"/>
			</div>
		</div>

		<div v-else>
			<div class="card-token">

				<img :src="cardBrandImg"/>

				<p>
					<strong>{{ cardBrand }}</strong> ending in <strong>{{ cardLast4 }}</strong> <br />
					owned by <strong>{{ cardName }}</strong>
				</p>

				<button @click="$emit('redo-card')">
					Change Card Info
				</button>

			</div>

		</div>

		<PagerButtons class="pager-buttons"
			:prev="true"
			:prev-ready="true"
			:next="true"
			:next-ready="next"
			@next="handleNext"
			@prev="$emit('prev')"/>

		<button @click="emit('back-to-confirm')" v-if="finished">
			Skip Ahead To Confirmation
		</button>

	</div>

</template>

<script>
import PagerButtons from '../../PagerButtons.vue';
export default {
	name: "CardInfo",
	props: ['billing-info', 'card-token', 'finished'],
	components: { PagerButtons },

	data: function() {
		return {
			cardHolderName: '',
			validNumber: false,
			validExpiry: false,
			validCVC: false,
		}
	},

	computed: {

		cardBrand: function() {

			return this.cardToken.card.brand;

		},

		// Given the card brand value of a stripe token, this will compute the
		// appropriate img src
		cardBrandImg: function() {

			let dir = '/card-brands/';

			switch (this.cardToken.card.brand) {

				case "American Express":
					return dir + "amex.png";
				case "Diners Club":
					return dir + "diners.png";
				case "Discover":
					return dir + "discover.png";
				case "JCB":
					return dir + "jcb.png";
				case "MasterCard":
					return dir + "mastercard.png";
				case "Visa":
					return dir + "visa.png";

			}

			// Got this far?  We have no idea what card was used.  Just use the default
			return dir + "credit.png";

		},

		cardLast4: function() {

			return this.cardToken.card.last4;

		},

		cardName: function() {

			return this.cardToken.card.name;

		},

		next: function() {

			if (this.cardToken) {
				return true;
			}

			if (!this.cardHolderName) {
				return false;
			}

			if (!this.validCVC) {
				return false;
			}

			if (!this.validExpiry) {
				return false;
			}

			if (!this.validNumber) {
				return false;
			}

			return true;

		}

	},

	methods: {

		handleCVCChange: function(evt) {

			if (evt.complete) {
				this.validCVC = true;
				return;
			}

			this.validCVC = false;
		},

		handleExpiryChange: function(evt) {

			if (evt.complete) {
				this.validExpiry = true;
				return;
			}

			this.validExpiry = false;
		},

		handleNext: function() {

			if (this.cardToken) {
				// Just move forward
				this.$emit('next', this.cardToken);
			}

			// Use the provided billing information along with what we have here to
			// tokenize the card.
			// Attempt to tokenize card info
			this.stripe.createToken(this.cardNumber, {
				name: this.cardHolderName,
				address_line1: this.billingInfo.streetAddr,
				address_line2: this.billingInfo.unitAddr,
				address_city: this.billingInfo.city,
				address_state: this.billingInfo.state,
				address_zip: this.billingInfo.zip,
				address_country: 'US'
			})
			.then((result) => {

				if (result.error) {

				}

				else {
					this.$emit('next', result.token);
				}

			});

		},

		handleNumberChange: function(evt) {

			if (evt.complete) {
				this.validNumber = true;
				return;
			}

			this.validNumber = false;

		},

	},

	mounted: function() {

		if (this.cardToken) {
			return;
		}

		this.stripe = Stripe(process.env.VUE_APP_STRIPE);
		const elements = this.stripe.elements();

		this.cardNumber = elements.create("cardNumber", {showIcon: true});
		const cardExpiry = elements.create("cardExpiry");
		const cardCVC = elements.create("cardCvc");

		this.cardNumber.mount("#card-number");
		cardExpiry.mount("#card-expiry");
		cardCVC.mount("#card-cvc")

		this.cardNumber.on('change', this.handleNumberChange);
		cardExpiry.on('change', this.handleExpiryChange);
		cardCVC.on('change', this.handleCVCChange);

	},

	watch: {

		cardToken: function() {

			if (this.cardToken) {
				return;
			}

			this.stripe = Stripe(process.env.VUE_APP_STRIPE);
			const elements = this.stripe.elements();

			this.cardNumber = elements.create("cardNumber", {showIcon: true});
			const cardExpiry = elements.create("cardExpiry");
			const cardCVC = elements.create("cardCvc");

			this.cardNumber.mount("#card-number");
			cardExpiry.mount("#card-expiry");
			cardCVC.mount("#card-cvc")

			this.cardNumber.on('change', this.handleNumberChange);
			cardExpiry.on('change', this.handleExpiryChange);
			cardCVC.on('change', this.handleCVCChange);

		}

	}

}
</script>

<style lang="scss" scoped>
.pager-buttons {
	margin-top: 2.4rem;
}

.card-token {
	display: flex;
	align-items: center;

	img {
		margin-right: 0.8rem;
	}

	button {
		margin-left: 1.6rem;
	}
}

@media(max-width: 768px) {
	.col {
		padding-left: 0;
		padding-right: 0;
	}
}
</style>
