<!-- Dedicated login page.  Can be used for both admin and checkout logins. -->
<template>
	<div class="login">

		<LoginForm
			@success="continueToOrders"/>

	</div>
</template>

<script>
import LoginForm from '../components/auth/LoginForm.vue';
export default {
	name: "Login",
	components: { LoginForm },

	methods: {

		continueToOrders: function() {
			this.$router.push({name: "CurrentOrder"});
		}
	}
}
</script>

<style lang="scss" scoped>
.login {
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}
</style>
