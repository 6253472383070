<!-- Displays a list of categories in a more cluster-like format. -->
<template>
	<div class="category-cluster">

		<slot></slot>

		<PatternCategory v-for="category in categories"
			format="cluster"
			:category="category"
			@select="$emit('select', category)"/>

	</div>
</template>

<script>
import PatternCategory from './PatternCategory.vue';
export default {
	name: "PatternCategoryCluster",
	props: ['categories'],
	components: { PatternCategory }
}
</script>

<style lang="scss" scoped>
.category-cluster {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
}
</style>
