
export default {
	
	DIAMETER: 20,
	
	RADIUS: 10,
	
	STROKE_WEIGHT: 4,
	
	MAGNITUDE: 7
	
}