<script>
import BallConstsMixin from '../../mixins/ball/consts';
export default {
	name: "Ball",
	props: ['paused', 'screen-width', 'screen-height', 'sounds'],
	inject: ['GameState', 'GameEventBus', 'PIXIWrapper'],
	mixins: [ BallConstsMixin ],
	data: function() {
		return {
			ballGraphics: null,
			ballMask: null,
			ballSprite: null,
			container: null,
			powerUpSelectorGraphics: null,
			powerUpSelection: [],
			rotation: 0,
			speed: 7,
			triggerNeedsReset: true,
			velocity: {
				x: 0,
				y: 0
			},
		}
	},
	
	methods: {
		
		adjustEdgeX: function(edgeX, desiredX) {
		
			const mag = this.getMagX(edgeX, desiredX);
		
			if (Math.abs(mag) > 21) {
				return false;
			}
		
			const move = {
				x: Math.cos(this.GameState.angle + Math.PI) * mag,
				y: Math.sin(this.GameState.angle + Math.PI) * mag
			}
		
			return {
				x: this.GameState.x + move.x,
				y: this.GameState.y + move.y
			}
		
		},
		
		adjustEdgeY: function(edgeY, desiredY) {
		
			// Need to move the contact point, and by extension the ball, such that
			// it appears as though the edge of ball is on the surface
			const mag = this.getMagY(edgeY, desiredY);
		
			if (Math.abs(mag) > 21) {
				return false;
			}
		
			const move = {
				x: Math.cos(this.GameState.angle + Math.PI) * mag,
				y: Math.sin(this.GameState.angle + Math.PI) * mag
			}
		
			return {
				x: this.GameState.x + move.x,
				y: this.GameState.y + move.y
			}
		
		},
		
		getMagX: function(edgeX, desiredX) {
			return (desiredX - edgeX) / Math.cos(this.GameState.angle + Math.PI);
		},
		
		getMagY: function(edgeY, desiredY) {
			return(desiredY - edgeY) / Math.sin(this.GameState.angle + Math.PI);
		},
		
		// Used when we're no longer "powering up" the ball
		clearPowerUp: function() {
			
			this.container.removeChild(this.powerUpSelectorGraphics);
			this.powerUpSelectorGraphics.destroy();
			this.powerUpSelectorGraphics = null;
			
			this.powerUpSelection.forEach((selection) => {
				
				this.container.removeChild(selection.border);
				selection.border.destroy();
				
				this.container.removeChild(selection.mask);
				selection.mask.destroy();
				
				this.container.removeChild(selection.sprite);
				selection.sprite.destroy();
				
			});
			
			this.powerUpSelection = [];
		},
		
		drawBall: function() {
			
			if (this.container == null) {
				this.container = new this.PIXIWrapper.PIXI.Container();
				this.container.sortableChildren = true;
				this.GameState.container.addChild(this.container);
			}
			
			if (this.ballGraphics == null){
				this.ballGraphics = new this.PIXIWrapper.PIXI.Graphics();
				this.ballMask = new this.PIXIWrapper.PIXI.Graphics();
				
				this.container.addChild(this.ballGraphics);
				this.container.addChild(this.ballMask);
			} else {
				this.ballGraphics.clear();
				this.ballMask.clear()
			}
			
			if (this.ballSprite != null) {
				this.ballSprite.destroy();
			}
						
			// Then, draw a sprite using one of the images from the color queue.  If it
			// doesn't exist yet, then tell the color queue to let us know when it's
			// ready
			this.ballSprite = new this.PIXIWrapper.PIXI.Sprite(this.GameState.colorqueue[this.GameState.choice].img);
			this.ballSprite.width = 16;
			this.ballSprite.height = 16;
			this.ballSprite.anchor.set(0.5);
			
			this.container.addChild(this.ballSprite);
						
			// Draw a basic outline for the ball
			this.ballGraphics.lineStyle(4, 0x000000);
			this.ballGraphics.drawCircle(0, 0, 10);
			this.ballGraphics.endFill();
			
			// Then a mask for said sprite
			this.ballMask.beginFill('0x0');
			this.ballMask.drawCircle(0, 0, 8);
			this.container.addChild(this.ballMask);
			this.ballSprite.mask = this.ballMask;
			
		},
		
		// Handles cases where we're "powering up" the ball.  This adds an
		// additional set of graphics to the container
		drawPowerUp: function() {
			
			// The container really should be initialized by this point, but if it
			// somehow isn't, then initialize it
			if (this.container == null) {
				this.container = new this.PIXIWrapper.PIXI.Container();
				this.container.sortableChildren = true;
				this.GameState.container.addChild(this.container);
			}
			
			// Likewise, if we haven't initialized the selector yet, then do so.
			if (this.powerUpSelectorGraphics == null) {
				this.powerUpSelectorGraphics = new this.PIXIWrapper.PIXI.Graphics();
				
				// Order matters in this case.  Make sure that the selector is drawn
				// below everything else
				this.container.addChildAt(this.powerUpSelectorGraphics, 0);
			} else {
				this.powerUpSelectorGraphics.clear();
			}
			
			// Let's go ahead and draw the selector, which is a simple circle with,
			// optionally, a border if the choice is 0, i.e. we haven't chosen to switch
			// colors
			if (this.GameState.choice == 0) {
				this.powerUpSelectorGraphics.lineStyle(4, 0x0);
			}
			
			let fill = 0xFFFFFF;
			switch(this.GameState.choice) {
				case 0:
					fill = 0xFFFFFF;
					break;
				case 1:
					fill = 0xFF220B;
					break;
				case 2:
					fill = 0xFFC100;
					break;
				case 3:
					fill = 0x1733ED;
					break;
			}
			
			this.powerUpSelectorGraphics.beginFill(fill);
			this.powerUpSelectorGraphics.drawCircle(0 + parseInt(this.GameState.choice) * 50, 0, 22.5);
			this.powerUpSelectorGraphics.endFill();
			
			// Then draw what is effectively a localized copy of the color queue
			if (this.powerUpSelection.length) {
				
				// In this instance we're replacing the colors with new ones, and
				// only need to redraw the sprites
				this.GameState.colorqueue.forEach((color, i) => {
					
					if (!i) {
						return;
					}
					
					let index = i -1;
					
					// Destroy the old sprite
					this.container.removeChild(this.powerUpSelection[index].sprite);
					this.powerUpSelection[index].sprite.destroy();
					
					// Replace it with the new
					const sprite = new this.PIXIWrapper.PIXI.Sprite(color.img);
					sprite.anchor.set(0.5);
					sprite.position.x = index * 50 + 50 
					sprite.width = 16;
					sprite.height = 16;
					
					sprite.mask = this.powerUpSelection[index].mask;
					
					this.powerUpSelection[index].sprite = sprite;
					this.container.addChild(sprite);
					
				});
				
			} else {
				
				// Effectively a copy of how ColorQueue draws its colors
				this.GameState.colorqueue.forEach((color, i) => {
					
					if (!i) {
						return;
					}
					
					let index = i -1;
					
					const mask = new this.PIXIWrapper.PIXI.Graphics();
					mask.beginFill('0x0');
					mask.drawCircle(index * 50 + 50, 0, 8);
					
					const border = new this.PIXIWrapper.PIXI.Graphics();
					border.beginFill('0x0', 0);
					border.lineStyle({
						width: 4,
						color: 0x0,
						alpha: 1,
						alignment: 0.5,
					})
					border.drawCircle(index * 50 + 50, 0, 10);
					
					const sprite = new this.PIXIWrapper.PIXI.Sprite(color.img);
					sprite.anchor.set(0.5);
					sprite.position.x = index * 50 + 50 
					sprite.width = 16;
					sprite.height = 16;
					
					sprite.mask = mask;
					
					this.powerUpSelection.push({
						border,
						mask,
						sprite
					});
					
					this.container.addChild(sprite);
					this.container.addChild(border);
					this.container.addChild(mask);
					
				})
			}
			
			
		},
		
		// Handles collisions with the "blob".  Adjusts ball position and angle
		// accordingly
		handleCollisionBlob: function() {
			
			// In either case you're going to play some sound indicating a collision
			const sound = this.sounds.blobCollision[this.sounds.blobIndex];
			this.sounds.blobIndex = (this.sounds.blobIndex + 1) % 10;
			sound.currentTime = 0;
			sound.play();

			let collision = this.GameState.blobettes[0];
			
			// Next, in the event that we have more than one collision, we're going
			// to attempt to find what the ball collided with first by doing some
			// math on each collision to see what it would take to move the ball
			// enough so that it's just touching the collision point.  The collision
			// that requires the GREATEST ABSOLUTE MAGNITUDE of movement is our "best".
			if (this.GameState.blobettes.length > 1) {
				
				const mags = [];
				const collisions = this.GameState.blobettes;
				for (let i = 0; i < collisions.length; i ++) {
				
					let side = collisions[i].side;
					let ballPoint = collisions[i].ballPoint;
					let blobPoint = collisions[i].blobPoint;
				
					let mag;
					switch (side) {
						case "top":
				
							// Try Y
							mag = this.getMagY(ballPoint.y, blobPoint.y);
							if (Math.abs(mag) > 21) {
								mag = 0;
							}
				
							mags.push(mag);
							break;
				
						case "top-left":
				
							// Try Y again
							mag = this.getMagY(ballPoint.y, blobPoint.y);
							if (Math.abs(mag) > 21) {
				
								// Eh, try X
								mag = this.getMagX(ballPoint.x, blobPoint.x);
								if (Math.abs(mag) > 21) {
				
									// Fuck it
									mag = 0;
								}
				
							}
				
							mags.push(mag);
							break;
				
						case "right":
				
							// Try X
							mag = this.getMagX(ballPoint.x, blobPoint.x);
							if (Math.abs(mag) > 21) {
				
								mag = 0;
				
							}
				
							mags.push(mag);
							break;
				
						case "top-right":
				
							// Try X?
							mag = this.getMagX(ballPoint.x, blobPoint.x);
							if (Math.abs(mag) > 21) {
				
								// Try Y?
								mag = this.getMagY(ballPoint.y, blobPoint.y);
								if (Math.abs(mag) > 21) {
				
									// fuck it
									mag = 0;
				
								}
							}
				
							mags.push(mag);
							break;
				
						case "bottom":
				
							// Try Y
							mag = this.getMagY(ballPoint.y, blobPoint.y);
							if (Math.abs(mag) > 21) {
				
								mag = 0;
				
							}
				
							mags.push(mag);
							break;
				
						case "bottom-right":
				
							// Try Y?
							mag = this.getMagY(ballPoint.y, blobPoint.y);
							if (Math.abs(mag) > 21) {
				
								// Try X?
								mag = this.getMagX(ballPoint.x, blobPoint.y);
								if (Math.abs(mag) > 21) {
				
									// Fuck it
									mag = 0;
								}
				
							}
				
							mags.push(mag);
							break;
				
						case "left":
				
							// Try X
							mag = this.getMagX(ballPoint.x, blobPoint.y);
							if (Math.abs(mag) > 21) {
				
								mag = 0;
				
							}
				
							mags.push(mag);
							break;
				
						case "bottom-left":
				
							// Try X?
							mag = this.getMagX(ballPoint.x, blobPoint.x);
							if (Math.abs(mag) > 21) {
				
								// Try Y?
								mag = this.getMagY(ballPoint.y, blobPoint.y);
								if (Math.abs(mag) > 21) {
				
									// Fuck it
									mag = 0;
								}
				
							}
				
							mags.push(mag);
							break;
				
						default:
							mags.push(0);
				
					}
				
				}
				
				// Alright, we've got our mags.  Now, see which one is the greatest
				// and let the collision from that mag be our canonical collision
				let maxMag = Math.max(mags);
				for(let i = 0; i < mags.length; i ++) {
				
					if (mags[i] == maxMag) {
				
						// Assign this collision to our collision variable and break
						collision = collisions[i];
						break;
				
					}
				
				}
				
			}
			
			// Alright, so now that we have THE collision, we're going to remove the
			// blobette in question and replace it with a rect
			let blobette = collision.blobette;
			
			const color = this.GameState.colorqueue[this.GameState.choice]
			this.GameEventBus.$emit('add-rect', blobette, color);
			this.GameEventBus.$emit('remove-blobette', blobette);
			
			// Then, we're going to adjust the ball's position and angle accordingly
			
			// Regardless, we have THE collision in our grasp.  Now let's see which side
			// we hit, then use that side to assign the proper reflectiveAngle and move
			// the ball by the proper amount
			let pos;
			let reflectiveAngle;
			let angleDelta;
			const deltaX = collision.ballPoint.x - collision.blobPoint.x;
			const deltaY = collision.ballPoint.y - collision.blobPoint.y;
			
			switch(collision.side) {
			
				case "top":
			
					// Try adjustY
					pos = this.adjustEdgeY(
						collision.ballPoint.y,
						collision.blobPoint.y,
					);
										
					// Edge case where the ball was coming in at 0 or 180.  In this case,
					// we simply nudge the ball up by the delta between ballPoint and
					// paddlePoint.  It'll be a tight fit, by come next frame the ball's
					// velocity should clear it from the paddle
					if (pos == false) {
					
						pos = {
							x: this.GameState.x,
							y: this.GameState.y - deltaY
						}
					
					}
					
					this.GameState.x = pos.x;
					this.GameState.y = pos.y;
					
					// For angle, reflect around 90
					reflectiveAngle = 90 * Math.PI / 180;
					break;
			
				case "top-left":
			
					// We'll make this part determinate on the ball angle.  If the angle is
					// betwen 45 and 180, then we'll count it as hitting the top.  Otherwise
					// from 270 wrapping around to 45 it was probably the left side
					if (this.GameState.angle >= 45 * Math.PI / 180 && this.GameState.angle < Math.PI) {
			
						// Try adjustY
						pos = this.adjustEdgeY(
							collision.ballPoint.y,
							collision.blobPoint.y
						);
			
						// Also, reflective angle is same as top's (90)
						reflectiveAngle = 90 * Math.PI / 180;
			
					} else {
			
						// Okay, try adjustEdgeX
						pos = this.adjustEdgeX(
							collision.ballPoint.x,
							collision.blobPoint.x
						);
			
						// And make reflective angle same as left's (0)
						reflectiveAngle = 0;
			
					}
			
					if (pos == false) {
			
						// Just nudge using deltas
						pos = {
							x: this.GameState.x - deltaX,
							y: this.GameState.y - deltaY
						}
			
					}
			
					this.GameState.x = pos.x;
					this.GameState.y = pos.y;
					break;
			
				case "right":
			
					// Try adjustX
					pos = this.adjustEdgeX(
						collision.ballPoint.x,
						collision.blobPoint.x
					);
			
					if (pos == false) {
			
						// Just nudge
						pos = {
							x: this.GameState.x - deltaX,
							y: this.GameState.y
						}
			
					}
			
					this.GameState.x = pos.x;
					this.GameState.y = pos.y;
			
					// For angle, reflect around 180
					reflectiveAngle = 180 * Math.PI / 180;
					break;
			
				case "top-right":
			
					// Again, determinate on angles here.  From 0 to 135 it was probably a
					// top collision.  From 135 to 270, probably a right collision
					if (this.GameState.angle >= 0 && this.GameState.angle < 135 * Math.PI / 180) {
			
						// Try adjustY
						pos = this.adjustEdgeY(
							collision.ballPoint.y,
							collision.blobPoint.y
						);
			
						// Also, reflective angle is same as top's (90)
						reflectiveAngle = 90 * Math.PI / 180;
			
					} else {
			
						// Try adjustEdgeX
						pos = this.adjustEdgeX(
							collision.ballPoint.x,
							collision.blobPoint.x
						);
			
						// And make reflective angle same as right's (180)
						reflectiveAngle = 180 * Math.PI / 180;
			
					}
			
					if (pos == false) {
			
						// Just nudge ball
						pos = {
							x: this.GameState.x - deltaX,
							y: this.GameState.y - deltaY
						}
			
					}
			
					this.GameState.x = pos.x;
					this.GameState.y = pos.y;
					break;
			
				case "bottom":
			
					// Try adjustEdgeY
					pos = this.adjustEdgeY(
						collision.ballPoint.y,
						collision.blobPoint.y
					);

			
					if(pos == false) {
			
						// Nudge
						pos = {
							x: this.GameState.x,
							y: this.GameState.y - deltaY
						}
			
					}
			
					this.GameState.x = pos.x;
					this.GameState.y = pos.y;
			
					// For angle, reflect around 270
					reflectiveAngle = 270 * Math.PI / 180;
					break;
			
				case "bottom-right":
			
					// Alright, so if the ball was somewhere between 90 and 225, then we'll
					// say it probably hit the right.  Otherwise, we'll say it hit the
					// bottom
					if (this.GameState.angle >= 90 * Math.PI / 180 && this.GameState.angle <= 225 * Math.PI / 180) {
			
						// Try adjustEdgeX
						pos = this.adjustEdgeX(
							collision.ballPoint.x,
							collision.blobPoint.x
						);
			
						// And make reflective angle same as right's (180)
						reflectiveAngle = 180 * Math.PI / 180;
			
					} else {
			
						// Try adjustEdgeY
						pos = this.adjustEdgeY(
							collision.ballPoint.y,
							collision.blobPoint.y
						);
			
						// And make reflective angle same as bottom's (270)
						reflectiveAngle = 270 * Math.PI / 180;
			
					}
			
					if (pos == false) {
			
						// Fine, just nudge
						pos = {
							x: this.GameState.x - deltaX,
							y: this.GameState.y - deltaY
						}
			
					}
			
					this.GameState.x = pos.x;
					this.GameState.y = pos.y;
					break;
			
				case "left":
			
					// Try adjustEdgeX
					pos = this.adjustEdgeX(
						collision.ballPoint.x,
						collision.blobPoint.x
					);
			
					if (pos == false) {
			
						// Nudge
						pos = {
							x: this.GameState.x - deltaX,
							y: this.GameState.y
						}
			
					}
			
					this.GameState.x = pos.x;
					this.GameState.y = pos.y;
			
					// For angle, reflect around 0
					reflectiveAngle = 0;
					break;
			
				case "bottom-left":
			
					// Alright, so, if the angle fell somewhere between 180 and 315, we'll
					// say that it likely hit the bottom.  Otherwise, from 315, wrapping
					// around to 0 an then 90, we an say it hit the left
					if (this.GameState.angle >= Math.PI && this.GameState.angle < 315 * Math.PI / 180) {
			
						// Try adjustEdgeY
						pos = this.adjustEdgeY(
							collision.ballPoint.y,
							collision.blobPoint.y
						);
			
						// And make reflective angle same as bottom's (270)
						reflectiveAngle = 270 * Math.PI / 180;
			
					} else {
			
						// Try adjustEdgeX
						pos = this.adjustEdgeX(
							collision.ballPoint.x,
							collision.blobPoint.x
						);
			
						// Make the reflective angle same as left's (it's 0)
						reflectiveAngle = 0;
			
					}
			
					if (pos == false) {
			
						// Nudge
						pos = {
							x: this.GameState.x - deltaX,
							y: this.GameState.y - deltaY
						}
			
					}
			
					this.GameState.x = pos.x;
					this.GameState.y = pos.y;
					break;
			
			}
						
			// Great, we've figured out the new position AND we have a reflective
			// angle to use.  Using said reflective angle, we're going to take the
			// current angle and reflect it around this angle
			angleDelta = reflectiveAngle - this.GameState.angle;
			
			// Now what we're gonna do is take that delta and add it to the reflective
			// angle, but also add 180
			this.GameState.angle = (reflectiveAngle + angleDelta) + Math.PI;
			
			// Let's also make sure that the angle isn't greater than 360
			this.GameState.angle = this.GameState.angle % (360 * Math.PI / 180);
			
			// I also don't like negative angles.  So, if the angle is negative then
			// take 360 and "add" to get an "overflowed" value
			if (this.GameState.angle < 0) {
				this.GameState.angle = (360 * Math.PI / 180) + this.GameState.angle;
			}
			
			// Now, with our new angle, create a new velocity.  Also, come up with a
			// less wasteful way to do this
			this.velocity = {
				x: Math.cos(this.GameState.angle) * this.speed,
				y: Math.sin(this.GameState.angle) * this.speed
			}
						
		},
		
		handleCollision: function() {
			
			
			this.sounds.paddleCollision.play();
			/*
			this.color = colors.getColor();
			*/
			
			let pos;
			let reflectiveAngle;
			let angleDelta;
			const deltaX = this.GameState.collision.ballPoint.x - this.GameState.collision.paddlePoint.x;
			const deltaY = this.GameState.collision.ballPoint.y - this.GameState.collision.paddlePoint.y;
			
			switch(this.GameState.collision.side) {
			
				case "top":
			
					// Do the position first
					pos = this.adjustEdgeY(
						this.GameState.collision.ballPoint.y,
						this.GameState.collision.paddlePoint.y
					);
			
					// Edge case where the ball was coming in at 0 or 180.  In this case,
					// we simply nudge the ball up by the delta between ballPoint and
					// paddlePoint.  It'll be a tight fit, by come next frame the ball's
					// velocity should clear it from the paddle
					if (pos == false) {
			
						pos = {
							x: this.GameState.x,
							y: this.GameState.y - deltaY
						}
			
					}
			
					this.GameState.x = pos.x;
					this.GameState.y = pos.y;
			
					// For angle, reflect around 90
					reflectiveAngle = 90 * Math.PI / 180;
					break;
			
				case "top-left":
			
					pos = this.adjustEdgeX(
						this.GameState.collision.ballPoint.x,
						this.GameState.collision.paddlePoint.x
					);
			
					if (pos == false) {
			
						// Try the other adjustEdge method
						pos = this.adjustEdgeY(
							this.GameState.collision.ballPoint.y,
							this.GameState.collision.paddlePoint.y
						);
			
						if (pos == false) {
			
							// Still not working?  Very well then, just nudge the ball
							pos = {
								x: this.GameState.x - deltaX,
								y: this.GameState.y - deltaY
							}
			
						}
					}
			
					this.GameState.x = pos.x;
					this.GameState.y = pos.y;
			
					// For the reflective angle, just use what we have for the top
					reflectiveAngle = 90 * Math.PI / 180;
					break;
			
				case "right":
			
					pos = this.adjustEdgeX(
						this.GameState.collision.ballPoint.x,
						this.GameState.collision.paddlePoint.y
					);
			
					if (pos == false) {
			
						// Edge case, just nudge the ball
						pos = {
							x: this.GameState.x - deltaX,
							y: this.GameState.y
						}
			
					}
			
					this.GameState.x = pos.x;
					this.GameState.y = pos.y;
			
					// For angle, reflect around 180
					reflectiveAngle = 180 * Math.PI / 180;
					break;
			
				case "top-right":
			
					pos = this.adjustEdgeX(
						this.GameState.collision.ballPoint.x,
						this.GameState.collision.paddlePoint.x
					);
			
					if (pos == false) {
			
						// Try the other adjustEdge method
						pos = this.adjustEdgeY(
							this.GameState.collision.ballPoint.y,
							this.GameState.collision.paddlePoint.y
						);
			
						if (pos == false) {
			
							// Still not working?  Very well then, just nudge the ball
							pos = {
								x: this.GameState.x - deltaX,
								y: this.GameState.y - deltaY
							}
			
						}
					}
			
					this.GameState.x = pos.x;
					this.GameState.y = pos.y;
			
					// For the reflective angle, just use what we have for the top
					reflectiveAngle = 90 * Math.PI / 180;
					break;
			
				case "bottom":
			
					// Literally impossible, just leaving this here so no one asks about
					// it
					break;
			
				case "bottom-right":
			
					pos = this.adjustEdgeY(
						this.GameState.collision.ballPoint.y,
						this.GameState.collision.paddlePoint.y
					);
			
					if (pos == false) {
			
						// Try again with other adjustment method
						pos = this.adjustEdgeX(
							this.GameState.collision.ballPoint.x,
							this.GameState.collision.paddlePoint.x
						);
			
						if (pos == false) {
			
							pos = {
								x: this.GameState.x - deltaX,
								y: this.GameState.y - deltaY
							}
			
						}
			
					}
			
					this.GameState.x = pos.x;
					this.GameState.y = pos.y;
			
					// As for the reflective angle, just use what we have for the right
					// side
					reflectiveAngle = 180 * Math.PI / 180;
					break;
			
				case "left":
			
					pos = this.adjustEdgeX(
						this.GameState.collision.ballPoint.x,
						this.GameState.collision.paddlePoint.x
					);
			
					if (pos == false) {
			
						pos = {
							x: this.GameState.x - deltaX,
							y: this.GameState.y
						}
			
					}
			
					this.GameState.x = pos.x;
					this.GameState.y = pos.y;
			
					// For angle, reflect around 0
					reflectiveAngle = 0;
					break;
			
				case "bottom-left":
			
					pos = this.adjustEdgeX(
						this.GameState.collision.ballPoint.x,
						this.GameState.collision.paddlePoint.x
					);
			
					if (pos == false) {
			
						pos = this.adjustEdgeY(
							this.GameState.collision.ballPoint.y,
							this.GameState.collision.paddlePoint.y
						);
			
						if (pos == false) {
			
							pos = {
								x: this.GameState.x - deltaX,
								y: this.GameState.y - deltaY
							}
			
						}
					}
			
					this.GameState.x = pos.x;
					this.GameState.y = pos.y;
			
					// For angle, just use angle we have for left
					reflectiveAngle = 0;
					break;
			
			}		
			
			// Alright, the position has been adjusted, let's talk angles.  First, a
			// a special case, one where the this.GameState.collision side is NOT top and the ball
			// is angled such that we can actually tell it's moving away from the
			// paddle given what we know about the side.  If that's the case, we're
			// gonna nudge the angle of the ball
			if (this.GameState.collision.side != "top") {
			
				// Right side?  Check to see if the ball is angled from 0 to 90 or from
				// 270 to 0
				const ballRightDown = this.GameState.angle >= 0 && this.GameState.angle < 90 * Math.PI / 180;
				const ballRightUp = this.GameState.angle >= 270 * Math.PI / 180 && this.GameState.angle < 360 * Math.PI / 180;
			
				// Left side?  Check to see if the ball is angled from 90 to 180 or from
				// 180 to 270
				const ballLeftDown = this.GameState.angle >= 90 * Math.PI / 180 && this.GameState.angle < Math.PI;
				const ballLeftUp = this.GameState.angle >= Math.PI && this.GameState.angle < 270 * Math.PI / 180;
				if (this.GameState.collision.side.includes("right") && ballRightDown || ballRightUp) {
			
					// Again, we're just gonna nudge the angle.  We'll keep it simple and
					// nudge by a fixed amount.  Let's say, 3 degrees.
					if (ballRightDown) {
			
						this.GameState.angle += 3 * Math.PI / 180;
			
						// Don't let it go over
						if (this.GameState.angle >= 90 * Math.PI / 180) {
							this.GameState.angle = 87 * Math.PI / 180;
						}
			
					} else {
			
						this.GameState.angle -= 3 * Math.PI / 180;
			
						// Don't let it go over
						if (this.GameState.angle <= 270 * Math.PI / 180) {
							this.GameState.angle = 267 * Math.PI / 180;
						}
			
					}
			
					this.velocity = {
						x: Math.cos(this.GameState.angle) * this.speed,
						y: Math.sin(this.GameState.angle) * this.speed
					}
					
					// Exit early.  Not doing so will really fuck things up
					return;
			
				}
			
				// Left side?  Check to see if the ball is angled from 180 to 90 or from
				// 180 to 270
				else if (this.GameState.collision.side.includes("left") && ballLeftUp || ballLeftDown) {
			
			
					// Nudging the angle, but this time we're subtracting 3 degrees
					if (ballLeftDown) {
			
						this.GameState.angle -= 3 * Math.PI / 180;
			
						// Don't let it go over
						if (this.GameState.angle <= 90 * Math.PI / 180) {
							this.GameState.angle = 93 * Math.PI / 180;
						}
			
					} else {
			
						this.GameState.angle += 3 * Math.PI / 180;
			
						// Don't let it go over
						if (this.GameState.angle >= 270 * Math.PI / 180) {
							this.GameState.angle = 267 * Math.PI / 180;
						}
			
					}
					
					this.velcoity = {
						x: Math.cos(this.GameState.angle) * this.speed,
						y: Math.sin(this.GameState.angle) * this.speed
					}
					// Again, exit early
					return;
			
				}
			}
			
			// Otherwise, we're gonna reflect the angle using the reflective angle
			// provided.  The math is a bit weird, so buckle up.
			// First, get the delta of the reflective angle and the angle itself
			angleDelta = reflectiveAngle - this.GameState.angle;
			
			// Now what we're gonna do is take that delta and add it to the reflective
			// angle, but also add 180, expressed simply as Math.PI
			this.GameState.angle = (reflectiveAngle + angleDelta) + Math.PI;
			
			// Let's also make sure that the angle isn't greater than 360
			this.GameState.angle = this.GameState.angle % (360 * Math.PI / 180);
			
			// I also don't like negative angles.  So, if the angle is negative then
			// take 360 and subtract angle from it to get an "overflowed" value
			if (this.GameState.angle < 0) {
				this.GameState.angle = (360 * Math.PI / 180) + this.GameState.angle;
			}
			
			// Now, with our new angle, create a new velocity.  Also, come up with a
			// less wasteful way to do this
			this.velocity = {
				x: Math.cos(this.GameState.angle) * this.speed,
				y: Math.sin(this.GameState.angle) * this.speed
			}
			
			// Now let's make it interestng.  If the ball HIT THE TOP OF THE PADDLE
			// then make further adjustments to the ball's angle that factors in the
			// velocity of the paddle along with some degree of randomness
			// Additionally, add a bit of randomness if the paddle is not moving
			if (this.GameState.collision.side == "top") {
			
				// For the determinate angle, it's a range between +- 40 degrees.  The
				// paddle velocity can never get any higher than 20
				let determinateAngle = 40 * (-this.GameState.collision.paddleVelocity / 20);
			
				// The random angle is a bit tricky.  Basically, if the paddle is
				// standing still or hardly moving at all, then we find a random angle
				// between +- 20 degrees.  However, the more the paddle moves, the less
				// randomness there is, to the point where the random angle is
				// nearly zero
				let inverseScalar = this.GameState.collision.paddleVelocity > 1 || this.GameState.collision.paddleVelocity < -1 ? this.GameState.collision.paddleVelocity : 1;
				let randomAngle = -1 * (20 / inverseScalar) + Math.random() * 2 * (20 / inverseScalar);
				
			
				// Finally, let's combine the determinate and random angle together and
				// convert them into radians
				let radians = (randomAngle + determinateAngle) * Math.PI / 180;
			
				// Then we're gonna add the result to our angle, let's also mod it
				// as well so that it stays within a range of 0 to 360)				
				this.GameState.angle += radians;
				this.GameState.angle = this.GameState.angle % (360 * Math.PI / 180);
			
				// Finally, make sure that the newly computed angle stays within a range
				// between 195 and 345 (180 + 15 degrees and 360 - 15 degrees, basically
				// make sure the ball actually goes up)
				if (this.GameState.angle < 195 * Math.PI / 180) {
					this.GameState.angle = 195 * Math.PI / 180
				} else if (this.GameState.angle > 345 * Math.PI / 180) {
					this.GameState.angle = 345 * Math.PI / 180;
				}
			
				// Now let's recalculate the velocity using this new angle
				// Finally, having updated the angle, let's use it to get the new velocity.
				this.velocity = {
					x: Math.cos(this.GameState.angle) * this.speed,
					y: Math.sin(this.GameState.angle) * this.speed
				}
				
			}
			

		},
		
		// Handles power up events
		handlePowerUp: function() {
			
			// Naturally, draw some new stuff
			this.drawPowerUp();
			
			// But also, slow the velocity down
			if (this.speed == 7) {
				this.velocity = {
					x: this.velocity.x / 7,
					y: this.velocity.y / 7
				}
			}

			
			this.speed = 1;
			
			console.log('hmm')
		},
		
		handlePowerUpRelease: function() {
			
			// No need if we've already reverted to the default state
			if (this.speed == 7) {
				return;
			}
						
			// Otherwise, clear the drawables
			this.clearPowerUp();
			
			// Set the speed back to full
			this.speed = 7;
			this.velocity = {
				x: this.velocity.x * this.speed,
				y: this.velocity.y * this.speed
			}
			
			// And change the color of the ball to reflect the new choice
			this.drawBall();
			
		},
		
		moveBall: function() {
			
			if (this.paused) {
				return;
			}
			
			if (this.GameState.pin) {
				this.GameState.y = this.screenHeight - 80;
				this.container.x = this.GameState.x;
				this.container.y = this.screenHeight - 80;
				return;
			}

			// Check for a collision with the sides of the screen
			if (this.screenSides()) {
				return;
			}
			
			// Check for a collision with the paddle
			if (this.GameState.collision) {
				
				this.handleCollision();
				this.GameState.collision = false;
				this.container.x = this.GameState.x;
				this.container.y = this.GameState.y;
				this.GameEventBus.$emit('new-color');
								
				this.drawBall();
				
				// We're then going to re-draw the ball using first color in the color
				// queue, then tell the color queue to add a new color
				// We don't bother checking blob collisions if we got a
				// paddle collision, so exit early
				return;
			}
			
			// Finally, check for a collision with the blobette
			if (this.GameState.blobettes.length) {
				
				this.handleCollisionBlob();
				this.GameState.blobettes = [];
				this.container.x = this.GameState.x;
				this.container.y = this.GameState.y;
				return;
			}
			
			this.GameState.x += this.velocity.x;
			this.GameState.y += this.velocity.y;
			this.container.x = this.GameState.x;
			this.container.y = this.GameState.y;
			
			// One final thing.  If the ball is moving down (velocity.y is positive) and
			// the ball is past a certain threshold, tell the Blob to bring in new
			// chunks, if necessary
			
			if (this.velocity.y > 0 && this.GameState.y > this.GameState.threshold && !this.triggerNeedsReset) {
				this.GameEventBus.$emit('refresh-chunks');
				this.triggerNeedsReset = true;
			} else if (this.GameState.y < this.GameState.threshold && this.triggerNeedsReset) {
				// Otherwise, if the ball is back above the threshold, then trip the reset
				// flag
				this.triggerNeedsReset = false;
			} else {
				this.velocity.y > 0 && this.GameState.y 
			}
			
		},
		
		// Triggered if the ball moves offscreen.  Resets velocity to go straight down.
		// We don't change position, but we do mark ball as "pinned" which "pins" its
		// position relative to the paddle's position.  Pressing space "unpins" it.
		resetBall() {
		
			this.GameState.pin = true;
			this.GameState.angle = 90 * Math.PI / 180;
			this.GameState.powerUp = true;
		
			this.velocity = {
				x: Math.cos(this.GameState.angle) * this.speed,
				y: Math.sin(this.GameState.angle) * this.speed
			}
			
			// Also, request a new color from the colorqueue
			this.GameEventBus.$emit('get-color');
			this.ballSprite.rotation = 0;
		
		},
		
		screenSides: function() {
		
			let updatedVelocity = Object.assign({}, this.velocity);
			let updatedPosition = Object.assign({}, {x: this.GameState.x, y: this.GameState.y});
			let updatedAngle = this.GameState.angle;
		
			let change = false;
		
			// First, determine the top-most, right-most, bottom-most, and left-most
			// positions of the ball.
			const contactPoints = [
				{
					x: this.GameState.x,
					y: this.GameState.y - (this.BALL_DIAMETER / 2 + this.BALL_STROKE_WEIGHT)
				}, // top
				{
					x: this.GameState.x + (this.BALL_DIAMETER / 2 + this.BALL_STROKE_WEIGHT),
					y: this.GameState.y
				}, // right
				{
					x: this.GameState.x,
					y: this.GameState.y + (this.BALL_DIAMETER / 2 + this.BALL_STROKE_WEIGHT)
				}, //  bottom
				{
					x: this.GameState.x - (this.BALL_DIAMETER / 2 + this.BALL_STROKE_WEIGHT),
					y: this.GameState.y
				} // left
			];
		
			// Now let's see if the ball made contact with the left bound of the screen
			if (contactPoints[3].x <= 0) {
		
				change = true;
				updatedVelocity.x *= -1;
		
				// Need to move the contact point, and by extension the ball, such that
				// it appears as though the edge of ball is on the surface
				let pos = this.adjustEdgeX(
					contactPoints[3].x,
					0
				);
				updatedPosition.x = pos.x;
				updatedPosition.y = pos.y;
				updatedAngle = Math.atan2(updatedVelocity.y, updatedVelocity.x);
		
			}
		
			// Now what about the right-hand side of the screen?
			if (contactPoints[1].x >= this.screenWidth) {
		
				change = true;
				updatedVelocity.x *= -1;
		
				// Need to move the contact point, and by extension the ball, such that
				// it appears as though the edge of ball is on the surface
				const pos = this.adjustEdgeX(
					contactPoints[1].x,
					this.screenWidth
				);
				updatedPosition.x = pos.x;
				updatedPosition.y = pos.y;
				updatedAngle = Math.atan2(updatedVelocity.y, updatedVelocity.x);
		
			}
		
			// Alright then, what about the upper bound?
			if (contactPoints[0].y < 0) {
		
				change = true;
				updatedVelocity.y *= -1;
		
				let pos = this.adjustEdgeY(
					contactPoints[0].y,
					0
				);
				updatedPosition.x = pos.x;
				updatedPosition.y = pos.y;
				updatedAngle = Math.atan2(updatedVelocity.y, updatedVelocity.x);
		
			}
		
			// Finally, lower bound.  We're gonna use the top-most position of the ball
			// again, this time seeing if it's ABOVE .height (the ball has totally
			// cleared the bottom of the screen)
			if (contactPoints[0].y > this.screenHeight) {
		
				// Welp, time to reset the ball
				this.resetBall();
				return;
		
			}
		
			// Now, using our updated values, we're going to change different parameters
			// of the ball.  Doesn't matter if they never changed, just do it.
			this.GameState.x = updatedPosition.x;
			this.GameState.y = updatedPosition.y;
			this.velocity = updatedVelocity;
			this.GameState.angle = updatedAngle;
		
			// We'll also want to play a sound if something actually was changed
			if (change) {
				this.sounds.paddleCollision.currentTime = 0;
				this.sounds.paddleCollision.play();
			}
		
			// And exit out
			return;
		
		}
	},
	
	beforeDestroy: function() {
		
		this.PIXIWrapper.PIXIApp.ticker.remove(this.moveBall);
		
		this.GameEventBus.$off('power-up', this.handlePowerUp);
		this.GameEventBus.$off('power-up-choice', this.drawPowerUp);
		this.GameEventBus.$off('power-up-release', this.handlePowerUpRelease);
		
		if (this.container != null) {
			
			this.GameState.container.removeChild(this.container);
			this.container.destroy({
				children: true
			});
			
		} else {
			
			if (this.ballSprite != null) {
				this.ballSprite.destroy();
			}
			
			if (this.ballGraphics != null) {			
				this.ballGraphics.destroy();
			}
			
			if (this.ballMask != null) {
				this.ballMask.destroy();
			}
			
		}
		
		// Probably not necessary, but just to make sure they're well and truly gone
		this.container = null;
		this.ballSprite = null;
		this.ballGraphics = null;
		this.ballMask = null;
	},
	
	mounted: function() {
		
		// Draw the ball
		this.drawBall();
		
		// Move contents
		this.container.x = this.screenWidth / 2;
		this.container.y = 100;
		this.GameState.angle = 90 * Math.PI / 180;
		
		this.velocity = {
			x: Math.cos(this.GameState.angle) * this.speed,
			y: Math.sin(this.GameState.angle) * this.speed
		}
		
		this.PIXIWrapper.PIXIApp.ticker.add(this.moveBall);
		
		this.GameEventBus.$on('power-up', this.handlePowerUp);
		this.GameEventBus.$on('power-up-choice', this.drawPowerUp);
		this.GameEventBus.$on('power-up-release', this.handlePowerUpRelease);
	},
	
	render: function(h) {
		return h('template');
	}
}
</script>