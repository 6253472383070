<!-- A component containing a number of different controls for the level editor.
	Namely, a means to toggle between moving, placing, and selecting, as well as
	a means of selecting different patterns. -->
<template>

	<div class="editor-controls"
		:class="{'hide': hide}">

		<button class="open-close square-button"
			@click.stop="$emit('toggle-controls')">
			<img src="../assets/left-arrow-black.svg" v-if="hide">
			<img src="../assets/right-arrow-black.svg" v-else/>
		</button>

		<div class="header" style="display: flex; align-items: center; margin-left: 8px">

			<!-- Settings button.  Opens a modal to tweak some of the more meta
				aspects of the level (name) -->
			<button class="square-button" @click.stop="openSettings">
				<img src="../assets/gear-black.svg"/>
			</button>

			<h2>{{ level.name ? level.name : "unnamed" }}</h2>

			<!-- Toggles the publish state of the level -->
			<button class="square-button" @click.stop="LevelEventBus.$emit('publish')">
				<img src="../assets/publish.svg"/>
			</button>

			<!-- Hard save.  Saves the level manually -->
			<button class="square-button" @click.stop="LevelEventBus.$emit('save')">
				<img src="../assets/save.svg"/>
			</button>

		</div>

		<!-- pan control -->
		<div class="control">
			<button class="square-button"
				:class="{'pressed': mode == 'pan'}"
				@click="$emit('change-mode', 'pan')">
			</button>

			<h4>Pan & Zoom</h4>
		</div>

		<!-- Place Block -->
		<div class="control">
			<button class="square-button"
				:class="{'pressed': mode == 'place'}"
				@click="$emit('change-mode', 'place')">
				<img src="../assets/plus-black.svg"/>
			</button>

			<h4>Place Blob</h4>
		</div>

		<!-- remove block -->
		<div class="control">
			<button class="square-button"
				:class="{'pressed': mode == 'remove'}"
				@click="$emit('change-mode', 'remove')">
				<img src="../assets/times.svg"/>
			</button>

			<h4>Remove Blob</h4>
		</div>

		<!-- select control -->
		<!--
		<div class="control">
			<button class="square-button"
				:class="{'pressed': mode == 'select'}"
				@click="$emit('change-mode', 'select')">
			</button>

			<h4>Select Blobs</h4>
		</div>
	-->

		<!-- grid angle -->
		<!--
		<div class="control">
			<button class="square-button"
				:class="{
						'zero': angle == 0,
						'half': angle == 45,
						'full': angle == 90
					}"
				@click="$emit('increase-angle')">
			</button>

			<h4>Change Angle (Current: 0 deg)</h4>
		</div>
	-->

	<router-link class="button block-button"
		:to="{ name: 'Game', params: {levelID: level.id} }">
		Play
	</router-link>

	</div>

</template>

<script>
import LevelPatternSelection from './LevelPatternSelection.vue';
export default {
	name: "EditorControls",
	props: ['angle', 'hide', 'level', 'mode', 'blob-type'],
	inject: ['LevelEventBus', 'ModalEventBus'],
	components: { LevelPatternSelection },

	methods: {

		// Triggers the settings modal
		openSettings: function() {

			this.ModalEventBus.$emit('modal', {
				modal: 'ModalLevelSettings',
				props: {
					level: this.level
				}
			});

		}

	}
}
</script>

<style lang="scss" scoped>
.header {
	display: flex;
	align-items: center;
	margin-left: 8px;
	margin-right: 12px;
	margin-top: 10px;
	margin-bottom: 20px;

	h2 {
		font-size: 24px;
		flex-grow: 1;
		margin: 0;
	}

}
.editor-controls {
	top: 4px;
	bottom: 4px;
	right: 4px;
	background-color: #FFF;
	position: absolute;
	width: 25%;
	border: 4px solid black;

	&.hide {
		right: -25%;
	}
}

.open-close {
	position: absolute;
	width: 45px;
	height: 45px;
	background-color: #FFF;
	top: 9px;
	left: -63px;
	cursor: pointer;
}

.control {
	display: flex;
	align-items: flex;

	h4 {
		display: block;
		margin: auto 4px
	}

	button {
		margin-left: 12px;
	}
}

.block-button {
	margin: 10px;
}
</style>
