<!-- Checkout page.  From here the user can confirm everything looks good
	and provide payment info before submitting their order. -->
<template>

	<div class="checkout">

		<Navbar />

		<div class="container">

			<div v-if="checkedOut == false">
				<h1 class="text-center">Checkout</h1>

				<div class="flex-row poster-row">

					<!-- Column containig poster details -->
					<div class="col w-4 p-2 poster">

						<div class="thicker-border p-2">

							<h2 class="m-b-2 m-t-0 text-center">Order Details</h2>

							<div class="poster-preview paper m-h-2">
								<img :src="previewURL"/>
								<p class="poster-specifics">
									Size: <strong>{{poster.size}} (<span>in</span>)</strong><br/>
									Orientation: <strong>{{poster.orientation}}</strong><br/>
									Material: <strong>{{poster.material}}</strong><br/></p>
							</div>

							<div class="paper p-2">
								<div class="costs">
									<h3>Base Cost: ${{poster.cost}}</h3>
									<h3>Shipping: Free</h3>
									<hr />
									<h3>Total: ${{ ( parseFloat(poster.cost) ).toFixed(2) }}</h3>
								</div>

								<p>The above picture is a preview image with a 1/10th resolution of the
									original.  All posters are printed at a resolution of 250 DPI.</p>

								<p>See a mistake?  You can still go back and correct your poster design</p>

								<button @click="$router.push({name: 'Poster'})" class="m-l-0">
									Go Back
								</button>
							</div>

						</div>

					</div>

					<div class="col w-8 p-2 billing">

						<div class="thicker-border p-2">

							<h2 class="m-b-2 m-t-0 text-center">Payment Info</h2>

							<CheckoutPager
								:poster="poster"
								@order-placed="handleOrder"/>
						</div>

					</div>

				</div>
			</div>

			<CheckoutSuccess v-else
				:order-id="orderID"/>

		</div>

	</div>

</template>

<script>
import CheckoutPager from '../components/checkout/CheckoutPager.vue';
import CheckoutSuccess from '../components/checkout/CheckoutSuccess.vue';
import Navbar from '../components/Navbar.vue';
export default {
	name: "Checkout",
	components: { CheckoutPager, CheckoutSuccess, Navbar },

	data: function() {
		return {
			checkedOut: false,
			orderID: '',

		}
	},

	computed: {

		poster: function() {
			return this.$store.state.poster;
		},

		previewURL: function() {

			if (this.$store.state.poster) {
				return URL.createObjectURL(this.$store.state.poster.preview);
			}

			return '';

		},

		shippingCost: function() {
			if (this.poster.size == '24x36' || this.poster.size == '36x24') {
				return '5.99';
			}
			return '4.99';
		}

	},

	methods: {

		handleOrder: function(orderID) {
			this.checkedOut = true;
			this.orderID = orderID;
		},

		// Handles submit events from the form.  Attempts a charge
		processOrder: function() {

			this.loading = true;
			const _this = this;

			// Attempt to tokenize card info
			this.stripe.createToken(this.cardNumber, {
				name: this.cardHolderName,
				address_line1: this.streetAddr,
				address_line2: this.unitAddr,
				address_city: this.city,
				address_state: this.state,
				address_zip: this.zip,
				address_country: 'US'
			})
			.then(function (result) {

				if (result.error) {

					_this.loading = false;

				} else {

					const payload = JSON.stringify({
						data: {
							type: "order",
							attributes: {
								token: result.token,
								first_name: this.sameAsBilling ? this.firstName : this.shippingFirstName,
								last_name: this.sameAsBilling ? this.lastName : this.shippingLastName,
								street_addr: this.sameAsBilling ? this.streetAddr : this.shippingStreet,
								unit_addr: this.sameAsBilling ? this.unitAddr : this.shippingUnit,
								city: this.sameAsBilling ? this.city : this.shippingCity,
								state: this.sameAsBilling ? this.state : this.shippingState,
								zip: this.sameAsBilling ? this.zip : this.shippingZip,
								poster_img: this.poster.graphic,
								poster_size: this.poster.size,
								poster_orientation: this.poster.orientation,
								poster_material: this.poster.material
							}
						}
					});

					CheckoutRequest.createOrder(payload, () => {
						_this.loading = false;
					});

				}

			});

		}

	},
}
</script>

<style lang="scss" scoped>
.checkout {
	padding-top: 50px;
}
.poster {
	img {
		max-height: 200px;
		max-width: 200px;
		flex-grow: 0;
		border: 1px solid #BEBEBE;
	}

}

.thicker-border {
	height: 100%;
	background-color: #FAFAFA;
	box-shadow: 2px 2px 1px #BBB;
}

.poster-preview {
	padding: 1.6rem;

	img, p {
		margin-left: auto;
		margin-right: auto;
		display: block;
	}

	p {
		text-align: center;
		font-family: 'neuzeit-grotesk';
		flex-grow: 1;
		margin-bottom: 0;
	}
}

.poster-specifics {

	display: inline-block;

	strong {
		text-transform: capitalize;

		span {
			text-transform: lowercase;
		}
	}
}

.checkbox {
	margin: 10px 15px 20px 0px;

	label {
		margin-right: 15px;
	}

}

.costs {
	h3 {
		margin: 0;
	}
}

@media(max-width: 768px) {
  .thicker-border {
  	padding: 16px 8px;
  }
}
</style>
