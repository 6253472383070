//
// Portion of package which handles the drawing of chunk lines.
/////

import Consts from './consts';

function drawHorizontalChunks(offset, zoom, sketch) {

	// Setup stroke
	sketch.stroke('#AD23FF');

	// Adjust cellWidth according to zoom
	const zoomHeight = this.cellHeight * zoom;

	// And the number of segments in a line
	const horizontalSegments = this.width / Consts.LINE_GAP;

	// Now let's determine how many lines can fit onto a screen
	let horizontalChunkLines = Math.ceil(this.height / (this.chunkHeight * zoomHeight));

	// As well as the leftover space
	let horizontalChunkRemainder = this.height % (this.chunkHeight * zoomHeight);

	// And just like with vertical, we're going to do some minor corrections
	// based on some factors.
	if (horizontalChunkLines % 2 == 0) {

		if (horizontalChunkRemainder > zoomHeight * this.chunkHeight /2) {

			horizontalChunkLines ++;
			horizontalChunkRemainder -= zoomHeight * this.chunkHeight;

		} else if (horizontalChunkRemainder < zoomHeight * this.chunkHeight / 2 && horizontalChunkRemainder != 0) {

			horizontalChunkRemainder += zoomHeight * this.chunkHeight;

		}

	} else {

		if (horizontalChunkRemainder > this.chunkHeight * zoomHeight / 2) {
			horizontalChunkLines ++;
		}

	}

	// Now for the drawing part
	for(let i = 0; i < horizontalChunkLines; i ++) {

		let yPos = i * zoomHeight * this.chunkHeight;

		yPos += horizontalChunkRemainder / 2;

		yPos += (offset.y % (zoomHeight * this.chunkHeight));

		if (yPos == this.midpoint.y + offset.y) {
			continue;
		}

		for(let j = 0; j < horizontalSegments; j += 2) {
			sketch.line(
				j * Consts.LINE_GAP,
				yPos,
				(j + 1) * Consts.LINE_GAP,
				yPos
			);
		}

	}

}

function drawVerticalChunks(offset, zoom, sketch) {

	// Go ahead and define our stroke color
	sketch.stroke('#AD23FF');

	// Adjust cellWidth according to zoom
	const zoomWidth = this.cellWidth * zoom;

	// And the number of segments in a line
	const verticalSegments = this.height / Consts.LINE_GAP;

	// Determine how many chunk lines can fit onto the screen
	let verticalChunkLines = Math.ceil(this.width  / (this.chunkWidth * zoomWidth));

	// Next, determine how much is "left over" since it's unlikely the above
	// result would have given us a nice even number
	let verticalChunkRemainder = this.width % (this.chunkWidth * zoomWidth);

	// Now then, behavior varies depending on whether or not we have an even
	// or odd number of chunk lines
	if (verticalChunkLines % 2 == 0) {

		// Namely, if we have an even number of them (meaning an odd number of
		// chunks to fit on the screen, which is wonky) and the remainder is
		// sufficiently large, then we're going to increase the number of chunk
		// lines at the expense of the remainder.
		if (verticalChunkRemainder > zoomWidth * this.chunkWidth /2) {

			verticalChunkLines ++;
			verticalChunkRemainder -= zoomWidth * this.chunkWidth;

		}

		// Otherwise, if the remainder isn't sufficiently large enough, then we
		// Actually increase it by one whole chunk's worth.  We keep the extra
		// line though.  It's good to have at least one off-screen line.
		else if (verticalChunkRemainder < zoomWidth * this.chunkWidth / 2 && verticalChunkRemainder != 0) {

			verticalChunkRemainder += zoomWidth * this.chunkWidth;

		}

	} else {

		// Otherwise, if the remainder is super large, we actually increase the
		// number of verticalChunkLines (it shows up offscreen, good to have)
		// (shouldn't we also decrease the remainder?)
		if (verticalChunkRemainder > this.chunkWidth * zoomWidth / 2) {
			verticalChunkLines ++;
		}

	}

	// Now the fun part, where we draw our lines
	for(let i = 0; i < verticalChunkLines; i ++) {

		// First, the variable part of the xPos is determine by the index (which
		// line are we drawing) multipled by cellWidth and chunkWidth to get
		// it's rough position
		let xPos = i * zoomWidth * this.chunkWidth;

		// Then to this, we add verticalChunkRemainder to offset the line somewhat
		xPos += verticalChunkRemainder / 2;

		// Finally, and this is the tricky part, we add the "offset" from the
		// default starting position (since the user is presumably scrolling
		// around everywhere.)  We do a mod operation on the offset so that if
		// the user scrolls past a chunk's worth of "level", then we effectively
		// "reset"
		// the offset to zero
		xPos += (offset.x % (zoomWidth * this.chunkWidth));

		// If this position overlaps the position of the midpoint, then don't bother
		if (xPos == this.midpoint.x + offset.x) {
			continue;
		}

		// Now let's draw the line segments of our line
		for(let j = 0; j < verticalSegments; j += 2) {

			sketch.line(
				xPos,
				j * Consts.LINE_GAP,
				xPos,
				(j + 1) * Consts.LINE_GAP
			);

		}

	}

}

export { drawHorizontalChunks, drawVerticalChunks };
