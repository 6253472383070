<!-- Controls various attributes of a modal, such as its name, whether it's
	quick-start appropriate, and whether it's easier or harder. -->
<template>

	<Modal title="Level Settings">

		<div class="container">

			<h3>Level Name</h3>
			<div v-if="!editing" class="flex-line">
				<h1 v-if="!editing && props.level.name">{{ name }}</h1>
				<button class="square-button" @click="editing = true">
					<img src="../../../assets/toybox-pencil-black.svg"/>
				</button>
			</div>

			<form v-else @submit.prevent="changeName" class="flex-line">

				<input type="text" class="h1 underlined" v-model="name" placeholder="Level Name"/>

				<button class="square-button" type="submit">
					<img src="../../../assets/save.svg"/>
				</button>

			</form>

			<h3>Difficulty</h3>

			<div class="check-container">
				<input type="checkbox" id="sameAsBilling" v-model="quickstart"
					@input="changeQuickStart"/>
				<label class="h5" for="sameAsBilling"><span class="checkbox"/>Quick Start</label>
			</div>

			<hr />

			<div class="check-container">
				<input type="radio" id="easier" name="difficulty" v-model="difficulty" value="0"
					:checked="difficulty == 0" @input="changeDifficulty"/>
				<label class="h5" for="easier"><span class="radio"/>Easier</label>
			</div>

			<div class="check-container">
				<input type="radio" id="harder" name="difficulty" v-model="difficulty" value="1"
					:checked="difficulty == 1" @input="changeDifficulty"/>
				<label class="h5" for="harder"><span class="radio"/>Harder</label>
			</div>

		</div>

	</Modal>

</template>

<script>
import LevelRequest from '../../../network/levels';
import Modal from '../../modals/Modal.vue'
export default {
	name: "LevelSettingsModal",
	props: ['props'],
	components: { Modal },

	data: function() {

		return {
			difficulty: this.props.level.difficulty,
			editing: false,
			name: this.props.level.name,
			quickstart: this.props.level.quickstart,
		}

	},

	methods: {

		changeDifficulty: function(event) {

			const level = Object.assign({}, this.props.level);
			level.difficulty = event.target.value;
			this.$store.commit('addLevels', [level]);

			const payload = JSON.stringify({
				data: {
					id: this.props.level.id,
					type: "level",
					attributes: {
						difficulty: level.difficulty
					}
				}
			});

			LevelRequest.updateLevel(this.props.level.id, payload, () => {});

		},

		// Pretty self-explanatory.  Changes the name of the modal
		changeName: function(event) {

			// Let's go ahead and update things locally
			this.editing = false;
			const level = Object.assign({}, this.props.level);
			level.name = this.name;
			this.$store.commit('addLevels', [level]);

			const payload = JSON.stringify({
				data: {
					id: this.props.level.id,
					type: "level",
					attributes: {
						name: this.name
					}
				}
			});

			LevelRequest.updateLevel(this.props.level.id, payload, () => {});

		},

		// Modifies whether or not this level is a "quick start" level, i.e. it's
		// approved as a good first level.
		changeQuickStart: function(event) {

			const level = Object.assign({}, this.props.level);
			level.quickstart = !this.quickstart;
			this.$store.commit('addLevels', [level]);

			const payload = JSON.stringify({
				data: {
					id: this.props.level.id,
					type: "level",
					attributes: {
						quickstart: `${level.quickstart}`
					}
				}
			});

			LevelRequest.updateLevel(this.props.level.id, payload, () => {});

		}

	}
}
</script>

<style lang="scss" scoped>
.container {
	padding: 0;
	margin: 20px;
}

input[type="text"] {
	margin-left: 0;
	margin-right: 10px;
}

.check-container {
	margin-left: 0;
}

.flex-line {
	margin: 10px 0;
}
</style>
