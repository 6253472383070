<!-- A modal for picking new patterns -->
<template>
	<Modal title="Patterns">

		<div class="pattern-grid">

			<!-- Random pattern always comes first -->
			<PatternSwatch
				action="pick"
				:random="true"
				@pattern="props.addPattern"/>

			<div v-for="pattern in patterns">
				<PatternSwatch
					action="pick"
					:pattern="pattern"
					@pattern="props.addPattern"/>
			</div>
		</div>

	</Modal>
</template>

<script>
import Modal from './Modal.vue';
import PatternRequest from '../../network/patterns';
import PatternSwatch from '../PatternSwatchGrid.vue';
export default {
	name: "ModalPatternPicker",
	props: ['props'],
	components: { Modal, PatternSwatch },

	computed: {

		// Only selects published patterns
		patterns: function() {

			let patterns = [];

			for(let i = 0; i < this.$store.state.patterns.length; i ++) {
				let pattern = this.$store.state.patterns[i];
				if (pattern.published) {
					patterns.push(pattern);
				}
			}

			return patterns;

		}

	},

	// In all likelihood we haven't retrieved the patterns yet, so do that.
	mounted: function() {
		let store = this.$store;
		PatternRequest.getPublishedPatterns((patterns, colors) => {
			store.commit('addPatterns', patterns);
			store.commit('addPatternColors', colors);
		});
	}

}
</script>

<style lang="scss" scoped>
.pattern-grid {
	display: flex;
	justify-content: flex-start;
	margin: 10px;
	flex-wrap: wrap;

	& > div {
		width: 25%;
	}

}
</style>
