//
// Portion of package handling PUT requests.
/////

import axios from 'axios';

export default {

	// Updates a level color.  Simple as that.
	color: (id, payload, success, failure) => {
		axios.put(`${process.env.VUE_APP_ENDPOINT}/level-colors/${id}`, payload, {
			headers: {"Authorization": "Bearer " + localStorage.getItem('token')}
		})
		.then((response) => {
			success();
		})
		.catch((error) => {

		});

	}
}
