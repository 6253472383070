<!-- Used to create new pattern categories, or edit a pre-existing one I suppose-->
<template>
	<div class="new-category">

		<CategoryPreview
			:name="name"
			:background-color="background"
			:text-color="text"/>

		<form @submit.prevent="saveCategory">
			<input class="h2" type="text" v-model="name" />
			<button class="square-button" type="submit">
				<img src="../../assets/save-icon.svg"/>
			</button>
		</form>

		<div style="display: flex; align-items: center; justify-content: center">
			
			<div style="display: inline-block;">
				<ColorSlider
					channel="red"
					:color="red"
					:whole-color="{red, green, blue, alpha}"
					@color="changeColor('red', $event)"/>
					
				<ColorSlider
					channel="green"
					:color="green"
					:whole-color="{red, green, blue, alpha}"
					@color="changeColor('green', $event)"/>
					
				<ColorSlider
					channel="blue"
					:color="blue"
					:whole-color="{red, green, blue, alpha}"
					@color="changeColor('blue', $event)"/>
			</div>
				
			<div class="colors" style="margin-left: 10px; display: inline-block">
				
				<CategoryColorPreview
					name="background"
					:color="backgroundHex"
					@click="activeColor = 'background'"/>
					
				<CategoryColorPreview
					name="text"
					:color="textHex"
					@click="activeColor = 'text'"/>
					
			</div>
			
		</div>
		
	</div>
</template>

<script>
import CategoryPreview from './CategoryPreview.vue';
import CategoryColorPreview from './CategoryColorPreview.vue';
import PatternCategoryRequest from '../../network/pattern-categories';
export default {
	name: "NewPatternCategory",
	components: { CategoryPreview, CategoryColorPreview },
	props: ['category', 'patternID', 'pre-name'],
	data: function() {
		return {
			name: this.preName ? this.preName : '',
			activeColor: 'background',
			background: {
				red: 255,
				green: 46,
				blue: 0
			},
			text: {
				red: 0,
				green: 0,
				blue: 0
			}
		}
	},

	computed: {

		// Converts channels in background object into a hex value
		backgroundHex: function() {

			let red = this.background.red.toString(16).toUpperCase();
			if (red.length < 2) {
				red = '0' + red;
			}
			let green = this.background.green.toString(16).toUpperCase();
			if (green.length < 2) {
				green = '0' + green;
			}
			let blue = this.background.blue.toString(16).toUpperCase();
			if (blue.length < 2) {
				blue = '0' + blue;
			}
			
			const color = red + green + blue;
			return color;
			
		},
		
		hexColor: function() {
			
			if (this.activeColor == 'background') {
				return this.background;
			}
			
			return this.text;
			
		},
		
		// Converts channels in text object into hex
		textHex: function() {
			
			let red = this.text.red.toString(16).toUpperCase();
			if (red.length < 2) {
				red = '0' + red;
			}
			let green = this.text.green.toString(16).toUpperCase();
			if (green.length < 2) {
				green = '0' + green;
			}
			let blue = this.text.blue.toString(16).toUpperCase();
			if (blue.length < 2) {
				blue = '0' + blue;
			}
			
			const color = red + green + blue;
			return color;
		}

	},

	methods: {

		// Changes a color channel to a new value
		changeColor: function(channel, value) {

			if (this.activeColor == 'background') {

				this.background = Object.assign({}, this.background);
				this.background[channel] = parseInt(value);

			} else {

				this.text = Object.assign({}, this.text);
				this.text[channel] = parseInt(value);

			}

		},

		// If the category doesn't exist, it will create one.  Otherwise, it will
		// simply change the category's name
		saveCategory: function() {

			// Create the bare minimum for our payload
			let data = {
				data: {
					type: "pattern_category",
					attributes: {
						name: this.name,
						pattern_id: this.patternID,
						background_color: this.backgroundHex,
						text_color: this.textHex
					}
				}
			};

			// Then, if we're editing, also attach the ID
			if (this.category) {
				data.data.id = this.category.id
			};

			// Finally, stringify payload
			const payload = JSON.stringify(data);

			// Then make the appropriate request based on what we're trying to do
			if (this.category) {
				PatternCategoryRequest.updateCategory(this.category.id, payload, () => {});
			} else {

				const store = this.$store;
				const patternID = this.patternID;
				PatternCategoryRequest.addCategory(payload, (category) => {

					// Once we have an ID for this category, we'll presumably want to assign
					// it to this pattern
					const ids = {
						patternID,
						categoryID: category.id
					}
					PatternCategoryRequest.addPatternToCategory(ids, () => {});
					store.commit('addPatternCategory', category);

				});

			}

		}
	},

	// It's entirely possible a category was passed in as a prop, indicating a
	// desire to edit.  If so, we'll need to make some assignments
	mounted: function() {

		if (this.category) {
			this.name = this.category.name;

			// Break down colors into channels
			const backgroundRed = parseInt(
				this.category.backgroundColor.substring(0, 2),
				16
			);
			this.background.red = backgroundRed;

			const backgroundGreen = parseInt(
				this.category.backgroundColor.substring(2, 4),
				16
			);
			this.background.green = backgroundGreen;

			const backgroundBlue = parseInt(
				this.category.backgroundColor.substring(4, 6),
				16
			);
			this.background.blue = backgroundBlue;

			const textRed = parseInt(
				this.category.textColor.substring(0, 2),
				16
			);
			this.text.red = textRed;

			const textGreen = parseInt(
				this.category.textColor.substring(2, 4),
				16
			);
			this.text.green = textGreen;

			const textBlue = parseInt(
				this.category.textColor.substring(4, 6),
				16
			);
			this.text.blue = textBlue;

		}
	}

}
</script>

<style lang="scss" scoped>

.new-category {
	padding: 10px 10px 20px;
}

form {
	margin: 20px;

	input {
		text-align: center;
		border: none;
		border-bottom: 4px dashed #000;
	}
}

.colors {
	display: flex;
	align-items: center;
	justify-content: center;
}
</style>
