//
// A package for controlling the positioning and scale of a finish level inside
// of a poster design context, as well as creating a bounding box for it
/////

const RECT_WIDTH = 80;
const RECT_HEIGHT = 40;

const ROWS_IN_CHUNK = 6;
const COLS_IN_CHUNK = 4;

export default class LevelBoundary {

	// The constructor only needs the cells as an argument.  Using this it will
	// define a bounding box, a midpoint, and from there determine where the
	// controls should be
	constructor(cells, sketch) {

		this.boundingBox = {
			x: {
				max: undefined,
				min: undefined
			},
			y: {
				max: undefined,
				min: undefined
			},

		}

		this.midpoint = {
			x: 0,
			y: 0,
		}

		this.offset = {
			x: 0,
			y: 0,
		}

		cells.forEach((cell, index) => {

			if (!cell) {
				return;
			}

			// Retrieve and adjust chunkX;
			let chunkX = cell.chunkID.x;
			if (chunkX > 0) {
				chunkX --;
			}

			// Retrieve and adjust rectX
			const rectX = cell.rectID.x - 1;

			// Compute x pos relative to a centerpoint located at 0
			const x = (RECT_WIDTH * chunkX * COLS_IN_CHUNK) + rectX * RECT_WIDTH;

			// Now re-assign min and max x-values of bounding box if necessary
			if (x >= this.boundingBox.x.max || this.boundingBox.x.max === undefined) {
				this.boundingBox.x.max = x + RECT_WIDTH;

			}

			if (x <= this.boundingBox.x.min || this.boundingBox.x.min === undefined) {
				this.boundingBox.x.min = x;
			}

			// Rinse and repeat for y-values
			let chunkY = cell.chunkID.y;
			if (chunkY < 0) {
				chunkY ++;
			}

			const rectY = cell.rectID.y - 1;

			const y = (RECT_HEIGHT * chunkY * ROWS_IN_CHUNK) - rectY * RECT_HEIGHT;

			if (y >= this.boundingBox.y.max || this.boundingBox.y.max === undefined) {
				this.boundingBox.y.max = y
			}

			if (y <= this.boundingBox.y.min || this.boundingBox.y.min === undefined) {
				this.boundingBox.y.min = y - RECT_HEIGHT;
			}

		});

		// After all that hoopla, we have the max and min of our bounding box.  From
		// this we can also calculate the width and height of the box
		this.width = this.boundingBox.x.max - this.boundingBox.x.min;
		this.height = this.boundingBox.y.max - this.boundingBox.y.min;
		this.midpoint = {
			x: this.width / 2,
			y: this.height / 2.
		};

		this.offset = {
			x: this.midpoint.x - this.boundingBox.x.max,
			y: this.midpoint.y - this.boundingBox.y.max,
		}

	}

}
