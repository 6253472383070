<!-- A component for a level editor.  Contains a fancy grid for showing off
	the layout, and a sort of side-thing for various controls -->
<template>

	<!-- Go ahead and create another vue p5 instance -->
	<div class="level-editor">
		<VueP5
			@setup="setup"
			@draw="draw"
			@mouseclicked="mouseClicked"
			@mousedragged="mouseMoved"
			@mousepressed="mousePressed"
			@mousereleased="mouseReleased"
			@mousewheel="mouseWheel"/>

		<!-- And also, create a collapseable sidebar used to display different
			controls -->
		<EditorControls
			:level="level"
			:mode="mode"
			:hide="!controls"
			@change-mode="mode = $event"
			@toggle-controls="controls = !controls"/>

		<LevelPatterns
			:addable="true"
			:level="level"
			:showRandom="true"/>
	</div>

</template>

<script>
import Blobprint from '../leveleditor/blobprint'
import EditorControls from '../components/EditorControls.vue';
import Grid from '../leveleditor/grid';
import LevelPatterns from './level/LevelPatterns.vue';
import PlacementRect from '../leveleditor/placement-rect';
import VueP5 from 'vue-p5';
import p5 from 'p5/lib/p5.min';
import 'p5/lib/addons/p5.sound';
export default {
	name: "LevelEditor",
	components: { EditorControls, LevelPatterns, VueP5 },
	props: ['level'],
	inject: [ 'LevelEventBus', 'ModalEventBus' ],
	data: function() {
		return {
			blobprint: null,
			controls: false,
			grid: null,
			midpoint: null,
			mode: 'pan',
			modal: false,
			offset: {
				x: 0,
				y: 0
			},
			originalMouse: {
				x: 0,
				y: 0
			},
			panning: false,
			placementRect: null,
			sketch: null,
			sounds: {
				place: [],
				placeIndex: 0,
				remove: null
			},
			zoom: 1
		}
	},

	methods: {

		// Handles click events.  What happens during these events may vary, but
		// by default a blobette is placed
		mouseClicked: function(sketch) {

			if (this.controls || this.modal) {
				return;
			}

			// Add a blobette to the blobprint
			if (this.mode == 'place') {

				const sound = this.sounds.place[this.sounds.placeIndex];
				sound.currentTime = 0;
				sound.play();
				this.sounds.placeIndex = (this.sounds.placeIndex + 1) % 3;

				this.blobprint.addTo({
					x: sketch.mouseX,
					y: sketch.mouseY
				}, this.offset, this.zoom);

				this.LevelEventBus.$emit('layout', this.blobprint.chunks);
			}

			// Remove a blobette from the blobprint
			if (this.mode == 'remove') {

				const sound = this.sounds.place[this.sounds.placeIndex];
				sound.currentTime = 0;
				sound.play();
				this.sounds.placeIndex = (this.sounds.placeIndex + 1) % 3;

				this.blobprint.remove({
					x: sketch.mouseX,
					y: sketch.mouseY
				}, this.offset, this.zoom);
				this.LevelEventBus.$emit('layout', this.blobprint.chunks);
			}

		},

		mouseMoved: function(sketch) {

			if (this.mode == 'pan' && this.panning) {

				// Change the offset to the delta betwen the originalMouse x and y
				// values and current mouseX and mouseY values
				this.offset.x = (sketch.mouseX - this.midpoint.x) - this.originalMouse.x;
				this.offset.y = (sketch.mouseY - this.midpoint.y) - this.originalMouse.y;
			}
		},

		mousePressed: function(sketch) {

			if (this.controls || this.modal) {
				return;
			}

			if (this.mode == 'pan') {

				// User, maybe, is attempting to pan the map.  Store the mouseX and
				// mouseY as coordinates relative to the midpoint with offsets factored
				// in
				this.originalMouse.x = sketch.mouseX - this.midpoint.x - this.offset.x;
				this.originalMouse.y = sketch.mouseY - this.midpoint.y - this.offset.y;

				this.panning = true;

			}

		},

		mouseReleased: function(sketch) {

			this.panning = false;

		},

		mouseWheel: function(sketch, eventData) {

			const newZoom = this.zoom + (eventData.delta / 1000);

			if (newZoom >= 0.5 && newZoom <= 2) {
				this.zoom = newZoom;
			} else if (newZoom < 0.5) {
				this.zoom = 0.4999;
			} else {
				this.zoom = 2.0001;
			}

		},

		setup: function(sketch) {

			this.sketch = sketch;
			this.midpoint = { x: sketch.windowWidth / 2 , y: sketch.windowHeight / 2};
			const cellHeight = 40;
			const cellWidth = 80;

			// Go ahead and create a full-screen canvas
			sketch.createCanvas(sketch.windowWidth, sketch.windowHeight);

			// Get our blobprint going
			this.blobprint = new Blobprint({
				midpoint: this.midpoint,
				cellHeight,
				cellWidth,
				chunkHeight: 6,
				chunkWidth: 4,
				width: sketch.windowWidth,
				height: sketch.windowHeight,
				chunks: this.level.layout ? this.level.layout : []
			}, sketch);

			// And draw a simple grid
			this.grid = new Grid({
				midpoint: this.midpoint,
				width: sketch.windowWidth,
				height: sketch.windowHeight,
				cellWidth,
				cellHeight,
				cellAngle: 0
			});

			// And our placement rect
			this.placementRect = new PlacementRect({
				midpoint: this.midpoint,
				cellWidth,
				cellHeight,
				width: sketch.windowWidth,
				height: sketch.windowHeight
			}, sketch);

		},

		draw: function(sketch) {

			// Reset the background
			sketch.background(255, 255, 245);

			// Redraw the grid
			this.grid.draw(sketch, this.offset, this.zoom);

			// Draw the blobprint
			this.blobprint.draw(sketch, this.offset, this.zoom);

			this.placementRect.draw(sketch, this.offset, this.zoom, this.mode, this.controls);

		}

	},

	beforeDestroy: function() {
		this.sketch.remove();
		this.sketch = null;
		gl.getExtension('WEBGL_lose_context').loseContext();
	},

	mounted: function() {

		for(let i = 0; i < 3; i ++) {
			this.sounds.place.push(new Audio('/click.mp3'));
		}

		this.ModalEventBus.$on('modal', () => {
			this.modal = true;
		});
		this.ModalEventBus.$on('close', () => {
			this.modal = false;
		});

	},

	watch: {
		level: function() {
			if (this.level.layout) {
				this.blobprint.chunks = this.level.layout;
				this.blobprint.recalcNeighbors();
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.level-editor {
	overflow: hidden;
	height: 100vh;
	width: 100vw;
	position: relative;
}
</style>
