<!-- Component used when a user doesn't actually have any orders at all. -->
<template>
	<div class="text-center full-height-viewport full-width-viewport flex-line"
		style="position: absolute; top: 0px;">
		<div style="margin: 0 auto;">
			<h1>No Orders</h1>

			<p>From the looks of it, you haven't actually ordered anything from us yet.</p>

			<p>This page will look a lot more interesting once you do.  Until then,
				there isn't much to look at here other than, well, this.</p>
		</div>
	</div>
</template>

<script>
export default {
	name: "NoOrders"
}
</script>
