//
// Portion of package that lets you create a new account
/////

import axios from 'axios';

export default {

	createAccount: (payload, success, failure) => {

		axios.post(`${process.env.VUE_APP_ENDPOINT}/users/signup`, payload)
		.then((response) => {

			// Same as login, attach their JWT.  Don't need to worry about an admin
			// flag
			const token = response.data.data.attributes.token;
			localStorage.setItem('token', token);
			success();

		});
	}
}
