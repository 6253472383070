//
// Handles management of posters
/////

export default {

	addPoster: function(state, payload) {

		state.poster = {
			graphic: payload.poster,
			preview: payload.preview,
			size: payload.size,
			material: payload.material,
			orientation: payload.orientation,
			cost: payload.cost
		}

	}

}
