<!-- Displays the core element of a repeating pattern as well as the default
	color palette used by default.  This component is used exclusively behind
	the scenes in the pattern catalogue. -->
<template>
	<div class="swatch">

		<div v-if="random">
			<h2 class="pattern-name text-center">Random</h2>
			<div class="random">
				<img src="dice.svg"/>
			</div>
		</div>

		<div v-else>
			<h2 class="pattern-name">{{ pattern.name }}</h2>
			<img :src="pattern.filePath" />
		</div>

		<SwatchColors v-if="colors" class="swatch-colors"
			:colors="colors"/>

		<router-link class="button block-button" v-if="action == 'look'"
			:to="{ name: 'Pattern', params: {patternID: pattern.id} }">
			Look At
		</router-link>

		<div class="button-row" v-else-if="action == 'pick'">

			<button style="margin-right: 4px;" class="block-button" @click="handlePick">
				Pick
			</button>

		</div>

	</div>
</template>

<script>
import SwatchColors from './SwatchColors.vue';
export default {
	name: "PatternSwatchGrid",
	props: ['action', 'pattern', 'random'],
	components: { SwatchColors },

	computed: {

		// Retrieves all color groups associated with pattern
		colors: function() {

			let colors = [];
			if (this.random) {
				return colors;
			}

			for (let i = 0; i < this.$store.state.patternColors.length; i ++) {
				let color = this.$store.state.patternColors[i];
				if (color.patternID == this.pattern.id) {
					colors.push(color);
				}
			}

			return colors;
		}

	},

	methods: {

		handlePick: function() {

			if (this.random) {
				this.$emit('pattern', {id: 'random'});
				return;
			}

			this.$emit('pattern', this.pattern);

		}
	}
}
</script>

<style lang="scss" scoped>

.swatch {
	margin: 0;
	padding: 10px;
}

h2 {
	overflow: hidden;
	max-width: 100%;
	white-space: nowrap;
	text-overflow: clip;
}

.swatch-colors {
	margin-bottom: 20px;
}

.button-row {
	display: flex;

	& > * {
		width: 50%;
		flex-grow: 1;
	}

}

img {
	border-radius: 10px;
	box-shadow: 2px 2px 2px #CCC;
	margin: 10px 20px 20px 20px;
}

.random {
	max-width: 200px;
	max-height: 200px;
	border-radius: 10px;
	padding: 20px;
	box-shadow: 2px 2px 2px #CCC;
	border: 1px solid #EEE;
	margin: 20px auto;;

	img {
		width: 100%;
		box-shadow: none;
		margin: 0;
	}

}
</style>
