<!-- Level header is a component that fits to the top of the level editor screen
	as well as the level preview screen.  It shows the title of the level and
	provides a play button. -->
<template>
	<div class="container">
		<div class="thick-border">
			<h4>{{ level.name }}</h4>
			<router-link class="button block-button" :to="{name: 'Game', params: {levelID: this.level.id}}">
				Play
			</router-link>
		</div>
	</div>
</template>

<script>
export default {
	name: "LevelHeader",
	props: ['level']
}
</script>

<style lang="scss" scoped>
.container {
	position: absolute;
	top: 0;
	width: 100vw;
}

.thick-border {
	background-color: #FFF;
	max-width: 330px;
	margin: 0 auto;
	padding: 10px;
}

.button {
	margin: 4px 0;
}

h4 {
	text-align: center;
	margin: 0px 0px 10px;
}
</style>
