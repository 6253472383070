//
// Portion of package for drawing lines which delineate cells.
/////

import Consts from './consts';

function drawHorizontalCells(zoom, offset, sketch) {

	// Go ahead and define stroke weight and color
	sketch.stroke('#6B7DF3');
	sketch.strokeWeight(1);

	// Let's adjust the cellHeight based on what the zoom is
	const zoomHeight = this.cellHeight * zoom;

	const horizontalSegments = this.width / Consts.LINE_GAP;

	let horizontalLines = Math.ceil(this.height / zoomHeight);

	let horizontalRemainder = (this.height % zoomHeight);

	if (horizontalLines % 2 == 0) {

		if (horizontalRemainder > zoomHeight / 2) {

			horizontalLines ++
			horizontalRemainder -= zoomHeight;

		} else if (horizontalRemainder < zoomHeight / 2 && horizontalRemainder != 0) {

			horizontalRemainder += zoomHeight;

		}

	} else {

		if (horizontalRemainder > zoomHeight/ 2) {
			horizontalLines ++
		}

	}

	for(let i = 0; i < horizontalLines; i ++) {

		let yPos = i * zoomHeight

		yPos += horizontalRemainder / 2;
		yPos += offset.y % zoomHeight;

		if (yPos == this.midpoint.y + offset.y) {
			continue;
		}

		for(let j = 0; j < horizontalSegments; j += 2) {
			sketch.line(
				j * Consts.LINE_GAP,
				yPos,
				(j + 1) * Consts.LINE_GAP,
				yPos
			);
		}


	}

}

function drawVerticalCells(zoom, offset, sketch) {

	// Go ahead and define stroke weight and color
	sketch.stroke('#6B7DF3');
	sketch.strokeWeight(1);

	// Adjust cellWidth based on what the zoom is
	const zoomWidth = this.cellWidth * zoom;

	const verticalSegments = this.height / Consts.LINE_GAP;

	// Now let's divide the width by the width of our cells to get the number we
	// of lines we can fit on the screen.
	let verticalLines = Math.ceil(this.width/ zoomWidth);

	// Then figure out how much is left over
	let verticalRemainder = this.width % zoomWidth;

	// Look, just go to chunks.drawVertical for reference on what this all means
	if (verticalLines % 2 == 0) {

		if (verticalRemainder > zoomWidth /2) {

			verticalLines ++;
			verticalRemainder -= zoomWidth;

		} else if (verticalRemainder < zoomWidth / 2 && verticalRemainder != 0) {

			verticalRemainder += zoomWidth;

		}

	} else {

		if (verticalRemainder > zoomWidth / 2) {
			verticalLines ++;
		}

	}

	for(let i = 0; i < verticalLines; i ++) {

		let xPos = i * zoomWidth

		xPos += verticalRemainder / 2
		xPos += offset.x % zoomWidth;

		// If this line has the same xPos as midpoint, bail
		if (xPos == this.midpoint.x + offset.x) {
			continue;
		}

		// Additionally, if it overlaps a chunk, bail
		// TODO

		for(let j = 0; j < verticalSegments; j += 2) {

			sketch.line(
				xPos,
				j * Consts.LINE_GAP,
				xPos,
				(j + 1) * Consts.LINE_GAP
			);

		}

	}
}

export { drawHorizontalCells, drawVerticalCells };
