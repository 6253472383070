<!-- Component for displaying a list of orders. -->
<template>

	<div class="thicker-border shadow">

		<OrderListItem v-for="order in orders" :order="order"
			@change-to="$emit('change-to')"/>

	</div>

</template>

<script>
import OrderListItem from './OrderListItem.vue';
import OrderRequest from '../../network/orders';
export default {
	name: "OrderList",
	components: { OrderListItem },
	props: ['orders']
}
</script>
