<!-- A basic modal component. -->
<template>
	<div class="modal" :class="{'fixed-height': height = 'fixed'}" @wheel.stop @mousedown.stop>

		<div class="modal-content thicker-border">

			<div class="modal-header">
				<h3>{{ title }}</h3>

				<button class="square-button" @click="ModalEventBus.$emit('close')">
					<img src="../../assets/times.svg"/>
				</button>
			</div>

			<div class="modal-body">
				<slot></slot>
			</div>

		</div>

	</div>
</template>

<script>
export default {
	name: "Modal",
	props: ['title'],
	inject: ['ModalEventBus']
}
</script>

<style lang="scss" scoped>
.modal {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(0, 0, 0, 0.7);
	z-index: 1000;
}

.modal-content {
	display: flex;
	flex-direction: column;
	align-items: stretch;
	max-width: 1200px;
	margin: 50px auto;
	background-color: #FFF;
	border-radius: 4px;
	min-height: 80vh;
}

.modal.fixed-height {

	.modal-content {
		height: 80vh;
	}

}

@media(max-width: 768px) {

	.modal-content, .modal.fixed-height .modal-content {
		max-height: none;
		height: calc(100% - 32px);
		margin: 16px;
		overflow-y: scroll;
	}

}

.modal-header {
	flex-grow: 0;
	display: flex;
	align-items: center;
	position: relative;

	h3 {
		width: 100%;
		text-align: center;
	}

	button {
		position: absolute;
		right: 0;
		top: 0;
	}
}

.modal-body {
	flex-grow: 1;
	height: 100%;
}
</style>
