<!-- Component for listing colors -->
<template>
	<div class="color-list" :class="{'single-color': colorFocus}">

		<div v-if="colors && !colorFocus" class="colors">

			<ColorListItem v-for="color in colors"
				:color="color"
				:level="level"
				:pattern="pattern"
				@toggle-edit="switchMode"/>

		</div>

		<div v-else-if="colorFocus" class="colors">

			<ColorListItem
				:edit="true"
				:color="color"
				:level="level"
				:pattern="pattern"
				@toggle-edit="switchMode"/>

		</div>

	</div>
</template>

<script>
import ColorListItem from './ColorListItem.vue';
export default {
	name: "ColorList",
	props: ['colors', 'level', 'pattern'],
	components: { ColorListItem },

	data: function() {
		return {
			colorFocus: false,
			color: null
		}
	},

	methods: {

		// Switches between focusing on a single color (when editing) or displaying
		// a list
		switchMode: function(toggle, editColor) {

			this.$emit('toggle-edit', toggle);
			this.colorFocus = toggle;
			if (this.colorFocus) {
				this.color = editColor;
			} else {
				this.color = null;
			}

		}

	}
}
</script>

<style lang="scss" scoped>
.color-list {
	height: 300px;
	overflow-y: scroll;
	display: flex;
	align-items: stretch;

	&.single-color {
		height: auto;
		overflow-y: auto;

		.colors {
			border: none;
			box-shadow: none;
		}
	}
}

@media(max-width: 768px) {

	.color-list {
		height: auto;
	}

}

.colors {
	overflow-y: scroll;
	width: 100%;
	border: 1px solid #EEE;
	border-radius: 10px;
	box-shadow: inset 2px 2px 2px #BBB;
	padding: 0 10px;
}
</style>
