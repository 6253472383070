<!-- Used largely in the context of creating and editing colors.  It's just a
	simple circle with the category's background or text color filling it.
	Clicking on it will emit an event, usually to tell the parent component to
	edit it. -->
<template>
	<div class="color-preview">
		<div class="thick-border well"
			:style="{'background-color': `#${color}`}"
			@click="$emit('click')"/>
		<h2>{{ name }}</h2>
	</div>
</template>

<script>
export default {
	name: "CategoryColorPreview",
	props: ['name', 'color']
}
</script>

<style lang="scss" scoped>
.color-preview {
	display: flex;
	cursor: pointer;
	align-items: center;
}

.well {
	width: 40px;
	height: 40px;
	border-radius: 20px;
	margin: 10px;
}

h2 {
	margin: 0;
}
</style>
