//
// Portion of package that handles GET requests.
/////

import axios from 'axios';

export default {

	// Retrieves current webhook configuration from printful
	config: (success, failure) => {

		axios.get(`${process.env.VUE_APP_ENDPOINT}/printful-webhooks/config`, {
			headers: {"Authorization": "Bearer " + localStorage.getItem('token')}
		})
		.then((response) => {

			success({
				url: response.data.result.url,
				types: response.data.result.types,
				params: response.data.result.params
			});

		})
	}
}
