<!-- A section that basically presents to the user everything that they've
	entered in and asks them to confirm that it's correct. -->
<template>
	<div>

		<section class="paper m-h-2 p-2">

			<h4>Contact Methods</h4>

			<p><strong>{{ email }}</strong></p>

			<p>This email address <strong><em>must</em></strong> be correct, since
				we'll be sending you important updates about your order.  If you want
				a different email address to be used, you'll need to do so by changing
				your account details.</p>


		</section>

		<section class="paper m-h-2 p-2">

			<h4>Billing</h4>

			<div class="flex-row">

				<ToggleableFormGroup class="col p-r-1"
					group-id="firstName"
					field-name="First Name"
					placeholder="Piet"
					type="text"
					v-model="billingFirstName"/>

				<ToggleableFormGroup class="col"
					group-id="lastName"
					field-name="Last Name"
					placeholder="Mondrian"
					type="text"
					v-model="billingLastName"/>

			</div>

			<div class="flex-row">

				<ToggleableFormGroup class="col p-r-1"
					group-id="billingStreetAddr"
					field-name="Street Address"
					placeholder="11 W 53rd St"
					type="text"
					v-model="billingStreetAddr"/>

				<ToggleableFormGroup class="col"
					group-id="billingUnitAddr"
					field-name="Unit Address"
					placeholder="Apt 303"
					type="text"
					v-model="billingUnitAddr"/>

			</div>

			<div class="flex-row">

				<ToggleableFormGroup class="col w-4 p-r-1"
					group-id="billingCity"
					field-name="City"
					placeholder="New York"
					type="text"
					v-model="billingCity"/>

				<ToggleableFormGroup class="col w-4 p-r-1"
					group-id="billingState"
					field-name="State"
					placeholder="NY"
					type="text"
					v-model="billingState"/>

				<ToggleableFormGroup class="col w-4"
					group-id="billingZip"
					field-name="Zip Code"
					placeholder="10019"
					type="text"
					v-model="billingZip"/>

			</div>

			<p></p>

			<button @click="$emit('redo', 'billing')">
				Change Billing Details
			</button>

		</section>

		<section class="paper m-h-2 p-2">

			<h4>Shipping</h4>

			<div v-if="shippingSame">

				<div class="same-as">
					<img src="../../assets/right-arrow-black.svg" />
					<p>Same As Billing</p>
				</div>

				<p>Do you want to ship this to a location that is
					<strong><em>different</em></strong> from your billing address?  If so,
					click the button below.</p>

				<button @click="$emit('redo', 'shipping')">
					Change Shipping Details
				</button>

			</div>

			<div v-else>

				<div class="row">

					<ToggleableFormGroup class="col p-r-1"
						group-id="shippingFirstName"
						field-name="First Name"
						placeholder="Piet"
						type="text"
						v-model="shippingFirstName"/>

					<ToggleableFormGroup class="col"
						group-id="shippingLastName"
						field-name="Last Name"
						placeholder="Mondrian"
						type="text"
						v-model="shippingLastName"/>

				</div>

				<div class="row">

					<ToggleableFormGroup class="col p-r-1"
						group-id="shippingStreetAddr"
						field-name="Street Address"
						placeholder="11 W 53rd St"
						type="text"
						v-model="shippingStreetAddr"/>

					<ToggleableFormGroup class="col"
						group-id="shippingUnitAddr"
						field-name="Unit Address"
						placeholder="Apt 303"
						type="text"
						v-model="shippingUnitAddr"/>

				</div>

				<div class="row">

					<ToggleableFormGroup class="col w-4 p-r-1"
						group-id="shippingCity"
						field-name="City"
						placeholder="New York"
						type="text"
						v-model="shippingCity"/>

					<ToggleableFormGroup class="col w-4 p-r-1"
						group-id="shippingState"
						field-name="State"
						placeholder="NY"
						type="text"
						v-model="shippingState"/>

					<ToggleableFormGroup class="col w-4"
						group-id="shippingZip"
						field-name="Zip Code"
						placeholder="10019"
						type="text"
						v-model="shippingZip"/>

				</div>

			</div>

		</section>

		<section class="paper m-h-2 p-2">

			<h4>Payment Method</h4>
			<div class="card-info">

				<img :src="cardBrandImg"/>

				<p>
					<strong>{{ cardBrand }}</strong> ending in <strong>{{ cardLast4 }}</strong> <br />
					owned by <strong>{{ cardName }}</strong>
				</p>
			</div>

			<button @click="$emit('redo', 'cardInfo')">
				Change Card Info
			</button>

		</section>

		<section class="paper m-h-2 p-2">

			<h4>Confirmation</h4>

			<div v-if="!loading && !error">
				<p>If everything above looks good to you, then click the button below to
					place your order.</p>

				<button @click="placeOrder">
					Place Order
				</button>
			</div>

			<div v-else-if="loading" class="loading">
				<Loader />
				<p class="text-center">Completing your order.  Please be patient.  This
					may take a while.</p>
			</div>

			<div v-else>
				<p class="text-center">Looks like there was an error processing your
					order.  You may want to wait a bit and try again later.  You can also
					try emailing <strong>john@glyphglyder.com</strong> for help.</p>
			</div>

		</section>

	</div>
</template>

<script>
import CheckoutRequest from '../../network/checkout';
import Loader from '../Loader.vue';
import ToggleableFormGroup from '../forms/ToggleableFormGroup.vue';
export default {
	name: "CheckoutConfirm",
	props: ['billing', 'email', 'shipping', 'card-token', 'poster'],
	components: { Loader, ToggleableFormGroup },

	data: function() {

		return {

			billingFirstName: this.billing ? this.billing.firstName : '',
			billingLastName: this.billing ? this.billing.lastName : '',
			billingStreetAddr: this.billing ? this.billing.streetAddr : '',
			billingUnitAddr: this.billing ? this.billing.unitAddr : '',
			billingCity: this.billing ? this.billing.city : '',
			billingState: this.billing ? this.billing.state : '',
			billingZip: this.billing ? this.billing.zip : '',

			error: false,
			loading: false,

			shippingSame: this.shipping ? this.shipping.sameAsBilling : '',
			shippingFirstName: this.shipping ? this.shipping.firstName : '',
			shippingLastName: this.shipping ? this.shipping.lastName : '',
			shippingStreetAddr: this.shipping ? this.shipping.streetAddr : '',
			shippingUnitAddr: this.shipping ? this.shipping.unitAddr: '',
			shippingCity: this.shipping ? this.shipping.city : '',
			shippingState: this.shipping ? this.shipping.state: '',
			shippingZip: this.shipping ? this.shipping.zip: '',

			shippingEmail: this.email,

		}

	},

	computed: {

		cardBrand: function() {

			return this.cardToken.card.brand;

		},

		// Given the card brand value of a stripe token, this will compute the
		// appropriate img src
		cardBrandImg: function() {

			let dir = '/card-brands/';

			switch (this.cardToken.card.brand) {

				case "American Express":
					return dir + "amex.png";
				case "Diners Club":
					return dir + "diners.png";
				case "Discover":
					return dir + "discover.png";
				case "JCB":
					return dir + "jcb.png";
				case "MasterCard":
					return dir + "mastercard.png";
				case "Visa":
					return dir + "visa.png";

			}

			// Got this far?  We have no idea what card was used.  Just use the default
			return dir + "credit.png";

		},

		cardLast4: function() {

			return this.cardToken.card.last4;

		},

		cardName: function() {

			return this.cardToken.card.name;

		}

	},

	methods: {

		// Sends all of the collected order details to the server.
		placeOrder: function() {


			const sameAsBilling = this.shipping.sameAsBilling;

			const payload = JSON.stringify({
				data: {
					type: "checkout",
					attributes: {
						card_token: this.cardToken.id,
						first_name: sameAsBilling ? this.billingFirstName : this.shippingFirstName,
						last_name: sameAsBilling ? this.billingLastName : this.shippingLastName,
						street_address: sameAsBilling ? this.billingStreetAddr : this.shippingStreetAddr,
						unit_address: sameAsBilling ? this.billingUnitAddr : this.shippingUnitAddr,
						city: sameAsBilling ? this.billingCity : this.shippingCity,
						state: sameAsBilling ? this.billingState : this.shippingState,
						zip: sameAsBilling ? this.billingZip : this.shippingZip,
						poster_size: this.poster.size,
						poster_material: this.poster.material
					}
				}
			});

			this.loading = true;
			const _this = this;

			CheckoutRequest.checkout(payload, this.poster.graphic, this.poster.preview, (orderID) => {
				_this.loading = false;
				_this.$emit('order-placed', orderID);
			}, () => {
				_this.loading = false;
				_this.error = true;
				// TODO
			});

		}

	}
}
</script>

<style lang="scss" scoped>

.same-as ~ button, .card-info ~ button {
	margin-left: 0;
}

.same-as {
	display: flex;
	align-items: center;

	p {
		margin: 0;
	}

	img {
		width: 2.5rem;
		margin-right: 0.8rem;
	}
}

h4 {
	margin-bottom: 2.4rem;
	padding-bottom: 0.8rem;
	border-bottom: 2px dotted #BEBEBE;
}

.card-info {
	display: flex;
	align-items: center;

	img {
		margin-right: 0.8rem;
	}
}

.loading {
	margin: 5rem auto;
	display: flex;
	flex-direction: column;
}

@media(max-width: 768px) {
	.paper {
		padding: 16px 8px;
	}

	.col {
		padding-left: 0;
		padding-right: 0;
	}

	button {
		display: block;
		width: 100%;
	}
}
</style>
