//
// Package that handles checkout related requests.  Not just checkout itself,
// but also endpoints that deal with checking the progress of an order.
/////

import Post from './post';

export default {

	checkout: Post.checkout,
	
}
