<!-- Variation of controls overlay, only it shows up when the game is paused. -->
<template>

	<div class="paused">

		<div>

			<h1 class="text-center m-b-1">Paused</h1>

			<Controls
				:context="'pause'"
				:mobile="mobile"
				@play="$emit('play')"/>

		</div>

	</div>

</template>

<script>
import Controls from './Controls.vue';
export default {
	name: "PauseOverlay",
	components: { Controls },
	props: ['mobile']
}
</script>

<style lang="scss" scoped>
.paused {
	height: 100%;
	width: 100%;
	background-color: rgba(255, 255, 255, 0.90);
	z-index: 1000;
	position: absolute;
	top: 0;
	left: 0;
	overflow-y: scroll;
	padding: 50px 8px;

	& > div {
		max-width: 300px;
		margin: 0 auto;
	}
}
</style>
