//
// Middleware for routers
/////

export default {

	adminMiddle: (next) => {

		if (localStorage.getItem('token') && localStorage.getItem('a')) {
			next();
		} else {
			// TODO: Redirect to a 404 page
			window.location = "https://google.com";
		}

	},

	authMiddle: (next) => {

		if (localStorage.getItem('token')) {
			next();
		} else {
			next('/login');
		}

	}
}
