<!-- Component that indicates communication between the server or really
	anything that takes time.  -->
<template>
	<div class="loader">

		<div class="foo">
			<div class="red"/>
			<div class="yellow"/>
		</div>

		<div class="foo">
			<div class="white"/>
			<div class="blue"/>
		</div>

	</div>
</template>

<script>
export default {
	name: "Loader"
}
</script>

<style lang="scss" scoped>
div.foo{
	margin: 0;
	padding: 0;
	height: 20px;
}

.loader {
	display: inline-block;
	margin: 0 auto;
}

.red, .yellow, .blue, .white {
	display: inline-block;
	width: 20px;
	height: 20px;
	margin: 0;
	padding: 0;
	border: 2px solid #333;
}

.red {
	border-top-width: 4px;
	border-left-width: 4px;
	animation: redstart 2s;
	animation-iteration-count: infinite;
}

.yellow {
	border-top-width: 4px;
	border-right-width: 4px;
	animation: yellowstart 2s;
	animation-iteration-count: infinite;
}

.blue {
	border-bottom-width: 4px;
	border-right-width: 4px;
	animation: bluestart 2s;
	animation-iteration-count: infinite;
}

.white {
	border-bottom-width: 4px;
	border-left-width: 4px;
	animation: whitestart 2s;
	animation-iteration-count: infinite;
}

@keyframes redstart {
	0% {
		background-color: #FF220B;
		animation-timing-function: steps(1, end);
	}
	25% {
		background-color: #FFF;
		animation-timing-function: steps(1, end);

	}
	50% {
		background-color: #1733ED;
		animation-timing-function: steps(1, end);
	}
	75% {
		background-color: #FFC100;
		animation-timing-function: steps(1, end);
	}
}

@keyframes yellowstart {
	0% {
		background-color: #FFC100;
		animation-timing-function: steps(1, end);
	}
	25% {
		background-color: #FF220B;
		animation-timing-function: steps(1, end);
	}
	50% {
		background-color: #FFF;
		animation-timing-function: steps(1, end);
	}
	75% {
		background-color: #1733ED;
		animation-timing-function: steps(1, end);
	}
}

@keyframes bluestart{
	0% {
		background-color: #1733ED;
		animation-timing-function: steps(1, end);
	}
	25% {
		background-color: #FFC100;
		animation-timing-function: steps(1, end);

	}
	50% {
		background-color: #FF220B;
		animation-timing-function: steps(1, end);
	}
	75% {
		background-color: #FFF;
		animation-timing-function: steps(1, end);
	}
}

@keyframes whitestart {
	0% {
		background-color: #FFF;
		animation-timing-function: steps(1, end);
	}
	25% {
		background-color: #1733ED;
		animation-timing-function: steps(1, end);

	}
	50% {
		background-color: #FFC100;
		animation-timing-function: steps(1, end);
	}
	75% {
		background-color: #FF220B;
		animation-timing-function: steps(1, end);
	}
}
</style>
