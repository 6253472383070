<!-- A component that has a standard input and a label for that input. -->
<template>

	<div class="form-group">
		<label :for="groupId">{{ fieldName }}: </label>
		<input :type="type"
			:id="groupId"
			:value="value"
			:placeholder="placeholder"
			@input="$emit('input', $event.target.value)"
			@keyup.enter="$emit('enter')">
	</div>

</template>

<script>
export default {
	name: "FormGroup",
	props: ['field-name', 'group-id', 'placeholder', 'type', 'value']
}
</script>

<style lang="scss" scoped>
.form-group {
	width: 100%;

	label {
		display: block;
	}

	input {
		width: 100%;
		display: block;
	}
}
</style>
