<!-- Used to render areas of the game where the blob has been "punced out"
	by the ball and replaced with a rect. -->

<script>
import BlobConsts from '../../mixins/blob/consts';
import CombineMixin from '../../mixins/rects/combine';
import InitialChunksMixin from '../../mixins/blob/initial-chunks';
import MinMaxMixin from '../../mixins/blob/min-max';
import TotalRowsColsMixin from '../../mixins/blob/total-rows-cols';
export default {
	name: "Rects",
	inject: ['GameEventBus', 'GameState', 'PIXIWrapper'],
	props: ['layout', 'screen-width', 'screen-height'],
	mixins: [ BlobConsts, CombineMixin, InitialChunksMixin, MinMaxMixin, TotalRowsColsMixin ],
	
	data: function() {
		return {
			container: null,
			cells: [],
			rectGraphics: null,
			rectSprites: [],
			width: 80,
			height: 40
		}
	},
	
	methods: {
		
		// Adds a new rect.  Takes a blobette as an argument to determine where
		// it should be drawn on the screen (when drawn)
		addRect: function(blobette, color) {
		
			// Go ahead and create a rect object
			const rect = {
				x: blobette.x,
				y: blobette.y,
				origin: {
					chunkID: blobette.chunkID,
					rectID: blobette.rectID,
				},
				rectID: blobette.rectID,
				chunkID: blobette.chunkID,
				wholeWidth: this.width,
				wholeHeight: this.height,
				width: this.width,
				height: this.height,
				color: color.img,
				assignmentID: color.assignmentID,
				omit: ''
			};
		
			// And set an empty cell to the newly created rect.  Because cells reflect
			// the absolute position of a rect within a level, we'll use the rect's
			// chunk and rectIDs to determine where to place it
			const index = this.calcIndex(rect.origin);
			this.cells[index] = rect
		
			// Now let's see if we can combine this rect into some larger rects
			let combined = this.combineY(index);
		
			if (!combined) {
		
				this.combineX(index);
		
			}
			
			// And finally, call the draw method again
			this.draw();
		
		},
		
			
		// Given an object containing both the chunkIDs and rectIDs, along with the
		// total rows and columns in the level, we can turn those IDs into a cell
		// index
		calcIndex: function(ids) {
		
			// First, as you may already have read elsewhere, we need to decrement the
			// chunkIDs if they're greater than 0
			let chunkX = ids.chunkID.x;
			let chunkY = ids.chunkID.y;
			if (chunkX > 0) {
				chunkX --;
			}
		
			const adjustedMaxY = this.maxY - 1;
			let adjustedY = chunkY;
			if (chunkY > 0) {
				adjustedY --;
			}
		
			// Now, we can calculate the column by taking chunkX, multiplying by the
			// width of a chunk (it's 4, using magic numbers here), then adding the
			// rectX (subtract by one, they're zero indexed) and finally add half of
			// the total cols to compensate for the result possibly being negative
			const col = chunkX * 4 + (ids.rectID.x - 1) + this.totalColumns / 2;
		
			// Rows are trickier.
			// Use the delta between maxY and the y value of the current chunk to get
			// the ballpark.  Then use the rectID.y to get the absolute row
			const row = (adjustedMaxY - adjustedY) * 6 + (ids.rectID.y - 1);
		
			// Finally, this
			return (row * this.totalColumns) + col;
		
		},
		
		// Draw function.  Draws only those chunks that are relevant to the scene.
		draw: function() {

			// You'll want to make the container sortable so that ghost sprites always
			// appear below the transparency layer
			if (this.container == null) {
				this.container = new this.PIXIWrapper.PIXI.Container();
				this.container.sortable = true;
				this.GameState.container.addChild(this.container);
			}
					
			if (this.rectGraphics) {
				this.rectGraphics.clear();
			} else {
				this.rectGraphics = new this.PIXIWrapper.PIXI.Graphics();
				this.container.addChild(this.rectGraphics);
			}

			// Flag used in determining if we've reached a game over state
			let stillDrawing = false;

			this.cells.forEach((rect) => {
				
				// If the cell is "falsy", then that means it's still waiting for a rect
				// and we haven't finished the game yet.  Exit early, but also trip a flag
				if (!rect) {
					stillDrawing = true;
					return;
				}
								
				// First, some rects are marked as invisible.  This means we're no longer
				// drawing it because the level got shifted down.  Exit early if this
				// rect is marked as such
				if (rect.invisible) {
					return;
				}
				
				// Otherwise, go ahead and draw the sprites first, but only if you have
				// to.  Check RectSprites and see if we haven't already drawn the Sprites
				// by looking up rectIDs.
				let drawnSprite = this.rectSprites.find((ds) => {
					return ds.rectID == rect.rectID;
				})
				
				if (!drawnSprite) {
				
					// Rect should have a color
					// property which is a texture, so we should be able to just take that
					// and make a sprite.  You'll need two of them
					const leftSprite = new this.PIXIWrapper.PIXI.Sprite(rect.color);
					leftSprite.x = rect.x
					leftSprite.y = rect.y;
					leftSprite.width = this.width / 2;
					leftSprite.height = this.height;
					this.container.addChildAt(leftSprite, 0);
					
					const rightSprite = new this.PIXIWrapper.PIXI.Sprite(rect.color);
					rightSprite.x = rect.x + this.width / 2;
					rightSprite.y = rect.y;
					rightSprite.width = this.width / 2;
					rightSprite.height = this.height;
					this.container.addChildAt(rightSprite, 0);
					
					this.rectSprites.push({
						left: leftSprite,
						right: rightSprite,
						rectID: rect.rectID
					});
					
					// We'll also assign this to drawnSprite just in case the rect is also
					// ghostly and we need to move it
					drawnSprite = this.rectSprites[this.rectSprites.length - 1];
					
				}				
								
				// If it was determined that the rect in question was a
				// "ghost" rect, then we'll add a semi-transparent rectangle to
				// rectGraphics where the sprites should be, as well as move the sprite
				// if needed
				let rectX = rect.x;
				let rectY = rect.y;
				if (rect.ghostly) {
										
					rectY += this.BLOB_HEIGHT * this.CHUNK_HEIGHT;
					this.rectGraphics.lineStyle({width: 0})
					this.rectGraphics.beginFill('0xFFFFFF', 0.6);
					this.rectGraphics.drawRect(
						rectX,
						rectY,
						this.width,
						this.height
					);
					this.rectGraphics.endFill();
					
					// Sprite check
					if (drawnSprite && drawnSprite.left.y != rectY) {
						drawnSprite.left.y = rectY;
						drawnSprite.right.y = rectY;
					}
				}
				
				
				// We'll then draw the outlines.  We do this by drawing four lines UNLESS
				// rect.omit instructs us to omit one of them
				this.rectGraphics.lineStyle({
					width: 8,
					color: rect.ghostly ? 0x444444 : 0x0,
					alpha: 1,
					alignment: 0.5,
				});

				if (!rect.omit.includes('top')) {
				
					// Draw a top line
					this.rectGraphics.moveTo(
						rectX - 4,
						rectY
					);
					this.rectGraphics.lineTo(
						rectX + (this.width + 4),
						rectY
					);
				
				}
				
				if (!rect.omit.includes('right')) {
				
					// Draw a right line
					this.rectGraphics.moveTo(
						rectX + this.width,
						rectY - 4
					);
					this.rectGraphics.lineTo(
						rectX + this.width,
						rectY + (this.height + 4),
					);
				
				}
				
				if (!rect.omit.includes('bottom')) {
				
					// Draw a bottom line
					this.rectGraphics.moveTo(
						rectX - 4,
						rectY + (this.height)
					)
					this.rectGraphics.lineTo(
						rectX + (this.width + 4),
						rectY + this.height
					);
				
				}
				
				if (!rect.omit.includes('left')) {
				
					// Draw a left line
					this.rectGraphics.moveTo(
						rectX,
						rectY - 4,
					)
					this.rectGraphics.lineTo(
						rectX,
						rectY + (this.height + 4)
					);
				
				}

			});
		
			// If we ran out of rects to draw and the chunks array is empty, then draw
			// all rects as they normally appear, and also emit an event informing the
			// parent that the game is over
			if (!stillDrawing && this.GameState.chunks.length == 0) {
				this.$emit('endgame', this.cells);
				this.drawAll();
			}
		
		},
		
		// Draw function for when we've reached the end game.  Draws all rectangles
		// for the level.  Uses special means of calculating rect x,y position
		drawAll: function() {
			
			// Do all the usual cleanup shit
			if (this.container == null) {
				this.container = new this.PIXIWrapper.PIXI.Container();
				this.container.sortable = true;
				this.GameState.container.addChild(this.container);
			}
					
			if (this.rectGraphics) {
				this.rectGraphics.clear();
			} else {
				this.rectGraphics = new this.PIXIWrapper.PIXI.Graphics();
				this.container.addChild(this.rectGraphics);
			}
			
			// Define a center point, positions will reflect off of this
			const centerX = this.screenWidth / 2;
			const centerY = this.screenHeight / 2;
			
			this.cells.forEach((rect, index) => {
				
				// Skip if there's no rect
				if(!rect) {
					return;
				}
				
				// ChunkX and chunkY need to be shifted by one depending on sign, do that
				// here
				let chunkX = rect.chunkID.x;
				if (chunkX > 0) {
					chunkX --;
				}
				let chunkY = rect.chunkID.y;
				if (chunkY < 0) {
					chunkY ++
				}
				
				// Right then, here's the interesting part.  We use the chunkID and rectID
				// information to determine the position.  Again, this is relative to the
				// center of the screen.  We do this in two parts to make it easier to read.
				// Basically, we first calculate the x,y position for the chunk, followed
				// by rect x,y
				let xPos = centerX + (chunkX * 4 * 80);
				let yPos = centerY - (chunkY * 6 * 40);
				
				xPos = xPos + (rect.rectID.x - 1) * 80;
				yPos = yPos + (rect.rectID.y - 1) * 40;
				
				// Now let's draw the sprites.  There's a chance we may already have drawn
				// sprites on the screen for the given rect, so double check real fast
				const drawnSprite = this.rectSprites.find((ds) => {
					return ds.rectID == rect.rectID;
				})
				
				if (drawnSprite) {
					
					// In which case, we simply have to adjust the positions of the left and
					// right sprite
					drawnSprite.left.x = xPos;
					drawnSprite.left.y = yPos;
					
					drawnSprite.right.x = xPos + this.width / 2;
					drawnSprite.right.y = yPos;
					
				} else {
					
					// Otherwise, create them once again
					const leftSprite = new this.PIXIWrapper.PIXI.Sprite(rect.color);
					leftSprite.x = xPos
					leftSprite.y = yPos
					leftSprite.width = this.width / 2;
					leftSprite.height = this.height;
					this.container.addChildAt(leftSprite, 0);
					
					const rightSprite = new this.PIXIWrapper.PIXI.Sprite(rect.color);
					rightSprite.x = xPos + this.width / 2;
					rightSprite.y = yPos
					rightSprite.width = this.width / 2;
					rightSprite.height = this.height;
					this.container.addChildAt(rightSprite, 0);
					
					// We're also going to add said sprites to the array for cleanup
					// purposes
					this.rectSprites.push({
						left: leftSprite,
						right: rightSprite,
						rectID: rect.rectID
					});
					
					
				}
				
				// And now the lines
				this.rectGraphics.lineStyle({
					width: 8,
					color: 0x0,
					alpha: 1,
					alignment: 0.5,
				});
				
				if (!rect.omit.includes('top')) {
				
					// Draw a top line
					this.rectGraphics.moveTo(
						xPos - 4,
						yPos
					);
					this.rectGraphics.lineTo(
						xPos + this.width + 4,
						yPos
					);
				
				}
				
				if (!rect.omit.includes('right')) {
				
					// Draw a right line
					this.rectGraphics.moveTo(
						xPos + this.width,
						yPos - 4
					);
					this.rectGraphics.lineTo(
						xPos + this.width,
						yPos + this.height + 4,
					);
				
				}
				
				if (!rect.omit.includes('bottom')) {
				
					// Draw a bottom line
					this.rectGraphics.moveTo(
						xPos - 4,
						yPos + this.height
					)
					this.rectGraphics.lineTo(
						xPos + this.width + 4,
						yPos + this.height
					);
				
				}
				
				if (!rect.omit.includes('left')) {
				
					// Draw a left line
					this.rectGraphics.moveTo(
						xPos,
						yPos - 4,
					)
					this.rectGraphics.lineTo(
						xPos,
						yPos + this.height + 4
					);
				
				}
				
				
			});
			
		},
		
		// Takes rects from a partially "carved" blob chunk and shifts them down.
		// A different function, churnRects, handles situations where we bring in
		// a whole new row of chunks
		shiftRects: function(chunkID, xShift, yShift) {
		
			this.cells.forEach((cell) => {
		
				// No cell?  Skip past
				if (!cell) {
					return;
				}
		
				// Have a match?  Shift it.
				if (cell.chunkID.x == chunkID.x && cell.chunkID.y == chunkID.y) {
		
					cell.x += xShift;
					cell.y += yShift;
		
				}
		
			});
		
		},
		
		// Handles scenarios where we've brought in an entirely new row of chunks.
		// Any current rects that aren't marked as "ghostly" are moved down by the
		// height of one chunk.  Any rects that have been moved are removed altogether
		churnRects: function() {
			
			this.cells.forEach((rect) => {
				
				// Skip over empty cells
				if (!rect) {
					return;
				}
				
				// Otherwise, if it isn't "ghostly", then mark it as such
				if (!rect.ghostly) {
					rect.ghostly = true;
					return;
				}
				
				// And if it's ghostly, then mark it as invisible to indicate not to draw
				// it, and clean up the sprite associated with it
				if (rect.ghostly) {
					rect.invisible = true;
					
					this.rectSprites.forEach((sprite) => {
						if (sprite.rectID == rect.rectID) {
							this.container.removeChild(sprite);
							sprite.destroy();
						}
					});
					
				}
			});
			
			// We're also going call draw once this is all done
			this.draw()
		}
	},
	
	mounted: function() {
		
		// Using our row and column data, initialize an array of cells with empty
		// values
		for(let i = 0; i < this.totalRows * this.totalColumns; i ++) {
			this.cells.push(false);
		}
		
		// Add a listener for rect events
		this.GameEventBus.$on('add-rect', this.addRect);
		this.GameEventBus.$on('churn-rects', this.churnRects);
		this.GameEventBus.$on('shift-rects', () => {
			console.log('listening')
		});
		
		// Call draw real fast, just to make sure we mount the container before the
		// ball graphics
		this.draw();
	},
	
		
	render: function(h) {
		return h('template')
	}
}
</script>