export default {

	data: function() {
		return {
			// Height of blobette actually, not the whole blobby mass.  Could be confusing
			BLOB_HEIGHT: 40,
			
			// Width of blobette.
			BLOB_WIDTH: 80,
			
			// How much space should be at the bottom, at minimum
			BOTTOM_PADDING: 160,
			
			// Height of chunks in blobettes
			CHUNK_HEIGHT: 6,
			
			// Width of chunks in blobettes
			CHUNK_WIDTH: 4,
			
			LEFT_PADDING: 60,
			
			// How much space should be at the top, at minimum
			TOP_PADDING: 80,
		}
	}

}
