<!-- A component that displays information about the latest order for a user. -->
<template>
	<div>

		<div v-if="loading" class="flex-line">
			<div class="text-center" style="margin: 0 auto;">
				<Loader/>
				<h4>Fetching Order...</h4>
			</div>
		</div>

		<div v-else="!loading">

			<div class="flex-row stretch thicker-border shadow p-2">

				<div class="col w-6 s-6 p-r-2">

					<div class="paper p-2" style="height: 100%;">

						<h2><span v-if="!orderID">Latest</span> Order: #{{ order.id }}</h2>
						<hr />
						<h3 class="m-t-0 m-b-2">Status: {{ orderStatus }}</h3>

						<h4>Cost</h4>
						<p class="sans-serif">
							<strong>18x24 Matte Poster: </strong> $29.99 <br />
							<strong>Shipping: </strong> $0.00
						</p>

						<hr />
						<p class="sans-serif"><strong>Total: </strong> $29.99</p>

					</div>

				</div>

				<div class="col w-6 s-6 p-l-2">

					<img :src="order.previewURL" class="m-l-auto"/>

				</div>

			</div>

			<div class="flex-row thicker-border shadow p-2 m-t-2" v-if="orderStatus != 'Canceled' && orderStatus != 'Failed'">

				<div class="col w-8">

					<div class="paper p-2">

						<div class="flex-line">

							<h4 style="flex-grow: 1;">Shipping Info</h4>

							<button class="m-r-0" @click="updateAddress = true" v-if="!updateAddress">
								Update Address
							</button>

							<button class="btn-yellow m-r-0" @click="editShipping" v-else>
								Save Changes
							</button>

						</div>

						<hr class="m-b-2"/>

						<!-- Can the shipping info still be changed?  If so, give the user the
							option to do so. -->
						<div v-if="orderStatus == 'Pending'">

							<ToggleableFormGroup
								group-id="shippingName"
								field-name="Shipping Name"
								placeholder="Piet Mondrian"
								type="text"
								v-model="shippingName"
								:edit="updateAddress"
								@finished="editShipping"/>

							<ToggleableFormGroup
								group-id="shippingStreet"
								field-name="Street Address"
								placeholder="11 W 53rd St"
								type="text"
								v-model="shippingStreet"
								:edit="updateAddress"
								@finished="editShipping"/>

							<ToggleableFormGroup
								group-id="shippingUnit"
								field-name="Unit Address"
								placeholder="Apt 303"
								type="text"
								v-model="shippingUnit"
								:edit="updateAddress"
								@finished="editShipping"/>

							<div class="flex-row">

								<ToggleableFormGroup class="col w-4 p-r-1"
									group-id="shippingCity"
									field-name="City"
									placeholder="New York"
									type="text"
									v-model="shippingCity"
									:edit="updateAddress"
									@finished="editShipping"/>

								<ToggleableFormGroup class="col w-4 p-r-1"
									group-id="shippingState"
									field-name="State"
									placeholder="NY"
									type="text"
									v-model="shippingState"
									:edit="updateAddress"
									@finished="editShipping"/>

								<ToggleableFormGroup class="col w-4"
									group-id="shippingZip"
									field-name="Zip Code"
									placeholder="10019"
									type="text"
									v-model="shippingZip"
									:edit="updateAddress"
									@finished="editShipping"/>

							</div>

						</div>

						<!-- Otherwise, just display the shipping address -->
						<div v-else>
						</div>

					</div>

				</div>

				<div class="col w-4" v-if="order.trackingCode">

					<h4>Tracking Info</h4>

				</div>


			</div>

			<div class="thicker-border shadow p-2 m-t-2" v-if="orderStatus == 'Pending'">
				<div class="paper p-2">
					<h4>Order Cancellation</h4>

					<p>Are you having second thoughts about your order?  If so, you can still
						cancel your order before it is processed for printing and get a full
						refund.</p>

					<button class="btn-red m-l-0" @click="cancelOrder">
						Cancel Order
					</button>
				</div>
			</div>

			<div class="thicker-border shadow p-2 m-t-2" v-if="orderStatus == 'Canceled'">
				<div class="paper p-2">
					<h4>This Order Was Canceled</h4>

					<p>If you'd like, you can re-order this order.  Otherwise, this order
						will be automatially deleted after 30 days.</p>
				</div>
			</div>

		</div>

	</div>
</template>

<script>
import Loader from '../../components/Loader.vue';
import OrderRequest from '../../network/orders';
import ToggleableFormGroup from '../../components/forms/ToggleableFormGroup.vue';
export default {
	name: "CurrentOrder",
	components: { Loader, ToggleableFormGroup },
	props: ['orderID'],

	data: function() {
		return {
			loading: true,
			latestID: '',

			shippingName: '',
			shippingStreet: '',
			shippingUnit: '',
			shippingCity: '',
			shippingState: '',
			shippingZip: '',

			updateAddress: false,
		}
	},

	computed: {

		// Retrieves order in question from the store.
		order: function() {

			const orderID = this.orderID ? this.orderID : this.latestID;

			if (!orderID) {
				return false;
			}

			for(let i = 0; i < this.$store.state.orders.length; i ++) {
				if (this.$store.state.orders[i].id == orderID) {
					return this.$store.state.orders[i];
				}
			}

			return false;

		},

		// Merges some of the order statuses into one, while also converting them
		// into something more user-friendly
		orderStatus: function() {

			if (!this.order) {
				return '';
			}

			if (this.order.status == 'draft' || this.order.status == 'pending') {
				return 'Pending';
			}

			return this.order.status.charAt(0).toUpperCase() + this.order.status.slice(1);

		}

	},

	methods: {

		// Makes a request to cancel an order.
		cancelOrder: function() {

			const _this = this;
			const store = this.$store;
			const orderID = this.orderID ? this.orderID : this.latestID;

			this.loading = true;

			OrderRequest.cancelOrder(orderID, () => {
				_this.loading = false;
				const canceledOrder = Object.assign({}, _this.order);
				canceledOrder.status = 'cancelled';
				store.commit('addOrders', [canceledOrder]);
			});

		},

		// Responds to the user changing shipping details by sending a request to
		// to the server, which in-turn forwards the updated information to Printful
		editShipping: function() {

			this.updateAddress = false;

			// So, what we'll do is just sent every shipping detail we have over.
			// Simpler than using if-statements to determine what was edited.
			const orderID = this.orderID ? this.orderID : this.latestID;

			const order = Object.assign({}, this.order);
			order.shippingName = this.shippingName;
			order.shippingStreet = this.shippingStreet;
			order.shippingUnit = this.shippingUnit;
			order.shippingState = this.shippingState;
			order.shippingCity = this.shippingCity;
			order.shippingZip	= this.shippingZip;

			const payload = JSON.stringify({
				data: {
					type: "order",
					id: orderID,
					attributes: {
						name: this.shippingName,
						shipping_street: this.shippingStreet,
						shipping_unit: this.shippingUnit,
						state: this.shippingState,
						shipping_city: this.shippingCity,
						zip: this.shippingZip
					}
				}
			});

			const _this = this;
			this.loading = true;
			OrderRequest.updateShipping(orderID, payload, () => {
				_this.loading = false;
			})
		}

	},

	// Attempt to retrieve the latest order from the server
	mounted: function() {

		const store = this.$store;
		const _this = this;

		if (this.orderID) {

			OrderRequest.getOrder(this.orderID, (order) => {

				_this.loading = false;
				store.commit('addOrders', [order]);

			});

		} else {

			OrderRequest.getOrder('latest', (order) => {

				_this.latestID = order.id;
				_this.loading = false;
				store.commit('addOrders', [order]);

			});

		}


	},

	watch: {

		order: function() {

			if (!this.order) {
				return;
			}

			this.shippingName = this.order.shippingName;
			this.shippingStreet = this.order.shippingStreet;
			this.shippingUnit = this.order.shippingUnit;
			this.shippingState = this.order.shippingState;
			this.shippingCity = this.order.shippingCity;
			this.shippingZip = this.order.shippingZip;
		},

		orderID: function() {

			const store = this.$store;
			const _this = this;

			if (this.orderID) {

				OrderRequest.getOrder(this.orderID, (order) => {

					_this.loading = false;
					store.commit('addOrders', [order]);

				});

			} else {

				OrderRequest.getOrder('latest', (order) => {

					_this.latestID = order.id;
					_this.loading = false;
					store.commit('addOrders', [order]);

				});

			}
		}

	}
}
</script>

<style lang="scss" scoped>
img {
	display: block;
	max-width: 400px;
	max-height: 400px;
	margin: 0 auto;
}

@media(max-width: 768px) {
	img {
		margin: 0 auto;
	}

	.col {
		padding-right: 0;
		padding-left: 0;
	}
}
</style>
