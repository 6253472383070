//
// Draws a nice grid for us for the level editor, with a clearly defined
// mid-point and cells that match the dimensions of a rect
//////

import { drawHorizontalCells, drawVerticalCells } from './cells';
import { drawHorizontalChunks, drawVerticalChunks } from './chunks';
import { drawMidpoint } from './midpoint';

export default class Grid {

	constructor(options) {

		this.drawHorizontalCells = this.drawHorizontalCells.bind(this);
		this.drawHorizontalChunks = this.drawHorizontalChunks.bind(this);
		this.drawMidpoint = this.drawMidpoint.bind(this);
		this.drawVerticalCells = this.drawVerticalCells.bind(this);
		this.drawVerticalChunks = this.drawVerticalChunks.bind(this);

		// Go ahead and make some assignments
		this.midpoint = options.midpoint ? options.midpoint : {x : 0, y : 0};

		// To elaborate, this is the width of the screen
		this.width = options.width ? options.width : 100;
		this.height = options.height ? options.height : 100;

		this.chunkWidth = options.chunkWidth ? options.chunkWidth : 4;
		this.chunkHeight = options.chunkHeight ? options.chunkHeight : 6;

		this.cellWidth = options.cellWidth ? options.cellWidth : 80;
		this.cellHeight = options.cellHeight ? options.cellHeight : 40;

		this.cellAngle = options.cellAngle ? options.cellAngle : 0;


	}

	// Draws our grid
	draw(sketch, offset, zoom) {

		// First, draw the lines which intersect our midpoint
		this.drawMidpoint(offset, sketch);

		// Next, draw the lines that will delineate our "chunks", starting with
		// those before the midpoint
		this.drawVerticalChunks(offset, zoom, sketch)
		this.drawHorizontalChunks(offset, zoom, sketch);

		// Now let's draw the lines themselves.
		this.drawVerticalCells(zoom, offset, sketch);
		this.drawHorizontalCells(zoom, offset, sketch);

	}
}

Grid.prototype.drawHorizontalCells = drawHorizontalCells;
Grid.prototype.drawHorizontalChunks = drawHorizontalChunks;
Grid.prototype.drawMidpoint = drawMidpoint;
Grid.prototype.drawVerticalCells = drawVerticalCells;
Grid.prototype.drawVerticalChunks = drawVerticalChunks;
