<template>
	<div>

		<hr />
		<h3>Patterns</h3>

		<div class="pattern-swatches">

			<PatternSwatch v-for="pattern in patterns" :key="pattern.id"
				:pattern="pattern"/>

		</div>

		<button class="block-button"
			@click="modal">
			Pick Pattern
		</button>

	</div>
</template>

<script>
import PatternSwatch from './PatternSwatchList.vue';
export default {
	name: "LevelPatternSelection",
	props: ['level'],
	components: { PatternSwatch },
	inject: ['LevelEventBus', 'ModalEventBus'],

	methods: {

		// Responds to events from pattern pick modal where the user picks a
		// pattern.  This will use the normal event bus to tell the page to add a
		// different pattern to this level
		addPattern: function(pattern) {
			this.LevelEventBus.$emit('pattern', pattern);
			this.ModalEventBus.$emit('close');
		},

		modal: function() {
			this.ModalEventBus.$emit('modal', {
				modal: 'ModalPatternPicker',
				props: {
					addPattern: this.addPattern
				}
			})
		}

	},

	computed: {

		patterns: function() {

			let patterns = [];

			if (!this.level) {
				return patterns;
			}

			this.level.patternIDs.forEach((patternID) => {

				for(let i = 0; i < this.$store.state.patterns.length; i ++) {

					let pattern = this.$store.state.patterns[i];
					if (pattern.id == patternID) {
						patterns.push(pattern);
					}

				}

			});

			return patterns;

		}
	}
}
</script>

<style lang="scss" scoped>
.pattern-swatches {
	margin: 12px;
}

h3 {
	margin-bottom: 0;
}
</style>
