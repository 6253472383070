<!-- UI component that displays the colors that the ball will soon be. -->
<script>
export default {
	props: ['svgs', 'zoom'],
	inject: ['GameState', 'GameEventBus', 'PIXIWrapper'],
	
	data: function() {
		return {
			arrow: null,
			colorGraphics: [],
			container: null,
			patterns: [],
			powerUpMouse: true
		}
	},
	
	methods: {
		
		// Adds a new color (pattern) the colorqueue
		addColor: function() {
			this.GameState.colorqueue.push(this.patterns[Math.floor(Math.random() * this.patterns.length)]);
		},
		
		draw: function() {
			
			// Initialize the container, if necessary
			if (this.container == null) {
				this.container = new this.PIXIWrapper.PIXI.Container();
				this.GameState.container.addChild(this.container);
			}
			
			// Likewise, initialize the arrow if we must.  We only really need the draw
			// the arrow once
			if (this.arrow == null) {
				this.arrow = new this.PIXIWrapper.PIXI.Graphics();
				
				this.arrow.lineStyle({
					width: 3,
					color: 0x0,
					alpha: 1,
					alignment: 0.5,
					cap: this.PIXIWrapper.PIXI.LINE_CAP.SQUARE
				});
				
				this.arrow.moveTo(45, 25);
				this.arrow.lineTo(53, 25);
				
				this.arrow.moveTo(50, 20);
				this.arrow.lineTo(55, 25);
				
				this.arrow.moveTo(50, 30);
				this.arrow.lineTo(55, 25);
				
				this.container.addChild(this.arrow);
				
			}
			
			// If we need to, create a mask, the mask simply being a circle
			if (this.mask == null) {
				this.mask = new this.PIXIWrapper.PIXI.Graphics();
				this.mask.beginFill('0x0');
				this.mask.drawEllipse(0, 0, 10, 10)
			}
			
			// With the balls themselves, we never need to redraw the mask or the border,
			// but we do need to redraw the sprites
			if (!this.colorGraphics.length) {
				
				this.GameState.colorqueue.forEach((color, i) => {
					
					if (!i) {
						return;
					}
					
					let index = i -1;
					
					const mask = new this.PIXIWrapper.PIXI.Graphics();
					mask.beginFill('0x0');
					mask.drawCircle(index * 30 + 75, 25, 8);
					
					const border = new this.PIXIWrapper.PIXI.Graphics();
					border.beginFill('0x0', 0);
					border.lineStyle({
						width: 4,
						color: 0x0,
						alpha: 1,
						alignment: 0.5,
					})
					border.drawCircle(index * 30 + 75, 25, 10);
					
					const sprite = new this.PIXIWrapper.PIXI.Sprite(color.img);
					sprite.position.x = index * 30 + 66, 
					sprite.position.y = 16;
					sprite.width = 18;
					sprite.height = 18;
					
					sprite.mask = mask;
					
					this.colorGraphics.push({
						border,
						mask,
						sprite
					});
					
					this.container.addChild(mask);
					this.container.addChild(sprite);
					this.container.addChild(border);
				})

			} else {
				
				// In this instance we're replacing the colors with new ones
				this.GameState.colorqueue.forEach((color, i) => {
					
					if (!i) {
						return;
					}

					let index = i -1;
					
					// Destroy the old sprite
					this.container.removeChild(this.colorGraphics[index].sprite);
					this.colorGraphics[index].sprite.destroy();
					
					// Replace it with the new
					const sprite = new this.PIXIWrapper.PIXI.Sprite(color.img);
					sprite.position.x = index * 30 + 66, 
					sprite.position.y = 16;
					sprite.width = 18;
					sprite.height = 18;
					
					sprite.mask = this.colorGraphics[index].mask;
					
					this.colorGraphics[index].sprite = sprite;
					this.container.addChild(sprite);
					
				});
			}

			
		},
		
		// Handles events where the ball requests a new color from the color queue to
		// be applied
		getColor: function() {
						
			this.GameState.colorqueue.splice(0, 1);
			this.addColor();
			this.draw();
			
			this.GameState.powerUp = true;
		},
		
	},
	
	beforeDestroy: function() {
		
		// Clear events
		this.GameEventBus.$off('new-color', this.getColor);
		this.GameEventBus.$off('power-up', this.draw);
		
		// Clear patterns
		this.patterns = [];
		
		// Clear colorqueue
		this.GameState.colorqueue = [];
		
		// Clear graphics
		if (this.container != null) {
			
			this.GameState.container(this.container);
			this.container.destroy({
				children: true
			});
			
		} else {
			
			if (this.arrow != null) {
				this.arrow.destroy();
			}
			
			this.colorGraphics.forEach((cg) => {
				
				if (cg.border != null) {
					cg.border.destroy();
				}
				
				if (cg.mask != null) {
					cg.mask.destroy();
				}
				
				if (cg.sprite != null) {
					cg.sprite.destroy();
				}
			});
			
		}
		
		this.container = null;
		this.arrow = null;
		this.mask = null;
		this.colorGraphics = [];
		
	},
	
	mounted: function() {
		
		// Initialize our internal patterns array
		this.svgs.forEach((svg, i) => {
			
			const patternTexture = this.PIXIWrapper.PIXI.Loader.shared.resources[`svg${svg.id}/${i}`].texture;

			this.patterns.push({
				id: svg.id,
				assignmentID: svg.assignmentID,
				svg: svg.svg,
				img: patternTexture
  			});
			  
		})
		
		// Add our initial 4 colors
		for(let i = 0; i < 4; i ++) {
			this.addColor();
		}
		
		this.getColor();
		
		// And draw the queue
		this.draw();
		
		this.GameEventBus.$on('new-color', this.getColor);
		
		// If a power-up event is emitted, redraw the queue to reflect the active
		// choice
		this.GameEventBus.$on('power-up', this.draw);
		
		// Likewise if an event is emitted indicating that a new choice was selected
		// TODO...
	},
	
	render: function(h) {
		return h('template');
	}
}
</script>