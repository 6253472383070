//
// Portion of package containing PUT handler methods.
/////

import axios from 'axios';

export default {

	// Used to update a pattern.  Doesn't really do anything after the fact.
	pattern: (id, payload, success, failure) => {
		axios.put(`${process.env.VUE_APP_ENDPOINT}/patterns/${id}`, payload, {
			headers: {"Authorization": "Bearer " + localStorage.getItem('token')}
		})
		.then((response) => {
			success();
		})
		.catch((error) => {

		});
	},

	// Special PUT method that tells the server to essentially add new color
	// groups while also deleting the old ones.  Not quite a POST or a DELETE, so
	// let's make it a PUT even when it's not.
	replaceColors: (id, payload, success, failure) => {

		axios.put(`${process.env.VUE_APP_ENDPOINT}/patterns/${id}/colors`, {
			headers: {"Authorization": "Bearer " + localStorage.getItem('token')}
		})
		.then((response) => {

		})
		.catch((error) => {

		});

	}
}
