<!-- Depending on whether not this is a mobile device, this will display either
	keyboard controls or touch controls in a paginated format. -->
<template>

	<div class="controls">
		<div v-if="!mobile">
			<MovingKeys v-if="page == 0"/>
			<LaunchingKeys v-if="page == 1"/>
			<PowerUpKeys v-if="page == 2"/>
			<PauseKeys v-if="page == 3" />
		</div>

		<div v-else>
			<MovingTouch v-if="page == 0"/>
			<LaunchingTouch v-if="page == 1"/>
			<PowerUpTouch v-if="page == 2"/>
			<PauseTouch v-if="page == 3"/>
		</div>

		<div class="flex-line m-h-1" v-if="context == 'first-time'">
			<Checkbox checkbox-id="noShow"
				label="Don't Show Again"
				v-model="noShow"/>
		</div>

		<div class="buttons">

			<button class="square-button" v-if="page > 0"
				@click="page = page - 1">
				<img src="../../assets/left-arrow-black.svg">
			</button>

			<button class="square-button" v-if="page < 3"
				@click="incPage">
				<img src="../../assets/right-arrow-black.svg">
			</button>

			<button v-if="lastPage || context == 'pause'" @click="$emit('play')">
				Play
			</button>

		</div>
	</div>

</template>

<script>
import MovingKeys from './MovingKeys.vue';
import MovingTouch from './MovingTouch.vue';
import LaunchingKeys from './LaunchingKeys.vue';
import LaunchingTouch from './LaunchingTouch.vue';
import PowerUpKeys from './PowerUpKeys.vue';
import PowerUpTouch from './PowerUpTouch.vue';
import PauseKeys from './PauseKeys.vue';
import PauseTouch from './PauseTouch.vue';
export default {
	name: "Controls",
	components: {
		LaunchingKeys,
		LaunchingTouch,
		MovingKeys,
		MovingTouch,
		PauseKeys,
		PauseTouch,
		PowerUpKeys,
		PowerUpTouch
	},
	props: ['context', 'mobile'],
	data: function() {
		return {
			page: 0,
			lastPage: false,
		}
	},

	methods: {

		incPage: function() {
			this.page ++;
			if (this.page == 3) {
				this.lastPage = true;
			}

		}

	}
}
</script>

<style lang="scss" scoped>
.controls {
	max-width: 300px;
}
.buttons {
	display: flex;
	justify-content: center;
}
</style>
