<!-- A component for displaying patterns for a given level.  Displays at the
	bottom, and can be minimized. -->
<template>
	<div class="level-patterns">

		<div class="thick-border">

			<TinySwatch v-for="pattern in patterns"
				:pattern="pattern"
				:context="context"
				:level="level"/>

			<!-- Add random placeholders at the end -->
			<TinySwatch v-for="n in randomPatterns"
				:random="true"
				:context="context"
				:level="level"/>

			<button v-if="!patterns.length && !randomPatterns.length && context != 'preview'" @click="pickPattern">
				Add First Pattern
			</button>

			<button class="square-button" v-else-if="patterns.length < 7 && context != 'preview'"
				@click="pickPattern">
				<img src="../../assets/plus-black.svg"/>
			</button>

		</div>

	</div>
</template>

<script>
import TinySwatch from '../patterns/TinySwatch.vue';
export default {
	name: "LevelPatterns",
	props: ['context', 'level'],
	inject: ['LevelEventBus', 'ModalEventBus'],
	components: { TinySwatch },

	methods: {

		// Responds to events from pattern pick modal where the user picks a
		// pattern.  This will use the normal event bus to tell the page to add a
		// different pattern to this level
		addPattern: function(pattern) {
			this.LevelEventBus.$emit('pattern', pattern);
			this.ModalEventBus.$emit('close');
		},

		pickPattern: function() {
			this.ModalEventBus.$emit('modal', {
				modal: 'ModalPatternPicker',
				props: {
					addPattern: this.addPattern
				}
			})
		}

	},

	computed: {

		// Retrieves all patterns from store associated with this level.
		patterns: function() {

			let patterns = [];

			if (!this.level) {
				return patterns;
			}

			this.$store.state.patternAssignments.forEach((assignment) => {

				if (assignment.levelID == this.level.id) {

					// Okay, find the matching pattern
					for(let i = 0; i < this.$store.state.patterns.length; i ++) {

						let pattern = this.$store.state.patterns[i];
						if (pattern.id == assignment.patternID) {

							let combo = Object.assign({}, pattern);
							combo.assignmentID = assignment.id;
							patterns.push(combo);
							return;
						}

					}

				}

			});

			return patterns;

		},

		randomPatterns: function() {

			if (this.context == "preview") {
				return 0;
			}

			return this.level.randomPatterns;
		}

	}
}
</script>

<style lang="scss" scoped>
.level-patterns {
	position: absolute;
	bottom: 0;
	width: 100%;
	display: flex;
	justify-content: center;

	& > div {
		display: inline-flex;
		align-items: center;
		margin: 10px;
		height: 60px;
		padding: 4px;
		background-color: #FFF;
	}
}

button, .button {
	border: 4px dashed #333;
	box-shadow: none;
	height: 40px;

	&.square-button {
		width: 40px;
	}

	img {
		height: 100%;
		width: 100%;
	}
}
</style>
