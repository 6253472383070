<!-- Uploads SVGs to be used as part of a pattern.  Naturally, quite important. -->
<template>

	<div style="display: flex; align-items: center; height: 100%;">

		<Loader v-if="loading"/>

		<form @submit.prevent class="pattern-upload" v-else-if="!parsedSVG">
			<!-- The most important part of the swatch is the pattern itself.  Though
				it may not be possible for what you're hoping to achieve, the easiest
				way to accomplish this is through a file upload.  Don't be upset if you
				need to copy/paste the text though. -->

			<label for="UploadPattern">
				Click here to choose a pattern, or drag-and-drop.
			</label>

			<input type="file" id="UploadPattern" @change="readSVG">
		</form>

		<form @submit.prevent class="pattern-preview" v-else>
			<label for="UploadPattern" v-html="parsedSVG"/>

			<input type="file" id="UploadPattern" @change="readSVG">
		</form>

	</div>

</template>

<script>
import HandleColorMixin from '../mixins/patterns/handle-color';
import Loader from './Loader.vue';
import PatternRequest from '../network/patterns';
import axios from 'axios';
export default {
	name: "PatternUploader",
	props: ['pattern', 'loading'],
	inject: ['PatternEventBus'],
	components: { Loader },
	mixins: [ HandleColorMixin ],
	data: function() {
		return {
			colors: [],
			file: null,
			parsedSVG: ''
		}
	},

	methods: {

		// Does exactly as advertised.  Special bit is that it also parses the SVG
		// for group tags and emits said group tags to parent.
		readSVG: function(evt) {

			evt.preventDefault();
			this.file = evt.srcElement.files[0];

			// Go ahead and set up our regular expressions.  We only care about groups
			const groupRegExp = /<g[^>]*>((?!<\/g>)\s|(?!<\/g>).)*<\/g>/g;
			const groupOpeningOnlyRegExp = /<g (.*)\>/g;
			const IDRegExp = /id="([^"]*)"/i;
			const fillRegExp = /fill="#([a-f0-9]{0,6})"|style="[^"]*fill:#([a-f0-9]{0,6})[^"]*"/i;
			const backgroundRegExp = /(background)|(bg)/i;

			// Go ahead and generate a preview of it and read the colors
			let reader = new FileReader();
			reader.addEventListener("load", (evt) => {

				// Here's the pattern, as raw text
				this.parsedSVG = evt.target.result;

				// Now, then, let's pick apart each group and generate palettes based on
				// whatever fill is present, and ideally name the palettes
				const groups = this.parsedSVG.match(groupRegExp);
				if (groups == null) { return; };

				for(let i = 0; i < groups.length; i ++) {

					const group = groups[i];
					let groupID;
					let groupColor;
					let isBackground = false;

					const groupIDMatch = group.match(IDRegExp);
					if (groupIDMatch) {

						groupID = groupIDMatch[1];

						// A special case is if the group in question is called "background"
						// or "bg", because background groups are special.  Flag if so
						const backgroundMatch = groupID.match(backgroundRegExp);
						if (backgroundMatch) {
							isBackground = true;
						}

					}

					const groupColorMatch = group.match(fillRegExp);
					if (groupColorMatch) {
						// So, the color is in there somewhere, but it could be in index 1
						// or two.  We'll default to the first if it exists, but settle for
						// the second
						groupColor = groupColorMatch[1] ? groupColorMatch[1] : groupColorMatch[2];
					}

					// That's all we need.  So, let's create a new color using the
					// information provided
					this.colors.push({
						name: groupID,
						color: groupColor,
						isBackground
					});

				}

				// Once we're done here, emit an event to the top with the new file and
				// color groups.  Let the parent figure things out
				this.$emit('svg', {file: this.file, colors: this.colors});

			}, false);
			reader.readAsText(this.file);

		},

		// Responds to events where the user finishes updating a color group.  This
		// takes the SVG text data that we have and re-uploads it to the server.
		// Parent normally handles network requests, but this it's probably best
		// we handle this here.
		updateSVG: function() {

			// First, we'll need to take the parsedSVG and convert it into a file.
			const fileSVG = new File([this.parsedSVG], `${this.pattern.id}.svg`, {type: 'image/svg+xml'});

			// Then, we'll make a request to upload this to the server
			let store = this.$store;
			let _this = this;
			PatternRequest.postSVG(this.pattern.id, fileSVG, (pattern) => {
				_this.loading = false;
				store.commit('addPatterns', [pattern]);
			});
		}

	},

	mounted: function() {

		if (this.pattern && this.pattern.filePath) {
			// Grab it using axios
			axios.get(this.pattern.filePath)
			.then((response) => {
				this.parsedSVG = response.data;
			})
			.catch((error) => {

			})
		}

		if (this.PatternEventBus) {
			this.PatternEventBus.$on('color', this.handleColor);
			this.PatternEventBus.$on('update-color', this.updateSVG);
		}

	}
}
</script>

<style lang="scss" scoped>
.pattern-preview {
	margin: 0;
	padding: 0;
	border-radius: 10px;
	max-width: 200px;
	max-height: 200px;
	box-shadow: 2px 2px 2px #BBB;
	overflow: hidden;
}

.pattern-upload {
	height: 100%;
	display: flex;
	align-items: center;
	margin: 0 auto;
	padding-left: 10px;
	padding-right: 10px;
	border: 4px dashed black;

	input[type="file"] {
		width: 0.1px;
		height: 0.1px;
		opacity: 0;
		overflow: hidden;
		position: absolute;
		z-index: -1;
	}

	label {
		margin: 0 auto;
		font-weight: 700;
		cursor: pointer;
	}
}
</style>
