//
// Portion of package containing POST methods
/////

import axios from 'axios';

export default {

	// Adds a new category, returns back category with new hashID
	category: (payload, success, failure) => {

		axios.post(`${process.env.VUE_APP_ENDPOINT}/pattern-categories`, payload)
		.then((response) => {

			success({
				id: response.data.data.id,
				patternID: response.data.data.attributes.pattern_id,
				name: response.data.data.attributes.name,
				backgroundColor: response.data.data.attributes.background_color,
				textColor: response.data.data.attributes.text_color
			});

		})
	},

	categoryToPattern: (ids, success, failure) => {

		const url = `${process.env.VUE_APP_ENDPOINT}/pattern-categories/${ids.categoryID}/patterns/${ids.patternID}`;
		axios.post(url)
		.then((response) => {

			// Just knowing it got assigned is good enough
			success();

		})
		.catch((error) => {
			// TODO
		})
	}

}
