//
// Portion of package that handles POST requests for checkout.
/////

import axios from 'axios';

export default {

	checkout: (payload, posterImg, posterPreview, success, failure) => {

		let formData = new FormData();
		formData.append("payload", payload);
		formData.append("poster", posterImg, "poster.png");
		formData.append("preview", posterPreview, "preview.png");
		axios.post(`${process.env.VUE_APP_ENDPOINT}/orders`, formData, {
			headers: {"Authorization": "Bearer " + localStorage.getItem('token')}
		})
		.then((response) => {

			// Should get an orderID back.  Go ahead pass it in to success
			const orderID = response.data.data.id;
			console.log(orderID);
			success(orderID);
		})
		.catch((error) => {

		});
	}

}
