<!-- Shows up the first time a user starts a new round.  Continues to show up
	 on new rounds unless the user opts out. -->
<template>

	<div class="how-to-play">

		<div>

			<Controls
				:context="'first-time'"
				:mobile="mobile"
				@play="$emit('play')"/>

			<div class="flex-line m-h-2">
				<Checkbox checkbox-id="noShow"
					label="Don't Show Again"
					v-model="removeControls"/>
			</div>

		</div>

	</div>

</template>

<script>
import Controls from './Controls.vue';
import Checkbox from '../forms/Checkbox.vue';
export default {
	name: "ControlOverlay",
	components: { Checkbox, Controls },
	props: ['mobile'],
	data: function() {
		return {
			play: false,
			removeControls: localStorage.getItem('remove-controls'),
		}
	},

	watch: {

		removeControls: function() {
			localStorage.setItem('remove-controls', this.removeControls);
		}
	}
}
</script>

<style lang="scss" scoped>
.how-to-play {
	height: 100%;
	width: 100%;
	background-color: rgba(255, 255, 255, 0.90);
	z-index: 1000;
	position: absolute;
	top: 0;
	left: 0;
	overflow-y: scroll;
	padding: 50px 8px;

	& > div {
		max-width: 300px;
		margin: 0 auto;
	}
}

.flex-line {
	justify-content: center;
}
</style>
