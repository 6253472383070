//
// Portion of package for handling POST requests involving level colors
/////

import axios from 'axios';

export default {

	color: (payload, success, failure) => {

		axios.post(`${process.env.VUE_APP_ENDPOINT}/level-colors`, payload, {
			headers: {"Authorization": "Bearer " + localStorage.getItem('token')}
		})
		.then((response) => {

			success({
				id: response.data.data.id,
				levelID: response.data.data.attributes.level_id,
				colorID: response.data.data.attributes.pattern_color_id,
				color: response.data.data.attributes.color
			});

		})
		.catch((error) => {
			// TODO
		});
	}

}
