//
// Package for requests involving orders past and present
/////

import Get from './get';
import Put from './put';
import Delete from './delete';

export default {

	getOrder: Get.order,
	getOrders: Get.orders,

	updateShipping: Put.shipping,

	cancelOrder: Delete.order,

}
