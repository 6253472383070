//
// A package containing network methods for pattern categories.
/////

import Post from './post';
import Get from './get';
import Put from './put';
import Delete from './delete';

export default {

	addCategory: Post.category,
	addPatternToCategory: Post.categoryToPattern,

	getAllCategories: Get.categories,
	getCategoriesFromPattern: Get.categoriesFromPattern,

	updateCategory: Put.category,

	deleteCategory: Delete.category,
	removePatternFromCategory: Delete.patternFromCategory

}
