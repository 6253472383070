<!-- A pattern swatch that fits into a constrained space, typically a level
	being edited.  Clicking on it can bring up controls to change it within the
	context of the level or remove it from the level -->
<template>
	<div class="tiny-swatch">

		<div class="pattern" v-if="random" @click="controls = !controls">
			<img src="dice.svg"/>
		</div>

		<div class="pattern" v-else v-html="parsedSVG" @click="controls = !controls"/>

		<div v-if="controls" class="controls">

			<button class="square-button" @click="inspectPattern" v-if="!random">
				<img src="../../assets/inspect.svg"/>
			</button>

			<button class="square-button" @click="pickRandom" v-if="context == 'preview'">
				<img src="dice.svg"/>
			</button>

			<button class="square-button" @click="removePattern" v-if="context != 'preview'">
				<img src="../../assets/times.svg"/>
			</button>

		</div>

	</div>
</template>

<script>
import axios from 'axios';
import HandleColorMixin from '../../mixins/patterns/handle-color';
export default {
	props: ['context', 'level', 'pattern', 'random'],
	mixins: [ HandleColorMixin ],
	inject: ['ModalEventBus', 'LevelEventBus', 'RandomEventBus'],
	data: function() {
		return {
			controls: false,
			parsedSVG: '',
			loading: true,
		}
	},

	computed: {

		colors: function() {

			let colors = [];
			this.$store.state.patternColors.forEach((color) => {

				if (color.patternID != this.pattern.id) {
					return;
				}

				for(let i = 0; i < colors; i ++) {
					if (colors[i].id == color.id || colors[i].colorID == color.id) {
						return;
					}
				}

				colors.push(color);

			});

			return colors;

		},

		// This pattern might use specific colors for this level.  Let's find them
		levelColors: function() {

			let colors = [];
			this.$store.state.levelColors.forEach((color) => {

				if (color.assignmentID == this.pattern.assignmentID) {
					colors.push(color);
				}

			});

			return colors;

		}

	},

	methods: {

		inspectPattern: function() {
			this.controls = false;
			this.ModalEventBus.$emit('modal', {
				modal: 'ModalPatternCustomizer',
				props: {
					level: this.level,
					pattern: this.pattern,
					context: this.context,
				}
			});
		},

		removePattern: function() {
			this.controls = false;
			if (this.random) {
				this.LevelEventBus.$emit('remove-pattern', 'random');
				return;
			}
			this.LevelEventBus.$emit('remove-pattern', this.pattern);
		},

		pickRandom: function() {

			if (this.RandomEventBus) {
				this.RandomEventBus.$emit('random', this.pattern.assignmentID);
			}

		},

		updateSVG: function() {

			if (this.loading) {
				return;
			}

			// Is this pattern random, and are we in a preview context?  If so,
			// then for each group we are going to generate a random color.  These
			// will serve as level colors.
			if (!this.levelColors.length && this.context == 'preview' && this.pattern.random) {

				// Okay, what we're going to do is find every single group in the
				// pattern and replace it's fill with, well, something random
				const groupRegExp = /<g[^>]*>((?!<\/g>)\s|(?!<\/g>).)*<\/g>/g;
				const fillRegExp = /fill="#([a-f0-9]{0,6})"|style="[^"]*fill:#([a-f0-9]{0,6})[^"]*"/i;
				const IDRegExp = /id="([^"]*)"/i;

				const groups = this.parsedSVG.match(groupRegExp);

				const levelColors = [];

				let randomColors = [];

				groups.forEach((group, index) => {

					// Let's generate our color channels
					let red = Math.round(Math.random() * 256).toString(16);
					if (red.length < 2) {
						red = '0' + red;
					}

					let green = Math.round(Math.random() * 256).toString(16);
					if (green.length < 2) {
						green = '0' + green;
					}

					let blue = Math.round(Math.random() * 256).toString(16);
					if (blue.length < 2) {
						blue = '0' + blue;
					}

					const groupIDMatch = group.match(IDRegExp);

					for(let i = 0; i < this.colors.length; i ++) {

						if (this.colors[i].name == groupIDMatch[1]) {

							randomColors.push({
								id: groupIDMatch[1] + this.pattern.assignmentID,
								name: groupIDMatch[1],
								levelID: this.level.id,
								color: `${red + green + blue}`,
								assignmentID: this.pattern.assignmentID,
								colorID: this.colors[i].id,
							});
							return;

						}

					}


				});

				this.$store.commit('addLevelColors', randomColors);

			}

			if (!this.levelColors.length) {

				// There are no custom colors, exit early
				return;

			}

			// Otherwise, what we're going to do is plumb the store for their respective
			// pattern colors.  Once we found a match, we're gonna update the
			// parsedSVG
			this.levelColors.forEach((color) => {

				if (color.colorID === undefined) {

					// Random color.  Just jump right into handleColor
					this.handleColor(color, color.color);
					return;

				}

				for(let i = 0; i < this.$store.state.patternColors.length; i ++) {

					const patternColor = this.$store.state.patternColors[i];
					if (color.colorID == patternColor.id) {

						// Okay, match found.  Now let's update parsedSVG.  We'll cheat a
						// little and use handleColor from the mixin
						this.handleColor(patternColor, color.color);
						return;

					}
				}

			});

		}
	},

	mounted: function() {

		axios.get(this.pattern.filePath)
		.then((response) => {
			this.loading = false;
			this.parsedSVG = response.data;
			this.updateSVG();
		})
		.catch((error) => {

		});

	},

	watch: {

		levelColors: function(newColors, oldColors) {

			if (newColors.length != 0) {
				this.updateSVG();
			}

		},

		pattern: function(newPattern, oldPattern) {

			if (newPattern.filePath != oldPattern.filePath) {

				this.loading = true;
				axios.get(this.pattern.filePath)
				.then((response) => {
					this.parsedSVG = response.data;
					this.loading = false;
					this.updateSVG();
				})
				.catch((error) => {

				});

			} else {
				this.updateSVG();
			}


		}
	}
}
</script>

<style lang="scss" scoped>
.tiny-swatch {
	height: 40px;
	display: flex;
	align-items: center;
}

.controls {
	height: 40px;
	display: inline-block;

	button {
		height: 40px;
		width: 40px;
		border: 4px dashed #333;
		margin: 0 4px;
		box-shadow: none;

		img {
			display: block;
			width: 100%;
			height: 100%;
		}
	}
}

.pattern {
	width: 40px;
	height: 40px;
	cursor: pointer;
	display: inline-block;
	box-shadow: 2px 2px 2px #BBB;
	margin: 0 4px;

	img {
		width: 30px;
		height: 30px;
		margin: 5px;
		display: block;
	}

	&::v-deep svg {
		height: 100%;
		width: 100%;
	}

}
</style>
