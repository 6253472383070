<!-- A slide-out set of options for customizing a poster.  Namely, you can
	change the size / dimensions of a poster and change its orientation.  You can
	also quick-center and scale to fit a canvas through here. -->
<template>

	<div class="editor-controls"
		:class="{'hide': hide}">

		<button class="open-close square-button"
			:class="{'opened': hide}"
			@click="$emit('toggle')">
			<img src="../../assets/left-arrow-black.svg" v-if="hide">
			<img src="../../assets/right-arrow-black.svg" v-else/>
		</button>

		<div class="content">

			<div class="options-header">
				<h2 class="text-center">Poster Options</h2>

				<button class="square-button"
					@click="$emit('toggle')">
					<img src="../../assets/left-arrow-black.svg" v-if="hide">
					<img src="../../assets/right-arrow-black.svg" v-else/>
				</button>
			</div>

			<hr class="m-w-1 m-h-2"/>

			<!-- Section for poster orientation -->
			<section>

				<h3 class="text-center">Orientation</h3>

				<div class="duo-buttons">

					<button
						:class="{'btn-red': orientation == 'portrait'}"
						@click="$emit('orientation', 'portrait')">
						Portrait
					</button>

					<button
						:class="{'btn-red': orientation == 'landscape'}"
						@click="$emit('orientation', 'landscape')">
						Landscape
					</button>

				</div>

			</section>

			<!-- Now for the size options.  A flexbox will do. -->
			<!--
			<section>

				<h3>Units</h3>

				<div class="duo-buttons">

					<button class="thick-border"
						:class="{'selected': units == 'in'}"
						@click="$emit('units', 'in')">
						Inches
					</button>

				</div>

			</section>
		-->

			<section>

				<h3 class="text-center">Sizes</h3>

				<div class="sizes" v-if="units == 'in'">

					<button
						:class="{'btn-yellow': size == '8x10'}"
						@click="$emit('size', '8x10')">
						8" x 10"
					</button>

					<button
						:class="{'btn-yellow': size == '18x24'}"
						@click="$emit('size', '18x24')">
						18" x 24"
					</button>

					<button
						:class="{'btn-yellow': size == '24x36'}"
						@click="$emit('size', '24x36')">
						24" x 36"
					</button>

				</div>

				<div class="sizes" v-if="units == 'cm'">

					<button
						:class="{'btn-yellow': size == '21x30'}"
						@click="$emit('size', '21x30')">
						21cm x 30cm
					</button>

					<button
						:class="{'btn-yellow': size == '50x70'}"
						@click="$emit('size', '50x70')">
						50cm x 70 cm
					</button>

					<button
						:class="{'btn-yellow': size == '70x100'}"
						@click="$emit('size', '70x100')">
						70cm x 100cm
					</button>

				</div>

			</section>

			<section>

				<h3 class="text-center">Material</h3>

				<div class="sizes">

					<button
						:class="{'btn-blue': material == 'matte'}"
						@click="$emit('material', 'matte')">
						Matte
					</button>

					<button
						:class="{'btn-blue': material == 'glossy'}"
						@click="$emit('material', 'glossy')">
						Glossy
					</button>

					<!--
					<button class="thick-border"
						:class="{'selected': material == 'canvas'}"
						@click="$emit('material', 'canvas')">
						Canvas
					</button>
				-->

				</div>

				<p v-if="material == 'matte'" class="text-center">Your poster will be
					printed on durable, thick, quality paper with a flat finish.</p>

				<p v-else-if="material == 'glossy'" class="text-center">Your poster will
					be printed on durable, thick, quality paper paper with a shiny, glossy
					finish.</p>

				<p v-else-if="material == 'canvas'" class="text-center">Your poster will
					be printed on a cloth canvas.</p>

			</section>
		</div>
	</div>
</template>

<script>
export default {
	name: "PosterControls",
	props: ['hide', 'size', 'orientation', 'units', 'material']
}
</script>

<style lang="scss" scoped>

.editor-controls {
	top: 58px;
	bottom: 4px;
	right: 4px;
	background-color: #FFF;
	position: absolute;
	width: 25%;
	border: 6px solid black;
	z-index: 1000;

	&.hide {
		right: -25%;
	}
}

@media(max-width: 768px) {

	.editor-controls {
		width: calc(100% - 8px);

		&.hide {
			right: -100%;
		}
	}

}

.content {
	height: 100%;
	overflow-y: scroll;
}

.options-header {
	display: flex;
	align-items: center;
	margin: 20px 0 10px;

	h2, button {
		margin: 0;
	}

	h2 {
		flex-grow: 1;
	}

	button {
		margin-right: 1.6rem;
	}
}

@media(min-width: 768px) {
	.options-header {
		button {
			display: none;
		}
	}
}

.open-close {
	position: absolute;
	width: 45px;
	height: 45px;
	background-color: #FFF;
	top: 1.6rem;
	left: -63px;
	cursor: pointer;
}

@media(max-width: 768px) {
	.open-close {
		left: -7.1rem;
	}
}

.block-button {
	margin: 10px;
}

.sizes {
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	justify-content: center;

	button {
		flex-grow: 1;
	}
}

.duo-buttons {
	display: flex;
	align-items: center;

	button {
		display: block;
		width: 100%;
	}
}

section {
	margin: 0 0.8rem 3.2rem;
	padding: 0.8rem;

	h3 {
		margin: 10px 0;
	}
}

.square-button {
	border: 6px solid black;
}
</style>
