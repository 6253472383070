<!-- Your standard footer.  Contains a link to GlyphGlyder as well as some
	social links. -->
<template>
	<div class="footer">
		<div class="container">
			<p>&#169; 2020 <a href="https://glyphglyder.com">Glyph Glyder</a> LLC</p>
		</div>
	</div>
</template>

<script>
export default {
	name: "Home"
}
</script>

<style lang="scss" scoped>
.footer {
	border-top: 1px solid #BEBEBE;
	background-color: #FFF;
}

.container {
	max-width: 1200px;
	margin: 0 auto;
}
</style>
