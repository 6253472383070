/**
 * Portion of store for managing levels.
 * * * * */

export default {

	// Finished level is a little bit weird.  It's not a "proper level" per say,
	// but rather an array of cells (rects) from a cell that was just completed
	// by a user.  It's stored here in the store for easy transport across pages
	addFinishedLevel: (state, payload) => {

		state.finishedLevel = payload;
		
	},

	// Adds levels to the store
	addLevels: (state, payload) => {

		payload.forEach((level) => {

			for(let i = 0; i < state.levels.length; i ++) {
				if (state.levels[i].id == level.id) {
					state.levels.splice(i, 1, level);
					return;
				}
			}

			state.levels.push(level);

		});

	}
}
