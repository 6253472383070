//
// Portion of package for handling PUT requests.
/////

import axios from 'axios';

export default {

	shipping: (orderID, payload, success, failure) => {
		axios.put(`${process.env.VUE_APP_ENDPOINT}/orders/${orderID}`, payload, {
			headers: {"Authorization": "Bearer " + localStorage.getItem('token')}
		})
		.then((response) => {
			success();
		});
	}
}
