//
// Responsible for defining the boundaries a poster given a set of dimensions
/////

const INCH = 40;
const CENTIMETER = 2.54;
const STROKE_WEIGHT = 12;

export default class PosterBoundary {

	constructor(sketch) {

		// Just define a midpoint, that's it
		this.midpoint = {
			x: sketch.width / 2,
			y: sketch.height / 2
		}

	}

	draw(width, height, offset, zoom, units, sketch) {

		// First, let's decide what untis we're going to use
		const UNIT = units == 'in' ? INCH : INCH / CENTIMETER;

		// First, let's draw a white background that covers the inside
		sketch.strokeWeight(0);
		sketch.fill('#FFFFFF');
		sketch.strokeCap(sketch.SQUARE);
		sketch.rect(
			this.midpoint.x - (UNIT * zoom * width) / 2 + offset.x * zoom,
			this.midpoint.y - (UNIT * zoom * height) / 2 + offset.y * zoom,
			UNIT * zoom * width,
			UNIT * zoom * height
		);

		const weight = units == 'in' ? STROKE_WEIGHT : STROKE_WEIGHT / 1.5
		sketch.strokeWeight(weight * zoom);

		// To make things simple, a default rect width (80 pixels) is an inch

		// So, let's draw the corners first, top-left going counter-clockwise
		const topLeft = {
			x: this.midpoint.x - (UNIT * zoom * width) / 2,
			y: this.midpoint.y - (UNIT * zoom * height) / 2
		}

		sketch.line(
			topLeft.x + offset.x * zoom - (weight) * zoom,
			topLeft.y + offset.y * zoom - (weight / 2) * zoom,
			topLeft.x + UNIT * zoom + offset.x * zoom,
			topLeft.y + offset.y * zoom - (weight / 2) * zoom
		);

		sketch.line(
			topLeft.x + offset.x * zoom - (weight / 2) * zoom,
			topLeft.y + offset.y * zoom - (weight) * zoom,
			topLeft.x + offset.x * zoom - (weight / 2) * zoom,
			topLeft.y + UNIT * zoom + offset.y * zoom
		);

		// Okay, let's draw a line across the top
		let multiplier = (width - 2) / (width - 3);
		for(let i = 0; i < width - 3; i ++) {

			// Only draw on odd
			if (i % 2 != 0) {
				sketch.line(
					topLeft.x + offset.x * zoom + (UNIT * multiplier * zoom) + (i * UNIT * multiplier) * zoom,
					topLeft.y + offset.y * zoom - (weight / 2) * zoom,
					topLeft.x + offset.x * zoom + (UNIT * multiplier * zoom) + ((i + 1) * UNIT * multiplier) * zoom,
					topLeft.y + offset.y * zoom - (weight / 2) * zoom
				)
			}
		}

		// Alright, let's do the top right
		const topRight = {
			x: this.midpoint.x + (UNIT * zoom * width) / 2,
			y: this.midpoint.y - (UNIT * zoom * height) / 2
		}

		sketch.line(
			topRight.x + offset.x * zoom + weight * zoom,
			topRight.y + offset.y * zoom - (weight / 2) * zoom,
			topRight.x - UNIT * zoom + offset.x * zoom,
			topRight.y + offset.y * zoom - (weight / 2) * zoom
		);

		sketch.line(
			topRight.x + offset.x * zoom + (weight / 2) * zoom,
			topRight.y + offset.y * zoom - weight * zoom,
			topRight.x + offset.x * zoom + (weight / 2) * zoom,
			topRight.y + UNIT * zoom + offset.y * zoom
		);

		multiplier = (height - 2) / (height - 3);
		for(let i = 0; i < height - 3; i ++) {

			// Only draw on odd
			if (i % 2 != 0) {
				sketch.line(
					topRight.x + offset.x * zoom + (weight / 2) * zoom,
					topRight.y + offset.y * zoom + (UNIT * multiplier * zoom) + (i * UNIT * multiplier) * zoom,
					topRight.x + offset.x * zoom + (weight / 2) * zoom,
					topRight.y + offset.y * zoom + (UNIT * multiplier * zoom) + ((i + 1) * UNIT * multiplier) * zoom
				)
			}
		}


		// Now let's do the bottom right
		const bottomRight = {
			x: this.midpoint.x + (UNIT * zoom * width) / 2,
			y: this.midpoint.y + (UNIT * zoom * height) / 2,
		}

		sketch.line(
			bottomRight.x + offset.x * zoom + weight * zoom,
			bottomRight.y + offset.y * zoom + (weight / 2) * zoom,
			bottomRight.x - UNIT * zoom + offset.x * zoom,
			bottomRight.y + offset.y * zoom + (weight / 2) * zoom
		);

		sketch.line(
			bottomRight.x + offset.x * zoom + (weight / 2) * zoom,
			bottomRight.y + offset.y * zoom + weight * zoom,
			bottomRight.x + offset.x * zoom + (weight / 2) * zoom,
			bottomRight.y - UNIT * zoom + offset.y * zoom
		);

		multiplier = (width - 2) / (width - 3);
		for(let i = 0; i < width - 3; i ++) {

			// Only draw on odd
			if (i % 2 != 0) {
				sketch.line(
					bottomRight.x + offset.x * zoom - (UNIT * multiplier * zoom) - (i * UNIT * multiplier) * zoom,
					bottomRight.y + offset.y * zoom + (weight / 2) * zoom,
					bottomRight.x + offset.x * zoom - (UNIT * multiplier * zoom) - ((i + 1) * UNIT * multiplier) * zoom,
					bottomRight.y + offset.y * zoom + (weight / 2) * zoom
				)
			}
		}


		const bottomLeft = {
			x: this.midpoint.x - (UNIT * zoom * width) / 2,
			y: this.midpoint.y + (UNIT * zoom * height) / 2,
		}

		sketch.line(
			bottomLeft.x + offset.x * zoom - weight * zoom,
			bottomLeft.y + offset.y * zoom + (weight / 2) * zoom,
			bottomLeft.x + UNIT * zoom + offset.x * zoom,
			bottomLeft.y + offset.y * zoom + (weight / 2) * zoom
		);

		sketch.line(
			bottomLeft.x + offset.x * zoom - (weight / 2) * zoom,
			bottomLeft.y + offset.y * zoom + weight * zoom,
			bottomLeft.x + offset.x * zoom - (weight / 2) * zoom,
			bottomLeft.y - UNIT * zoom + offset.y * zoom
		);

		multiplier = (height - 2) / (height - 3);
		for(let i = 0; i < height - 3; i ++) {

			// Only draw on odd
			if (i % 2 != 0) {
				sketch.line(
					bottomLeft.x + offset.x * zoom - (weight / 2) * zoom,
					bottomLeft.y + offset.y * zoom - (UNIT * multiplier * zoom) - (i * UNIT * multiplier) * zoom,
					bottomLeft.x + offset.x * zoom - (weight / 2) * zoom,
					bottomLeft.y + offset.y * zoom - (UNIT * multiplier * zoom) - ((i + 1) * UNIT * multiplier) * zoom
				)
			}
		}


	}

}
