<!-- Displays a single pattern in the context of a page. -->
<template>

	<div class="new-pattern">

		<div style="width: 100%" v-if="pattern">

			<EditablePatternName
				:pattern="pattern"
				v-model="name"
				@save="saveName"
				@delete="deletePattern"
				@publish="publish"/>

			<PatternCategoryCluster
				:categories="categories">
				<button class="dash-button" @click="triggerCategoryModal">
					Add Category
				</button>
			</PatternCategoryCluster>

			<div class="action">

				<div class="pattern">

					<PatternUploader
						:pattern="pattern"
						@svg="handleSVG"/>

				</div>

				<!-- Finally a list of colors used by the pattern -->
				<div class="colors">
					<ColorList :colors="colorList"/>
				</div>

			</div>

		</div>

	</div>

</template>

<script>
import ColorList from '../components/colors/ColorList.vue';
import EditablePatternName from '../components/patterns/EditablePatternName.vue';
import PatternCategoryCluster from '../components/pattern-categories/PatternCategoryCluster.vue';
import PatternRequest from '../network/patterns';
import PatternCategoryRequest from '../network/pattern-categories';
import PatternColorRequest from '../network/pattern-colors';
import PatternUploader from '../components/PatternUploader.vue';
import Vue from 'vue';
export default {
	name: "Pattern",
	props: ['patternID'],
	inject: ['ModalEventBus'],
	components: {
		ColorList,
		EditablePatternName,
		PatternCategoryCluster,
		PatternUploader
	},
	data: function() {
		return {
			name: '',
			colors: [],
			PatternEventBus: new Vue()
		}
	},

	computed: {

		// Retrieves all categories assigned to this pattern
		categories: function() {

			const categories = [];

			if (!this.pattern.categoryIDs) {
				return categories;
			}

			this.pattern.categoryIDs.forEach((categoryID) => {

				for(let i = 0; i < this.$store.state.patternCategories.length; i ++) {
					const category = this.$store.state.patternCategories[i];

					if (category.id == categoryID) {
						categories.push(category);
						return;
					}

				}
			});

			return categories;

		},

		colorList: function() {

			if (this.colors.length) {
				return this.colors;
			}
			return this.patternColors;

		},

		pattern: function() {

			for(let i = 0; i < this.$store.state.patterns.length; i ++) {
				let pattern = this.$store.state.patterns[i];
				if (pattern.id == this.patternID) {
					return pattern;
				}
			}

			return false;

		},

		patternColors: function() {

			let colors = [];

			for(let i = 0; i < this.$store.state.patternColors.length; i ++) {
				let color = this.$store.state.patternColors[i];
				if (color.patternID == this.patternID) {
					colors.push(color);
				}
			}

			return colors;

		}

	},

	methods: {

		// Deletes the pattern, naturally
		deletePattern: function() {

			this.$store.commit('removePattern', this.patternID);
			PatternRequest.deletePattern(this.patternID, () => {

			});
			this.$router.push({name: 'PatternCatalogue'});

		},

		// Handles evens bubbled up by the PatternUploader.  Will attempt to update
		// the pattern both with the new SVG and with whatever new color groups it
		// contains
		handleSVG: function(event) {

			// Order doesn't really matter.  I suppose let's make a call to upload
			//  the SVG first
			let store = this.$store;
			PatternRequest.postSVG(this.patternID, event.file, (pattern) => {
				store.commit('addPatterns', pattern);
			});

			// And then update the color groups
			let data = {
				data: {
					type: "pattern",
					id: this.patternID,
					relationships: {
						pattern_colors: {
							data: []
						}
					}
				},
				included: []
			}

			event.colors.forEach((color, index) => {

				data.data.relationships.pattern_colors.data.push({
					id: `${index}`,
					type: "pattern_colors"
				});

				data.included.push({
					id: `${index}`,
					type: "pattern_colors",
					attributes: {
						name: color.name,
						color: color.color,
						background: color.isBackground
					}
				});

			});

			let payload = JSON.stringify(data);

			PatternRequest.replaceColorGroups(this.patternID, payload, (pattern, colors) => {
				store.commit('addPatterns', [pattern]);
				store.commit('addColors', colors);
			});

		},

		// Fires off a request to update this pattern's publish state to the
		// opposite of what it is currently
		publish: function() {

			// Go ahead and update things locally first
			let pattern = Object.assign(this.pattern, {published: !this.published});
			this.$store.commit('addPatterns', [pattern]);

			// Then update things server-side
			let payload = JSON.stringify({
				data: {
					id: pattern.id,
					type: "pattern",
					attributes: {
						published: `${pattern.published}`
					}
				}
			});

			PatternRequest.updatePattern(pattern.id, payload, () => {
				// TODO
			})
		},

		// Used to update the name of a pattern
		saveName: function() {

			// Go ahead and optimistically update the pattern client-side
			let pattern = Object.assign(this.pattern, {name: this.name});
			this.$store.commit('addPatterns', [pattern]);

			// Now construct a simple payload and send to the server
			let payload = JSON.stringify({
				data: {
					id: pattern.id,
					type: "pattern",
					attributes: {
						name: this.name
					}
				}
			});

			// And make a request.  Don't worry about getting anything back but a
			// simple acknowledgement that things worked
			PatternRequest.updatePattern(pattern.id, payload, () => {
				// TODO
			});

		},

		// Emits modal event through event bus.
		triggerCategoryModal: function() {
			this.ModalEventBus.$emit('modal', {
				modal: 'ModalPatternCategories',
				props: {
					pattern: this.pattern.id
				}
			})
		},

		// Updates a color group.  Specifically, updates the color part.
		updateColor: function(color) {

			// First, the color should have already been updated store side.  Let's
			// move right into making the request.
			let payload = JSON.stringify({
				data: {
					id: color.id,
					type: "pattern_color",
					attributes: {
						color: color.color
					}
				}
			});

			// Now let's send off our request.
			PatternColorRequest.updateColor(color.id, payload, () => {
				// TODO
			})

		}

	},

	// Grab the pattern, setup event listeners for bus
	mounted: function() {

		let store = this.$store;
		PatternRequest.getPattern(this.patternID, (pattern, colors) => {

			store.commit('addPatterns', [pattern]);
			store.commit('addPatternColors', colors);

		});

		// Go ahead and also get the categories
		PatternCategoryRequest.getCategoriesFromPattern(this.patternID, (categories, pattern) => {

			store.commit('addPatternCategories', categories);
			store.commit('addPatterns', [pattern]);

		});

		this.PatternEventBus.$on('update-color', this.updateColor);

	},

	provide: function() {
		return {
			PatternEventBus: this.PatternEventBus
		}
	}
}
</script>

<style lang="scss" scoped>
.new-pattern {
	display: flex;
	align-items: center;
	width: 100%;
	height: 100vh;
}

.action {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: stretch;
}

.pattern, .colors {
	width: 30%;
	margin: 12px;
}

.pattern {
	display: flex;
	align-items: center;
	justify-content: center;

	&:after {
		content: "";
		padding-bottom: 100%;
		display: block;
	}

}

.editable-pattern-name {
	width: 60%;
	padding: 0 -24px;
	margin: 0 auto;
}

.color-list {
	height: 100%;
	overflow-y: scroll;
}
</style>
