<!-- Component for displaying order information in a list context.  Displays
	only the bare essentials, namely orderID, date of of placement, status, and a
	small preview image -->
<template>

	<div class="paper flex-line p-1 m-2">

		<div class="thumbnail-container">
			<img :src="order.previewURL"/>
		</div>

		<p class="m-l-2 sans-serif">
			<router-link class="strong"
				:to="{name: 'Order', params: {orderID: order.id}}"
				@click.native="$emit('change-to')">
				{{ order.id }}
			</router-link>

			<strong>{{ order.status }}</strong>
		</p>

	</div>

</template>

<script>
export default {
	name: "OrderListItem",
	props: ['order'],

	computed: {

		// Converts placed on value into an easily readable date
		placedOnDate: function() {

		}

	}
}
</script>
