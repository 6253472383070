<!-- It's a simple success page.  Doesn't really do anything other than let the
	user know that things are good -->
<template>

	<div>

		<h1 class="text-center">Order Complete</h1>

		<p class="text-center">Your order number is
			<router-link :to="{name: 'Order', params: {orderID: orderId}}">
				<strong>{{ orderId }}</strong>
			</router-link>.  You can check the progress of your
			order at any time by going to your orders page.</p>

		<p class="text-center">You'll also be receiving an email shortly confirming
			that your order has been placed, as well as a follow-up email in the near
			future when your order has been shipped.</p>

		<p class="text-center">Thanks for supporting Mondranoid!</p>

	</div>

</template>

<script>
export default {
	name: "CheckoutSuccess",
	props: ['order-id']
}
</script>
