<!-- Similar to pattern uploader component, only you can't upload new pattern
	svgs through this.  Instead, it only changes the appearance of the SVG locally
	to reflect changes to a pattern's color group.  Good for patterns that change
	appearance for specific levels. -->
<template>

	<div v-html="parsedSVG"/>

</template>

<script>
import axios from 'axios';
import HandleColorMixin from '../../mixins/patterns/handle-color';
export default {
	name: "PatternPreview",
	mixins: [ HandleColorMixin ],
	props: ['pattern', 'colors'],
	inject: ['PatternEventBus'],

	data: function() {
		return {
			parsedSVG: ''
		}
	},

	methods: {

		updateColors: function() {

			if (this.colors) {

				this.colors.forEach((color) => {

					this.handleColor(color, color.color);

				});

			}

		}

	},

	mounted: function() {

		if (this.pattern && this.pattern.filePath) {
			// Grab it using axios
			axios.get(this.pattern.filePath)
			.then((response) => {
				this.parsedSVG = response.data;
				this.updateColors();
			})
			.catch((error) => {

			})
		}

		this.PatternEventBus.$on('color', this.handleColor);

	}
}
</script>
