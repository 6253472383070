<!-- Shows a preview of a level before the user actually plays it.  Namely, it
	shows what patterns are going to be used, what all the chunks look like, and
	allows the user to do some basic color correction on patterns. -->
<template>

	<div class="preview" ref="preview">
		<div v-if="!ready"
			class="full-height-viewport full-width-viewport center absolute">
			<Loader />
		</div>

		<div v-else>

			<LevelHeader
				:level="level" />

			<VueP5
				@setup="setup"
				@draw="draw"
				@mousedragged="mouseDragged"
				@mousepressed="mousePressed"
				@mousewheel="mouseWheel"
				@touchstarted="mousePressed"
				@touchmoved="mouseDragged"/>

			<LevelPatterns
				context="preview"
				:addable="false"
				:level="level"
				:showRandom="false"/>

		</div>
	</div>

</template>

<script>
import Blobprint from '../leveleditor/blobprint'
import Grid from '../leveleditor/grid';
import Hammer from 'hammerjs/hammer.min';
import Loader from '../components/Loader.vue';
import LevelHeader from '../components/level/LevelHeader.vue';
import LevelPatterns from '../components/level/LevelPatterns.vue';
import LevelRequest from '../network/levels';
import MobileMixin from '../mixins/mobile';
import PatternRequest from '../network/patterns';
import Vue from 'vue';
import VueP5 from 'vue-p5';
export default {
	name: "Preview",
	props: ['levelID'],
	components: { LevelHeader, LevelPatterns, Loader, VueP5 },
	mixins: [ MobileMixin ],

	data: function() {

		return {
			blobprint: null,
			grid: null,
			offset: {
				x: 0,
				y: 0,
			},
			originalMouse: {
				x: 0,
				y: 0,
			},
			isMobile: false,
			midpoint: {
				x: 0,
				y: 0,
			},
			nonRandomID: this.levelID,
			randomEventBus: new Vue(),
			ready: false,
			zoom: this.isMobile ? 0.5 : 0.75
		}

	},

	computed: {

		// Retrieves level from the store.
		level: function() {

			for(let i = 0; i < this.$store.state.levels.length; i ++) {
				const level = this.$store.state.levels[i];
				if (level.id == this.nonRandomID) {
					return level;
				}
			}

		},

	},

	methods: {

		draw: function(sketch) {

			// Reset the background
			sketch.background(255, 255, 245);

			// Redraw the grid
			this.grid.draw(sketch, this.offset, this.zoom);

			// Draw the blobprint
			this.blobprint.draw(sketch, this.offset, this.zoom);

		},

		// Retrieves a random pattern to replace the pattern with the arg id
		getRandom: function(assignmentID) {

			const store = this.$store;
			const levelID = this.level.id;
			PatternRequest.getRandomPatterns(1, (patterns, colors) => {

				// Okay, this is a little tricky.  Go ahead and add the pattern and its
				// colors to the store though
				store.commit('addPatterns', patterns);
				store.commit('addPatternColors', colors);

				// Then, we're going to replace the old assignment with a new one made
				// up with this pattern
				const assignment = {
					id: assignmentID,
					levelID,
					patternID: patterns[0].id
				}
				store.commit('addPatternAssignments', [assignment]);
				store.commit('removeLevelColors', assignmentID);
			});
		},

		handlePinch: function(evt) {

			// Don't do anything if we're not on mobile
			if (this.isMobile) {
				return;
			}

			let newZoom = this.zoom;
			if (evt.scale > 1) {
				newZoom += (evt.scale - 1) / 10;
			} else {
				newZoom -= (1 - evt.scale) / 10;
			}

			if (newZoom >= 0.4 && newZoom <= 2.5) {
				this.zoom = newZoom;
			} else if (newZoom < 0.4) {
				this.zoom = 0.3999;
			} else {
				this.zoom = 2.5001;
			}

		},

		mouseDragged: function(sketch) {

			if (this.panning) {

				// Change the offset to the delta betwen the originalMouse x and y
				// values and current mouseX and mouseY values
				this.offset.x = (sketch.mouseX - this.midpoint.x) - this.originalMouse.x;
				this.offset.y = (sketch.mouseY - this.midpoint.y) - this.originalMouse.y;
			}

		},

		mousePressed: function(sketch) {

			// User, maybe, is attempting to pan the map.  Store the mouseX and
			// mouseY as coordinates relative to the midpoint with offsets factored
			// in
			this.originalMouse.x = sketch.mouseX - this.midpoint.x - this.offset.x;
			this.originalMouse.y = sketch.mouseY - this.midpoint.y - this.offset.y;

			this.panning = true;

		},

		mouseWheel: function(sketch, eventData) {

			const newZoom = this.zoom + (eventData.delta / 1000);

			if (newZoom >= 0.5 && newZoom <= 2) {
				this.zoom = newZoom;
			} else if (newZoom < 0.5) {
				this.zoom = 0.4999;
			} else {
				this.zoom = 2.0001;
			}

		},

		setup: function(sketch) {

			this.midpoint = { x: sketch.windowWidth / 2 , y: sketch.windowHeight / 2};
			const cellHeight = 40;
			const cellWidth = 80;

			// Go ahead and create a full-screen canvas
			sketch.createCanvas(sketch.windowWidth, sketch.windowHeight);

			// Get our blobprint going
			this.blobprint = new Blobprint({
				midpoint: this.midpoint,
				cellHeight,
				cellWidth,
				chunkHeight: 6,
				chunkWidth: 4,
				width: sketch.windowWidth,
				height: sketch.windowHeight,
				chunks: this.level.layout ? this.level.layout : [],
				preview: true,
			}, sketch);

			// And draw a simple grid
			this.grid = new Grid({
				midpoint: this.midpoint,
				width: sketch.windowWidth,
				height: sketch.windowHeight,
				cellWidth,
				cellHeight,
				cellAngle: 0
			});

		},

	},

	// Should be a levelID.  Let's use that to get the published level.
	mounted: function() {

		let store = this.$store;
		let _this = this;

		const difficulty = this.$route.query.difficulty ? this.$route.query.difficulty : 0;

		// Retrieve a specific level
		LevelRequest.getLevel(this.levelID, false, difficulty, (level, categories, patterns, colors, levelColors, patternAssignments) => {

			_this.ready = true;

			if (this.levelID == 'random') {
				this.nonRandomID = level.id;
			}

			store.commit('addLevels', [level]);
			store.commit('addLevelCategories', categories);
			store.commit('addPatterns', patterns);
			store.commit('addPatternColors', colors);
			store.commit('addLevelColors', levelColors);
			store.commit('addPatternAssignments', patternAssignments);
		});

		this.randomEventBus.$on('random', this.getRandom);

		const previewElement = this.$refs.preview;
		const hammer = new Hammer(previewElement);
		hammer.get('pinch').set({enable: true});
		hammer.on('pinch', this.handlePinch);


	},

	provide: function() {
		return {
			RandomEventBus: this.randomEventBus
		}
	},

}
</script>

<style lang="scss" scoped>
.preview {
	overflow: hidden;
	height: 100%;
	width: 100%;
	position: absolute;
}
</style>
