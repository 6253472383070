<!-- Pretty similar to patternswatch.  Basically, gives the basics of a level
	in a nice, compact package. -->
<template>
	<div>
		<h2>{{ level.name }}</h2>

		<router-link class="button block-button"
			:to="{ name: 'EditLevel', params: {levelID: level.id} }">
			Look At
		</router-link>
	</div>
</template>

<script>
export default {
	name: "LevelPreview",
	props: ['level']
}
</script>
