<!-- A component for displaying the estimated cost of a poster and for directing
	the user to a checkout page. -->
<template>

	<div class="thick-border m-2 p-1">

		<h2>${{ cost }}</h2>

		<button class="btn-yellow" @click="$emit('confirm', cost)">
			Checkout
		</button>

	</div>

</template>

<script>
export default {
	name: "CostAndConfirm",
	props: ['material', 'size', 'units'],

	computed: {

		// Given props, will come up with the price of the poster
		cost: function() {

			switch(this.size) {

				case '8x10':

					if (this.units != 'in') {
						return '?'
					}

					if (this.material == 'matte') {
						return '19.99';
					}

					if (this.material == 'glossy') {
						return '24.99';
					}

					break;

				case '18x24':

					if (this.units != 'in') {
						return '?'
					}

					if (this.material == 'matte') {
						return '29.99';
					}

					if (this.material == 'glossy') {
						return '34.99';
					}

					break;

				case '24x36':

					if (this.units != 'in') {
						return '?';
					}

					if (this.material == 'matte') {
						return '39.99';
					}

					if (this.material == 'glossy') {
						return '44.99';
					}

					break;

			}
		}

	}
}
</script>

<style lang="scss" scoped>
div {
	position: absolute;
	width: 20%;
	display: flex;
	bottom: 0;
	left: 40%;
	background-color: #FFF;

	h2 {
		margin: 0;
		margin-right: 10px;
	}

	button {
		flex-grow: 1;
		margin: 0;
	}
}

@media(max-width: 768px) {
	div {
		left: 0;
		width: calc(100% - 3.2rem);
	}
}
</style>
