//
// Portion of package that manages the pattern category part of the store.
/////

export default {

	addPatternCategories: (state, payload) => {

		payload.forEach((category) => {

			for(let i = 0; i < state.patternCategories.length; i ++) {
				if(state.patternCategories[i].id == category.id) {
					state.patternCategories.splice(i, 1, category);
					return;
				}
			}

			state.patternCategories.push(category);

		});

	},

	removePatternCategory: (state, id) => {

		for(let i = 0; i < state.patternCategories.length; i ++) {
			if (state.patternCategories[i].id == id) {
				state.patternCategories.splice(i, 1);
				return;
			}
		}

	}
}
